import {
  EventVisibilityOption,
  TimelineFilterType,
  TimelineLessonReviewFocus,
  TimelineOutcome,
  TimelineViewOption,
} from '@hoot-reading/hoot-core/dist/enums/student-timeline';
import { Stack } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import _ from 'lodash';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useGetStudentTimeline from '@hoot/hooks/api/my-student/getStudentTimeline';
import { StudentProfileV2Response } from '@hoot/hooks/api/user/useGetStudentProfileV2';
import { routesDictionary } from '@hoot/routes/routesDictionary';
import ViewStateIllustration, { IllustrationEnum } from '@hoot/ui/components/v2/ViewStateIllustration';
import { Button } from '@hoot/ui/components/v2/core/Button';
import Card from '@hoot/ui/components/v2/core/Card';
import TimelineGrid from '@hoot/ui/pages/v2/teacher/my-students/student-details/timeline/TimelineGrid';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';
import { useStudentDetailsContext } from '../StudentDetailsContextProvider';

const SNAPSHOT_TIMELINE_OUTCOMES_TO_SHOW = [
  TimelineOutcome.InstructionRequired,
  TimelineOutcome.Mastered,
  TimelineOutcome.ReviewRequired,
  TimelineLessonReviewFocus.LessonPrimaryFocus,
  TimelineLessonReviewFocus.LessonSecondaryFocus,
  TimelineLessonReviewFocus.FormativeAssessment,
];

interface Props {
  student?: StudentProfileV2Response;
}

const TimelineSnapshotCard = (props: Props) => {
  const { student } = props;
  const [expanded, setExpanded] = useState(false);
  const { studentProfileSnippet } = useStudentDetailsContext();
  const navigate = useNavigate();

  const showTimeline = studentProfileSnippet.showTimeLine;

  const { data: timelineData, isFetching } = useGetStudentTimeline(
    student?.id ?? '',
    {
      filterType: TimelineFilterType.PeriodBased,
      viewOption: TimelineViewOption.MostRecentHRAUntilNow,
      eventVisibility: [EventVisibilityOption.Lessons, EventVisibilityOption.Hra, EventVisibilityOption.Pma],
    },
    { enabled: !!student?.id },
  );

  const shouldHideColumns = !expanded && timelineData && timelineData.headerRow.length > 7;

  const modulesWithoutEmptyUnits = timelineData?.modules.map((module) => {
    module.units = module.units.filter((unit) =>
      unit.cells.some((cell) => cell?.outcome && SNAPSHOT_TIMELINE_OUTCOMES_TO_SHOW.includes(cell.outcome)),
    );
    return module;
  });

  const populatedModules = modulesWithoutEmptyUnits?.filter((module) => module.units.length > 0) ?? [];

  const filteredData = shouldHideColumns
    ? {
        headerRow: [...timelineData.headerRow.slice(0, 2), null, ...timelineData.headerRow.slice(-5)],
        modules: _.cloneDeep(populatedModules).map((module) => {
          module.units = module.units.map((unit) => {
            unit.cells = [...unit.cells.slice(0, 2), null, ...unit.cells.slice(-5)];
            return unit;
          });
          return module;
        }),
      }
    : {
        // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
        headerRow: timelineData?.headerRow!,
        modules: populatedModules,
      };

  const onOpenTimelineClick = () => {
    if (!!student) {
      navigate(routesDictionary.myStudents.details.timeline.url(student.id));
    }
  };

  if (!student || isFetching) {
    return (
      <Card title="" isLoading={isFetching}>
        <Stack direction="row" alignItems="flex-start" justifyContent="space-between">
          <Skeleton variant="text" sx={{ width: '70px', height: '40px', ...hootTokens.text.bodylarge }} />
          <Skeleton variant="rounded" sx={{ width: '200px', height: '50px', ...hootTokens.text.bodylarge }} />
        </Stack>
        <Stack alignItems="flex-start" gap={2} py={2}>
          <Skeleton variant="rounded" sx={{ width: '80%', height: '100px', ...hootTokens.text.bodylarge }} />
          <Skeleton variant="rounded" sx={{ width: '80%', height: '100px', ...hootTokens.text.bodylarge }} />
        </Stack>
      </Card>
    );
  }

  return (
    <Card
      title={
        <Stack direction="row" alignItems="flex-start" justifyContent="space-between">
          <span>Student Timeline Snapshot</span>
          {showTimeline ? (
            <Button color="neutral.195" size="small" variant="contained" onClick={onOpenTimelineClick}>
              Open Timeline
            </Button>
          ) : null}
        </Stack>
      }
    >
      <Stack sx={{ overflowX: 'auto' }}>
        {filteredData.modules.length > 0 ? (
          <TimelineGrid data={filteredData} studentProfileId={student.id!} onExpand={() => setExpanded(true)} />
        ) : (
          <Stack direction="row" justifyContent="center" style={{ backgroundColor: hootTokens.palette.primary['195'] }}>
            <ViewStateIllustration
              illustration={IllustrationEnum.EmptyState}
              title="No Data... Yet"
              subtitle="This Snapshot will populate once a
Hoot Reading Assessment has been completed."
            />
          </Stack>
        )}
      </Stack>
    </Card>
  );
};

export default TimelineSnapshotCard;
