import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Fade, IconButton, LinearProgress, Stack } from '@mui/material';
import { capitalCase } from 'change-case';
import { useEffect, useState } from 'react';
import { BookSearch } from '@hoot/events/interfaces/book-search';
import useGetInstructionalUnitsForLessonPlan from '@hoot/hooks/api/lesson-reviews/useGetInstructionalUnitsForLessonPlan';
import useCheckStudentInstructionalLibrary from '@hoot/hooks/api/library/useCheckStudentInstructionalLibrary';
import useSearchLibrary, { LibraryOrderByEnum, QueryLibraryV2 } from '@hoot/hooks/api/library/useSearchLibrary';
import { InstructionalFocus } from '@hoot/models/api/enums/instructional-focus';
import { OrderBy } from '@hoot/models/api/enums/queryEnums';
import { ShelfType } from '@hoot/models/api/enums/shelf-type-enum';
import { BookType } from '@hoot/models/api/library';
import { GenericPaginatedResponse } from '@hoot/models/api/pagination';
import { error } from '@hoot/redux/reducers/alertSlice';
import { DEFAULT_PAGE, PaginationMode } from '@hoot/redux/reducers/librarySlice';
import ViewState, { ViewStateEnum } from '@hoot/ui/components/v2/ViewState';
import ViewStateIllustration, { IllustrationEnum } from '@hoot/ui/components/v2/ViewStateIllustration';
import { Button } from '@hoot/ui/components/v2/core/Button';
import { Chip } from '@hoot/ui/components/v2/core/Chip';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';
import { Icon } from '@hoot/ui/components/v2/core/Icon';
import SearchTextField, { SearchTextFieldProps } from '@hoot/ui/components/v2/core/SearchTextField';
import { HeaderData, TableV2 } from '@hoot/ui/components/v2/core/Table';
import TeacherLibraryFilterDrawer from '@hoot/ui/pages/v2/teacher/library/TeacherLibraryFilterDrawer';
import { useLessonReviewWizardContext } from '../LessonReviewContextProvider';
import { SelectedUnit } from '../steps/LessonReviewLessonPlanStep';
import { BookSelectorTableRow } from './BookPickerModal';

export interface LessonPlanBookPickerProps {
  show: boolean;
  onDismiss: () => void;
  onApply: (selectedBooks: BookSearch[]) => void;
  isLoading?: boolean;
  existingBookIds: string[];
  studentProfileId?: string;
  studentName: string;
  selectedUnit: SelectedUnit;
}

const PAGE_SIZE = 10;

const filterCount = (libraryQuery: QueryLibraryV2 | null): number => {
  if (!libraryQuery) {
    return 0;
  }
  const nonFilterKeys = ['page', 'pageSize', 'studentProfileId', 'teacherProfileId', 'shelfType', 'orderBy', 'orderSort', 'title'];
  const filterKeysToIgnore = [
    'subject',
    'language',
    'bookCollectionIds',
    'isInstructionalLibrary',
    'excludedResourceTypes',
    'instructionalFocusIds',
    'instructionalUnitIds',
    'resourceType',
  ]; //subject and language are default queries and always applied
  const libraryFilterQueries = Object.entries(libraryQuery).filter(([key, _value]) => !nonFilterKeys.includes(key));

  return libraryFilterQueries.filter(([key, value]) => !filterKeysToIgnore.includes(key) && !!value).length;
};

const LessonPlanBookPicker = (props: LessonPlanBookPickerProps) => {
  const { show, onDismiss, onApply, isLoading = false, existingBookIds, studentProfileId, studentName, selectedUnit } = props;

  const { instructionalFocusState } = useLessonReviewWizardContext();
  const lessonReviewBooks = [...(instructionalFocusState?.primaryFocusBooks ?? []), ...(instructionalFocusState?.secondaryFocusBooks ?? [])];

  const moduleName = selectedUnit.moduleName;

  // If the selected unit is Language Comprehension, Reading Comprehension, or Fluency, show the Filter button, otherwise do not
  const showFilterButton = !!selectedUnit.focusId;

  const libraryFilters: QueryLibraryV2 = {
    shelfType: ShelfType.AllBooks,
    page: DEFAULT_PAGE,
    pageSize: PAGE_SIZE,
    title: '',
    studentProfileId: studentProfileId,
    isInstructionalLibrary: true,
    instructionalUnitIds: selectedUnit.unitId ? [selectedUnit.unitId] : undefined,
    instructionalFocusIds: selectedUnit.focusId ? [selectedUnit.focusId, InstructionalFocus.FormativeAssessmentTextReading] : undefined,
    resourceType: showFilterButton ? BookType.Instruction : undefined,
    hiLo: false,
  };

  const [showFilterDialog, setShowFilterDialog] = useState<boolean>(false);
  const [libraryQuery, setLibraryQuery] = useState<QueryLibraryV2>(libraryFilters);
  const numberOfFilters = filterCount(libraryQuery);

  const { data: doesStudentHaveInstructionalLibrary = true } = useCheckStudentInstructionalLibrary(studentProfileId ?? '', {
    onSuccess: (data) => {
      setLibraryQuery({
        ...libraryFilters,
        isInstructionalLibrary: data,
      });
    },
  });

  const { data: instructionalUnitsData } = useGetInstructionalUnitsForLessonPlan(
    { instructionalUnitIds: libraryQuery.instructionalUnitIds ?? [] },
    {
      enabled: !!libraryQuery?.instructionalUnitIds,
    },
  );

  const headers: HeaderData<BookSelectorTableRow>[] = [
    { name: 'Cover', property: 'cover', isSortable: false },
    { name: 'Book Title', property: 'title', isSortable: true },
    { name: 'Book Level', property: 'bookLevel', isSortable: true },
    { name: 'Resource Progress', property: 'resourceProgress', isSortable: false },
    { name: 'Resource State', property: 'resourceState', isSortable: false },
  ];

  const applyFilters = (updatedQuery: Partial<QueryLibraryV2>) => {
    const newQuery: QueryLibraryV2 = { ...libraryFilters, ...updatedQuery };
    setLibraryQuery(newQuery);
  };

  const clearFilters = () => {
    setLibraryQuery({
      ...libraryFilters,
      title: libraryQuery.title,
      page: 1,
      isInstructionalLibrary: libraryQuery.isInstructionalLibrary,
      instructionalUnitIds: libraryQuery.instructionalUnitIds,
    });
  };

  const clearInstructionalLibraryFilter = () => {
    setLibraryQuery({
      ...libraryQuery,
      title: libraryQuery.title,
      page: 1,
      isInstructionalLibrary: undefined,
    });
  };

  const FilterChips = () => {
    return (
      <Stack direction="row" gap={2} flexWrap={'wrap'}>
        {moduleName ? <Chip label={moduleName} /> : null}
        {instructionalUnitsData?.instructionalUnits.map((unit) => (
          <Chip key={unit.id} label={unit.name} icon={<Icon name="scope_and_sequence" stroke={unit.primaryColor} fill={unit.secondaryColor} />} />
        ))}
        {numberOfFilters > 0 && showFilterButton ? (
          <Chip
            label={`Other Filters: ${numberOfFilters}`}
            onClick={() => setShowFilterDialog(true)}
            onDelete={clearFilters}
            deleteIcon={<Icon name="close" />}
          />
        ) : null}
        {libraryQuery.isInstructionalLibrary ? (
          <Chip label={'Only show books from Instructional Library'} onDelete={clearInstructionalLibraryFilter} deleteIcon={<Icon name="close" />} />
        ) : null}
      </Stack>
    );
  };

  const [viewState, setViewState] = useState<ViewStateEnum>(ViewStateEnum.EmptyState);
  const [searchResults, setSearchResults] = useState<GenericPaginatedResponse<BookSearch>>({
    data: [],
    count: 0,
    page: 1,
    pageSize: PAGE_SIZE,
  });
  const [selectedBooks, setSelectedBooks] = useState<BookSearch[]>([]);
  const [searchInput, setSearchInput] = useState<string>('');

  const searchRequest = useSearchLibrary(libraryQuery, {
    retry: false,
    onSuccess: (searchData) => {
      // We need to check if the books resrouce progress was updated during this review
      setSearchResults({
        ...searchData,
        data: searchData.data.map((d) => ({
          ...d,
          resourceProgress: lessonReviewBooks.find((book) => book.bookId === d.id && book.progress)?.progress ?? d.resourceProgress,
        })),
      });
      setViewState(searchData.count === 0 ? ViewStateEnum.NoResults : ViewStateEnum.Results);
    },
    onError: (err) => {
      console.error(err);
      error(`An error occurred while loading books.`);
      setViewState(ViewStateEnum.Error);
    },
  });

  // When we show this modal, reset all values.
  useEffect(() => {
    if (show) {
      setSelectedBooks([]);
    }
  }, [show]);

  const handleToggleOption = (bookId: string) => {
    if (existingBookIds.includes(bookId)) {
      return;
    }

    if (selectedBooks.some((book) => book.id === bookId)) {
      setSelectedBooks((prevSelectedBooks) => prevSelectedBooks.filter((book) => book.id !== bookId));
    } else {
      const selectedBook = searchResults.data.find((book) => book.id === bookId);
      setSelectedBooks((prevSelectedBooks) => [...prevSelectedBooks, selectedBook!]);
    }
  };

  const onSearchInputChanged: SearchTextFieldProps['onSearchInputChanged'] = (text) => {
    setSearchInput(text);
  };

  const onSearchInputDebounced: SearchTextFieldProps['onSearchInputDebounced'] = (text) => {
    if (searchResults.data.length === 0 && !text) {
      setViewState(ViewStateEnum.EmptyState);
    }
    setLibraryQuery((prevState) => {
      return {
        ...prevState,
        title: text,
        page: 1,
      };
    });
  };

  const onClearSearchInput = () => {
    setSearchInput('');
    setSelectedBooks([]);
    setViewState(ViewStateEnum.EmptyState);
    setLibraryQuery((prevState) => {
      return {
        ...prevState,
        title: '',
        page: 1,
      };
    });
  };

  const onClickApply = () => {
    onApply(selectedBooks!);
    onClearSearchInput();
  };

  const onClickDismiss = () => {
    onDismiss();
    onClearSearchInput();
  };

  const handleSortBy = (column: keyof BookSelectorTableRow) => {
    function orderColumn() {
      switch (column) {
        case 'title':
          return LibraryOrderByEnum.Title;
        case 'bookLevel':
          return LibraryOrderByEnum.BookLevel;
        case 'bookNumber':
          return LibraryOrderByEnum.BookNumber;
        default:
          return LibraryOrderByEnum.Title;
      }
    }

    setLibraryQuery((prev) => ({
      ...prev,
      orderColumn: orderColumn(),
      orderBy: prev.orderBy === OrderBy.Asc ? OrderBy.Desc : OrderBy.Asc,
    }));
  };

  function sortBy(): keyof BookSelectorTableRow {
    switch (libraryQuery.orderColumn) {
      case LibraryOrderByEnum.Title:
        return 'title';
      case LibraryOrderByEnum.BookLevel:
        return 'bookLevel';
      case LibraryOrderByEnum.BookNumber:
        return 'bookNumber';
      default:
        return 'title';
    }
  }

  const tableData: BookSelectorTableRow[] = searchResults.data.map((d) => ({
    cover: (
      <img
        alt={d.title}
        src={d?.coverUrl ?? undefined}
        style={{
          width: '40px',
          maxWidth: '40px',
          objectFit: 'contain',
        }}
      />
    ),
    id: d.id,
    title: d.title,
    bookLevel: d.readingLevel,
    bookNumber: d.sequenceNumber,
    instructionalUnit: d.units?.map((unit) => unit.name).join(', ') ?? '',
    filterLevelPrioritizedSkill: d.focuses?.map((focus) => focus.name).join(', ') ?? '',
    resourceProgress: capitalCase(d.resourceProgress ?? ''),
    resourceState: capitalCase(d.resourceState ?? ''),
    selected: existingBookIds.includes(d.id) || selectedBooks.some((book) => book.id === d.id),
    disabled: existingBookIds.includes(d.id),
  }));

  return (
    <Dialog open={show} onClose={onClickDismiss} PaperProps={{ sx: { height: '900px' } }} maxWidth="md" fullWidth>
      <Box height="2px">
        <Fade in={searchRequest.isFetching}>
          <LinearProgress color="secondary" />
        </Fade>
      </Box>
      <DialogTitle sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <HootTypography variant="titlelarge" isPII={true}>
          Select Book - {studentName}
        </HootTypography>
        <IconButton onClick={onClickDismiss}>
          <Icon name="close" />
        </IconButton>
      </DialogTitle>
      <Stack direction="column" gap={2} padding={(theme) => theme.spacing(0, 2, 2, 2)}>
        <HootTypography isPII={true} variant="titlemedium">
          Select a book
        </HootTypography>
        <HootTypography isPII={true} variant="bodymedium">
          Based on your selection of <strong>{selectedUnit.unitName}</strong> – the following books are recommended for{' '}
          <strong>{studentName}'s</strong> lesson plan.
        </HootTypography>
        <Stack direction="row" gap={2}>
          <SearchTextField
            label="Book Title"
            searchInput={searchInput}
            onSearchInputChanged={onSearchInputChanged}
            onClearButtonClicked={onClearSearchInput}
            onSearchInputDebounced={onSearchInputDebounced}
            inputRef={(input) => input && input.focus()}
            clearable
            required
            placeholder="Search"
          />
          {showFilterButton ? (
            <Button variant="contained" onClick={() => setShowFilterDialog(true)} startIcon={<Icon name="filter" color="primary.200" />}>
              Filter
            </Button>
          ) : null}
        </Stack>
        <Stack direction="column" gap={'4px'}>
          <HootTypography isPII={false} variant="labelsmall">
            Active Filters
          </HootTypography>
          <FilterChips />
        </Stack>
      </Stack>
      <DialogContent sx={{ paddingBottom: 1 }}>
        <ViewState
          state={viewState}
          EmptyStateIllustrationProps={{
            title: 'Search for a Book',
            subtitle: 'To apply it to your lesson',
            illustration: IllustrationEnum.NoResults,
          }}
        >
          <TableV2
            isPaginated={true}
            allowRowsPerPage={true}
            rowsPerPage={libraryQuery.pageSize}
            count={searchResults.count}
            page={libraryQuery.page - 1}
            data={tableData}
            headers={headers}
            isSortable
            onSortBy={handleSortBy}
            sortOrder={libraryQuery.orderBy ?? OrderBy.Asc}
            sortBy={sortBy()}
            onPageChange={(_event, page) => {
              setLibraryQuery((prevState) => ({ ...prevState, page: page + 1 }));
            }}
            onRowsPerPageChange={(event) => {
              setLibraryQuery((prevState) => ({ ...prevState, page: 1, pageSize: +event.target.value }));
            }}
            emptyViewState={<ViewStateIllustration illustration={IllustrationEnum.EmptyState} />}
            isSelectable
            isRowSelectable
            onSelect={(book) => handleToggleOption(book.id)}
          />
        </ViewState>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClickDismiss} variant="outlined">
          Cancel
        </Button>
        <Button onClick={onClickApply} variant="contained" disabled={!selectedBooks.length} isLoading={isLoading}>
          Apply
        </Button>
      </DialogActions>
      <TeacherLibraryFilterDrawer
        isOpen={showFilterDialog}
        onCloseFilterDrawer={() => setShowFilterDialog(false)}
        onLibraryQueryChanged={applyFilters}
        query={libraryQuery}
        doesStudentHaveInstructionalLibrary={doesStudentHaveInstructionalLibrary}
        paginationMode={PaginationMode.Replace}
        isInLessonPlan={true}
        studentProfileId={studentProfileId}
      />
    </Dialog>
  );
};

export default LessonPlanBookPicker;
