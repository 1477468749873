import { DistrictRepPermissionLevel } from '@hoot-reading/hoot-core/dist/enums/hfs/district-rep-access-level.enum';
import axios from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '@hoot/config';
import { QueryKey } from '../queryKeys';

export interface GetDistrictRepPermission {
  permissions: DistrictRepPermissionLevel[];
}
async function getDistrictRepPermissions(userId: string): Promise<DistrictRepPermissionLevel[]> {
  const { data } = await axios.get<GetDistrictRepPermission>(`${config.apiUrl}/public/district-representative/${userId}/permissions`);
  return data.permissions;
}

export default function useGetDistrictRepPermissions(
  userId: string,
  options?: Omit<UseQueryOptions<DistrictRepPermissionLevel[]>, 'queryKey' | 'queryFn'>,
) {
  return useQuery([QueryKey.GetDistrictRepPermissions, userId], () => getDistrictRepPermissions(userId), options);
}
