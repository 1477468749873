import { EnrolmentRosterStatus } from '@hoot-reading/hoot-core/dist/enums/hfs/enrolment-roster-status.enum';
import { EnrolmentStatus } from '@hoot-reading/hoot-core/dist/enums/hfs/enrolment-status.enum';
import { EnrolmentType } from '@hoot-reading/hoot-core/dist/enums/hfs/enrolment-type.enum';
import { ProductStatus } from '@hoot-reading/hoot-core/dist/enums/hfs/product-status.enum';
import axios, { AxiosError } from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '@hoot/config';
import { QueryKey } from '../queryKeys';

export interface DistrictRepEnrolmentProductResponse {
  id: string;
  friendlyId: string;
  status: ProductStatus;
}

export interface DistrictRepEnrolmentResponse {
  id: string;
  status: EnrolmentStatus;
  rosterStatus: EnrolmentRosterStatus;
  numberOfStudentsAllowed: number;
  numberOfStudentsRegistered: number;
  type: EnrolmentType;
  startDate: number;
  endDate: number;
  product: DistrictRepEnrolmentProductResponse;
}

export default function useDistrictRepGetEnrolment(
  enrolmentId: string,
  options?: Omit<UseQueryOptions<DistrictRepEnrolmentResponse, AxiosError>, 'queryKey' | 'queryFn'>,
) {
  return useQuery(
    [QueryKey.GetDistrictRepEnrolment, enrolmentId],
    async () => {
      return (await axios.get<DistrictRepEnrolmentResponse>(`${config.apiUrl}/public/district-representative/enrolment/${enrolmentId}`)).data;
    },
    options,
  );
}
