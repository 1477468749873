import { Accordion, AccordionDetails, AccordionSummary, Box, Dialog, DialogContent, Skeleton, Stack } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useEffect, useState } from 'react';
import { BookResponse } from '@hoot/events/interfaces/book-response';
import { BookSearch } from '@hoot/events/interfaces/book-search';
import useStudentLibraryGetBook from '@hoot/hooks/api/student-library/useStudentLibraryGetBook';
import useToggleStudentFavouriteBook from '@hoot/hooks/api/student-library/useToggleStudentFavouriteBook';
import useProfile from '@hoot/hooks/useProfile';
import { createFlashMessage } from '@hoot/redux/reducers/flashMessageSlice';
import { useAppDispatch } from '@hoot/redux/store';
import AsyncImage from '@hoot/ui/components/v2/AsyncImage';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';
import { Icon } from '@hoot/ui/components/v2/core/Icon';
import IconButton from '@hoot/ui/components/v2/core/IconButton';
import ReadOnlyTextField from '@hoot/ui/components/v2/core/ReadOnlyTextField';
import { LottieFile } from '@hoot/ui/components/v2/lottie/Lottie';
import LottieButton from '@hoot/ui/components/v2/lottie/LottieButton';
import LottieToggleButton from '@hoot/ui/components/v2/lottie/LottieToggleButton';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';
import { preloadImageQueue } from '../../../../../utils/preloadImage';

export interface StudentBookPreviewModalProps {
  show: boolean;
  book: BookSearch | undefined;
  onNavigateToSeries: (seriesId: string) => void;
  onDismiss: (shouldInvalidateFavs: boolean) => void;
  onDismissed: () => void;
  openBook: (book: BookResponse) => void;
}

const StudentBookPreviewModal = (props: StudentBookPreviewModalProps) => {
  const { show, book, onNavigateToSeries, onDismiss, onDismissed, openBook } = props;

  const [showBookDetails, setShowBookDetails] = useState(false);
  const [bookDetails, setBookDetails] = useState<BookResponse>();
  const [wasFavToggled, setWasFavToggled] = useState(false);

  const profileId = useProfile().profile!.id;

  const dispatch = useAppDispatch();

  // Reset this value whenever we (re)show this dialog.
  useEffect(() => {
    if (show) {
      setWasFavToggled(false);
    }
  }, [show]);

  const getBookRequest = useStudentLibraryGetBook(book?.id ?? '', {
    enabled: !!book?.id,
    retry: false,
    onSuccess: (response) => {
      preloadImageQueue(response.pages.map((p) => p.url));
      setBookDetails(response);
    },
    onError: (err) => {
      dispatch(
        createFlashMessage({
          variant: 'error',
          message: err?.response?.data?.message ?? 'An error occurred loading book.',
        }),
      );
    },
  });
  const isFavourite = !!bookDetails?.shelf[profileId];

  const toggleStudentFavouriteBookRequest = useToggleStudentFavouriteBook({
    onSuccess: (response) => {
      setWasFavToggled(true);
      setBookDetails(response);
      dispatch(
        createFlashMessage({
          message: `${response.title} ${response.shelf[profileId] ? 'has been added to your favorites' : 'has been removed from your favorites'}.`,
        }),
      );
    },
    onError: (err) => {
      console.log('err', err.response);
      dispatch(
        createFlashMessage({
          variant: 'error',
          message: err?.response?.data?.message ?? 'An error occurred toggling favourite book.',
        }),
      );
    },
  });

  const toggleShowBookDetails = () => setShowBookDetails((prev) => !prev);

  const toggleFavourite = () => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
    toggleStudentFavouriteBookRequest.mutate({ bookId: book?.id! });
  };

  const handleOpenBook = () => {
    if (bookDetails) {
      openBook(bookDetails);
    }
  };

  const _onDismiss = () => onDismiss(wasFavToggled);

  const GridInfoField = (props: { label: string; value: string | number | undefined | null; seriesId?: string }) => {
    const { label, value, seriesId } = props;

    const handleSeriesButtonClicked = () => {
      onNavigateToSeries(seriesId!);
      _onDismiss();
    };

    if (!getBookRequest?.isFetching && !value) {
      return null;
    }
    return (
      <Grid size={{ xs: 12, sm: 6 }}>
        {getBookRequest?.isFetching ? (
          <Skeleton variant="rectangular" height="58px" width="100%" />
        ) : (
          <ReadOnlyTextField
            label={label}
            body={value?.toString() ?? ''}
            filledColour="neutral.190"
            invertLabelStyles
            endAdornment={
              seriesId ? (
                <IconButton onClick={handleSeriesButtonClicked}>
                  <Icon name="arrow_icon" />
                </IconButton>
              ) : undefined
            }
          />
        )}
      </Grid>
    );
  };

  return (
    <Dialog
      open={show}
      onClose={_onDismiss}
      fullWidth
      TransitionProps={{ onExited: onDismissed }}
      PaperProps={{
        sx: {
          borderRadius: '8px',
          maxWidth: '700px',
        },
      }}
    >
      <Stack
        direction="row"
        sx={{
          pl: 5,
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <HootTypography
          isPII={false}
          variant="displaysmall"
          sx={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {book?.title}
        </HootTypography>
        <LottieButton
          sx={{ width: '104px', height: '104px' }}
          tooltipLabel="Cancel"
          lottieFile={LottieFile.ErrorCross}
          aria-label="Cancel"
          variant="transparent"
          onClick={_onDismiss}
        />
      </Stack>
      <DialogContent sx={{ px: 5, pb: '2px' }}>
        <Stack direction="row" alignItems="center" gap={5} justifyContent="center">
          <AsyncImage
            draggable={false}
            style={{
              objectFit: 'cover',
              width: '216px',
              minHeight: '216px',
              borderRadius: '8px',
            }}
            src={book?.coverUrl ?? undefined}
            alt={book?.title ?? 'Book image'}
          />
          <Stack direction="row" justifyContent="center" gap={2}>
            <LottieToggleButton
              tooltipLabel={isFavourite ? 'Remove from favorites' : 'Add to favorites'}
              lottieFile={LottieFile.Heart}
              aria-label={isFavourite ? 'Remove from favorites' : 'Add to favorites'}
              isSelected={isFavourite}
              onClick={toggleFavourite}
              sx={{ width: '104px', height: '104px' }}
            />
            <LottieButton
              tooltipLabel="Open book"
              lottieFile={LottieFile.Play}
              aria-label="Open book"
              onClick={handleOpenBook}
              sx={{ width: '248px', height: '104px' }}
            />
          </Stack>
        </Stack>
        <Accordion
          sx={{
            py: 5,
            px: 0,
            margin: `0 !important`,
            minHeight: `0 !important`,
            boxShadow: 'unset',
            '&::before': {
              display: 'none',
            },
          }}
          expanded={showBookDetails}
          onChange={toggleShowBookDetails}
        >
          <AccordionSummary
            sx={{
              ...hootTokens.text.headlinesmall,
              '& .MuiAccordionSummary-content': {
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                gap: 2,
              },
            }}
          >
            <Box width={44} height={44} display="flex" justifyContent="center" alignItems="center" marginLeft="-11px">
              {showBookDetails ? (
                <Icon name="collapse_filled" color={'primary.0'} sx={{ width: 18, height: 18 }} />
              ) : (
                <Icon name={'solid_add_rectangle'} color={'primary.0'} sx={{ width: 20, height: 20 }} />
              )}
            </Box>
            <span>{showBookDetails ? 'Less' : 'More'}</span>
          </AccordionSummary>
          <AccordionDetails sx={{ mt: 2 }}>
            <Grid container spacing={2}>
              <GridInfoField label="Book Level" value={bookDetails?.readingLevel?.name} />
              <GridInfoField label="Minimum Age" value={bookDetails?.minAgeLevel?.toString()} />
              <GridInfoField label="Content Tags" value={bookDetails?.bookContentTags?.join(', ')} />
              <GridInfoField label="Series" value={bookDetails?.series ?? ''} seriesId={bookDetails?.seriesId ?? ''} />
            </Grid>
          </AccordionDetails>
        </Accordion>
      </DialogContent>
    </Dialog>
  );
};

export default StudentBookPreviewModal;
