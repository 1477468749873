import { PMAUnitIdentifier } from '@hoot-reading/hoot-core/dist/enums/progress-monitoring-assessment';
import { Module_4_GenericAnswer } from '@hoot-reading/hoot-core/dist/interfaces/progress-monitoring-assessment/pma-submission-data.interface';
import { CircularProgress, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { useDispatch, useSelector } from 'react-redux';
import { UnitIdentifierHRAV2 } from '@hoot/models/api/enums/hoot-reading-assessment';
import useGetBookComprehensionQuestions, {
  PMABookComprehensionQuestionsResponse,
} from '../../../../../../hooks/api/pma/useGetBookComprehensionQuestions';
import { createFlashMessage } from '../../../../../../redux/reducers/flashMessageSlice';
import { RootState } from '../../../../../../redux/store';
import { RadioOption } from '../../../../../pages/v2/teacher/lesson/progress-monitoring-assessment/interface';
import { hootTokens } from '../../../../../theme/v2/tokens';
import HootTypography from '../../../core/HootTypography';
import { QuestionnaireContainer } from '../QuestionnaireContainer';
import { QuestionnaireFooter } from '../QuestionnaireFooter';
import { LeaveCurrentUnitDialog } from '../dialogs/LeaveCurrentUnitDialog';

interface Props {
  title: string;
  instruction: string;
  unitIdentifier: UnitIdentifierHRAV2 | PMAUnitIdentifier;
  unitTitle: string;
  questionnaire: Layout6Questionnaire[];
  isNextEnabled: boolean;
  isPrevEnabled: boolean;
  isSubmitting: boolean;
  onPreviousUnitClick: () => void;
  onSubmit: () => void;
  onNext?: () => void;
  confirmPrevUnitClick: () => void;
  leaveDialog: boolean;
  setLeaveDialog: (val: boolean) => void;
  value: Module_4_GenericAnswer[];
  onChange: (val: Module_4_GenericAnswer[]) => void;
  isUnitQuestionnaireDirty: boolean;
  showQuestionnaireDefault?: boolean;
  onExitAssessment: () => void;
}

export interface Layout6Questionnaire {
  title: 'Text-Specific Knowledge' | 'Text Structure' | 'Sentence Analysis' | 'Verbal Reasoning' | 'Vocabulary (Overall)';
  subTitle?: string;
  options: RadioOption[];
}

export function QuestionnaireLayout6(props: Props) {
  const { unitIdentifier } = props;

  const optionFlatMapped = props.questionnaire.flatMap((q) => q.options);

  const [showQuestionnaire, setShowQuestionnaire] = useState(props.showQuestionnaireDefault);

  const dispatch = useDispatch();

  const onPreviousUnitClick = () => {
    setShowQuestionnaire(false);
  };

  const handleOptionChange = (section: string) => (_event: React.ChangeEvent<HTMLInputElement>, value: string) => {
    const optionSelected = optionFlatMapped.find((o) => o.text === value);
    if (!optionSelected) {
      dispatch(createFlashMessage({ message: 'There was a problem with processing your selection.' }));
    } else {
      const updatedAnswer = props.value.map((a) =>
        a.section !== section
          ? a
          : {
              ...a,
              answer: optionSelected.text,
              answerValue: Number.parseInt(optionSelected.val),
            },
      );
      props.onChange(updatedAnswer);
    }
  };

  const onContinueClicked = () => {
    setShowQuestionnaire(true);
  };

  const handleSubmit = () => {
    if (!props.onNext || props.isUnitQuestionnaireDirty) {
      props.onSubmit();
    } else {
      if (props.onNext) {
        props.onNext();
      }
    }
  };

  const bookId = useSelector((state: RootState) => state.readers.inLessonReader.book?.id);

  const bookComprehensionQuestions = useGetBookComprehensionQuestions(bookId!, { enabled: !!bookId });

  if (!bookComprehensionQuestions.data) {
    return <CircularProgress />;
  }

  const disableSubmissionBtn = props.value.some((a) => !a.answer) || props.isSubmitting;
  const primaryButtonLabel = !disableSubmissionBtn && !props.isUnitQuestionnaireDirty ? 'Continue' : undefined;

  return (
    <QuestionnaireContainer
      unitIdentifier={unitIdentifier}
      title={props.title}
      instruction={props.instruction}
      unitTitle={props.unitTitle}
      onExitAssessment={props.onExitAssessment}
      showBook
    >
      {showQuestionnaire ? (
        <Stack flex={1} marginTop="32px" spacing="64px">
          {props.questionnaire.map((q, idx) => (
            <Section key={q.title} onChange={handleOptionChange} q={q} ruberic={bookComprehensionQuestions.data} answer={props.value[idx].answer} />
          ))}
          <QuestionnaireFooter
            allFormFieldsFilledOut={false}
            onPreviousUnitClick={onPreviousUnitClick}
            setLeaveDialog={props.setLeaveDialog}
            isUnitQuestionnaireDirty={props.isUnitQuestionnaireDirty}
            disableSubmissionBtn={disableSubmissionBtn}
            onNextUnitAndSubmissionClick={handleSubmit}
            isNextEnabled={props.isNextEnabled}
            isPrevEnabled={true}
            submitButtonLabel={primaryButtonLabel}
            prevButtonLabel="Back"
          />
          <LeaveCurrentUnitDialog open={props.leaveDialog} setOpen={props.setLeaveDialog} confirmLeave={props.confirmPrevUnitClick} />
        </Stack>
      ) : (
        <Stack>
          <QuestionnaireFooter
            allFormFieldsFilledOut={false}
            onPreviousUnitClick={props.onPreviousUnitClick}
            setLeaveDialog={props.setLeaveDialog}
            isUnitQuestionnaireDirty={props.isUnitQuestionnaireDirty}
            disableSubmissionBtn={false}
            onNextUnitAndSubmissionClick={onContinueClicked}
            isNextEnabled={true}
            isPrevEnabled={props.isPrevEnabled}
            submitButtonLabel="Continue"
          />
          <LeaveCurrentUnitDialog open={props.leaveDialog} setOpen={props.setLeaveDialog} confirmLeave={props.confirmPrevUnitClick} />
        </Stack>
      )}
    </QuestionnaireContainer>
  );
}

function Section(props: {
  q: Layout6Questionnaire;
  onChange: (section: string) => (_event: React.ChangeEvent<HTMLInputElement>, value: string) => void;
  ruberic: PMABookComprehensionQuestionsResponse;
  answer: string | null;
}) {
  const ruberic = () => {
    switch (props.q.title) {
      case 'Text-Specific Knowledge':
        return props.ruberic.textSpecificKnowledge;
      case 'Sentence Analysis':
        return props.ruberic.sentenceAnalysis;
      case 'Text Structure':
        return props.ruberic.textStructure;
      case 'Verbal Reasoning':
        return props.ruberic.verbalReasoning;
      default:
        return null;
    }
  };

  const rubericValue = ruberic();

  return (
    <Stack marginTop="24px">
      <Typography variant="titlesmall">{props.q.title}</Typography>
      <Typography sx={{ fontStyle: 'italic', fontWeight: 400 }} variant="titlesmall">
        {props.q.subTitle}
      </Typography>
      {rubericValue ? (
        <Stack sx={{ backgroundColor: hootTokens.surface[2], padding: '24px', borderRadius: '8px', marginY: '16px' }}>
          <ReactMarkdown>{rubericValue}</ReactMarkdown>
        </Stack>
      ) : null}
      <RadioGroup onChange={props.onChange(props.q.title)} name="test" value={props.answer}>
        {props.q.options.map((o) => (
          <FormControlLabel
            key={o.val}
            value={o.text}
            control={<Radio />}
            label={<HootTypography isPII={false}>{o.text}</HootTypography>}
            sx={{ display: 'flex', alignItems: 'center' }}
          />
        ))}
      </RadioGroup>
    </Stack>
  );
}
