import { PropsWithChildren, ReactNode, createContext, useCallback, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { EventType } from '@hoot/events/eventType';
import { LessonReviewSubmittedNotificationMessage } from '@hoot/events/messages/lesson-review-submitted-notification.message';
import useCreateLessonReview, {
  ComprehensionFormativeAssessment,
  CreateLessonReviewRequest,
  FluencyFormativeAssessment,
  FocusBook,
  LettersAndSoundsFormativeAssessment,
  WordReadingFormativeAssessment,
} from '@hoot/hooks/api/lesson-reviews/useCreateLessonReview';
import { LessonPlanBook } from '@hoot/hooks/api/lesson-reviews/useGetLessonPlan';
import useGetLessonReview, { GetLessonReviewResponse } from '@hoot/hooks/api/lesson-reviews/useGetLessonReview';
import useUpdateLessonReview from '@hoot/hooks/api/lesson-reviews/useUpdateLessonReview';
import useGetTeacherLessonById, { TeacherLessonResponse } from '@hoot/hooks/api/lesson/useGetTeacherLessonById';
import {
  ComprehensionQuestions,
  FormativeAssessmentType,
  LessonReviewEngagement,
  LessonReviewFocus,
  LessonReviewFocusSkill,
  LessonReviewFocusType,
  LessonReviewFocusUnit,
  ResourceReader,
  formativeAssessmentLetters,
} from '@hoot/models/api/enums/lesson-review-enums';
import { createFlashMessage } from '@hoot/redux/reducers/flashMessageSlice';
import { useAppDispatch } from '@hoot/redux/store';
import { useSocket } from '@hoot/ui/context/SocketContext';
import LegacyLessonReviewFormativeAssessmentStep from '@hoot/ui/pages/v2/teacher/my-students/student-details/lesson-reviews/lesson-review-wizard/steps/LegacyLessonReviewFormativeAssessmentStep';
import LegacyLessonReviewInstructionalFocusStep from '@hoot/ui/pages/v2/teacher/my-students/student-details/lesson-reviews/lesson-review-wizard/steps/LegacyLessonReviewInstructionalFocusStep';
import LegacyLessonReviewLessonPlanStep from '@hoot/ui/pages/v2/teacher/my-students/student-details/lesson-reviews/lesson-review-wizard/steps/LegacyLessonReviewLessonPlanStep';
import LessonReviewCompletedStep from '@hoot/ui/pages/v2/teacher/my-students/student-details/lesson-reviews/lesson-review-wizard/steps/LessonReviewCompletedStep';
import LessonReviewFocusPriorityStep from '@hoot/ui/pages/v2/teacher/my-students/student-details/lesson-reviews/lesson-review-wizard/steps/LessonReviewFocusPriorityStep';
import LessonReviewFormativeAssessmentStep from '@hoot/ui/pages/v2/teacher/my-students/student-details/lesson-reviews/lesson-review-wizard/steps/LessonReviewFormativeAssessmentStep';
import LessonReviewInstructionalFocusStep from '@hoot/ui/pages/v2/teacher/my-students/student-details/lesson-reviews/lesson-review-wizard/steps/LessonReviewInstructionalFocusStep';
import LessonReviewIntroductionStep from '@hoot/ui/pages/v2/teacher/my-students/student-details/lesson-reviews/lesson-review-wizard/steps/LessonReviewIntroductionStep';
import LessonReviewLessonNotesStep from '@hoot/ui/pages/v2/teacher/my-students/student-details/lesson-reviews/lesson-review-wizard/steps/LessonReviewLessonNotesStep';
import LessonReviewLessonPlanStep from '@hoot/ui/pages/v2/teacher/my-students/student-details/lesson-reviews/lesson-review-wizard/steps/LessonReviewLessonPlanStep';
import LessonReviewLessonStructureStep from '@hoot/ui/pages/v2/teacher/my-students/student-details/lesson-reviews/lesson-review-wizard/steps/LessonReviewLessonStructureStep';
import LessonReviewRapportBuildingStep from '@hoot/ui/pages/v2/teacher/my-students/student-details/lesson-reviews/lesson-review-wizard/steps/LessonReviewRapportBuildingStep';
import LessonReviewResourceProgressStep from '@hoot/ui/pages/v2/teacher/my-students/student-details/lesson-reviews/lesson-review-wizard/steps/LessonReviewResourceProgressStep';
import LessonReviewStudentEngagementStep from '@hoot/ui/pages/v2/teacher/my-students/student-details/lesson-reviews/lesson-review-wizard/steps/LessonReviewStudentEngagementStep';
import { BookAttributeDetails } from '../../../../../../../../events/interfaces/book-search';
import { ResourceProgress } from '../../../../../../../../models/api/library';
import LessonReviewComprehensionRubricStep from './steps/LessonReviewComprehensionRubricStep';

export enum LegacyLessonReviewWizardStepEnum {
  Introduction = 0,
  FormativeAssessment,
  InstructionalFocus,
  ResourceProgress,
  LessonPlan,
  StudentEngagement,
  LessonNotes,
  Completed,
}

export enum LessonReviewWizardStepEnum {
  Introduction = 0,
  FormativeAssessment,
  ComprehensionRubric,
  LessonStructure,
  RapportBuilding,
  InstructionalFocus,
  FocusPriority,
  ResourceProgress,
  LessonPlan,
  StudentEngagement,
  LessonNotes,
  Completed,
}
export interface LessonReviewWizardStep {
  id: LessonReviewWizardStepEnum | LegacyLessonReviewWizardStepEnum;
  render: () => ReactNode;
  enabled: boolean;
}

export interface LessonReviewBook {
  bookId: string;
  title: string;
  coverImageUrl: string | undefined;
  progress?: ResourceProgress;
  reader?: ResourceReader;
  focuses?: BookAttributeDetails[] | undefined;
}

export interface PerformanceMetrics {
  wordCount?: number;
  errors?: number;
  runningTime?: number;
}

export enum TextReadingAssessmentType {
  Fluency = 'FLUENCY',
  Comprehension = 'COMPREHENSION',
}

export interface LessonReviewFormativeAssessmentBook {
  bookId: string;
  title: string;
  coverImageUrl: string | undefined;
  wordCount: number | null;
  formativeAssessmentWords: string[];
  formativeAssessmentType: FormativeAssessmentType[];
  wordsReadCorrectly?: Set<string>;
  lettersReadCorrectly?: Set<string>;
  textReadingData?: { performanceMetrics: PerformanceMetrics | undefined; comprehensionQuestions: ComprehensionQuestions | undefined };
}

interface FormativeAssessmentState {
  formativeAssessmentBooks: LessonReviewFormativeAssessmentBook[];
}

interface LessonStructureState {
  assessment: boolean;
  instruction: boolean;
  rapportBuilding: boolean;
}

export interface LessonReviewInstructionalFocusOption {
  id: string;
  focus: LessonReviewFocus | undefined;
  focusUnit: LessonReviewFocusUnit | undefined;
  focusSkill: LessonReviewFocusSkill | undefined;
  focusBooks: LessonReviewBook[];
}

export interface LessonReviewInstructionalFocusState {
  primaryFocus: LessonReviewFocus | undefined;
  primaryFocusUnit: LessonReviewFocusUnit | undefined;
  primaryFocusSkill: LessonReviewFocusSkill | undefined;
  secondaryFocus: LessonReviewFocus | undefined;
  secondaryFocusUnit: LessonReviewFocusUnit | undefined;
  secondaryFocusSkill: LessonReviewFocusSkill | undefined;
  primaryFocusBooks: LessonReviewBook[];
  secondaryFocusBooks: LessonReviewBook[];
  noResourcesUsed: boolean;
  lessonReviewVersion: number;
}

interface LessonPlanState {
  lessonPlanBooks: LessonPlanBook[];
}

export interface LessonReviewEngagementExternalFactorsState {
  classroomOrSurroundingsWereLoud: boolean;
  peopleInSurroundingsDistracting: boolean;
  audioOrVideoIssues: boolean;
}

interface LessonReviewWizardContextProps {
  shouldUpdateFocusOptions: boolean;
  currentStep: LessonReviewWizardStep;
  isLoadingLesson: boolean;
  lessonToReview: TeacherLessonResponse | undefined;
  existingLessonReview: GetLessonReviewResponse | undefined;
  lessonReviewNotes: string;
  lessonStructureState: LessonStructureState | undefined;
  formativeAssessmentState: FormativeAssessmentState | undefined;
  instructionalFocusOptions: LessonReviewInstructionalFocusOption[];
  instructionalFocusState: LessonReviewInstructionalFocusState | undefined;
  lessonPlanState: LessonPlanState | undefined;
  studentEngagementState: LessonReviewEngagement | undefined;
  studentEngagementExternalFactorsState: LessonReviewEngagementExternalFactorsState | undefined;
  isSubmittingReview: boolean;
  actions: {
    setShouldUpdateFocusOptions: (hasSavedFocusOptions: boolean) => void;
    toggleWizardStep: (step: number, state: boolean) => void;
    goToNextStep: () => void;
    goToPreviousStep: () => void;
    setLessonStructure: (lessonStructure: LessonStructureState) => void;
    attachFormativeAssessmentData: (selectedFormativeAssessmentBooks: LessonReviewFormativeAssessmentBook[]) => void;
    attachComprehensionRubricData: (selectedFormativeAssessmentBooks: LessonReviewFormativeAssessmentBook[]) => void;
    attachInstructionalFocusOptions: (instructionalFocusOptions: LessonReviewInstructionalFocusOption[]) => void;
    attachInstructionalFocusData: (instructionalFocusData: LessonReviewInstructionalFocusState) => void;
    attachResourceProgressData: (books: LessonReviewBook[]) => void;
    attachLessonPlanData: (lessonPlanBooks: LessonPlanBook[]) => void;
    attachStudentEngagementData: (studentEngagement: LessonReviewEngagement) => void;
    attachStudentEngagementExternalFactorsData: (studentEngagementExternalFactors: LessonReviewEngagementExternalFactorsState) => void;
    attachLessonNotesData: (notes: string) => void;
    submitLessonReview: () => void;
    dismissLessonReviewWizard: () => void;
    reset: () => void;
  };
}

export const legacyLessonReviewWizardSteps: LessonReviewWizardStep[] = [
  {
    id: LegacyLessonReviewWizardStepEnum.Introduction,
    render: () => <LessonReviewIntroductionStep />,
    enabled: true,
  },
  {
    id: LegacyLessonReviewWizardStepEnum.FormativeAssessment,
    render: () => <LegacyLessonReviewFormativeAssessmentStep />,
    enabled: true,
  },
  {
    id: LegacyLessonReviewWizardStepEnum.InstructionalFocus,
    render: () => <LegacyLessonReviewInstructionalFocusStep />,
    enabled: true,
  },
  {
    id: LegacyLessonReviewWizardStepEnum.ResourceProgress,
    render: () => <LessonReviewResourceProgressStep />,
    enabled: false,
  },
  {
    id: LegacyLessonReviewWizardStepEnum.LessonPlan,
    render: () => <LegacyLessonReviewLessonPlanStep />,
    enabled: true,
  },
  {
    id: LegacyLessonReviewWizardStepEnum.StudentEngagement,
    render: () => <LessonReviewStudentEngagementStep />,
    enabled: true,
  },
  {
    id: LegacyLessonReviewWizardStepEnum.LessonNotes,
    render: () => <LessonReviewLessonNotesStep />,
    enabled: true,
  },
  {
    id: LegacyLessonReviewWizardStepEnum.Completed,
    render: () => <LessonReviewCompletedStep />,
    enabled: true,
  },
];

export const lessonReviewWizardSteps: LessonReviewWizardStep[] = [
  {
    id: LessonReviewWizardStepEnum.Introduction,
    render: () => <LessonReviewIntroductionStep />,
    enabled: true,
  },
  {
    id: LessonReviewWizardStepEnum.FormativeAssessment,
    render: () => <LessonReviewFormativeAssessmentStep />,
    enabled: true,
  },
  {
    id: LessonReviewWizardStepEnum.ComprehensionRubric,
    render: () => <LessonReviewComprehensionRubricStep />,
    enabled: true,
  },
  {
    id: LessonReviewWizardStepEnum.LessonStructure,
    render: () => <LessonReviewLessonStructureStep />,
    enabled: true,
  },
  {
    id: LessonReviewWizardStepEnum.RapportBuilding,
    render: () => <LessonReviewRapportBuildingStep />,
    enabled: false,
  },
  {
    id: LessonReviewWizardStepEnum.InstructionalFocus,
    render: () => <LessonReviewInstructionalFocusStep />,
    enabled: false,
  },
  {
    id: LessonReviewWizardStepEnum.FocusPriority,
    render: () => <LessonReviewFocusPriorityStep />,
    enabled: false,
  },
  {
    id: LessonReviewWizardStepEnum.ResourceProgress,
    render: () => <LessonReviewResourceProgressStep />,
    enabled: false,
  },
  {
    id: LessonReviewWizardStepEnum.LessonPlan,
    render: () => <LessonReviewLessonPlanStep />,
    enabled: true,
  },
  {
    id: LessonReviewWizardStepEnum.StudentEngagement,
    render: () => <LessonReviewStudentEngagementStep />,
    enabled: true,
  },
  {
    id: LessonReviewWizardStepEnum.LessonNotes,
    render: () => <LessonReviewLessonNotesStep />,
    enabled: true,
  },
  {
    id: LessonReviewWizardStepEnum.Completed,
    render: () => <LessonReviewCompletedStep />,
    enabled: true,
  },
];

export const getFocusId = (focus: LessonReviewFocus, unit?: LessonReviewFocusUnit | string): string => {
  return `${focus}${unit ? `:${unit}` : ``}`;
};

const LessonReviewWizardContext = createContext<LessonReviewWizardContextProps>(undefined!);

interface LessonReviewWizardContextProviderProps extends PropsWithChildren<any> {
  lessonIdToReview: string | undefined;
  onDismissLessonReviewWizard: () => void;
  onCompleted?: () => void;
}

const LessonReviewWizardContextProvider = (props: LessonReviewWizardContextProviderProps) => {
  const { onDismissLessonReviewWizard, lessonIdToReview, children } = props;
  const { socket } = useSocket();

  const currentLessonReviewWizardSteps = lessonReviewWizardSteps;

  const dispatch = useAppDispatch();
  const { studentProfileId } = useParams();

  const [currentStep, setCurrentStep] = useState(currentLessonReviewWizardSteps[0]);
  const [lessonStructureState, setLessonStructureState] = useState<LessonStructureState>({
    assessment: false,
    rapportBuilding: false,
    instruction: false,
  });
  const [formativeAssessmentState, setFormativeAssessmentState] = useState<FormativeAssessmentState | undefined>(undefined);
  const [instructionalFocusOptions, setInstructionalFocusOptions] = useState<LessonReviewInstructionalFocusOption[]>([]);
  const [instructionalFocusState, setInstructionalFocusState] = useState<LessonReviewInstructionalFocusState>();
  const [lessonPlanState, setLessonPlanState] = useState<LessonPlanState>();
  const [studentEngagementState, setStudentEngagementState] = useState<LessonReviewEngagement>();
  const [studentEngagementExternalFactorsState, setStudentEngagementExternalFactorsState] = useState<LessonReviewEngagementExternalFactorsState>();
  const [lessonReviewNotes, setLessonReviewNotes] = useState('');
  const [shouldUpdateFocusOptions, setShouldUpdateFocusOptions] = useState(true);

  const getLessonRequest = useGetTeacherLessonById(lessonIdToReview!, {
    enabled: !!lessonIdToReview,
  });

  // If there's already a review for this lesson, then pre-populate all expected fields.
  const getLessonReviewRequest = useGetLessonReview(lessonIdToReview!, {
    enabled: !!lessonIdToReview,
    retry: false,
    onSuccess: (response) => {
      const bookMap = new Map<string, LessonReviewFormativeAssessmentBook>();

      response.formativeAssessment.fluencyBooks.forEach((book) => {
        return bookMap.set(book.bookId, {
          ...book,
          textReadingData: {
            performanceMetrics: {
              wordCount: book.wordCount,
              errors: book.errors,
              runningTime: book.runningTime,
            },
            comprehensionQuestions: undefined,
          },
          coverImageUrl: book.imageUrl,
          formativeAssessmentWords: [],
          formativeAssessmentType: [FormativeAssessmentType.Fluency],
        });
      });

      response.formativeAssessment.comprehensionBooks.forEach((book) => {
        const existingBook = bookMap.get(book.bookId);
        const textReadingData = existingBook?.textReadingData;
        if (textReadingData) {
          existingBook.formativeAssessmentType = [FormativeAssessmentType.Fluency, FormativeAssessmentType.Comprehension];
          textReadingData.comprehensionQuestions = {
            TEXT_SPECIFIC_KNOWLEDGE: book.textSpecificKnowledge,
            TEXT_STRUCTURE: book.textStructure,
            SENTENCE_ANALYSIS: book.sentenceAnalysis,
            VERBAL_REASONING: book.verbalReasoning,
            VOCABULARY: book.vocabulary,
          };
        } else {
          bookMap.set(book.bookId, {
            ...book,
            coverImageUrl: book.imageUrl,
            wordCount: null,
            formativeAssessmentWords: [],
            formativeAssessmentType: [FormativeAssessmentType.Comprehension],
            textReadingData: {
              comprehensionQuestions: {
                TEXT_SPECIFIC_KNOWLEDGE: book.textSpecificKnowledge,
                TEXT_STRUCTURE: book.textStructure,
                SENTENCE_ANALYSIS: book.sentenceAnalysis,
                VERBAL_REASONING: book.verbalReasoning,
                VOCABULARY: book.vocabulary,
              },
              performanceMetrics: undefined,
            },
          });
        }
      });

      const wordReadingFormativeAssessmentBooks: LessonReviewFormativeAssessmentBook[] = response.formativeAssessment.wordReadingBooks.map(
        (book) => ({
          bookId: book.bookId,
          title: book.title,
          coverImageUrl: book.imageUrl,
          formativeAssessmentWords: book.words.map((w) => w.word),
          wordsReadCorrectly: new Set(book.words.filter((w) => w.readCorrectly).map((w) => w.word)),
          wordCount: 0,
          formativeAssessmentType: [FormativeAssessmentType.WordReading],
        }),
      );

      const letterSoundsFormativeAssessmentBooks: LessonReviewFormativeAssessmentBook[] = response.formativeAssessment.lettersAndSoundsBooks.map(
        (book) => ({
          bookId: book.bookId,
          title: book.title,
          coverImageUrl: book.imageUrl,
          formativeAssessmentWords: [],
          lettersReadCorrectly: new Set(book.letters.filter((l) => l.readCorrectly).map((l) => l.letter)),
          wordCount: 0,
          formativeAssessmentType: [FormativeAssessmentType.LettersAndSounds],
        }),
      );

      const mergedFluencyComprehensionBooks: LessonReviewFormativeAssessmentBook[] = Array.from(bookMap.values());

      const combinedFormativeAssessmentBooks: LessonReviewFormativeAssessmentBook[] = [
        ...mergedFluencyComprehensionBooks,
        ...wordReadingFormativeAssessmentBooks,
        ...letterSoundsFormativeAssessmentBooks,
      ];

      const shouldUpdateInstructionalFocusOptions = ![
        response.instructionalFocus.primaryFocus.focus,
        response.instructionalFocus.secondaryFocus?.focus,
      ].some(
        (focus) =>
          focus &&
          [
            LessonReviewFocus.PreWordReading,
            LessonReviewFocus.EarlyWordReading,
            LessonReviewFocus.ComplexWordReading,
            LessonReviewFocus.TextReading,
          ].includes(focus),
      );
      setShouldUpdateFocusOptions(shouldUpdateInstructionalFocusOptions);

      // Pre-populate Step 0: Lesson Structure
      setLessonStructureState({
        assessment: [response.instructionalFocus.primaryFocus.focus, response.instructionalFocus.secondaryFocus?.focus].some(
          (focus) => focus && [LessonReviewFocus.HootReadingAssessment, LessonReviewFocus.ProgressMonitoringAssessment].includes(focus),
        ),
        instruction: [response.instructionalFocus.primaryFocus.focus, response.instructionalFocus.secondaryFocus?.focus].some(
          (focus) =>
            focus &&
            [
              LessonReviewFocus.PreWordReading,
              LessonReviewFocus.EarlyWordReading,
              LessonReviewFocus.ComplexWordReading,
              LessonReviewFocus.TextReading,
            ].includes(focus),
        ),
        rapportBuilding: [response.instructionalFocus.primaryFocus.focus, response.instructionalFocus.secondaryFocus?.focus].includes(
          LessonReviewFocus.RelationshipAndRapportBuilding,
        ),
      });

      // Pre-populate Step 1: Formative Assessment
      setFormativeAssessmentState({
        formativeAssessmentBooks: combinedFormativeAssessmentBooks,
      });

      // Pre-populate Step 2: Instructional Focus
      setInstructionalFocusState({
        primaryFocus: response.instructionalFocus.primaryFocus.focus,
        primaryFocusUnit: response.instructionalFocus.primaryFocus.unit,
        primaryFocusSkill: response.instructionalFocus.primaryFocus.skill,
        primaryFocusBooks: response.instructionalFocus.primaryFocus.books.map((b) => {
          return {
            bookId: b.bookId,
            title: b.title,
            coverImageUrl: b.imageUrl,
            reader: b.reader,
            progress: b.progress,
          };
        }),
        secondaryFocus: response.instructionalFocus.secondaryFocus?.focus,
        secondaryFocusUnit: response.instructionalFocus.secondaryFocus?.unit,
        secondaryFocusSkill: response.instructionalFocus.secondaryFocus?.skill,
        secondaryFocusBooks:
          response.instructionalFocus.secondaryFocus?.books.map((b) => {
            return {
              bookId: b.bookId,
              title: b.title,
              coverImageUrl: b.imageUrl,
              reader: b.reader,
              progress: b.progress,
            };
          }) ?? [],
        noResourcesUsed: ![...response.instructionalFocus.primaryFocus.books, ...(response.instructionalFocus.secondaryFocus?.books ?? [])].length,
        lessonReviewVersion: response.version,
      });

      // Pre-populate Focus Options
      setInstructionalFocusOptions([
        ...(!!response.instructionalFocus.primaryFocus.focus
          ? [
              {
                id: getFocusId(response.instructionalFocus.primaryFocus.focus!, response.instructionalFocus.primaryFocus.unit),
                focus: response.instructionalFocus.primaryFocus.focus,
                focusUnit: response.instructionalFocus.primaryFocus.unit,
                focusSkill: response.instructionalFocus.primaryFocus.skill,
                focusBooks: response.instructionalFocus.primaryFocus.books.map((b) => {
                  return {
                    bookId: b.bookId,
                    title: b.title,
                    coverImageUrl: b.imageUrl,
                    reader: b.reader,
                    progress: b.progress,
                  };
                }),
              },
            ]
          : []),
        ...(!!response.instructionalFocus.secondaryFocus?.focus
          ? [
              {
                id: getFocusId(response.instructionalFocus.secondaryFocus.focus!, response.instructionalFocus.secondaryFocus.unit),
                focus: response.instructionalFocus.secondaryFocus.focus,
                focusUnit: response.instructionalFocus.secondaryFocus.unit,
                focusSkill: response.instructionalFocus.secondaryFocus.skill,
                focusBooks: response.instructionalFocus.secondaryFocus.books.map((b) => {
                  return {
                    bookId: b.bookId,
                    title: b.title,
                    coverImageUrl: b.imageUrl,
                    reader: b.reader,
                    progress: b.progress,
                  };
                }),
              },
            ]
          : []),
      ]);

      // -- Step 4 state is handled internally. --
      // Pre-populate Step 5: setStudentEngagementState
      setStudentEngagementState(response.studentEngagement);
      setStudentEngagementExternalFactorsState(response.studentEngagementExternalFactors);
      setLessonReviewNotes(response.lessonNotes);
    },
  });
  const createLessonReviewRequest = useCreateLessonReview(lessonIdToReview!);
  const updateLessonReviewRequest = useUpdateLessonReview(lessonIdToReview!);

  useEffect(() => {
    currentLessonReviewWizardSteps[LessonReviewWizardStepEnum.InstructionalFocus].enabled = lessonStructureState.instruction;
    if (!lessonStructureState.instruction) {
      currentLessonReviewWizardSteps[LessonReviewWizardStepEnum.ResourceProgress].enabled = false;
    }
    currentLessonReviewWizardSteps[LessonReviewWizardStepEnum.RapportBuilding].enabled = lessonStructureState.rapportBuilding;
    currentLessonReviewWizardSteps[LessonReviewWizardStepEnum.FocusPriority].enabled =
      lessonStructureState.rapportBuilding || lessonStructureState.instruction;
  }, [currentLessonReviewWizardSteps, lessonStructureState]);

  useEffect(() => {
    currentLessonReviewWizardSteps[LessonReviewWizardStepEnum.ResourceProgress].enabled = !instructionalFocusState?.noResourcesUsed;
  }, [currentLessonReviewWizardSteps, instructionalFocusState?.noResourcesUsed]);

  const findEnabledStep = (step: number, direction: number): number | null => {
    let nextIndex = step;
    while (true) {
      nextIndex += direction;
      if (nextIndex < 0 || nextIndex >= currentLessonReviewWizardSteps.length) {
        return null;
      }
      if (currentLessonReviewWizardSteps[nextIndex].enabled) {
        return nextIndex;
      }
    }
  };

  const goToStep = (stepId: LessonReviewWizardStepEnum | LegacyLessonReviewWizardStepEnum) => {
    setCurrentStep(currentLessonReviewWizardSteps[stepId]);
  };

  const clearLessonReviewState = useCallback(() => {
    setLessonReviewNotes('');
    setLessonStructureState({ assessment: false, rapportBuilding: false, instruction: false });
    setFormativeAssessmentState(undefined);
    setInstructionalFocusOptions([]);
    setStudentEngagementExternalFactorsState(undefined);
    setShouldUpdateFocusOptions(true);
    setInstructionalFocusState(undefined);
    setLessonPlanState(undefined);
    setStudentEngagementState(undefined);
    goToStep(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <LessonReviewWizardContext.Provider
      value={{
        shouldUpdateFocusOptions: shouldUpdateFocusOptions,
        currentStep,
        isLoadingLesson: getLessonRequest.isFetching || getLessonReviewRequest.isFetching,
        lessonToReview: getLessonRequest.data,
        existingLessonReview: getLessonReviewRequest.data,
        lessonStructureState,
        formativeAssessmentState,
        instructionalFocusOptions,
        instructionalFocusState,
        lessonPlanState,
        studentEngagementState,
        studentEngagementExternalFactorsState,
        lessonReviewNotes,
        isSubmittingReview: createLessonReviewRequest.isLoading || updateLessonReviewRequest.isLoading,
        actions: {
          setShouldUpdateFocusOptions: (hasSavedFocusOptions: boolean) => {
            setShouldUpdateFocusOptions(hasSavedFocusOptions);
          },
          toggleWizardStep: (step: number, state: boolean): void => {
            currentLessonReviewWizardSteps[step].enabled = state;
          },
          goToNextStep: () => {
            const nextStep = findEnabledStep(currentStep.id, 1);
            if (nextStep !== null) {
              goToStep(nextStep);
            }
          },
          goToPreviousStep: () => {
            const prevStep = findEnabledStep(currentStep.id, -1);
            if (prevStep !== null) {
              goToStep(prevStep);
            }
          },
          setLessonStructure: (lessonStructure: LessonStructureState) => {
            setLessonStructureState(lessonStructure);
          },
          attachFormativeAssessmentData: (formativeAssessmentBooks: LessonReviewFormativeAssessmentBook[]) => {
            if (!formativeAssessmentBooks.length) {
              setFormativeAssessmentState(undefined);
            } else {
              setFormativeAssessmentState({
                formativeAssessmentBooks: formativeAssessmentBooks,
              });
            }
          },
          attachComprehensionRubricData: (formativeAssessmentBooksWithComprehensionData: LessonReviewFormativeAssessmentBook[]) => {
            setFormativeAssessmentState((currentFormativeAssessmentState) => ({
              formativeAssessmentBooks:
                currentFormativeAssessmentState?.formativeAssessmentBooks.map((book) => {
                  const updatedBook = formativeAssessmentBooksWithComprehensionData.find((updated) => updated.bookId === book.bookId);
                  if (updatedBook) {
                    return {
                      ...book,
                      ...updatedBook,
                    };
                  }
                  return book;
                }) ?? [],
            }));
          },
          attachInstructionalFocusOptions: (instructionalFocusOptions: LessonReviewInstructionalFocusOption[]) => {
            setInstructionalFocusOptions(instructionalFocusOptions);
          },
          attachInstructionalFocusData: (instructionalFocusData) => {
            setInstructionalFocusState(instructionalFocusData);
          },
          attachResourceProgressData: (books: LessonReviewBook[]) => {
            setInstructionalFocusState((currentState) =>
              currentState
                ? {
                    ...currentState,
                    primaryFocusBooks: currentState.primaryFocusBooks.map((pb) => books.find((b) => b.bookId === pb.bookId) ?? pb),
                    secondaryFocusBooks: currentState.secondaryFocusBooks.map((pb) => books.find((b) => b.bookId === pb.bookId) ?? pb),
                  }
                : undefined,
            );
          },
          attachLessonPlanData: (lessonPlanBooks: LessonPlanBook[]) => {
            setLessonPlanState({
              lessonPlanBooks: lessonPlanBooks,
            });
          },
          attachStudentEngagementData: (studentEngagement: LessonReviewEngagement) => {
            setStudentEngagementState(studentEngagement);
          },
          attachStudentEngagementExternalFactorsData: (studentEngagementExternalFactors: LessonReviewEngagementExternalFactorsState) => {
            setStudentEngagementExternalFactorsState(studentEngagementExternalFactors);
          },
          attachLessonNotesData: (notes) => {
            setLessonReviewNotes(notes);
          },
          submitLessonReview: () => {
            const wordReadingFormativeAssessments: WordReadingFormativeAssessment[] = [];
            const fluencyFormativeAssessments: FluencyFormativeAssessment[] = [];
            const comprehensionFormativeAssessments: ComprehensionFormativeAssessment[] = [];
            const lettersAndSoundsFormativeAssessments: LettersAndSoundsFormativeAssessment[] = [];

            if (formativeAssessmentState) {
              for (const book of formativeAssessmentState.formativeAssessmentBooks) {
                const performanceMetrics = book.textReadingData?.performanceMetrics;
                const comprehensionQuestions = book.textReadingData?.comprehensionQuestions;
                if (!!performanceMetrics && Object.values(performanceMetrics).every((value) => value !== undefined)) {
                  const fluencyFormativeAssessment: FluencyFormativeAssessment = {
                    bookId: book.bookId,
                    wordCount: performanceMetrics.wordCount!,
                    errors: performanceMetrics.errors!,
                    runningTime: performanceMetrics.runningTime!,
                  };
                  fluencyFormativeAssessments.push(fluencyFormativeAssessment);
                }
                if (comprehensionQuestions) {
                  const comprehensionFormativeAssessment: ComprehensionFormativeAssessment = {
                    bookId: book.bookId,
                    textSpecificKnowledge: comprehensionQuestions?.TEXT_SPECIFIC_KNOWLEDGE
                      ? Number(comprehensionQuestions.TEXT_SPECIFIC_KNOWLEDGE)
                      : undefined,
                    textStructure: Number(comprehensionQuestions.TEXT_STRUCTURE),
                    sentenceAnalysis: Number(comprehensionQuestions.SENTENCE_ANALYSIS),
                    verbalReasoning: Number(comprehensionQuestions.VERBAL_REASONING),
                    vocabulary: Number(comprehensionQuestions.VOCABULARY),
                  };
                  comprehensionFormativeAssessments.push(comprehensionFormativeAssessment);
                } else if (book.formativeAssessmentType.includes(FormativeAssessmentType.WordReading)) {
                  for (const word of book.formativeAssessmentWords) {
                    const wordsReadCorrectly = book.wordsReadCorrectly;
                    const formativeAssessment: WordReadingFormativeAssessment = {
                      bookId: book.bookId,
                      word: word,
                      readCorrectly: wordsReadCorrectly?.has(word) ?? false,
                    };
                    wordReadingFormativeAssessments.push(formativeAssessment);
                  }
                } else if (book.formativeAssessmentType.includes(FormativeAssessmentType.LettersAndSounds)) {
                  for (const letter of formativeAssessmentLetters) {
                    const lettersReadCorrectly = book.lettersReadCorrectly;
                    const formativeAssessment: LettersAndSoundsFormativeAssessment = {
                      bookId: book.bookId,
                      letter: letter,
                      readCorrectly: lettersReadCorrectly?.has(letter) ?? false,
                    };
                    lettersAndSoundsFormativeAssessments.push(formativeAssessment);
                  }
                }
              }
            }

            const mapFocusBooks = (books: LessonReviewBook[], focusType: LessonReviewFocusType) => {
              return books.map<FocusBook>((b) => ({
                id: b.bookId,
                focusType,
                reader: b.reader,
                progress: b.progress,
              }));
            };
            const focusBooks = [
              ...mapFocusBooks(instructionalFocusState?.primaryFocusBooks ?? [], LessonReviewFocusType.Primary),
              ...mapFocusBooks(instructionalFocusState?.secondaryFocusBooks ?? [], LessonReviewFocusType.Secondary),
            ];

            const lessonPlanBookIds = lessonPlanState?.lessonPlanBooks.map((book) => book.id) ?? [];

            const lessonReviewData: CreateLessonReviewRequest = {
              studentProfileId: studentProfileId!,
              // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
              primaryFocus: instructionalFocusState?.primaryFocus!,
              primaryFocusUnit: instructionalFocusState?.primaryFocusUnit,
              primaryFocusSkill: instructionalFocusState?.primaryFocusSkill,
              secondaryFocus: instructionalFocusState?.secondaryFocus,
              secondaryFocusUnit: instructionalFocusState?.secondaryFocusUnit,
              secondaryFocusSkill: instructionalFocusState?.secondaryFocusSkill,
              focusBooks: focusBooks,
              lessonPlanBookIds: lessonPlanBookIds,
              studentEngagement: studentEngagementState!,
              studentEngagementExternalFactors: {
                // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
                classroomOrSurroundingsWereLoud: studentEngagementExternalFactorsState?.classroomOrSurroundingsWereLoud!,
                // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
                peopleInSurroundingsDistracting: studentEngagementExternalFactorsState?.peopleInSurroundingsDistracting!,
                // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
                audioOrVideoIssues: studentEngagementExternalFactorsState?.audioOrVideoIssues!,
              },
              notes: lessonReviewNotes,
              wordReadingFormativeAssessments,
              fluencyFormativeAssessments,
              comprehensionFormativeAssessments,
              lettersAndSoundsFormativeAssessments,
            };
            // If we already have an existing lesson review, then we'll be updating the review.
            // Else this is a brand-new review.
            (getLessonReviewRequest.data ? updateLessonReviewRequest : createLessonReviewRequest).mutate(lessonReviewData, {
              onSuccess: () => {
                goToStep(currentStep.id + 1);
                if (props.onCompleted) {
                  props.onCompleted();
                }
                const lessonReviewSubmittedMessage: LessonReviewSubmittedNotificationMessage = {
                  reviewLessonId: lessonIdToReview!,
                };
                socket.emit(EventType.NotifyLessonReviewSubmitted, lessonReviewSubmittedMessage);
              },
              onError: (err) => {
                console.error(err.response?.data.message);
                dispatch(
                  createFlashMessage({
                    variant: 'error',
                    message: err.response?.data.message ?? 'Whoops! It looks like an error occurred.',
                  }),
                );
              },
            });
          },
          dismissLessonReviewWizard: () => {
            onDismissLessonReviewWizard();
          },
          reset: useCallback(() => {
            clearLessonReviewState();
          }, [clearLessonReviewState]),
        },
      }}
    >
      {children}
    </LessonReviewWizardContext.Provider>
  );
};

export const useLessonReviewWizardContext = () => {
  const context = useContext(LessonReviewWizardContext);

  if (context === undefined) {
    throw new Error('useLessonReviewWizardContext must be used within a LessonReviewWizardContextProvider');
  }
  return context;
};

export default LessonReviewWizardContextProvider;
