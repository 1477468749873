import { useNetwork } from '@daily-co/daily-react';
import { DateTime } from 'luxon';
import { useInterval } from '@hoot/hooks/useInterval';
import { sentry } from '@hoot/telemetry/sentry';

const FREQUENCY_MS = 30000; // 30s

export function useInLessonLogger(lessonStartMillis?: number) {
  const network = useNetwork();
  const logTime = () => {
    const now = DateTime.now().toUTC();
    const utc = now.toLocaleString(DateTime.TIME_WITH_SHORT_OFFSET);
    const local = now.toLocal().toLocaleString(DateTime.TIME_WITH_SHORT_OFFSET);

    const log = `[InLessonLogger] Current time: ${utc} / ${local}`;
    console.log(log);
    sentry.addBreadcrumb({ message: log });

    if (lessonStartMillis) {
      const timeSinceStart = now.diff(DateTime.fromMillis(lessonStartMillis).toUTC()).rescale().set({ millisecond: 0 });
      const timeSinceStartLog = `[InLessonLogger] Time since lesson start: ${timeSinceStart.toHuman({ unitDisplay: 'short' })}`;
      console.log(timeSinceStartLog);
      sentry.addBreadcrumb({ message: timeSinceStartLog });
    }
  };

  const logNetwork = async () => {
    const stats = await network.getStats();
    console.log(`[InLessonLogger] Network stats: `, stats);
  };

  useInterval(logTime, FREQUENCY_MS);
  useInterval(logNetwork, FREQUENCY_MS);
}
