import { DialogActions, DialogContent, Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import {
  LessonReviewFocus,
  LessonReviewFocusUnit,
  lessonReviewFocusLabel,
  lessonReviewFocusUnitLabel,
} from '@hoot/models/api/enums/lesson-review-enums';
import { Button } from '@hoot/ui/components/v2/core/Button';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';
import { Select, SelectProps } from '@hoot/ui/components/v2/core/Select';
import {
  LessonReviewInstructionalFocusOption,
  LessonReviewWizardStepEnum,
  getFocusId,
  useLessonReviewWizardContext,
} from '@hoot/ui/pages/v2/teacher/my-students/student-details/lesson-reviews/lesson-review-wizard/LessonReviewContextProvider';
import LessonReviewWarningCard from '@hoot/ui/pages/v2/teacher/my-students/student-details/lesson-reviews/lesson-review-wizard/LessonReviewWarningCard';

const MAX_FOCUSES_ALLOWED = 2;

const getCurrentFocusId = (
  focus: LessonReviewFocus | undefined,
  focusUnit: LessonReviewFocusUnit | undefined,
  instructionalFocusOptions: LessonReviewInstructionalFocusOption[],
): string | undefined => {
  if (!focus) {
    return undefined;
  }
  const focusId = getFocusId(focus, focusUnit);
  const activeFocus = instructionalFocusOptions.find((focus) => focus.id === focusId);
  return !!activeFocus ? focusId : undefined;
};

const LessonReviewFocusPriorityStep = () => {
  const {
    instructionalFocusState,
    instructionalFocusOptions,
    actions: { goToNextStep, goToPreviousStep, attachInstructionalFocusData, toggleWizardStep },
  } = useLessonReviewWizardContext();

  const [primaryFocusId, setPrimaryFocusId] = useState<string | undefined>(
    getCurrentFocusId(instructionalFocusState?.primaryFocus, instructionalFocusState?.primaryFocusUnit, instructionalFocusOptions),
  );
  const [secondaryFocusId, setSecondaryFocusId] = useState<string | undefined>(
    getCurrentFocusId(instructionalFocusState?.secondaryFocus, instructionalFocusState?.secondaryFocusUnit, instructionalFocusOptions),
  );

  const focusesFilteringUnpopulatedTextReadings = instructionalFocusOptions.filter(
    (focusOption) => !(focusOption.focus === LessonReviewFocus.TextReading && !focusOption.focusUnit),
  );
  const hasTooManyFocuses = focusesFilteringUnpopulatedTextReadings.length > MAX_FOCUSES_ALLOWED;
  const primaryFocus = focusesFilteringUnpopulatedTextReadings.find((focus) => focus.id === primaryFocusId);
  const secondaryFocus = focusesFilteringUnpopulatedTextReadings.find((focus) => focus.id === secondaryFocusId);
  const hasBooks = [...(primaryFocus?.focusBooks ?? []), ...(secondaryFocus?.focusBooks ?? [])].length > 0;
  const canGoToNextStep = focusesFilteringUnpopulatedTextReadings.length > 1 ? !!primaryFocus && !!secondaryFocus : !!primaryFocus;

  useEffect(() => {
    toggleWizardStep(LessonReviewWizardStepEnum.ResourceProgress, hasBooks);
  }, [toggleWizardStep, hasBooks]);

  const onPrimaryFocusChanged: SelectProps['onChange'] = (event) => {
    const newFocus = event.target.value ?? undefined;
    setPrimaryFocusId(newFocus);
    if (newFocus === secondaryFocusId) {
      setSecondaryFocusId(undefined);
    }
  };

  const onSecondaryFocusChanged: SelectProps['onChange'] = (event) => {
    const newFocus = event.target.value ?? undefined;
    setSecondaryFocusId(newFocus);
  };

  const submitAndGoToNextStep = () => {
    attachInstructionalFocusData({
      primaryFocus: primaryFocus?.focus,
      secondaryFocus: secondaryFocus?.focus,
      primaryFocusBooks: primaryFocus?.focusBooks ?? [],
      secondaryFocusBooks: secondaryFocus?.focusBooks ?? [],
      primaryFocusUnit: primaryFocus?.focusUnit,
      secondaryFocusUnit: secondaryFocus?.focusUnit,
      primaryFocusSkill: primaryFocus?.focusSkill,
      secondaryFocusSkill: secondaryFocus?.focusSkill,
      noResourcesUsed: false,
      lessonReviewVersion: 2,
    });
    goToNextStep();
  };

  return (
    <>
      <DialogContent>
        <Stack gap={2} paddingBottom={1}>
          <HootTypography isPII={false} variant="titlemedium">
            Indicate the Focus Priority
          </HootTypography>
          <Stack gap={1}>
            <Stack direction={'column'} gap={'16px'} sx={{ width: '50%' }}>
              <HootTypography isPII={false} variant="bodymedium">
                What was the Primary focus of the lesson?
              </HootTypography>
              <Select label="Primary Focus" name="primaryFocus" value={primaryFocusId} onChange={onPrimaryFocusChanged} required fullWidth>
                <option value="">Select</option>
                {focusesFilteringUnpopulatedTextReadings.map((option) => (
                  <option value={option.id} key={`primary-focus-option-${option.id}`}>
                    {`${option.focus ? lessonReviewFocusLabel[option.focus] : ''}${option.focusUnit ? `>${lessonReviewFocusUnitLabel[option.focusUnit]}` : ''}`}
                  </option>
                ))}
              </Select>
              {focusesFilteringUnpopulatedTextReadings.length > 1 ? (
                <>
                  <HootTypography isPII={false} variant="bodymedium">
                    What was the Secondary focus of the lesson ?
                  </HootTypography>
                  <Select
                    label="Secondary Focus"
                    name="secondaryFocus"
                    value={secondaryFocusId}
                    onChange={onSecondaryFocusChanged}
                    disabled={!primaryFocusId}
                    required
                    fullWidth
                  >
                    <option value="">Select</option>
                    {focusesFilteringUnpopulatedTextReadings
                      .filter((f) => f.id !== primaryFocusId)
                      .map((option) => (
                        <option value={option.id} key={`secondary-focus-option-${option.id}`}>
                          {`${option.focus ? lessonReviewFocusLabel[option.focus] : ''}${option.focusUnit ? `>${lessonReviewFocusUnitLabel[option.focusUnit]}` : ''}`}
                        </option>
                      ))}
                  </Select>
                </>
              ) : null}
            </Stack>
          </Stack>
          <Stack gap={2} flex={1} minHeight="327px">
            {hasTooManyFocuses ? (
              <LessonReviewWarningCard
                text={
                  <Stack>
                    <HootTypography isPII={false} variant="bodylarge">
                      This Lesson had too many focuses.
                    </HootTypography>
                    <HootTypography isPII={false} variant="bodylarge">
                      Only two focuses can be submitted with this review.
                    </HootTypography>
                  </Stack>
                }
              />
            ) : null}
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={goToPreviousStep}>
          Go Back
        </Button>
        <Button variant="contained" onClick={submitAndGoToNextStep} disabled={!canGoToNextStep}>
          Next
        </Button>
      </DialogActions>
    </>
  );
};

export default LessonReviewFocusPriorityStep;
