import { AgreementEndReason } from '@hoot-reading/hoot-core/dist/enums/user/teacher/agreement-end-reason.enum';
import { DiversitySelfIdentification } from '@hoot-reading/hoot-core/dist/enums/user/teacher/diversity-self-identification.enum';
import { HootQualification } from '@hoot-reading/hoot-core/dist/enums/user/teacher/hoot-qualification.enum';
import { LanguageQualification } from '@hoot-reading/hoot-core/dist/enums/user/teacher/language-qualification.enum';
import { OnLeaveReason } from '@hoot-reading/hoot-core/dist/enums/user/teacher/on-leave-reason.enum';
import { SpokenLanguage } from '@hoot-reading/hoot-core/dist/enums/user/teacher/spoken-language.enum';
import { TeacherCertificationArea } from '@hoot-reading/hoot-core/dist/enums/user/teacher/teacher-certification-area.enum';
import { TeacherSpecializedTraining } from '@hoot-reading/hoot-core/dist/enums/user/teacher/teacher-specialized-training.enum';
import { TeacherStanding } from '@hoot-reading/hoot-core/dist/enums/user/teacher/teacher-standing.enum';
import { TeacherStatus } from '@hoot-reading/hoot-core/dist/enums/user/teacher/teacher-status.enum';
import { User } from './user';

export interface TeacherAccount {
  id: string;
  displayName: string;
  createdAt: number;
  hootQualifications: HootQualification[];
  bio: string | null;
  user?: User; // not sure this is needed
  number: number;
  isDisabled: boolean;
  status?: TeacherStatus;
  standing?: TeacherStanding;
  languageQualifications: LanguageQualification[];
  spokenLanguages: SpokenLanguage[];
  certificationAreas: TeacherCertificationArea[];
  specializedTraining: TeacherSpecializedTraining[];
  agreementDate?: string;
  goLiveDate?: string;
  diversitySelfIdentifications: DiversitySelfIdentification[];
  onLeaveReason?: OnLeaveReason;
  agreementEndDate?: string;
  agreementEndReason?: AgreementEndReason;
  institutionNumber?: number;
  branchNumber?: number;
  accountNumber?: number;
  identificationNumber?: number;
  emergencyContactFirstName?: string;
  emergencyContactLastName?: string;
  emergencyContactEmail?: string;
  emergencyContactPhoneNumber?: string;
  teacherStage: TeacherStage | null;
}

export enum TeacherStage {
  Interviewing = 'INTERVIEWING',
  OfferPending = 'OFFER_PENDING',
  NotHired = 'NOT_HIRED',
  Onboarding = 'ONBOARDING',
  Live = 'LIVE',
  OnLeave = 'ON_LEAVE',
  PreDeparture = 'PRE_DEPARTURE',
}
