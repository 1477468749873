import { StudentGrade } from '@hoot-reading/hoot-core/dist/enums/student-grade';
import { StudentStatus } from '@hoot-reading/hoot-core/dist/enums/user/student/student-status.enum';
import { LocationReportingFilters, LocationReportingMetricResponse } from '@hoot/hooks/api/district-rep/reporting/useGetLocationReportingMetrics';

export const filterCategoryLabel: Record<keyof LocationReportingFilters, string> = {
  date: 'Date',
  schoolIds: 'School',
  studentStatus: 'Student Status',
  studentGrade: 'Grade',
  attendance: 'Attendance',
  lateJoin: 'Late Join',
};

export const getFilterOptionLabel = (filterKey: keyof LocationReportingFilters, value: string) => {
  switch (filterKey) {
    case 'date':
      return dateFilterLabels[value as DateFilterEnum] || value;
    case 'attendance':
      return attendanceLabels[value as AttendanceFilterEnum] || value;
    case 'lateJoin':
      return lateJoinLabels[value as LateJoinFilterEnum] || value;
    case 'studentStatus':
      return studentStatusLabels[value as StudentStatus] || value;
    case 'studentGrade':
      return studentGradeLabels[value as StudentGrade] || value;
    default:
      return value;
  }
};

export const getFormattedMetricLabel = (metricKey: keyof LocationReportingMetricResponse) => {
  switch (metricKey) {
    case 'totalLessons':
      return 'Total Lessons';
    case 'occurredLessons':
      return 'Occurred Lessons';
    case 'totalStudents':
      return 'Total Students';
    case 'averageAttendanceRate':
      return 'Average Attendance Rate';
    case 'lateJoinRate':
      return 'Late Join Rate';
    case 'minutesInLesson':
      return 'Minutes In Lesson';
    case 'totalSchools':
      return 'Total Schools';
    default:
      return metricKey;
  }
};

export const getFormattedMetricValue = (metricValue: number, metricKey: keyof LocationReportingMetricResponse) => {
  switch (metricKey) {
    case 'totalLessons':
    case 'occurredLessons':
    case 'minutesInLesson':
    case 'totalStudents':
    case 'totalSchools':
      return Intl.NumberFormat().format(metricValue);
    case 'averageAttendanceRate':
    case 'lateJoinRate':
      return `${metricValue}%`;
    default:
      return metricValue;
  }
};

// Date Filter
export enum DateFilterEnum {
  Today = 'TODAY',
  Yesterday = 'YESTERDAY',
  ThisWeek = 'THIS_WEEK',
  LastWeek = 'LAST_WEEK',
  ThisMonth = 'THIS_MONTH',
  LastMonth = 'LAST_MONTH',
  ThisSchoolYear = 'THIS_SCHOOL_YEAR',
  LastSchoolYear = 'LAST_SCHOOL_YEAR',
  SpecificDates = 'SPECIFIC_DATES',
}

export const dateFilterLabels: Record<DateFilterEnum, string> = {
  [DateFilterEnum.Today]: 'Today',
  [DateFilterEnum.Yesterday]: 'Yesterday',
  [DateFilterEnum.ThisWeek]: 'This week',
  [DateFilterEnum.LastWeek]: 'Last week',
  [DateFilterEnum.ThisMonth]: 'This month',
  [DateFilterEnum.LastMonth]: 'Last month',
  [DateFilterEnum.ThisSchoolYear]: 'This school year',
  [DateFilterEnum.LastSchoolYear]: 'Last school year',
  [DateFilterEnum.SpecificDates]: 'Specific Dates',
};

// Attendance Filter
export enum AttendanceFilterEnum {
  LessThan10 = 'LESS_THAN_10',
  Between10And20 = 'BETWEEN_10_AND_20',
  Between20And30 = 'BETWEEN_20_AND_30',
  Between30And40 = 'BETWEEN_30_AND_40',
  Between40And50 = 'BETWEEN_40_AND_50',
  Between50And60 = 'BETWEEN_50_AND_60',
  Between60And70 = 'BETWEEN_60_AND_70',
  Between70And80 = 'BETWEEN_70_AND_80',
  Between80And90 = 'BETWEEN_80_AND_90',
  GreaterThan90 = 'GREATER_THAN_90',
}

export const attendanceLabels: Record<AttendanceFilterEnum, string> = {
  [AttendanceFilterEnum.LessThan10]: '<= 10%',
  [AttendanceFilterEnum.Between10And20]: '11 - 20%',
  [AttendanceFilterEnum.Between20And30]: '21 - 30%',
  [AttendanceFilterEnum.Between30And40]: '31 - 40%',
  [AttendanceFilterEnum.Between40And50]: '41 - 50%',
  [AttendanceFilterEnum.Between50And60]: '51 - 60%',
  [AttendanceFilterEnum.Between60And70]: '61 - 70%',
  [AttendanceFilterEnum.Between70And80]: '71 - 80%',
  [AttendanceFilterEnum.Between80And90]: '81 - 90%',
  [AttendanceFilterEnum.GreaterThan90]: '> 90%',
};

// Late Join Filter
export enum LateJoinFilterEnum {
  LessThan10 = 'LESS_THAN_10',
  Between10And20 = 'BETWEEN_10_AND_20',
  Between20And30 = 'BETWEEN_20_AND_30',
  Between30And40 = 'BETWEEN_30_AND_40',
  Between40And50 = 'BETWEEN_40_AND_50',
  Between50And60 = 'BETWEEN_50_AND_60',
  Between60And70 = 'BETWEEN_60_AND_70',
  Between70And80 = 'BETWEEN_70_AND_80',
  Between80And90 = 'BETWEEN_80_AND_90',
  GreaterThan90 = 'GREATER_THAN_90',
}

export const lateJoinLabels: Record<LateJoinFilterEnum, string> = {
  [LateJoinFilterEnum.LessThan10]: '<= 10%',
  [LateJoinFilterEnum.Between10And20]: '11 - 20%',
  [LateJoinFilterEnum.Between20And30]: '21 - 30%',
  [LateJoinFilterEnum.Between30And40]: '31 - 40%',
  [LateJoinFilterEnum.Between40And50]: '41 - 50%',
  [LateJoinFilterEnum.Between50And60]: '51 - 60%',
  [LateJoinFilterEnum.Between60And70]: '61 - 70%',
  [LateJoinFilterEnum.Between70And80]: '71 - 80%',
  [LateJoinFilterEnum.Between80And90]: '81 - 90%',
  [LateJoinFilterEnum.GreaterThan90]: '> 90%',
};

// Student Grade Filter
export const studentGradeLabels: Record<StudentGrade, string> = {
  [StudentGrade.PreKindergarten]: 'Pre-Kindergarten',
  [StudentGrade.Kindergarten]: 'Kindergarten',
  [StudentGrade.Grade1]: 'Grade 1',
  [StudentGrade.Grade2]: 'Grade 2',
  [StudentGrade.Grade3]: 'Grade 3',
  [StudentGrade.Grade4]: 'Grade 4',
  [StudentGrade.Grade5]: 'Grade 5',
  [StudentGrade.Grade6]: 'Grade 6',
  [StudentGrade.Grade7]: 'Grade 7',
  [StudentGrade.Grade8]: 'Grade 8',
  [StudentGrade.Grade9]: 'Grade 9',
  [StudentGrade.Grade10]: 'Grade 10',
  [StudentGrade.Grade11]: 'Grade 11',
  [StudentGrade.Grade12]: 'Grade 12',
};

// Student Status Filter
export const studentStatusLabels: Record<StudentStatus, string> = {
  [StudentStatus.Active]: 'Active',
  [StudentStatus.Lead]: 'Lead',
  [StudentStatus.Cold]: 'Cold',
  [StudentStatus.Paused]: 'Paused',
  [StudentStatus.Inactive]: 'Inactive',
};
