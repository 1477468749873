import { Box, Slider, Stack, Tooltip, styled } from '@mui/material';
import { SliderOwnProps } from '@mui/material/Slider/Slider';
import React, { useRef } from 'react';
import Draggable, { DraggableData, DraggableEvent } from 'react-draggable';
import { BookPage } from '@hoot/events/interfaces/book-page';
import { ReaderContext, readerKeyLookup, useReaders } from '@hoot/redux/reducers/readingSlice';
import { Button } from '@hoot/ui/components/v2/core/Button';
import { FloatingDialog } from '@hoot/ui/components/v2/core/FloatingDialog';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';
import { Icon, IconName } from '@hoot/ui/components/v2/core/Icon';
import IconButton from '@hoot/ui/components/v2/core/IconButton';
import BlankBookPage from '@hoot/ui/components/v2/library/BlankBookPage';
import BookPageV2 from '@hoot/ui/components/v2/library/BookPageV2';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';

const ZOOM_STEP = 20;
const ZOOM_MIN = 100;
const ZOOM_MAX = 300;

// these values correspond to single-page and double-page mode, respectively
enum ZoomOrientation {
  Portrait = 'portrait',
  Landscape = 'landscape',
}

export interface Coords {
  x: number;
  y: number;
}

export function ZoomControl(props: {
  readerContext: ReaderContext;
  pages: BookPage[];
  zoomValue: number;
  onClose: () => void;
  onReset: () => void;
  onZoomChange: (value: number) => void;
  onOffsetChange: (offsetLeft: number, offsetTop: number) => void;
  zoomBoxCoordsRef: React.MutableRefObject<Coords>;
  onToggleDoublePage: () => void;
}) {
  const divRef = useRef<HTMLDivElement>(null);

  const readerKey = readerKeyLookup[props.readerContext];
  const reader = useReaders()[readerKey];
  const { book, isDoublePage, pageIndex } = reader;

  const orientation = isDoublePage ? ZoomOrientation.Landscape : ZoomOrientation.Portrait;

  // If on the last page, AND the book has an even # of pages, AND we're in double page mode,
  // insert a blank page at the end of the book to maintain consistent slicing of pages
  const numPages = book?.pages?.length ?? 0;
  const showBlankPage = isDoublePage && numPages % 2 === 0 && pageIndex === numPages - 1;

  const isFirstPage = pageIndex === 0;
  const disableDoublePage = book ? !book.isDoublePageEnabled || (book.isStandardDoublePage && isFirstPage) : false;

  // If in double-page mode and standard double page, the front cover may only be shown by itself.
  const showDoublePage = isDoublePage && book?.isStandardDoublePage && isFirstPage ? false : isDoublePage;
  const pagesToShow = props.pages.slice(pageIndex, pageIndex + (showDoublePage ? 2 : 1)).map((p) => p.url);

  const doublePageToolTip = () => {
    if (!book?.isDoublePageEnabled) {
      return 'This feature is not available for decodables';
    } else if (disableDoublePage) {
      return 'Disabled on First Page';
    }
    return undefined;
  };

  const handleResetClick = () => {
    props.onReset();
    props.zoomBoxCoordsRef.current = { x: 0, y: 0 };
  };

  const handleOKClick = () => {
    props.onClose();
  };

  const handleSliderChange: SliderOwnProps['onChange'] = (_event, value, _activeThumb) => {
    if (!isNaN(value as number)) {
      props.onZoomChange(value as number);
    }
  };

  const handleZoomMinusClick = () => {
    const newValue = Math.max(props.zoomValue - 25, ZOOM_MIN);
    props.onZoomChange(newValue);
  };

  const handleZoomPlusClick = () => {
    const newValue = Math.min(props.zoomValue + 25, ZOOM_MAX);
    props.onZoomChange(newValue);
  };

  const handleDragStop = (e: DraggableEvent, data: DraggableData) => {
    props.zoomBoxCoordsRef.current = { x: data.x, y: data.y };
    const bounds = divRef.current?.getBoundingClientRect();

    if (bounds) {
      props.onOffsetChange((data.x / bounds.width) * 100, (data.y / bounds.height) * 100);
    }
  };

  const ZoomAmount = ZOOM_MIN * (ZOOM_MIN / props.zoomValue);

  const OrientationButton = function (props: { value: ZoomOrientation; onChangeOrientation: () => void }) {
    const { value, onChangeOrientation } = props;
    const isSelected = disableDoublePage ? value === ZoomOrientation.Portrait : orientation === value;
    const iconColor = isSelected ? hootTokens.palette.white : hootTokens.palette.black;

    const styles = {
      backgroundColor: isSelected ? hootTokens.palette.secondary[60] : hootTokens.palette.white,
      borderColor: isSelected ? hootTokens.palette.secondary[60] : hootTokens.palette.black,
      color: isSelected ? hootTokens.palette.white : hootTokens.palette.black,
    };

    let icon: IconName, label: string;
    if (value === ZoomOrientation.Portrait) {
      icon = 'crop_portrait';
      label = 'Portrait';
    } else {
      icon = 'crop_landscape';
      label = 'Landscape';
    }

    const onOrientationChange = () => {
      if (!isSelected) {
        onChangeOrientation();
      }
    };

    return (
      <Button
        sx={{ flexGrow: 1, ...styles }}
        size="small"
        startIcon={<Icon htmlColor={iconColor} name={icon} />}
        onClick={onOrientationChange}
        variant={isSelected ? 'contained' : 'outlined'}
        disabled={disableDoublePage || !book?.isDoublePageEnabled}
      >
        {label}
      </Button>
    );
  };

  return (
    <FloatingDialog width="308px" title="Zoom Control" onClose={props.onClose}>
      <Stack>
        <Stack
          sx={{
            overflow: 'hidden',
            position: 'relative',
          }}
          alignItems="center"
        >
          {/* Note: key required to be reset to reset the internal state of the draggable component */}
          <Draggable
            key={ZoomAmount === 100 ? 'zoomControl100' : 'zoomControl'}
            defaultPosition={props.zoomBoxCoordsRef.current}
            position={props.zoomBoxCoordsRef.current}
            bounds="parent"
            handle="#zoomer"
            onStop={handleDragStop}
          >
            <Zoomer
              id="zoomer"
              sx={{
                height: `min(calc(${ZoomAmount}% + 8px), 100%)`,
                width: `min(calc(${ZoomAmount}% + 8px), 100%)`,
              }}
            />
          </Draggable>

          <Stack ref={divRef} direction="row">
            {pagesToShow.map((p, idx) => (
              <Box key={`${p}-${idx}`}>
                <BookPageV2 key={idx} src={p} pageSide={props.pages.length === 1 ? 'singlePage' : idx === 0 ? 'left' : 'right'} />
              </Box>
            ))}
            {showBlankPage ? <BlankBookPage /> : null}
          </Stack>
        </Stack>

        <Stack sx={{ marginTop: '16px' }} alignItems="center" direction="row">
          <IconButton onClick={handleZoomMinusClick}>
            <Icon name="minus_circle" />
          </IconButton>
          <Stack alignItems="center" sx={{ paddingX: '8px', width: '100%' }}>
            <Slider
              onChange={handleSliderChange}
              color="secondary"
              min={ZOOM_MIN}
              step={ZOOM_STEP}
              max={ZOOM_MAX}
              aria-label="Small"
              value={Math.floor(props.zoomValue)}
              valueLabelDisplay="auto"
              valueLabelFormat={(val) => `${val}%`}
              onKeyDown={(event) => {
                // keyboard action reserved for pagination of book
                event.preventDefault();
              }}
            />
          </Stack>

          <IconButton onClick={handleZoomPlusClick}>
            <Icon name="plus_circle" />
          </IconButton>
        </Stack>

        <Stack direction="column">
          <Box mb={1}>
            <HootTypography isPII={false} variant="labelsmall">
              Mode
            </HootTypography>
          </Box>
          <Tooltip title={doublePageToolTip()}>
            <Stack direction="row" justifyContent="space-between" spacing={1}>
              <OrientationButton value={ZoomOrientation.Portrait} onChangeOrientation={props.onToggleDoublePage} />
              <OrientationButton value={ZoomOrientation.Landscape} onChangeOrientation={props.onToggleDoublePage} />
            </Stack>
          </Tooltip>
        </Stack>

        <Stack sx={{ marginTop: '24px' }} direction="row" justifyContent="space-between">
          <Button onClick={handleResetClick} variant="outlined">
            Reset
          </Button>
          <Button onClick={handleOKClick} variant="contained">
            OK
          </Button>
        </Stack>
      </Stack>
    </FloatingDialog>
  );
}

const Zoomer = styled('div')({
  borderRadius: '8px',
  border: `8px solid ${hootTokens.palette.error[100]}`,
  position: 'absolute',
  top: 0,
  left: 0,
  cursor: 'grab',
});
