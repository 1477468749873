import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Box, CircularProgress, LinearProgress } from '@mui/material';
import { useEffect } from 'react';
import { BookSearch } from '@hoot/events/interfaces/book-search';
import { QueryLibraryV2 } from '@hoot/hooks/api/library/useSearchLibrary';
import GrowList from '@hoot/ui/components/v2/GrowList';
import ViewStateIllustration, { IllustrationEnum } from '@hoot/ui/components/v2/ViewStateIllustration';
import { Button } from '@hoot/ui/components/v2/core/Button';
import Card from '@hoot/ui/components/v2/core/Card';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';
import { Icon } from '@hoot/ui/components/v2/core/Icon';
import { TeacherLibrarySearchResults } from '@hoot/ui/pages/v2/teacher/library/TeacherLibrary';
import { hasFilters } from '@hoot/ui/pages/v2/teacher/library/TeacherLibraryFilterBar';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';
import useIntersectionObserver from '../../../../../hooks/useIntersectionObserver';
import BookDisplay from './BookDisplay';

interface Props {
  query: QueryLibraryV2;
  isLoadingBooks: boolean;
  librarySearchResults: TeacherLibrarySearchResults;
  studentProfileId?: string;
  onFetchNextPage: () => void;
  onClearSearch: () => void;
  onClearFiltersAndSearch: () => void;
  handleBookClick: (bookId: string) => void;
}

const TeacherLibraryGalleryView = (props: Props) => {
  const { query, isLoadingBooks, librarySearchResults, studentProfileId, onFetchNextPage, onClearSearch, onClearFiltersAndSearch, handleBookClick } =
    props;

  const currentPage = query.page;
  const isAtEndOfLibrary = librarySearchResults.books.length >= librarySearchResults.total;

  const hasFiltersOrSearch = hasFilters(query) || !!query.title;
  const { isIntersecting: isLastBookVisible, observerRef } = useIntersectionObserver();

  // Load subsequent pages when we've scrolled to the bottom of the page.
  useEffect(() => {
    if (isLastBookVisible) {
      _onFetchNextPage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLastBookVisible]);

  const _onFetchNextPage = () => {
    if (!isLoadingBooks && !isAtEndOfLibrary) {
      onFetchNextPage();
    }
  };

  function Fetching() {
    return (
      <Box
        sx={{
          width: '248px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '216px',
        }}
      >
        <CircularProgress disableShrink />
      </Box>
    );
  }

  if (!isLoadingBooks && !librarySearchResults.books.length) {
    const title = query.title ? `No results found for "${query.title}"` : `No results found`;
    return (
      <Card sx={{ textAlign: 'center' }}>
        <>
          <ViewStateIllustration illustration={IllustrationEnum.NoResults} title={title} />
          {hasFiltersOrSearch ? (
            <Button variant="contained" size="small" onClick={onClearFiltersAndSearch} startIcon={<ChevronLeftIcon sx={{ color: '#fff' }} />}>
              Back
            </Button>
          ) : null}
        </>
      </Card>
    );
  }

  const searchResultsTitle = query?.title ? `Search results for "${query.title}"` : ``;

  return (
    <>
      {isLoadingBooks && currentPage === 1 && <LinearProgress sx={{ position: 'absolute', top: 0, left: 0, right: 0 }} />}
      {query?.title ? (
        <Box
          sx={(theme) => ({
            position: 'relative',
            display: 'flex',
            justifyContent: 'center',
            my: theme.spacing(3),
          })}
        >
          <HootTypography isPII={false} variant="titlelarge" sx={{ color: hootTokens.palette.primary['60'] }}>
            {searchResultsTitle}
          </HootTypography>

          <Button
            sx={{ marginLeft: '16px' }}
            color="error.80"
            variant="outlined"
            size="small"
            onClick={onClearSearch}
            startIcon={<Icon color="error" name="close_square" />}
          >
            Clear
          </Button>
        </Box>
      ) : null}
      <GrowList<BookSearch>
        StackProps={{
          direction: 'row',
          sx: {
            display: 'flex',
            flexDirection: 'row',
            gap: 2,
            flexWrap: 'wrap',
          },
        }}
        itemRenderTimeoutMillis={100}
        cascadeAnimate={(query?.page ?? 1) <= 1}
        items={librarySearchResults.books}
        getKey={(book) => book.id}
        observerRef={observerRef}
        renderItem={(book) => (
          <Box key={book.id} sx={{ width: '248px' }}>
            <BookDisplay
              book={{
                id: book.id,
                coverUrl: book.coverUrl,
                title: book.title,
                resourceState: book.resourceState,
              }}
              studentProfileId={studentProfileId}
              handleBookClick={handleBookClick}
            />
          </Box>
        )}
        // Only show the trailing loading indicator after we've loaded the first page. It looks weird and out of place
        // when there are no books visible. We use LinearProgress indicator at the top for the initial load.
        renderEnsuingItems={(query?.page ?? 1) > 1 && isLoadingBooks ? <Fetching /> : null}
      />
    </>
  );
};

export default TeacherLibraryGalleryView;
