import { DialogActions, DialogContent, FormControlLabel, Radio, RadioGroup, Stack } from '@mui/material';
import _ from 'lodash';
import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import { BookSearch } from '@hoot/events/interfaces/book-search';
import useGetInstructionalFocusWithUnits, { InstructionalFocusUnitResponse } from '@hoot/hooks/api/lesson-reviews/useGetInstructionalFocusWithUnits';
import useSearchLibrary, { QueryLibraryV2 } from '@hoot/hooks/api/library/useSearchLibrary';
import { InstructionalFocus } from '@hoot/models/api/enums/instructional-focus';
import {
  LessonReviewFocus,
  LessonReviewFocusSkill,
  LessonReviewFocusUnit,
  lessonReviewFocusLabel,
  lessonReviewFocusSkillLabel,
  lessonReviewFocusUnitLabel,
  lessonReviewLabelToFocus,
  lessonReviewLabelToUnit,
  lessonReviewSkillByUnit,
  lessonReviewUnitByFocusV2,
} from '@hoot/models/api/enums/lesson-review-enums';
import { ShelfType } from '@hoot/models/api/enums/shelf-type-enum';
import { BookType } from '@hoot/models/api/library';
import { GenericPaginatedResponse } from '@hoot/models/api/pagination';
import { error } from '@hoot/redux/reducers/alertSlice';
import { DEFAULT_PAGE } from '@hoot/redux/reducers/librarySlice';
import ViewStateIllustration, { IllustrationEnum } from '@hoot/ui/components/v2/ViewStateIllustration';
import { Button } from '@hoot/ui/components/v2/core/Button';
import Card from '@hoot/ui/components/v2/core/Card';
import { Chip } from '@hoot/ui/components/v2/core/Chip';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';
import { Icon } from '@hoot/ui/components/v2/core/Icon';
import { Select } from '@hoot/ui/components/v2/core/Select';
import { HeaderData, TableV2 } from '@hoot/ui/components/v2/core/Table';
import BasicAlertDialog from '@hoot/ui/components/v2/dialogs/BasicAlertDialog';
import {
  LessonReviewBook,
  LessonReviewInstructionalFocusOption,
  LessonReviewWizardStepEnum,
  getFocusId,
  useLessonReviewWizardContext,
} from '@hoot/ui/pages/v2/teacher/my-students/student-details/lesson-reviews/lesson-review-wizard/LessonReviewContextProvider';

const PAGE_SIZE = 100;

interface FocusAndBooksProps {
  index?: number;
  focusGroup?: LessonReviewInstructionalFocusOption;
  onRemoveBook?: (bookId: string) => void;
}

interface TextReadingFocusAndBooksProps {
  index: number;
  focusGroups: LessonReviewInstructionalFocusOption[];
  onRemoveBook: (bookId: string) => void;
  onUpdateLocalFocus: (id: string, localFocus: LessonReviewInstructionalFocusOption) => void;
  setCanGoNextPage: (canGoNextPage: boolean) => void;
}

interface InstructionalFocusBookTableRow {
  coverImageUrl: ReactNode;
  title: ReactNode;
  action: ReactNode;
}

interface TextReadingInstructionalFocusBookTableRow {
  title: ReactNode;
}

const FocusAndBooks = (props: FocusAndBooksProps) => {
  const [bookToRemove, setBookToRemove] = useState<LessonReviewBook | undefined>();

  const headers: HeaderData<InstructionalFocusBookTableRow>[] = [
    { name: '', property: 'coverImageUrl', isSortable: false, width: '10%' },
    { name: 'Resource Title', property: 'title', isSortable: false, width: '60%' },
    { name: 'Action', property: 'action', isSortable: false, width: '30%' },
  ];

  const books: InstructionalFocusBookTableRow[] = props.focusGroup?.focusBooks
    ? props.focusGroup.focusBooks.map((book) => {
        return {
          coverImageUrl: (
            <img
              src={book.coverImageUrl}
              alt={book.title}
              style={{
                objectFit: 'contain',
                maxWidth: '50px',
                width: '100%',
                borderRadius: '8px',
              }}
            />
          ),
          title: book.title,
          action: (
            <Button variant="outlined" color="error" size="small" onClick={() => setBookToRemove(book)}>
              Remove from Review
            </Button>
          ),
        };
      })
    : [];

  const focusLabel = props.focusGroup?.focus ? lessonReviewFocusLabel[props.focusGroup.focus] : '';
  const focusUnitLabel = props.focusGroup?.focusUnit ? lessonReviewFocusUnitLabel[props.focusGroup.focusUnit] : '';

  const handleRemove = () => {
    if (bookToRemove?.bookId) {
      props.onRemoveBook?.(bookToRemove.bookId);
    }
    setBookToRemove(undefined);
  };

  return (
    <Card sx={{ flex: 1 }}>
      <Stack gap={1} p={2}>
        <HootTypography isPII={false} variant="titlemedium">
          {`Instructional Focus ${props.index ? `#${props.index}` : ``}`}
        </HootTypography>
        {props?.index ? (
          <>
            <HootTypography isPII={false} variant="titlesmall">
              {`${focusLabel} ${focusUnitLabel ? `: ${focusUnitLabel}` : ``}`}
            </HootTypography>
            <HootTypography isPII={false} variant="bodymedium">
              {`Review the ${focusLabel} resources used in this lesson`}
            </HootTypography>
          </>
        ) : null}
      </Stack>
      <TableV2
        isPaginated={false}
        data={books}
        headers={headers}
        emptyViewState={
          <ViewStateIllustration
            title="No resources were used"
            subtitle="If you didn’t spend time on instruction, please go back to the previous step and select 'Rapport Building' instead"
            illustration={IllustrationEnum.EmptyInbox}
            subtitleSx={{ textAlign: 'center' }}
          />
        }
      />
      {!!bookToRemove ? (
        <BasicAlertDialog
          show={!!bookToRemove}
          onDismiss={() => setBookToRemove(undefined)}
          title={'Are you sure you want to remove the book?'}
          content={
            <Stack direction="row" gap={1} alignItems="center" justifyContent="center">
              <img
                src={bookToRemove.coverImageUrl}
                alt={bookToRemove.title}
                style={{
                  objectFit: 'contain',
                  maxWidth: '100px',
                  width: '100%',
                  borderRadius: '0px',
                }}
              />
              <HootTypography isPII={false} variant="labellarge">
                {bookToRemove.title}
              </HootTypography>
            </Stack>
          }
          primaryAction={{
            label: 'Confirm',
            onClick: handleRemove,
            props: {
              variant: 'contained',
            },
          }}
          secondaryAction={{
            label: 'Cancel',
            onClick: () => setBookToRemove(undefined),
          }}
        />
      ) : null}
    </Card>
  );
};

const TextReadingFocusesAndBooks = (props: TextReadingFocusAndBooksProps) => {
  const { index, focusGroups, onRemoveBook, onUpdateLocalFocus, setCanGoNextPage } = props;

  const [bookToRemove, setBookToRemove] = useState<LessonReviewBook | undefined>();
  const [numberOfTextReadingOptions, setNumberOfTextReadingOptions] = React.useState(!!focusGroups[1]?.focusUnit ? 2 : 1);
  const primaryFocus: LessonReviewInstructionalFocusOption = focusGroups[0];
  const secondaryFocus: LessonReviewInstructionalFocusOption | undefined = focusGroups[1];

  const headers: HeaderData<TextReadingInstructionalFocusBookTableRow>[] = [
    { name: 'Resource Title', property: 'title', isSortable: false, width: '100%' },
  ];

  const unitsRequiringSkill = useMemo(() => {
    return [LessonReviewFocusUnit.LanguageComprehension, LessonReviewFocusUnit.ReadingComprehension];
  }, []);

  const focusUnitOptions = lessonReviewUnitByFocusV2[LessonReviewFocus.TextReading].map<{
    value: LessonReviewFocusUnit;
    label: string;
  }>((focus) => {
    return {
      value: focus,
      label: lessonReviewFocusUnitLabel[focus],
    };
  });

  const focusSkillOptions = (lessonReviewFocusUnit?: LessonReviewFocusUnit) => {
    if (!lessonReviewFocusUnit) {
      return [];
    }
    return lessonReviewSkillByUnit[lessonReviewFocusUnit].map<{
      value: LessonReviewFocusSkill;
      label: string;
    }>((skill) => {
      return {
        value: skill,
        label: lessonReviewFocusSkillLabel[skill],
      };
    });
  };

  useEffect(() => {
    if (numberOfTextReadingOptions === 1) {
      const primaryIsCompleted =
        !!primaryFocus.focusUnit &&
        (!unitsRequiringSkill.includes(primaryFocus.focusUnit) ||
          (unitsRequiringSkill.includes(primaryFocus.focusUnit) && !!primaryFocus?.focusSkill));
      setCanGoNextPage(primaryIsCompleted);
    }
    if (numberOfTextReadingOptions === 2) {
      const primaryIsCompleted =
        !!primaryFocus.focusUnit &&
        (!unitsRequiringSkill.includes(primaryFocus.focusUnit) ||
          (unitsRequiringSkill.includes(primaryFocus.focusUnit) && !!primaryFocus?.focusSkill));
      const secondaryIsCompleted =
        !!secondaryFocus?.focusUnit &&
        (!unitsRequiringSkill.includes(secondaryFocus.focusUnit) ||
          (unitsRequiringSkill.includes(secondaryFocus.focusUnit) && !!secondaryFocus?.focusSkill));
      setCanGoNextPage(primaryIsCompleted && secondaryIsCompleted);
    }
  }, [primaryFocus, secondaryFocus, numberOfTextReadingOptions, unitsRequiringSkill, setCanGoNextPage]);

  const onUpdateRadioButton = (value: string) => {
    const numericValue = Number(value);
    setNumberOfTextReadingOptions(numericValue);
    if (numericValue === 1) {
      onUpdateLocalFocus(secondaryFocus.id, { ...secondaryFocus, focusUnit: undefined, focusSkill: undefined });
    }
  };

  const onUpdatePrimaryUnit = (value: string) => {
    onUpdateLocalFocus(primaryFocus.id, { ...primaryFocus, focusUnit: value as LessonReviewFocusUnit });
    if (secondaryFocus?.focusUnit === LessonReviewFocusUnit.Fluency && value === LessonReviewFocusUnit.Fluency) {
      onUpdateLocalFocus(secondaryFocus.id, { ...secondaryFocus, focusUnit: undefined, focusSkill: undefined });
    }
  };

  const onUpdatePrimarySkill = (value: string) => {
    onUpdateLocalFocus(primaryFocus.id, { ...primaryFocus, focusSkill: value as LessonReviewFocusSkill });
    if (primaryFocus.focusUnit === secondaryFocus?.focusUnit && secondaryFocus?.focusSkill === value) {
      onUpdateLocalFocus(secondaryFocus.id, { ...secondaryFocus, focusSkill: undefined });
    }
  };

  const onUpdateSecondaryUnit = (value: string) => {
    onUpdateLocalFocus(secondaryFocus.id, { ...secondaryFocus, focusUnit: value as LessonReviewFocusUnit });
  };

  const onUpdateSecondarySkill = (value: string) => {
    onUpdateLocalFocus(secondaryFocus.id, { ...secondaryFocus, focusSkill: value as LessonReviewFocusSkill });
  };

  const handleRemove = () => {
    if (bookToRemove?.bookId) {
      onRemoveBook?.(bookToRemove.bookId);
    }
    setBookToRemove(undefined);
  };

  const books: TextReadingInstructionalFocusBookTableRow[] = [
    {
      title: (
        <Stack gap={2} px={2}>
          <Stack direction="row" gap={2} flexWrap={'wrap'}>
            {primaryFocus?.focusBooks.map((book) => (
              <Chip
                key={`key-${book.bookId}`}
                label={
                  <Stack direction="row" gap={1} alignItems="center">
                    <img
                      src={book.coverImageUrl}
                      alt={book.title}
                      style={{
                        objectFit: 'contain',
                        maxWidth: '24px',
                        width: '100%',
                        borderRadius: '0px',
                      }}
                    />
                    <HootTypography isPII={false} variant="labelsmall">
                      {book.title}
                    </HootTypography>
                  </Stack>
                }
                onDelete={() => setBookToRemove(book)}
                deleteIcon={<Icon name="close" />}
              />
            ))}
          </Stack>
          {secondaryFocus ? (
            <Stack gap={1}>
              <HootTypography isPII={false} variant="titlesmall">
                Did you focus instruction on one or two areas of Text Reading?
              </HootTypography>
              <RadioGroup onChange={(_event, value) => onUpdateRadioButton(value)} value={numberOfTextReadingOptions}>
                <FormControlLabel
                  value={1}
                  control={<Radio />}
                  label={
                    <HootTypography isPII={false} variant="labellarge">
                      Instruction focused only on one Unit of Text Reading.
                    </HootTypography>
                  }
                  sx={{ display: 'flex', alignItems: 'center' }}
                />
                <FormControlLabel
                  value={2}
                  control={<Radio />}
                  label={
                    <HootTypography isPII={false} variant="labellarge">
                      Instruction focused on multiple Units of Text Reading.
                    </HootTypography>
                  }
                  sx={{ display: 'flex', alignItems: 'center' }}
                />
              </RadioGroup>
            </Stack>
          ) : null}
          <Stack gap={2}>
            <Stack direction="row" gap={2}>
              <Select
                label="Unit"
                name="primaryFocusUnit"
                value={primaryFocus.focusUnit}
                onChange={(e) => onUpdatePrimaryUnit(e.target.value)}
                fullWidth
              >
                <option value="">Select</option>
                {focusUnitOptions.map((option) => (
                  <option value={option.value} key={`unit-option-${option.value}`}>
                    {option.label}
                  </option>
                ))}
              </Select>
              {!!primaryFocus.focusUnit && unitsRequiringSkill.includes(primaryFocus.focusUnit) ? (
                <Select
                  label="Skill"
                  name="primaryFocusSkill"
                  value={primaryFocus?.focusSkill}
                  onChange={(e) => onUpdatePrimarySkill(e.target.value)}
                  fullWidth
                >
                  <option value="">Select</option>
                  {focusSkillOptions(primaryFocus?.focusUnit).map((option) => (
                    <option value={option.value} key={`skill-option-${option.value}`}>
                      {option.label}
                    </option>
                  ))}
                </Select>
              ) : null}
            </Stack>
            {numberOfTextReadingOptions === 2 && secondaryFocus ? (
              <Stack direction="row" gap={2}>
                <Select
                  label="Unit"
                  name="primaryFocusUnit"
                  value={secondaryFocus.focusUnit}
                  onChange={(e) => onUpdateSecondaryUnit(e.target.value)}
                  fullWidth
                >
                  <option value="">Select</option>
                  {focusUnitOptions
                    .filter((option) => primaryFocus.focusUnit !== LessonReviewFocusUnit.Fluency || option.value !== LessonReviewFocusUnit.Fluency)
                    .map((option) => (
                      <option value={option.value} key={`unit-option-${option.value}`}>
                        {option.label}
                      </option>
                    ))}
                </Select>
                {!!secondaryFocus.focusUnit && unitsRequiringSkill.includes(secondaryFocus.focusUnit) ? (
                  <Select
                    label="Skill"
                    name="primaryFocusSkill"
                    value={secondaryFocus?.focusSkill}
                    onChange={(e) => onUpdateSecondarySkill(e.target.value)}
                    fullWidth
                  >
                    <option value="">Select</option>
                    {focusSkillOptions(secondaryFocus?.focusUnit)
                      .filter(
                        (option) =>
                          (primaryFocus.focusUnit === secondaryFocus.focusUnit && option.value !== primaryFocus.focusSkill) ||
                          primaryFocus.focusUnit !== secondaryFocus.focusUnit,
                      )
                      .map((option) => (
                        <option value={option.value} key={`skill-option-${option.value}`}>
                          {option.label}
                        </option>
                      ))}
                  </Select>
                ) : null}
              </Stack>
            ) : null}
          </Stack>
        </Stack>
      ),
    },
  ];

  return (
    <Card sx={{ flex: 1 }}>
      <Stack gap={1} p={2}>
        <HootTypography isPII={false} variant="titlemedium">
          {`Instructional Focus ${index ? `#${index}` : ``}`}
        </HootTypography>
        {props?.index ? (
          <>
            <HootTypography isPII={false} variant="titlesmall">
              Text Reading
            </HootTypography>
            <HootTypography isPII={false} variant="bodymedium">
              Review the Text Reading resources used in this lesson
            </HootTypography>
          </>
        ) : null}
      </Stack>
      <TableV2
        isPaginated={false}
        data={books}
        headers={headers}
        emptyViewState={
          <ViewStateIllustration
            title="No resources were used"
            subtitle="If you didn’t spend time on instruction, please go back to the previous step and select 'Rapport Building' instead"
            illustration={IllustrationEnum.EmptyInbox}
            subtitleSx={{ textAlign: 'center' }}
          />
        }
      />
      {!!bookToRemove ? (
        <BasicAlertDialog
          show={!!bookToRemove}
          onDismiss={() => setBookToRemove(undefined)}
          title={'Are you sure you want to remove the book?'}
          content={
            <Stack direction="row" gap={1} alignItems="center" justifyContent="center">
              <img
                src={bookToRemove.coverImageUrl}
                alt={bookToRemove.title}
                style={{
                  objectFit: 'contain',
                  maxWidth: '100px',
                  width: '100%',
                  borderRadius: '0px',
                }}
              />
              <HootTypography isPII={false} variant="labellarge">
                {bookToRemove.title}
              </HootTypography>
            </Stack>
          }
          primaryAction={{
            label: 'Confirm',
            onClick: handleRemove,
            props: {
              variant: 'contained',
            },
          }}
          secondaryAction={{
            label: 'Cancel',
            onClick: () => setBookToRemove(undefined),
          }}
        />
      ) : null}
    </Card>
  );
};

const LessonReviewInstructionalFocusStep = () => {
  const {
    shouldUpdateFocusOptions,
    lessonToReview,
    instructionalFocusOptions,
    actions: { goToNextStep, goToPreviousStep, attachInstructionalFocusOptions, toggleWizardStep, setShouldUpdateFocusOptions },
  } = useLessonReviewWizardContext();

  const [focusGroupsCombinations, setFocusGroupsCombinations] = useState<InstructionalFocusUnitResponse[]>([]);
  const [localFocusOptions, setLocalFocusOptions] = useState<LessonReviewInstructionalFocusOption[]>(
    shouldUpdateFocusOptions
      ? []
      : instructionalFocusOptions.filter(
          (option) =>
            option.focus &&
            [
              LessonReviewFocus.PreWordReading,
              LessonReviewFocus.EarlyWordReading,
              LessonReviewFocus.ComplexWordReading,
              LessonReviewFocus.TextReading,
            ].includes(option.focus),
        ),
  );
  const [canGoNextStep, setCanGoNextPage] = useState<boolean>(true);
  const booksUsedInRapportBuilding = instructionalFocusOptions
    .find((focusOption) => focusOption.focus === LessonReviewFocus.RelationshipAndRapportBuilding)
    ?.focusBooks.map((books) => books.bookId);

  const { isFetching: isInstructionFocusWithUnitsFetching } = useGetInstructionalFocusWithUnits(BookType.Instruction, {
    onSuccess: (response) => {
      setFocusGroupsCombinations(
        response.instructionalFocusUnits.filter((option) => option.instructionalFocusName !== lessonReviewFocusLabel[LessonReviewFocus.TextReading]),
      );
    },
  });

  const libraryQuery: QueryLibraryV2 = {
    shelfType: ShelfType.AllBooks,
    page: DEFAULT_PAGE,
    pageSize: PAGE_SIZE,
    instructionalFocusIds: undefined,
    excludedInstructionalFocusIds: [
      InstructionalFocus.FormativeAssessmentTextReading,
      InstructionalFocus.FormativeAssessmentEarlyWordReading,
      InstructionalFocus.FormativeAssessmentComplexWordReading,
      InstructionalFocus.FormativeAssessmentPreWordReading,
    ],
    scheduledLessonId: lessonToReview?.lessonId,
    excludeBookIds: booksUsedInRapportBuilding,
  };

  const { isFetching: isBooksFetching } = useSearchLibrary(libraryQuery, {
    enabled: !!lessonToReview?.lessonId && focusGroupsCombinations.length > 0 && shouldUpdateFocusOptions,
    retry: false,
    onSuccess: (response: GenericPaginatedResponse<BookSearch>) => {
      let books = _.cloneDeep(response.data);
      if (books.length === 0) {
        return;
      }
      const focusGroups: LessonReviewInstructionalFocusOption[] = [];

      focusGroupsCombinations.forEach((focusGroupCombination) => {
        const matchBooks = books.filter(
          (book) =>
            book.focuses?.find((focus) => focus.id === focusGroupCombination.instructionalFocusId) &&
            book.units?.find((unit) => unit.id === focusGroupCombination.instructionalUnitId),
        );
        if (matchBooks.length > 0) {
          focusGroups.push({
            id: getFocusId(focusGroupCombination.instructionalFocusName!, focusGroupCombination.instructionalUnitName),
            focus: lessonReviewLabelToFocus[focusGroupCombination.instructionalFocusName],
            focusUnit: lessonReviewLabelToUnit[focusGroupCombination.instructionalUnitName],
            focusSkill: undefined,
            focusBooks: matchBooks.map((book) => ({
              bookId: book.id,
              title: book.title,
              coverImageUrl: book.coverUrl || undefined,
              focuses: book.focuses,
              units: book.units,
            })),
          });
          const matchIds = new Set(matchBooks.map((book) => book.id));
          books = books.filter((book) => !matchIds.has(book.id));
        }
      });

      const hasNonTextReadingFocuses = focusGroups.length > 0;

      if (books.length > 0) {
        const currentTextReadingFocuses = instructionalFocusOptions.filter((option) => option.focus === LessonReviewFocus.TextReading);

        const updatedTextReadingFocuses =
          currentTextReadingFocuses.length > 0
            ? currentTextReadingFocuses.map((option) => ({
                ...option,
                focusBooks: books.map((book) => ({
                  bookId: book.id,
                  title: book.title,
                  coverImageUrl: book.coverUrl || undefined,
                })),
              }))
            : [
                {
                  id: getFocusId(LessonReviewFocus.TextReading, 'ONE'),
                  focus: LessonReviewFocus.TextReading,
                  focusUnit: undefined,
                  focusSkill: undefined,
                  focusBooks: books.map((book) => ({
                    bookId: book.id,
                    title: book.title,
                    coverImageUrl: book.coverUrl || undefined,
                    focuses: book.focuses,
                    units: book.units,
                  })),
                },
                ...(hasNonTextReadingFocuses
                  ? []
                  : [
                      {
                        id: getFocusId(LessonReviewFocus.TextReading, 'TWO'),
                        focus: LessonReviewFocus.TextReading,
                        focusUnit: undefined,
                        focusSkill: undefined,
                        focusBooks: books.map((book) => ({
                          bookId: book.id,
                          title: book.title,
                          coverImageUrl: book.coverUrl || undefined,
                          focuses: book.focuses,
                          units: book.units,
                        })),
                      },
                    ]),
              ];

        focusGroups.push(...updatedTextReadingFocuses);
      }

      setLocalFocusOptions(focusGroups);
    },
    onError: (err) => {
      console.error(err);
      error(`An error occurred while loading books.`);
    },
  });

  const focusOptionsWithBooks = localFocusOptions.filter((focusGroup) => focusGroup.focusBooks.length > 0) ?? [];
  const nonTextReadingFocusOptionsWithBooks = focusOptionsWithBooks.filter((focusGroup) => focusGroup.focus !== LessonReviewFocus.TextReading);
  const textReadingFocusOptionsWithBooks = focusOptionsWithBooks.filter((focusGroup) => focusGroup.focus === LessonReviewFocus.TextReading);
  const allFocusOptionsWithBooks = [...nonTextReadingFocusOptionsWithBooks, ...textReadingFocusOptionsWithBooks];
  const resourcesUsed = allFocusOptionsWithBooks.length > 0;

  useEffect(() => {
    if (resourcesUsed) {
      toggleWizardStep(LessonReviewWizardStepEnum.FocusPriority, resourcesUsed);
    }
  }, [resourcesUsed, toggleWizardStep]);

  const onRemoveBook = (bookId: string): void => {
    setLocalFocusOptions((prevState) => {
      return [...prevState].map((focusGroup) => {
        return { ...focusGroup, focusBooks: focusGroup.focusBooks.filter((book) => book.bookId !== bookId) };
      });
    });
  };

  const onUpdateLocalFocus = (id: string, updatedLocalFocus: LessonReviewInstructionalFocusOption) => {
    setLocalFocusOptions((prevState) => prevState.map((localFocus) => (localFocus.id === id ? { ...localFocus, ...updatedLocalFocus } : localFocus)));
  };

  const submitInstructionalFocusData = () => {
    const nonInstructionalFocuses = instructionalFocusOptions.filter(
      (option) =>
        option.focus &&
        ![
          LessonReviewFocus.PreWordReading,
          LessonReviewFocus.EarlyWordReading,
          LessonReviewFocus.ComplexWordReading,
          LessonReviewFocus.TextReading,
        ].includes(option.focus),
    );
    const instructionalFocuses = focusOptionsWithBooks.map((focusGroup) => ({
      ...focusGroup,
      id: getFocusId(focusGroup.focus!, focusGroup.focusUnit),
    }));
    attachInstructionalFocusOptions([...nonInstructionalFocuses, ...instructionalFocuses]);
    setShouldUpdateFocusOptions(false);
    goToNextStep();
  };

  if (isInstructionFocusWithUnitsFetching || isBooksFetching) {
    return null;
  }

  return (
    <>
      <DialogContent>
        <Stack gap={2} pb={2}>
          <HootTypography isPII={false} variant="titlemedium">
            Instructional Focus
          </HootTypography>
          <HootTypography isPII={true} variant="bodymedium">
            {`The books that were used in ${lessonToReview?.student.name ?? ''}’s lesson have been placed below for review.`}
          </HootTypography>

          <Stack gap={2}>
            {allFocusOptionsWithBooks.length > 0 ? (
              <>
                {nonTextReadingFocusOptionsWithBooks
                  ? nonTextReadingFocusOptionsWithBooks.map((group, index) => (
                      <FocusAndBooks index={index + 1} focusGroup={group} onRemoveBook={onRemoveBook} />
                    ))
                  : null}
                {textReadingFocusOptionsWithBooks.length > 0 ? (
                  <TextReadingFocusesAndBooks
                    index={nonTextReadingFocusOptionsWithBooks.length + 1}
                    focusGroups={textReadingFocusOptionsWithBooks}
                    onRemoveBook={onRemoveBook}
                    onUpdateLocalFocus={onUpdateLocalFocus}
                    setCanGoNextPage={setCanGoNextPage}
                  />
                ) : null}
              </>
            ) : (
              <FocusAndBooks />
            )}
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={goToPreviousStep}>
          Go Back
        </Button>
        <Button variant="contained" onClick={submitInstructionalFocusData} disabled={textReadingFocusOptionsWithBooks.length > 0 && !canGoNextStep}>
          Next
        </Button>
      </DialogActions>
    </>
  );
};

export default LessonReviewInstructionalFocusStep;
