import axios from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '@hoot/config';
import { FormativeAssessmentType } from '@hoot/models/api/enums/lesson-review-enums';
import { SortableQuery } from '@hoot/models/api/order-by';
import { GenericPaginatedResponse, PaginatableQuery } from '@hoot/models/api/pagination';
import { QueryKey } from '../queryKeys';

export interface FormativeAssessmentSearchResult {
  lessonReviewId: string;
  bookId: string;
  bookTitle: string;
  studentProfileId: string;
  lastUpdatedAt: number;
  formativeAssessmentType: FormativeAssessmentType;
  score?: string;
}

export enum StudentFormativeAssessmentsQuerySortKeyEnum {
  Date = 'Date',
  ResourceUsed = 'ResourceUsed',
  Score = 'Score',
}

export type GetStudentFormativeAssessmentsQuery = PaginatableQuery &
  SortableQuery<StudentFormativeAssessmentsQuerySortKeyEnum> & { lessonReviewId?: string };

export function useGetFormativeAssessments(
  studentProfileId: string,
  query: GetStudentFormativeAssessmentsQuery,
  options?: Omit<UseQueryOptions<GenericPaginatedResponse<FormativeAssessmentSearchResult>>, 'queryKey' | 'queryFn'>,
) {
  return useQuery(
    [QueryKey.GetStudentFormativeAssessments, studentProfileId, query],
    async () => {
      return (
        await axios.get<GenericPaginatedResponse<FormativeAssessmentSearchResult>>(
          `${config.apiUrl}/public/lesson-review/formative-assessment/${studentProfileId}`,
          {
            params: query,
          },
        )
      ).data;
    },
    options,
  );
}
