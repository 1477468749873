import { PasswordStatus } from '@hoot-reading/hoot-core/dist/enums/hfs/password-status.enum';
import CloseIcon from '@mui/icons-material/Close';
import { Dialog, DialogActions, DialogContent, DialogTitle, Stack } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { PASSWORD_MAXIMUM_LENGTH, PASSWORD_MINIMUM_LENGTH } from '@hoot/constants/constants';
import { DistrictRepresentativeStudentResponse } from '@hoot/hooks/api/district-rep/useSearchDistrictRepresentativeStudents';
import { useSetStudentPassword } from '@hoot/hooks/api/district-rep/useSetStudentPassword';
import { QueryKey } from '@hoot/hooks/api/queryKeys';
import { RootState } from '@hoot/redux/store';
import { Button } from '@hoot/ui/components/v2/core/Button';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';
import { IconButton } from '@hoot/ui/components/v2/core/IconButton';
import TextField from '@hoot/ui/components/v2/core/TextField';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';

interface PasswordDialogProps {
  show: boolean;
  student: DistrictRepresentativeStudentResponse;
  onDismiss: () => void;
  setShowSuccess: Dispatch<SetStateAction<boolean>>;
  setShowError: Dispatch<SetStateAction<string>>;
}

export interface PasswordForm {
  password: string;
  confirmPassword: string;
}

const PasswordDialog = (props: PasswordDialogProps) => {
  const { show, onDismiss, student, setShowSuccess, setShowError } = props;
  const districtRepresentativeId = useSelector((state: RootState) => state.profile.profile?.id);
  const isEditMode = student.passwordStatus === PasswordStatus.Set;
  const {
    formState: { errors, isDirty },
    handleSubmit,
    control,
    watch,
  } = useForm<PasswordForm>({
    defaultValues: {
      password: '',
      confirmPassword: '',
    },
  });
  const { password } = watch();
  const queryClient = useQueryClient();
  const setStudentPasswordMutation = useSetStudentPassword(districtRepresentativeId!, student.id, {
    onSuccess: () => {
      queryClient.invalidateQueries(QueryKey.SearchDistrictRepresentativeStudents);
      setShowSuccess(true);
    },
    onError: (exc) => {
      const errorText = `There was a problem changing ${student.name} password.\nContact help@hootreading.com`;
      console.error(exc.message);
      setShowError(errorText);
    },
  });

  const onSubmit = async (data: any) => {
    setStudentPasswordMutation.mutate({
      password: data.password,
      confirmPassword: data.confirmPassword,
    });
    onDismiss();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} id="district-rep-students-password">
      <Dialog open={show} fullWidth maxWidth="sm">
        <DialogTitle sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          {isEditMode ? 'Change' : 'Set'} Student Password
          <IconButton onClick={onDismiss} sx={{ color: hootTokens.palette.black }}>
            <CloseIcon color="inherit" />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <HootTypography isPII={true} variant="titlesmall">
            {isEditMode ? 'Change' : 'Set'} Password for {student.name}
          </HootTypography>
          <Stack gap={3} justifyContent="space-between" sx={{ mt: 2 }}>
            <Controller
              name="password"
              control={control}
              rules={{
                required: 'Password is required.',
                minLength: {
                  value: PASSWORD_MINIMUM_LENGTH,
                  message: `Password must be a minimum of ${PASSWORD_MINIMUM_LENGTH} characters.`,
                },
                maxLength: {
                  value: PASSWORD_MAXIMUM_LENGTH,
                  message: `Password must be a maximum of ${PASSWORD_MAXIMUM_LENGTH} characters.`,
                },
              }}
              render={({ field: { onChange, value } }) => (
                <TextField
                  variant="outlined"
                  fullWidth
                  id="password"
                  type="password"
                  label="New Password"
                  onChange={onChange}
                  value={value}
                  helperText={errors.password?.message ?? ''}
                  error={!!errors.password}
                />
              )}
            />
            <Controller
              name="confirmPassword"
              control={control}
              rules={{
                validate: (value) => value === password || 'Passwords do not match.',
                minLength: {
                  value: PASSWORD_MINIMUM_LENGTH,
                  message: `Password must be a minimum of ${PASSWORD_MINIMUM_LENGTH} characters.`,
                },
                maxLength: {
                  value: PASSWORD_MAXIMUM_LENGTH,
                  message: `Password must be a maximum of ${PASSWORD_MAXIMUM_LENGTH} characters.`,
                },
              }}
              render={({ field: { onChange, value } }) => (
                <TextField
                  variant="outlined"
                  fullWidth
                  id="confirmPassword"
                  type="password"
                  label="Confirm New Password"
                  onChange={onChange}
                  value={value}
                  helperText={errors.confirmPassword?.message ?? ''}
                  error={!!errors.confirmPassword}
                />
              )}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button type="button" onClick={onDismiss} variant="outlined" size="medium">
            Cancel
          </Button>
          <Button type="submit" disabled={!isDirty} variant="contained" size="medium" form="district-rep-students-password">
            Apply
          </Button>
        </DialogActions>
      </Dialog>
    </form>
  );
};
export default PasswordDialog;
