import axios, { AxiosError } from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '../../../config';
import { QueryKey } from '../queryKeys';

export interface LessonPlanBook {
  id: string;
  title: string;
  resourceProgress: string;
  resourceType: string;
  instructionalFocus: string;
  instructionalUnit: string;
}

export interface GetLessonPlanBookDetailsQuery {
  bookIds: string[];
}
export interface GetLessonPlanBookDetailsResponse {
  bookDetails: LessonPlanBook[];
}

async function getLessonPlanBookDetails(studentProfileId: string, query: GetLessonPlanBookDetailsQuery): Promise<GetLessonPlanBookDetailsResponse> {
  const { data } = await axios.get<GetLessonPlanBookDetailsResponse>(
    `${config.apiUrl}/public/lesson-review/lesson-plan/${studentProfileId}/book-details`,
    { params: { bookIds: query.bookIds } },
  );
  return data;
}

export default function useGetLessonPlanBookDetails(
  studentProfileId: string,
  query: GetLessonPlanBookDetailsQuery,
  options?: Omit<UseQueryOptions<GetLessonPlanBookDetailsResponse, AxiosError>, 'queryKey' | 'queryFn'>,
) {
  return useQuery([QueryKey.GetLessonPlanBookDetails, studentProfileId], () => getLessonPlanBookDetails(studentProfileId, query), options);
}
