import { hootTokens } from '@hoot/ui/theme/v2/tokens';

export enum FocusAreaSlug {
  preWordReading = 'pre-word-reading',
  earlyWordReading = 'early-word-reading',
  complexWordReading = 'complex-word-reading',
  textReading = 'text-reading',
}

export interface ScopeAndSequenceFocusArea {
  instructionalFocusId: string;
  slug: FocusAreaSlug;
  title: string;
  tileImageUrl: string;
  tileImageAlt: string;
  splashImageUrl: string;
  splashImageAlt: string;
}

export const scopeAndSequenceFocusAreaDictionary: Record<FocusAreaSlug, ScopeAndSequenceFocusArea> = {
  [FocusAreaSlug.preWordReading]: {
    instructionalFocusId: '062ff663-d269-00ac-7703-c944874cde5e',
    slug: FocusAreaSlug.preWordReading,
    title: 'Pre-Word Reading',
    tileImageUrl: 'https://images.hootreading.com/scope-and-sequence/pre-word-reading-tile-image.png',
    tileImageAlt: 'Pre-word reading tile image',
    splashImageUrl: 'https://images.hootreading.com/scope-and-sequence/pre-word-reading-splash-image.png',
    splashImageAlt: 'Pre-word reading splash image',
  },
  [FocusAreaSlug.earlyWordReading]: {
    instructionalFocusId: 'd21bfa7f-7ae4-1847-d931-668c388b02e9',
    slug: FocusAreaSlug.earlyWordReading,
    title: 'Early-Word Reading',
    tileImageUrl: 'https://images.hootreading.com/scope-and-sequence/early-word-reading-tile-image.png',
    tileImageAlt: 'Early-word reading tile image',
    splashImageUrl: 'https://images.hootreading.com/scope-and-sequence/early-word-reading-splash-image.png',
    splashImageAlt: 'Early-word reading splash image',
  },
  [FocusAreaSlug.complexWordReading]: {
    instructionalFocusId: '72e9e1a2-56c4-d876-3f54-e1f84945548d',
    slug: FocusAreaSlug.complexWordReading,
    title: 'Complex-Word Reading',
    tileImageUrl: 'https://images.hootreading.com/scope-and-sequence/complex-word-reading-tile-image.png',
    tileImageAlt: 'Complex-word reading tile image',
    splashImageUrl: 'https://images.hootreading.com/scope-and-sequence/complex-word-reading-splash-image.png',
    splashImageAlt: 'Complex-word reading splash image',
  },
  [FocusAreaSlug.textReading]: {
    instructionalFocusId: '53a6971a-d545-465d-6029-c50a525ccd90',
    slug: FocusAreaSlug.textReading,
    title: 'Text Reading',
    tileImageUrl: 'https://images.hootreading.com/scope-and-sequence/text-reading-tile-image.png',
    tileImageAlt: 'Text-word reading tile image',
    splashImageUrl: 'https://images.hootreading.com/scope-and-sequence/text-reading-splash-image.png',
    splashImageAlt: 'Text reading splash image',
  },
};

export const scopeAndSequenceTileColourDictionary: Record<
  FocusAreaSlug,
  {
    tileColours: { primary: string; secondary: string }[];
  }
> = {
  [FocusAreaSlug.preWordReading]: {
    tileColours: [{ primary: '#740128', secondary: '#815363' }],
  },
  [FocusAreaSlug.earlyWordReading]: {
    tileColours: [
      { primary: '#B80201', secondary: '#C05B5B' },
      { primary: '#A7006A', secondary: '#A55387' },
      { primary: '#111C54', secondary: '#576193' },
      { primary: '#01591C', secondary: '#387A4C' },
      { primary: '#180D87', secondary: '#5C55A9' },
      { primary: '#880394', secondary: '#AA51B2' },
    ],
  },
  [FocusAreaSlug.complexWordReading]: {
    tileColours: [
      { primary: '#034B73', secondary: '#306989' },
      { primary: '#034140', secondary: '#276766' },
      { primary: '#660264', secondary: '#873886' },
      { primary: '#2E0065', secondary: '#593980' },
      { primary: '#405F3F', secondary: '#448342' },
      { primary: '#4B0C2B', secondary: '#994870' },
    ],
  },
  [FocusAreaSlug.textReading]: {
    tileColours: [{ primary: hootTokens.palette.black, secondary: hootTokens.palette.neutral['100'] }],
  },
};
