import { StudentType } from '@hoot-reading/hoot-core/dist/enums/user/student/student-type.enum';
import { Backdrop, DialogContent, DialogTitle, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { FieldPath } from 'react-hook-form/dist/types/path';
import { zIndexes } from '@hoot/constants/zIndices';
import useGetFilterLevelPrioritizedSkills from '@hoot/hooks/api/library/useGetFilterLevelPrioritizedSkills';
import useGetInstructionalFocuses from '@hoot/hooks/api/library/useGetInstructionalFocuses';
import useGetInstructionalUnits from '@hoot/hooks/api/library/useGetInstructionalUnits';
import { InstructionalFocus } from '@hoot/models/api/enums/instructional-focus';
import { BookType } from '@hoot/models/api/library';
import SublibrarySelector from '@hoot/ui/components/v2/SublibrarySelector';
import BackdroplessDialog from '@hoot/ui/components/v2/core/BackdroplessDialog';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';
import OptionSelectedChip from '@hoot/ui/components/v2/core/OptionSelectedChip';
import PlainButton from '@hoot/ui/components/v2/core/PlainButton';
import { LottieFile } from '@hoot/ui/components/v2/lottie/Lottie';
import LottieButton from '@hoot/ui/components/v2/lottie/LottieButton';
import StudentDecodableAccessLibraryFilterSelectDialog from '@hoot/ui/pages/v2/student/library/StudentDecodableAccessLibraryFilterSelectDialog';
import { StudentSubLibrarySelection } from '@hoot/ui/pages/v2/student/library/StudentLibraryPage';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';

export interface StudentDecodableAccessLibraryFilterModalProps {
  isOpen: boolean;
  studentType: StudentType;
  defaultSublibrarySelection: StudentSubLibrarySelection;
  defaultFilters: Partial<StudentDecodableLibraryFilters> | undefined;
  onApplyFilters: (filters: StudentDecodableLibraryFilters, sublibrary: StudentSubLibrarySelection) => void;
  onDismiss: () => void;
}

interface DecodableLibraryFilterForm {
  instructionalFocusId: InstructionalFocus | null;
  instructionalUnitId: string | null;
  prioritizedSkillIds: Set<string>;
}

export interface StudentDecodableLibraryFilters {
  instructionalFocusId: InstructionalFocus | undefined;
  instructionalUnitId: string | undefined;
  prioritizedSkillIds: string[] | undefined;
}

type OptionValue = string;
type OptionLabel = string;

export interface StudentDecodableLibraryFilterOptionSelectProps {
  formKey: FieldPath<DecodableLibraryFilterForm>;
  label: string;
  options: Record<OptionValue, OptionLabel>;
  isMultiSelect: boolean;
}

const StudentDecodableAccessLibraryFilterDialog = (props: StudentDecodableAccessLibraryFilterModalProps) => {
  const { isOpen, defaultSublibrarySelection, defaultFilters, studentType, onApplyFilters, onDismiss } = props;

  const [sublibrarySelection, setSublibrarySelection] = useState(defaultSublibrarySelection);

  const [filterOptionSelect, setFilterOptionSelect] = useState<StudentDecodableLibraryFilterOptionSelectProps>();
  const [showFilterSelectDialog, setShowFilterSelectDialog] = useState(false);
  const [instructionalFocusOptions, setInstructionalFocusOptions] = useState<StudentDecodableLibraryFilterOptionSelectProps['options']>({});
  const [instructionalUnitOptions, setInstructionalUnitOptions] = useState<StudentDecodableLibraryFilterOptionSelectProps['options']>({});
  const [prioritizedSkillOptions, setPrioritizedSkillOptions] = useState<StudentDecodableLibraryFilterOptionSelectProps['options']>({});

  const defaultFormValues: DecodableLibraryFilterForm = {
    instructionalFocusId: defaultFilters?.instructionalFocusId ?? null,
    instructionalUnitId: defaultFilters?.instructionalUnitId ?? null,
    prioritizedSkillIds: !!defaultFilters?.prioritizedSkillIds ? new Set(defaultFilters?.prioritizedSkillIds) : new Set(),
  };

  const form = useForm<DecodableLibraryFilterForm>({
    defaultValues: defaultFormValues,
  });

  const selectedInstructionalFocusId = form.watch('instructionalFocusId');
  const selectedInstructionalUnitId = form.watch('instructionalUnitId');

  useGetInstructionalFocuses(
    { resourceType: BookType.Instruction },
    {
      enabled: isOpen,
      onSuccess: (response) => {
        setInstructionalFocusOptions(
          response.instructionalFocuses
            // Kinda hacky, but 'Text Reading' is the only "instruction" book type that we want to hide here.
            .filter((x) => x.id !== InstructionalFocus.InstructionTextReading)
            .reduce(
              (map, curr) => ({
                ...map,
                [curr.id]: curr.name,
              }),
              {},
            ),
        );
      },
    },
  );
  const getInstructionalUnitsRequest = useGetInstructionalUnits(
    {
      instructionalFocuses: selectedInstructionalFocusId ? [selectedInstructionalFocusId] : [],
    },
    {
      enabled: isOpen && !!selectedInstructionalFocusId,
      onSuccess: (response) => {
        setInstructionalUnitOptions(
          response.categorizedInstructionalUnits
            .flatMap((x) => x.listItems)
            .reduce(
              (map, curr) => ({
                ...map,
                [curr.value]: curr.label,
              }),
              {},
            ),
        );
      },
    },
  );
  const getFilterLevelPrioritizedSkillRequest = useGetFilterLevelPrioritizedSkills(
    {
      instructionalUnits: selectedInstructionalUnitId ? [selectedInstructionalUnitId] : [],
    },
    {
      enabled: isOpen && !!selectedInstructionalUnitId,
      onSuccess: (response) => {
        setPrioritizedSkillOptions(
          response.categorizedPrioritizedSkills
            .flatMap((x) => x.listItems)
            .reduce(
              (map, curr) => ({
                ...map,
                [curr.value]: curr.label,
              }),
              {},
            ),
        );
      },
    },
  );

  // Reset everything when dialog is opened.
  useEffect(() => {
    if (isOpen) {
      setShowFilterSelectDialog(false);
      clearFilterOptionSelect();
      // Set defaults.
      setSublibrarySelection(defaultSublibrarySelection);
      form.reset(defaultFormValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const onSublibrarySelectionChanged = (sublibrarySelection: StudentSubLibrarySelection) => {
    setSublibrarySelection(sublibrarySelection);
  };

  const onResetForm = () => {
    setSublibrarySelection(StudentSubLibrarySelection.AllBooks);
    form.reset({
      instructionalFocusId: null,
      instructionalUnitId: null,
      prioritizedSkillIds: new Set(),
    });
  };

  const onSubmitFilters = (values: DecodableLibraryFilterForm) => {
    onApplyFilters(
      {
        instructionalFocusId: values.instructionalFocusId ?? undefined,
        instructionalUnitId: values.instructionalUnitId ?? undefined,
        prioritizedSkillIds: values.prioritizedSkillIds.size > 0 ? Array.from(values.prioritizedSkillIds.values()) : undefined,
      },
      sublibrarySelection,
    );
    onDismiss();
  };

  const onApplyFilterSelection = (formKey: FieldPath<DecodableLibraryFilterForm>, value: string | Set<string>) => {
    form.setValue(formKey, value);
    onDismissFilterSelectDialog();
  };

  const onDismissFilterSelectDialog = () => {
    setShowFilterSelectDialog(false);
  };

  const clearFilterOptionSelect = () => {
    setFilterOptionSelect(undefined);
  };

  const showFocusSelector = () => {
    setFilterOptionSelect({
      label: 'Select Focus',
      options: instructionalFocusOptions,
      formKey: 'instructionalFocusId',
      isMultiSelect: false,
    });
    setShowFilterSelectDialog(true);
  };

  const showInstructionalUnitSelector = () => {
    setFilterOptionSelect({
      label: 'Select Unit',
      options: instructionalUnitOptions,
      formKey: 'instructionalUnitId',
      isMultiSelect: false,
    });
    setShowFilterSelectDialog(true);
  };

  const showPrioritizedSkillsSelector = () => {
    setFilterOptionSelect({
      label: 'Select Skill(s)',
      options: prioritizedSkillOptions,
      formKey: 'prioritizedSkillIds',
      isMultiSelect: true,
    });
    setShowFilterSelectDialog(true);
  };

  const onRemoveSingleSelectItem = (formKey: FieldPath<DecodableLibraryFilterForm>) => {
    form.setValue(formKey, null);

    // Clearing instructional focus ID selection should invalidate all "unfurled" filter selections as well.
    if (formKey === 'instructionalFocusId') {
      form.setValue('instructionalUnitId', null);
      form.setValue('prioritizedSkillIds', new Set());
    } else if (formKey === 'instructionalUnitId') {
      // Clearing instructional unit ID selection should invalidate all "unfurled" filter selections as well (there is only one in this case).
      form.setValue('prioritizedSkillIds', new Set());
    }
  };

  const onRemoveMultiSelectItem = (formKey: FieldPath<DecodableLibraryFilterForm>, multiSelectItemToRemove: string) => {
    const currentFormValue = form.getValues(formKey) as Set<string>;
    const newMultiSelectValue = new Set(currentFormValue);
    newMultiSelectValue.delete(multiSelectItemToRemove);
    form.setValue(formKey, newMultiSelectValue);
  };

  return (
    <>
      {/* Root-level filter dialog. */}
      <BackdroplessDialog open={isOpen && !showFilterSelectDialog} onClose={onDismiss}>
        <form onSubmit={form.handleSubmit(onSubmitFilters)}>
          <DialogTitle
            component={'div'}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              px: 5,
              height: '104px',
              ...hootTokens.text.displaysmall,
            }}
          >
            <span>Filter</span>
            <Stack direction={'row'}>
              <LottieButton
                tooltipLabel="Reset filters"
                lottieFile={LottieFile.RestartArrow}
                aria-label="Reset arrow"
                onClick={onResetForm}
                variant="transparent"
                sx={{ p: 1, width: '104px', height: '104px', transform: 'scale(-1, 1)' }}
              />
              <LottieButton
                tooltipLabel="Cancel changes"
                aria-label="Cancel changes"
                lottieFile={LottieFile.ErrorCross}
                onClick={onDismiss}
                variant="transparent"
                sx={{ p: 1, width: '104px', height: '104px' }}
              />
              <LottieButton
                type="submit"
                tooltipLabel="Apply and close"
                aria-label="Apply and close"
                lottieFile={LottieFile.ApprovedCheck}
                variant="transparent"
                sx={{ p: 1, width: '104px', height: '104px' }}
              />
            </Stack>
          </DialogTitle>
          <DialogContent sx={{ pb: 5, px: 5 }}>
            <Stack gap={5}>
              <Stack gap={0.5}>
                <HootTypography isPII={false} variant="headlinesmall">
                  Only show results from
                </HootTypography>
                <SublibrarySelector fullWidth value={sublibrarySelection} onChange={onSublibrarySelectionChanged} studentType={studentType} />
              </Stack>
              <Stack>
                {/* Instructional Focus */}
                <HootTypography isPII={false} variant="headlinesmall">
                  Instructional Focus
                </HootTypography>
                {selectedInstructionalFocusId ? (
                  <Controller
                    control={form.control}
                    name="instructionalFocusId"
                    render={({ field: { value, name } }) => (
                      <OptionSelectedChip
                        sx={{ width: 'max-content' }}
                        onDelete={() => onRemoveSingleSelectItem(name)}
                        label={instructionalFocusOptions[value!]}
                      />
                    )}
                  />
                ) : (
                  <PlainButton onClick={showFocusSelector}>Select Focus</PlainButton>
                )}
              </Stack>

              {/* Instructional Unit */}
              {selectedInstructionalFocusId &&
                !getInstructionalUnitsRequest.isLoading &&
                (getInstructionalUnitsRequest.data?.categorizedInstructionalUnits.length ?? 0) > 0 && (
                  <Stack>
                    <HootTypography isPII={false} variant="headlinesmall">
                      Instructional Unit
                    </HootTypography>
                    {selectedInstructionalUnitId ? (
                      <Controller
                        control={form.control}
                        name="instructionalUnitId"
                        render={({ field: { value, name } }) => (
                          <OptionSelectedChip
                            sx={{ width: 'max-content' }}
                            onDelete={() => onRemoveSingleSelectItem(name)}
                            label={instructionalUnitOptions[value!]}
                          />
                        )}
                      />
                    ) : (
                      <PlainButton onClick={showInstructionalUnitSelector}>Select Unit</PlainButton>
                    )}
                  </Stack>
                )}

              {/* Filter Level Prioritized Skill */}
              {selectedInstructionalUnitId &&
                !getFilterLevelPrioritizedSkillRequest.isLoading &&
                (getFilterLevelPrioritizedSkillRequest.data?.categorizedPrioritizedSkills.length ?? 0) > 0 && (
                  <Stack>
                    <HootTypography isPII={false} variant="headlinesmall">
                      Skills
                    </HootTypography>
                    <Stack direction="row" gap={5}>
                      <LottieButton
                        tooltipLabel="Add skills"
                        aria-label="Add skills"
                        lottieFile={LottieFile.AddPlus}
                        onClick={showPrioritizedSkillsSelector}
                      />
                      <Stack
                        gap={2}
                        direction="row"
                        sx={{
                          flex: 1,
                          flexWrap: 'wrap',
                        }}
                      >
                        <Controller
                          control={form.control}
                          name="prioritizedSkillIds"
                          render={({ field: { name, value } }) => (
                            <>
                              {Array.from(value ?? []).map((skillId) => (
                                <OptionSelectedChip
                                  key={skillId}
                                  sx={{ width: 'max-content' }}
                                  onDelete={() => onRemoveMultiSelectItem(name, skillId)}
                                  label={prioritizedSkillOptions[skillId]}
                                />
                              ))}
                            </>
                          )}
                        />
                      </Stack>
                    </Stack>
                  </Stack>
                )}
            </Stack>
          </DialogContent>
        </form>
      </BackdroplessDialog>

      {/* Second-level filter dialog. */}
      <StudentDecodableAccessLibraryFilterSelectDialog
        isOpen={isOpen && showFilterSelectDialog && !!filterOptionSelect}
        defaultValue={filterOptionSelect ? (form.getValues(filterOptionSelect.formKey) as string | Set<string>) : null}
        filterOptionSelect={filterOptionSelect}
        onApply={(value) => onApplyFilterSelection(filterOptionSelect!.formKey, value)}
        onCancel={onDismissFilterSelectDialog}
        onDialogDismissAnimationCompleted={clearFilterOptionSelect}
      />

      {/* We're going to use a single backdrop for all dialogs here so, we don't see the screen flash. */}
      <Backdrop
        open={isOpen}
        sx={{
          zIndex: zIndexes.dialogBackdrop,
        }}
      />
    </>
  );
};

export default StudentDecodableAccessLibraryFilterDialog;
