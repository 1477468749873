import { Dialog, DialogActions, DialogContent, DialogProps, DialogTitle, Skeleton, Stack } from '@mui/material';
import { FormativeAssessmentSearchResult } from '@hoot/hooks/api/assessment/useGetStudentFormativeAssessments';
import { useGetFluencyFormativeAssessmentDetails } from '@hoot/hooks/api/lesson-reviews/useGetFluencyFormativeAssessmentDetails';
import { Button } from '@hoot/ui/components/v2/core/Button';
import { Icon } from '@hoot/ui/components/v2/core/Icon';
import IconButton from '@hoot/ui/components/v2/core/IconButton';
import ReadOnlyTextField from '@hoot/ui/components/v2/core/ReadOnlyTextField';

interface FluencyFormativeAssessmentDetailsModalProps extends Pick<DialogProps, 'open' | 'onClose'> {
  formativeAssessment: FormativeAssessmentSearchResult | undefined;
}

const FluencyFormativeAssessmentDetailsModal = (props: FluencyFormativeAssessmentDetailsModalProps) => {
  const { formativeAssessment, ...dialogProps } = props;

  const { data, isFetching } = useGetFluencyFormativeAssessmentDetails(formativeAssessment?.lessonReviewId ?? '', formativeAssessment?.bookId ?? '', {
    enabled: !!formativeAssessment,
  });

  const _onClose = () => {
    dialogProps.onClose?.({}, 'escapeKeyDown');
  };

  const LessonReviewContent = () => (
    <Stack gap={2}>
      <ReadOnlyTextField label="Date" body={data?.lessonDate} />
      <ReadOnlyTextField label="Type" body={'Fluency'} />
      <ReadOnlyTextField label="Word Count" body={data?.wordCount} />
      <ReadOnlyTextField label="Errors" body={data?.errors} />
      <ReadOnlyTextField label="Record Running Time" body={data?.runningTime} />
      <ReadOnlyTextField label="Words Correct Per Minute" body={data?.wordsCorrectPerMinute} />
      <ReadOnlyTextField label="Accuracy" body={data?.accuracy} />
    </Stack>
  );

  const LoadingContent = () => (
    <Stack gap={2}>
      <Skeleton variant="rectangular" width="100%" height="58px" />
      <Skeleton variant="rectangular" width="100%" height="58px" />
      <Skeleton variant="rectangular" width="100%" height="58px" />
      <Skeleton variant="rectangular" width="100%" height="58px" />
      <Skeleton variant="rectangular" width="100%" height="58px" />
      <Skeleton variant="rectangular" width="100%" height="58px" />
      <Skeleton variant="rectangular" width="100%" height="58px" />
    </Stack>
  );

  return (
    <Dialog fullWidth maxWidth="sm" {...dialogProps}>
      <DialogTitle sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        Formative Assessment Details
        <IconButton onClick={_onClose}>
          <Icon name="close" />
        </IconButton>
      </DialogTitle>
      <DialogContent>{isFetching ? <LoadingContent /> : <LessonReviewContent />}</DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={_onClose}>
          Done
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FluencyFormativeAssessmentDetailsModal;
