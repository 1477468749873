import axios from 'axios';
import { useQuery } from 'react-query';
import { config } from '@hoot/config';
import { InstructionalUnitResponse } from '@hoot/hooks/api/library/useGetInstructionalUnitsForInstructionalFocus';
import { QueryKey } from '@hoot/hooks/api/queryKeys';
import { UseGetOptions } from '@hoot/models/api/axios-options';

export default function useGetInstructionalUnitBySlug(instructionalUnitSlug: string, options?: UseGetOptions<InstructionalUnitResponse>) {
  return useQuery(
    [QueryKey.GetInstructionalUnitBySlug, instructionalUnitSlug],
    async () => {
      return (await axios.get<InstructionalUnitResponse>(`${config.apiUrl}/library/instructional-units/${instructionalUnitSlug}`)).data;
    },
    options,
  );
}
