import { useNavigate } from 'react-router-dom';
import { routesDictionary } from '@hoot/routes/routesDictionary';
import HootTypography from '../core/HootTypography';
import BasicAlertDialog from './BasicAlertDialog';

interface PermissionDeniedProps {
  isModalOpen: boolean;
  closeModal?: () => void;
}

const PermissionDeniedDialog = (props: PermissionDeniedProps) => {
  const navigate = useNavigate();

  const backToDashboard = () => navigate(routesDictionary.dashboard.url);

  return (
    <BasicAlertDialog
      title={'Permission Denied'}
      onDismiss={backToDashboard}
      show={props.isModalOpen}
      maxWidth="sm"
      content={
        <HootTypography isPII={false}>
          You do not have access to view this page. For help, use the Live Chat Support under the Learning & Support Menu.
        </HootTypography>
      }
      primaryAction={{
        label: 'Back to Dashboard',
        onClick: backToDashboard,
      }}
    />
  );
};

export default PermissionDeniedDialog;
