import { DistrictRepPermissionLevel } from '@hoot-reading/hoot-core/dist/enums/hfs/district-rep-access-level.enum';
import { CircularProgress } from '@mui/material';
import { Navigate, Outlet } from 'react-router-dom';
import useGetDistrictRepPermissions from '@hoot/hooks/api/user/useGetDistrictRepPermissions';
import useDistrictRepLocationDetails from '@hoot/hooks/useDistrictRepLocationDetails';
import useProfile from '@hoot/hooks/useProfile';

/*
 * The following access levels correspend to the scenarios from this sheet:
 * https://docs.google.com/spreadsheets/d/1bki8oY5RlvquSgHIXxVUJvumAIyuH0hyNJF7o1O8nAc/edit?gid=509973738#gid=509973738
 * BILLABLE_ENTITY -> 1, 5
 * SCHOOL_OR_DISTRICT -> 1, 2, 5
 * ENROLMENT_EDIT_LEVEL -> 1, 2, 3, 4, 5, 6, 7
 * ENROLMENT_VIEW_LEVEL  -> 1, 2, 3, 5, 6
 * NONE -> 8, 9
 */
export enum DistrictRepAccessLevelCombinations {
  BillableEntity = 'BILLABLE_ENTITY',
  SchoolOrDistrict = 'SCHOOL_OR_DISTRICT',
  EnrolmentEditLevel = 'ENROLMENT_EDIT_LEVEL',
  EnrolmentViewLevel = 'ENROLMENT_VIEW_LEVEL',
  None = 'NONE',
}

export const useHasDistrictPermission = (
  requiredPermissionCombination: DistrictRepAccessLevelCombinations,
  districtRepPermissions?: DistrictRepPermissionLevel[],
) => {
  const { districtRepLocationDetails } = useDistrictRepLocationDetails();

  if (!districtRepPermissions) {
    return false;
  }

  if (requiredPermissionCombination === DistrictRepAccessLevelCombinations.BillableEntity) {
    return (
      districtRepPermissions.some((p) => [DistrictRepPermissionLevel.DistrictFullAccess, DistrictRepPermissionLevel.SchoolFullAccess].includes(p)) &&
      !!districtRepLocationDetails?.isBillableEntity
    );
  } else if (requiredPermissionCombination === DistrictRepAccessLevelCombinations.SchoolOrDistrict) {
    return districtRepPermissions.some((p) =>
      [DistrictRepPermissionLevel.DistrictFullAccess, DistrictRepPermissionLevel.SchoolFullAccess].includes(p),
    );
  } else if (requiredPermissionCombination === DistrictRepAccessLevelCombinations.EnrolmentViewLevel) {
    return districtRepPermissions.some((p) =>
      [
        DistrictRepPermissionLevel.DistrictFullAccess,
        DistrictRepPermissionLevel.SchoolFullAccess,
        DistrictRepPermissionLevel.EnrolmentEdit,
        DistrictRepPermissionLevel.EnrolmentView,
      ].includes(p),
    );
  } else if (requiredPermissionCombination === DistrictRepAccessLevelCombinations.EnrolmentEditLevel) {
    return districtRepPermissions.some((p) =>
      [DistrictRepPermissionLevel.DistrictFullAccess, DistrictRepPermissionLevel.SchoolFullAccess, DistrictRepPermissionLevel.EnrolmentEdit].includes(
        p,
      ),
    );
  } else {
    return false;
  }
};

export function DistrictRepProtectedRoute(props: { requiredPermissionCombination: DistrictRepAccessLevelCombinations }) {
  const { isDistrictRep, profile } = useProfile();
  const { data: districtRepPermissions, isLoading } = useGetDistrictRepPermissions(profile?.id || '', { enabled: isDistrictRep });
  const hasPermissions = useHasDistrictPermission(props.requiredPermissionCombination, districtRepPermissions);

  if (isLoading) {
    return <CircularProgress />;
  }

  if (hasPermissions) {
    return <Outlet />;
  } else {
    return <Navigate to="/" />;
  }
}
