import { Link, LinkProps } from 'react-router-dom';
import { routesDictionary } from '@hoot/routes/routesDictionary';

interface ScopeAndSequenceLinkProps extends LinkProps {
  studentProfileId: string | undefined;
}

// This a bit of a hack...
// The S&S pages exist under both the root (/scope-and-sequence) and student details (/my-students/:studentProfileId).
// When we're navigating around under the student details path, we need to preserve the student ID within the link.
// This component will modify the Link to include the student details suffix portion of the URL when a student profile
// ID exists.
export const ScopeAndSequenceLink = (props: ScopeAndSequenceLinkProps) => {
  const { studentProfileId, ...LinkProps } = props;

  let linkTo = props.to;

  if (studentProfileId) {
    linkTo = `${routesDictionary.myStudents.details.url(studentProfileId)}${linkTo}`;
  }

  return <Link {...LinkProps} to={linkTo} />;
};
