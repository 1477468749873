import { Box, Grid, Grid2, Skeleton, Stack } from '@mui/material';
import { StudentProfileMetricsResponse } from '@hoot/hooks/api/district-rep/reporting/useGetStudentProfileMetrics';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';
import { getFormattedMetricValue, studentProfileMetricLabels } from './common';

interface StudentReportMetricsProps {
  data: StudentProfileMetricsResponse | undefined;
  isLoading: boolean;
}

const EXCLUDED_METRICS: (keyof StudentProfileMetricsResponse)[] = ['schoolName', 'number', 'status', 'grade'];

const StudentReportMetrics = (props: StudentReportMetricsProps) => {
  const { data, isLoading } = props;

  return (
    <Box mt={7} mb={7}>
      {isLoading ? (
        <SkeletonGrid />
      ) : (
        <Grid2 container spacing={2}>
          {data &&
            Object.entries(data)
              .filter(([label]) => !EXCLUDED_METRICS.includes(label as keyof StudentProfileMetricsResponse))
              .map(([label, value], index) => (
                <Grid2
                  key={label}
                  size={{ xs: index < 4 ? 3 : 4 }}
                  sx={{
                    padding: 3,
                    border: '1px solid #000',
                    borderRadius: '8px',
                    backgroundColor: hootTokens.palette.success[180],
                  }}
                >
                  <Stack alignItems="center">
                    <HootTypography variant="bodylarge" textAlign="center" isPII={false}>
                      {studentProfileMetricLabels[label as keyof StudentProfileMetricsResponse]}
                    </HootTypography>
                    <HootTypography variant="headlinelarge" textAlign="center" isPII={false}>
                      {getFormattedMetricValue(value, label as keyof StudentProfileMetricsResponse)}
                    </HootTypography>
                  </Stack>
                </Grid2>
              ))}
        </Grid2>
      )}
    </Box>
  );
};

const SkeletonGrid = (): JSX.Element => {
  return (
    <Grid container spacing={3}>
      {Array.from({ length: 7 }).map((_, index) => (
        <Grid item xs={index < 4 ? 3 : 4} key={index}>
          <Skeleton variant="rectangular" height={122} sx={{ borderRadius: '8px' }} />
        </Grid>
      ))}
    </Grid>
  );
};

export default StudentReportMetrics;
