import { PMAUnitIdentifier } from '@hoot-reading/hoot-core/dist/enums/progress-monitoring-assessment';
import { Module_4_GenericAnswer } from '@hoot-reading/hoot-core/dist/interfaces/progress-monitoring-assessment/pma-submission-data.interface';
import { FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { useDispatch } from 'react-redux';
import { UnitIdentifierHRAV2 } from '@hoot/models/api/enums/hoot-reading-assessment';
import { createFlashMessage } from '../../../../../../redux/reducers/flashMessageSlice';
import { Layout5Questionnaire } from '../../../../../pages/v2/teacher/lesson/progress-monitoring-assessment/units/PMA_QUESTIONNAIRE_LAYOUT_5';
import HootTypography from '../../../core/HootTypography';
import { QuestionnaireContainer } from '../QuestionnaireContainer';
import { QuestionnaireFooter } from '../QuestionnaireFooter';
import { LeaveCurrentUnitDialog } from '../dialogs/LeaveCurrentUnitDialog';

export interface Props {
  title: string;
  instruction: string;
  unitIdentifier: UnitIdentifierHRAV2 | PMAUnitIdentifier;
  unitTitle: string;
  leaveDialog: boolean;
  setLeaveDialog: (val: boolean) => void;
  isNextEnabled: boolean;
  isPrevEnabled: boolean;
  isSubmitting: boolean;
  questionnaire: Layout5Questionnaire[];
  onPreviousUnitClick: () => void;
  onSubmit: () => void;
  onNext?: () => void;
  confirmPrevUnitClick: () => void;
  isUnitQuestionnaireDirty: boolean;
  value: Module_4_GenericAnswer[];
  onChange: (val: Module_4_GenericAnswer[]) => void;
  onExitAssessment: () => void;
}

export function QuestionnaireLayout5(props: Props) {
  const dispatch = useDispatch();

  const handleOptionChange = (section: string) => (_event: React.ChangeEvent<HTMLInputElement>, value: string) => {
    const optionSelected = props.questionnaire.find((q) => q.title === section)?.options.find((o) => o.val === value);

    if (!optionSelected) {
      dispatch(createFlashMessage({ message: 'There was a problem with processing your selection.' }));
    } else {
      const updatedValue = props.value.map((a) =>
        a.section !== section
          ? a
          : {
              ...a,
              answer: optionSelected.text,
              answerValue: Number.parseInt(optionSelected.val),
            },
      );
      props.onChange(updatedValue);
    }
  };

  const handleSubmit = () => {
    if (!props.onNext || props.isUnitQuestionnaireDirty) {
      props.onSubmit();
    } else {
      if (props.onNext) {
        props.onNext();
      }
    }
  };

  const disableSubmissionBtn = props.value.some((a) => !a.answer) || props.isSubmitting;
  const primaryButtonLabel = !disableSubmissionBtn && !props.isUnitQuestionnaireDirty ? 'Continue' : undefined;

  return (
    <QuestionnaireContainer
      unitIdentifier={props.unitIdentifier}
      title={props.title}
      instruction={props.instruction}
      unitTitle={props.unitTitle}
      onExitAssessment={props.onExitAssessment}
    >
      <Stack flex={1}>
        {props.questionnaire.map((q, idx) => (
          <Stack key={`${q.title}-${idx}`} marginTop="24px">
            <Typography variant="titlesmall">{q.title}</Typography>
            <RadioGroup onChange={handleOptionChange(q.title)} name={q.title} value={props.value[idx].answerValue}>
              {q.options.map((o) => {
                return (
                  <FormControlLabel
                    key={o.val}
                    value={o.val}
                    control={<Radio />}
                    label={<HootTypography isPII={false} dangerouslySetInnerHTML={{ __html: o.text }} />}
                    sx={{ display: 'flex', alignItems: 'center' }}
                  />
                );
              })}
            </RadioGroup>
          </Stack>
        ))}

        <QuestionnaireFooter
          allFormFieldsFilledOut={false}
          onPreviousUnitClick={props.onPreviousUnitClick}
          setLeaveDialog={props.setLeaveDialog}
          isUnitQuestionnaireDirty={props.isUnitQuestionnaireDirty}
          disableSubmissionBtn={disableSubmissionBtn}
          onNextUnitAndSubmissionClick={handleSubmit}
          isNextEnabled={props.isNextEnabled}
          isPrevEnabled={props.isPrevEnabled}
          submitButtonLabel={primaryButtonLabel}
        />
        <LeaveCurrentUnitDialog open={props.leaveDialog} setOpen={props.setLeaveDialog} confirmLeave={props.confirmPrevUnitClick} />
      </Stack>
    </QuestionnaireContainer>
  );
}
