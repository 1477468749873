import axios from 'axios';
import { useQuery } from 'react-query';
import { config } from '@hoot/config';
import { QueryKey } from '@hoot/hooks/api/queryKeys';
import { UseGetOptions } from '@hoot/models/api/axios-options';

export interface PrioritizedSkillFilterDocumentationResponse {
  prioritizedSkillFilter: {
    id: string;
    name: string;
    instructionalUnit: {
      id: string;
      name: string;
    };
  };
  documentationHtmlContent: string | undefined;
}

export default function useGetPrioritizedSkillDocumentation(skillId: string, options?: UseGetOptions<PrioritizedSkillFilterDocumentationResponse>) {
  return useQuery(
    [QueryKey.GetPrioritizedSkillDocumentation, skillId],
    async () => {
      return (
        await axios.get<PrioritizedSkillFilterDocumentationResponse>(`${config.apiUrl}/library/prioritized-skill-filter/${skillId}/documentation`)
      ).data;
    },
    options,
  );
}
