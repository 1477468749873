import { Collapse, Divider, List, ListItem, ListProps, Radio, Stack, TypographyProps } from '@mui/material';
import ListItemButton from '@mui/material/ListItemButton';
import React from 'react';
import { Virtuoso } from 'react-virtuoso';
import { TypographyVariant } from '@hoot/ui/theme/v2/typography';
import { Button } from './core/Button';
import { Checkbox } from './core/Checkbox';
import HootTypography from './core/HootTypography';
import { Icon } from './core/Icon';

export interface CheckListProps {
  title: string;
  items: BaseCheckListItem[];
  selectedValues: string[];
  toggleOption: (option: CheckListItem) => void;
  disabledValues?: string[];
  divider?: boolean;
  collapsibleItems?: boolean;
  isRadio?: boolean;
  listSx?: ListProps['sx'];
  titleVariant?: TypographyVariant | undefined;
}

export interface BaseCheckListItem {
  label: TypographyProps['children'];
  value: string;
}

export interface CheckListItem extends BaseCheckListItem {
  isChecked: boolean;
  isDisabled: boolean;
}

const CheckList = (props: CheckListProps) => {
  const { title, items, selectedValues, toggleOption, disabledValues, divider, collapsibleItems, isRadio, listSx, titleVariant } = props;
  const [open, setOpen] = React.useState<boolean>(true);

  const handleClick = () => {
    setOpen((prevVal) => !prevVal);
  };

  const displayItems: CheckListItem[] = items.map((item) => ({
    label: item.label,
    value: item.value,
    isChecked: selectedValues.concat(disabledValues ?? []).includes(item.value),
    isDisabled: disabledValues?.includes(item.value) ?? false,
  }));

  const showVirtualizedList = displayItems.length > 100;

  return (
    <Stack gap={1.25} height={'80%'} justifyContent={'space-between'}>
      {collapsibleItems ? (
        <div>
          <Button sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 1 }} onClick={handleClick}>
            <HootTypography isPII={false} variant={titleVariant ?? 'titlesmall'}>
              {title}
            </HootTypography>
            {open ? <Icon name="chevron" sx={{ rotate: '270deg' }} /> : <Icon name="chevron" sx={{ rotate: '90deg' }} />}
          </Button>
          <Collapse in={open} timeout="auto" unmountOnExit>
            {showVirtualizedList ? (
              <VirtualizedCheckListContent
                displayItems={displayItems}
                divider={divider}
                isRadio={isRadio}
                items={items}
                toggleOption={toggleOption}
              />
            ) : (
              <CheckListContent
                displayItems={displayItems}
                divider={divider}
                isRadio={isRadio}
                items={items}
                toggleOption={toggleOption}
                listSx={listSx}
              />
            )}
          </Collapse>
        </div>
      ) : (
        <div>
          <HootTypography isPII={false} variant={titleVariant ?? 'titlesmall'}>
            {title}
          </HootTypography>
          {showVirtualizedList ? (
            <VirtualizedCheckListContent displayItems={displayItems} divider={divider} isRadio={isRadio} items={items} toggleOption={toggleOption} />
          ) : (
            <CheckListContent
              displayItems={displayItems}
              divider={divider}
              isRadio={isRadio}
              items={items}
              toggleOption={toggleOption}
              listSx={listSx}
            />
          )}
        </div>
      )}
    </Stack>
  );
};

const CheckListContent = (props: {
  displayItems: CheckListItem[];
  divider?: boolean;
  isRadio?: boolean;
  toggleOption: (option: CheckListItem) => void;
  items: BaseCheckListItem[];
  disabledValues?: string[];
  listSx?: ListProps['sx'];
}) => (
  <List sx={{ ...props.listSx }}>
    {props.displayItems.map((x, index) => (
      <React.Fragment key={x.value}>
        <ListItem disablePadding>
          <ListItemButton onClick={() => props.toggleOption(x)} sx={{ padding: 1 }} disabled={props.disabledValues && x.isDisabled}>
            {props.isRadio ? <Radio checked={x.isChecked} sx={{ mr: 2 }} /> : <Checkbox checked={x.isChecked} sx={{ mr: 2 }} />}
            <HootTypography isPII={false} variant="labellarge">
              {x.label}
            </HootTypography>
          </ListItemButton>
        </ListItem>
        {props.divider && index + 1 < props.items.length ? <Divider /> : null}
      </React.Fragment>
    ))}
  </List>
);

const VirtualizedCheckListContent = (props: {
  displayItems: CheckListItem[];
  divider?: boolean;
  isRadio?: boolean;
  toggleOption: (option: CheckListItem) => void;
  items: BaseCheckListItem[];
  disabledValues?: string[];
}) => (
  <Virtuoso
    style={{ height: '40vh' }}
    data={props.displayItems}
    itemContent={(index, x) => (
      <React.Fragment key={x.value}>
        <ListItem disablePadding>
          <ListItemButton onClick={() => props.toggleOption(x)} sx={{ padding: 1 }} disabled={props.disabledValues && x.isDisabled}>
            {props.isRadio ? <Radio checked={x.isChecked} sx={{ mr: 2 }} /> : <Checkbox checked={x.isChecked} sx={{ mr: 2 }} />}
            <HootTypography isPII={false} variant="labellarge">
              {x.label}
            </HootTypography>
          </ListItemButton>
        </ListItem>
        {props.divider && index + 1 < props.items.length ? <Divider /> : null}
      </React.Fragment>
    )}
  />
);

export default CheckList;
