import { StudentGrade } from '@hoot-reading/hoot-core/dist/enums/student-grade';
import { StudentStatus } from '@hoot-reading/hoot-core/dist/enums/user/student/student-status.enum';
import { Box, Stack } from '@mui/material';
import { useState } from 'react';
import { DateFormats } from '@hoot/constants/constants';
import { LocationReportingFilters } from '@hoot/hooks/api/district-rep/reporting/useGetLocationReportingMetrics';
import { DistrictRepresentativeSchool } from '@hoot/hooks/api/district-rep/useSearchDistrictRepresentativeSchools';
import { Button } from '@hoot/ui/components/v2/core/Button';
import { Checkbox } from '@hoot/ui/components/v2/core/Checkbox';
import { Chip } from '@hoot/ui/components/v2/core/Chip';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';
import { Icon } from '@hoot/ui/components/v2/core/Icon';
import PopoverMenu from '@hoot/ui/components/v2/core/PopoverMenu';
import DateRangeDialog, { DateRangeFormValues } from '@hoot/ui/components/v2/dialogs/DateRangeDialog';
import CalendarIcon from '@hoot/ui/components/v2/icons/CalendarIcon';
import {
  AttendanceFilterEnum,
  DateFilterEnum,
  LateJoinFilterEnum,
  attendanceLabels,
  dateFilterLabels,
  filterCategoryLabel,
  getFilterOptionLabel,
  lateJoinLabels,
  studentGradeLabels,
  studentStatusLabels,
} from '../../../../../../models/api/district-rep-reporting/common';

type AnchorElement = {
  date: HTMLElement | null;
  school: HTMLElement | null;
  studentStatus: HTMLElement | null;
  studentGrade: HTMLElement | null;
  attendance: HTMLElement | null;
  lateJoin: HTMLElement | null;
};

interface DistrictFilterProps {
  filters: LocationReportingFilters;
  setFilters: React.Dispatch<React.SetStateAction<LocationReportingFilters>>;
  schoolOptions: DistrictRepresentativeSchool[];
  statusOptions: StudentStatus[];
  gradeOptions: StudentGrade[];
  hasFullDistrictAccess?: boolean;
}

const StudentAggregateFilters = (props: DistrictFilterProps) => {
  const { filters, setFilters, schoolOptions, hasFullDistrictAccess, statusOptions, gradeOptions } = props;

  // Anchor Elements used for the popover menus.
  const [anchorEl, setAnchorEl] = useState<AnchorElement>({
    date: null,
    school: null,
    studentStatus: null,
    studentGrade: null,
    attendance: null,
    lateJoin: null,
  });

  const [isDateRangeDialogOpen, setIsDateRangeDialogOpen] = useState<boolean>(false);
  const [schoolChips, setSchoolChips] = useState<{ id: string; label: string }[]>([]);

  const handleMenuOpen = (key: keyof AnchorElement, target: HTMLButtonElement) => {
    setAnchorEl((prev) => ({ ...prev, [key]: target }));
  };

  const handleMenuClose = (key: keyof AnchorElement) => {
    setAnchorEl((prev) => ({ ...prev, [key]: null }));
  };

  const handleDateRangeDialogOpen = () => {
    handleMenuClose('date');
    setIsDateRangeDialogOpen(true);
  };

  const handleDateRangeDialogClose = () => setIsDateRangeDialogOpen(false);

  const handleCheckboxChange = (filterKey: keyof LocationReportingFilters, value: string) => {
    setFilters((prev) => {
      if (filterKey === 'date') {
        const currentDateFilterSelection = prev['date'];
        // if it's currently an array and not an object, we need to toggle the selection but clear any other selection
        if (Array.isArray(currentDateFilterSelection)) {
          // if the values already checked, clear it - otherwise select it
          const newPredefinedDateFilter = (currentDateFilterSelection as DateFilterEnum[]).includes(value as DateFilterEnum)
            ? ([] as DateFilterEnum[])
            : ([value] as DateFilterEnum[]);
          return { ...prev, [filterKey]: newPredefinedDateFilter };
        } else {
          // if the previous selection was an object, replace the custom date range with the selection
          return { ...prev, [filterKey]: [value] as DateFilterEnum[] };
        }
      }

      const currentValues = Array.isArray(prev[filterKey]) ? (prev[filterKey] as string[]) : [];
      const newValues = currentValues.includes(value)
        ? currentValues.filter((v) => v !== value) // If `value` is already present, remove it.
        : [...currentValues, value]; // Else, add it to `newValues`.

      return { ...prev, [filterKey]: newValues };
    });
  };

  const handleDateRangeFilterApply = (formValues: DateRangeFormValues) => {
    setFilters((prev) => ({ ...prev, date: formValues }));
  };

  const handleSchoolChecked = (id: string, name: string) => {
    handleCheckboxChange('schoolIds', id);
    setSchoolChips((prev) => {
      const idExists = prev.some((chip) => chip.id === id);
      if (idExists) return prev.filter((chip) => chip.id !== id);

      return [...prev, { id: id, label: name }];
    });
  };

  const handleFilterDelete = (filterKey: keyof LocationReportingFilters, value: string) => {
    setFilters((prev) => {
      const updatedValues = (prev[filterKey] as string[]).filter((v) => v !== value);
      return { ...prev, [filterKey]: updatedValues };
    });
  };

  const renderDateRangeChip = () => {
    const { startDate, endDate } = filters.date as DateRangeFormValues;

    if (!startDate || !endDate) return;

    const formattedStart = startDate.toFormat(DateFormats.SHORT_MDY);
    const formattedEnd = endDate.toFormat(DateFormats.SHORT_MDY);

    return (
      <Chip
        label={`Date Range: ${formattedStart} - ${formattedEnd}`}
        onDelete={() => {
          setFilters((prev) => {
            return { ...prev, date: [] };
          });
        }}
        deleteIcon={<Icon name="close" htmlColor="#FFF" />}
        sx={{
          background: '#000',
          color: '#FFF',
        }}
      />
    );
  };

  const renderSchoolChips = () => {
    return schoolChips.map((school) => (
      <Chip
        key={school.id}
        label={`School: ${school.label}`}
        onDelete={() => handleSchoolChecked(school.id, school.label)}
        deleteIcon={<Icon name="close" htmlColor="#FFF" />}
        sx={{
          background: '#000',
          color: '#FFF',
        }}
      />
    ));
  };

  const groupedFilters = Object.entries(filters).filter(([, values]) => values.length > 0);

  const showDateRangeChip = !!filters.date && !Array.isArray(filters.date);
  const showSchoolChip = !!schoolChips.length;

  return (
    <>
      <Stack gap={2}>
        <HootTypography variant="titlemedium" isPII={false}>
          Filters
        </HootTypography>
        <Stack direction="row" gap={2}>
          <Button
            onClick={(e) => handleMenuOpen('date', e.currentTarget)}
            variant="contained"
            color="neutral.180"
            startIcon={<Icon name="chevron" sx={{ rotate: '270deg' }} />}
          >
            Date
          </Button>
          <PopoverMenu
            id="date-menu"
            open={!!anchorEl.date}
            onClose={() => handleMenuClose('date')}
            anchorEl={anchorEl.date}
            items={Object.values(DateFilterEnum).map((dateFilter) => ({
              id: dateFilter,
              label: dateFilterLabels[dateFilter],
              onClick: () =>
                dateFilter === DateFilterEnum.SpecificDates
                  ? handleDateRangeDialogOpen() // Special case where we use a custom date range instead of preset filters.
                  : handleCheckboxChange('date', dateFilter),
              icon:
                dateFilter === DateFilterEnum.SpecificDates ? (
                  <CalendarIcon />
                ) : (
                  <Checkbox
                    checked={Array.isArray(filters.date) && filters.date.includes(dateFilter)}
                    onChange={() => handleCheckboxChange('date', dateFilter)}
                  />
                ),
              menuItemProps: {
                divider: dateFilter === DateFilterEnum.LastMonth || dateFilter === DateFilterEnum.LastSchoolYear,
                sx: { borderRadius: 0 },
              },
            }))}
          />

          {hasFullDistrictAccess && (
            <>
              <Button
                onClick={(e) => handleMenuOpen('school', e.currentTarget)}
                variant="contained"
                color="neutral.180"
                startIcon={<Icon name="chevron" sx={{ rotate: '270deg' }} />}
              >
                School
              </Button>
              <PopoverMenu
                id="school-menu"
                open={!!anchorEl.school}
                onClose={() => handleMenuClose('school')}
                anchorEl={anchorEl.school}
                items={schoolOptions.map((option) => ({
                  id: option.id,
                  label: option.name,
                  onClick: () => handleSchoolChecked(option.id, option.name),
                  icon: <Checkbox checked={filters.schoolIds?.includes(option.id)} />,
                }))}
              />
            </>
          )}

          <Button
            onClick={(e) => handleMenuOpen('studentStatus', e.currentTarget)}
            variant="contained"
            color="neutral.180"
            startIcon={<Icon name="chevron" sx={{ rotate: '270deg' }} />}
          >
            Student Status
          </Button>
          <PopoverMenu
            id="status-menu"
            open={!!anchorEl.studentStatus}
            onClose={() => handleMenuClose('studentStatus')}
            anchorEl={anchorEl.studentStatus}
            items={statusOptions.map((statusFilter) => ({
              id: statusFilter,
              label: studentStatusLabels[statusFilter],
              onClick: () => handleCheckboxChange('studentStatus', statusFilter),
              icon: <Checkbox checked={(filters.studentStatus as string[])?.includes(statusFilter)} />,
            }))}
          />

          <Button
            onClick={(e) => handleMenuOpen('studentGrade', e.currentTarget)}
            variant="contained"
            color="neutral.180"
            startIcon={<Icon name="chevron" sx={{ rotate: '270deg' }} />}
          >
            Student Grade
          </Button>
          <PopoverMenu
            id="grade-menu"
            open={!!anchorEl.studentGrade}
            onClose={() => handleMenuClose('studentGrade')}
            anchorEl={anchorEl.studentGrade}
            items={gradeOptions.map((gradeFilter) => ({
              id: gradeFilter,
              label: studentGradeLabels[gradeFilter],
              onClick: () => handleCheckboxChange('studentGrade', gradeFilter),
              icon: <Checkbox checked={(filters.studentGrade as string[])?.includes(gradeFilter)} />,
            }))}
          />

          <Button
            onClick={(e) => handleMenuOpen('attendance', e.currentTarget)}
            variant="contained"
            color="neutral.180"
            startIcon={<Icon name="chevron" sx={{ rotate: '270deg' }} />}
          >
            Attendance
          </Button>
          <PopoverMenu
            id="attendance-menu"
            open={!!anchorEl.attendance}
            onClose={() => handleMenuClose('attendance')}
            anchorEl={anchorEl.attendance}
            items={Object.values(AttendanceFilterEnum).map((attendanceFilter) => ({
              id: attendanceFilter,
              label: attendanceLabels[attendanceFilter],
              onClick: () => handleCheckboxChange('attendance', attendanceFilter),
              icon: <Checkbox checked={(filters.attendance as string[])?.includes(attendanceFilter)} />,
            }))}
          />

          <Button
            onClick={(e) => handleMenuOpen('lateJoin', e.currentTarget)}
            variant="contained"
            color="neutral.180"
            startIcon={<Icon name="chevron" sx={{ rotate: '270deg' }} />}
          >
            Late Join
          </Button>
          <PopoverMenu
            id="late-join-menu"
            open={!!anchorEl.lateJoin}
            onClose={() => handleMenuClose('lateJoin')}
            anchorEl={anchorEl.lateJoin}
            items={Object.values(LateJoinFilterEnum).map((lateJoinFilter) => ({
              id: lateJoinFilter,
              label: lateJoinLabels[lateJoinFilter],
              onClick: () => handleCheckboxChange('lateJoin', lateJoinFilter),
              icon: <Checkbox checked={(filters.lateJoin as string[])?.includes(lateJoinFilter)} />,
            }))}
          />
        </Stack>

        {/* Chips */}
        <Box display="flex" flexWrap="wrap" gap={2}>
          {showDateRangeChip ? renderDateRangeChip() : null}

          {showSchoolChip ? renderSchoolChips() : null}

          {groupedFilters
            .filter(([val]) => val !== 'schoolIds')
            .map(([filterKey, values]) =>
              (values as string[]).map((value) => {
                return (
                  <Chip
                    key={`${filterKey}-${value}`}
                    label={`
                    ${filterCategoryLabel[filterKey as keyof LocationReportingFilters]}:
                     ${getFilterOptionLabel(filterKey as keyof LocationReportingFilters, value)}
                  `}
                    onDelete={() => handleFilterDelete(filterKey as keyof LocationReportingFilters, value)}
                    deleteIcon={<Icon name="close" htmlColor="#FFF" />}
                    sx={{
                      background: '#000',
                      color: '#FFF',
                    }}
                  />
                );
              }),
            )}
        </Box>
      </Stack>

      <DateRangeDialog
        title="Specific Date Filter"
        open={isDateRangeDialogOpen}
        onDismiss={handleDateRangeDialogClose}
        onApply={handleDateRangeFilterApply}
      />
    </>
  );
};

export default StudentAggregateFilters;
