import axios, { AxiosError } from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '@hoot/config';
import { StudentReportingRequest } from '@hoot/hooks/api/district-rep/reporting/useGetStudentReportingMetrics';
import { QueryKey } from '../../queryKeys';
import { LocationReportingFilters } from './useGetLocationReportingMetrics';

export default function useGetStudentReportingMetricsCsvReport(
  locationId: string,
  paginationQuery: StudentReportingRequest,
  filtersQuery: LocationReportingFilters,
  options?: Omit<UseQueryOptions<any, AxiosError>, 'queryKey' | 'queryFn'>,
) {
  const reportingRequestParams: StudentReportingRequest = { ...paginationQuery, filters: filtersQuery };

  return useQuery(
    [QueryKey.GetDistrictRepReportingLocationStudentMetricsCsvReport, locationId, filtersQuery],
    async () => {
      return await axios.get<any>(`${config.apiUrl}/public/district-representative/reporting/location/${locationId}/student-metrics/csv`, {
        params: reportingRequestParams,
      });
    },
    options,
  );
}
