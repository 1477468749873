import React, { useEffect, useLayoutEffect, useState } from 'react';
import { isIOS } from 'react-device-detect';
import { useLocation } from 'react-router-dom';
import screenfull from 'screenfull';
import { useRouteMatch } from '@hoot/hooks/useRouteMatch';
import { routesDictionary } from '@hoot/routes/routesDictionary';

interface Props {
  children: React.ReactNode;
}

interface Values {
  isFullscreenEnabled: boolean;
  isFullscreen: boolean;
  toggleFullscreen: () => Promise<void>;
}

const FullscreenContext = React.createContext<Values>(undefined!);

const fullscreenEnabledRoutes = [
  `${routesDictionary.home.path}`, //home path required for rerouting, useLayoutEffect will run a second time to validate after redirect to profile's home url
  // Teacher routes
  `${routesDictionary.library.absolutePath}/*`,
  `${routesDictionary.lesson.absolutePath}/*`,
  `${routesDictionary.myStudents.details.library.basePath}/*`,
  // Student Routes
  `${routesDictionary.library.absolutePath}/*`,
  `${routesDictionary.lesson.absolutePath}/*`,
];

export const FullscreenProvider = (props: Props) => {
  const { children } = props;
  // Using fullscreen on iOS is creating more issues than what it's worth. Just disable FS mode on iOS.
  const [isFullscreenEnabled] = useState<boolean>(screenfull.isEnabled && !isIOS);
  const [isFullscreen, setIsFullscreen] = useState<boolean>(screenfull.isFullscreen);
  const location = useLocation();
  const isFullscreenEnabledRoute = useRouteMatch(fullscreenEnabledRoutes);

  const onFullscreenChange = () => {
    setIsFullscreen(screenfull.isFullscreen);
  };

  useEffect(() => {
    document.addEventListener('fullscreenchange', onFullscreenChange);
    document.addEventListener('webkitfullscreenchange', onFullscreenChange);
    document.addEventListener('mozfullscreenchange', onFullscreenChange);
    document.addEventListener('MSFullscreenChange', onFullscreenChange);

    return () => {
      document.removeEventListener('fullscreenchange', onFullscreenChange);
      document.removeEventListener('webkitfullscreenchange', onFullscreenChange);
      document.removeEventListener('mozfullscreenchange', onFullscreenChange);
      document.removeEventListener('MSFullscreenChange', onFullscreenChange);
    };
  }, []);

  useLayoutEffect(() => {
    const exitFullscreenIfNotInRouteWhitelist = async () => {
      if (!isFullscreenEnabledRoute && screenfull.isFullscreen) {
        await screenfull.toggle();
      }
    };

    exitFullscreenIfNotInRouteWhitelist().catch(console.error);
  }, [isFullscreenEnabledRoute, location, isFullscreen]);

  const toggleFullscreen = async () => {
    if (isFullscreenEnabled) {
      try {
        await screenfull.toggle();
      } catch (err) {
        console.log('Fullscreen not supported on this user-agent', err);
      }
    }
  };

  return <FullscreenContext.Provider value={{ isFullscreenEnabled, isFullscreen, toggleFullscreen }}>{children}</FullscreenContext.Provider>;
};

export const useFullscreen = () => {
  const context = React.useContext(FullscreenContext);

  if (context === undefined) {
    throw new Error('useFullscreen must be used within a FullscreenProvider');
  }

  return context;
};
