import Grid from '@mui/material/Grid2';
import { Stack } from '@mui/system';
import phone from 'phone';
import { Control, Controller, UseFormWatch } from 'react-hook-form';
import Card from '@hoot/ui/components/v2/core/Card';
import TextField from '@hoot/ui/components/v2/core/TextField';
import { Icon } from '../../../../components/v2/core/Icon';
import ReadOnlyTextField from '../../../../components/v2/core/ReadOnlyTextField';
import { TeacherProfileForm } from './TeacherProfile';

interface Props {
  readonly: boolean;
  control: Control<TeacherProfileForm, object>;
  watch: UseFormWatch<TeacherProfileForm>;
}

const EmergencyContactDetails = (props: Props) => {
  const { readonly, control, watch } = props;

  return readonly ? (
    <EmergencyContactDetailsReadonly control={control} watch={watch} readonly={props.readonly} />
  ) : (
    <EmergencyContactDetailsEmpty control={control} watch={watch} readonly={props.readonly} />
  );
};

const EmergencyContactDetailsReadonly = (props: Props) => {
  const { control } = props;

  return (
    <Card
      sx={{ width: '100%' }}
      title={
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <span>Emergency Contact</span>
          <Icon name="locked" color="primary" />
        </Stack>
      }
    >
      <Grid container rowGap={'16px'} columnSpacing={'16px'}>
        <Grid size={{ xs: 12, lg: 6 }}>
          <Controller
            name="emergencyContactFirstName"
            control={control}
            render={({ field: { value } }) => <ReadOnlyTextField label="Emergency Contact First Name" body={value} />}
          />
        </Grid>
        <Grid size={{ xs: 12, lg: 6 }}>
          <Controller
            name="emergencyContactLastName"
            control={control}
            render={({ field: { value } }) => <ReadOnlyTextField label="Emergency Contact Last Name" body={value} />}
          />
        </Grid>
        <Grid size={{ xs: 12, lg: 6 }}>
          <Controller
            name="emergencyContactEmail"
            control={control}
            render={({ field: { value } }) => <ReadOnlyTextField label="Emergency Contact Email" body={value} />}
          />
        </Grid>
        <Grid size={{ xs: 12, lg: 6 }}>
          <Controller
            name="emergencyContactPhoneNumber"
            control={control}
            render={({ field: { value } }) => <ReadOnlyTextField label="Emergency Phone Number" body={value} />}
          />
        </Grid>
      </Grid>
    </Card>
  );
};

const EmergencyContactDetailsEmpty = (props: Props) => {
  const { control } = props;

  return (
    <Card sx={{ width: '100%' }} title="Emergency Contact">
      <Grid container rowGap={'16px'} columnSpacing={'16px'}>
        <Grid size={{ xs: 12, lg: 6 }}>
          <Controller
            name="emergencyContactFirstName"
            control={control}
            rules={{
              required: 'Emergency Contact First Name Required!',
            }}
            render={({ field: { onChange, value }, fieldState }) => (
              <TextField
                variant="outlined"
                fullWidth
                label="Emergency Contact First Name"
                value={value}
                onChange={onChange}
                error={!!fieldState.error}
                helperText={fieldState.error?.message ?? ''}
              />
            )}
          />
        </Grid>

        <Grid size={{ xs: 12, lg: 6 }}>
          <Controller
            name="emergencyContactLastName"
            control={control}
            rules={{
              required: 'Emergency Contact Last Name Required!',
            }}
            render={({ field: { onChange, value }, fieldState }) => (
              <TextField
                variant="outlined"
                fullWidth
                label="Emergency Contact Last Name"
                value={value}
                onChange={onChange}
                error={!!fieldState.error}
                helperText={fieldState.error?.message ?? ''}
              />
            )}
          />
        </Grid>
        <Grid size={{ xs: 12, lg: 6 }}>
          <Controller
            name="emergencyContactEmail"
            control={control}
            rules={{
              required: 'Emergency Contact Email Required!',
            }}
            render={({ field: { onChange, value }, fieldState }) => (
              <TextField
                variant="outlined"
                fullWidth
                label="Emergency Contact Email"
                value={value}
                onChange={onChange}
                error={!!fieldState.error}
                helperText={fieldState.error?.message ?? ''}
              />
            )}
          />
        </Grid>
        <Grid size={{ xs: 12, lg: 6 }}>
          <Controller
            name="emergencyContactPhoneNumber"
            control={control}
            rules={{
              required: 'Emergency Contact Phone Number Required!',
              validate: (value) => {
                if (value?.length === 0) {
                  return true;
                }

                const phoneNum = value ? value.toString().trim() : '';
                if (phoneNum === '+' || phoneNum === '+1' || !phone(phoneNum || '')?.isValid) {
                  return 'Valid phone number is required: +x (xxx) xxx-xxxx';
                }
                return true;
              },
            }}
            render={({ field: { onChange, value }, fieldState }) => (
              <TextField
                variant="outlined"
                fullWidth
                label="Emergency Contact Phone Number"
                value={value}
                onChange={onChange}
                error={!!fieldState.error}
                helperText={fieldState.error?.message ?? ''}
              />
            )}
          />
        </Grid>
      </Grid>
    </Card>
  );
};

export default EmergencyContactDetails;
