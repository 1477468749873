import { useEffect } from 'react';
import { EventType } from '@hoot/events/eventType';
import { BookResponse } from '@hoot/events/interfaces/book-response';
import { PageReadMessage } from '@hoot/events/messages/page-read.message';
import { useEmit } from '@hoot/hooks/useEmit';
import { UserProfileType } from '@hoot/models/api/user';

interface PageReadAuditorProps {
  book: BookResponse | undefined;
  pageIndex: number;
  userProfileType: UserProfileType;
  lessonId?: string;
}

/**
 * Emits a page-read socket event for every active page.
 */
const usePageReadAuditor = (props: PageReadAuditorProps) => {
  const { book, pageIndex, userProfileType, lessonId } = props;

  const bookId = book?.id;
  const bookPages = book?.pages;
  const pageId = bookPages?.[pageIndex]?.id;

  // Page read auditor.
  const pageReadEmitter = useEmit<PageReadMessage>(EventType.PageRead, undefined, {
    enabled: false,
  });

  useEffect(() => {
    if (!bookId || !pageId) {
      return;
    }
    pageReadEmitter.queueMessage({
      bookId: bookId,
      bookPageId: pageId,
      userProfileType,
      lessonId,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookId, pageId]);
};

export default usePageReadAuditor;
