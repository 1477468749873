import { Box, Skeleton, Stack } from '@mui/material';
import React, { ReactNode, useState } from 'react';
import { useOutletContext, useParams } from 'react-router-dom';
import { InstructionalUnitResponse } from '@hoot/hooks/api/library/useGetInstructionalUnitsForInstructionalFocus';
import useGetPrioritizedSkillsAndResourcesForInstructionalUnit, {
  PrioritizedSkillAndResourcesResponse,
} from '@hoot/hooks/api/library/useGetPrioritizedSkillsAndResourcesForInstructionalUnit';
import { BookType, DecodableFormat } from '@hoot/models/api/library';
import { routesDictionary } from '@hoot/routes/routesDictionary';
import ViewState, { ViewStateEnum } from '@hoot/ui/components/v2/ViewState';
import { Button, ButtonProps } from '@hoot/ui/components/v2/core/Button';
import Card from '@hoot/ui/components/v2/core/Card';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';
import { HeaderData, TableV2 } from '@hoot/ui/components/v2/core/Table';
import { ScopeAndSequenceLink } from '@hoot/ui/pages/v2/teacher/scope-and-sequence/focus-area/ScopeAndSequenceLink';
import { FocusAreaSlug, ScopeAndSequenceFocusArea } from '@hoot/ui/pages/v2/teacher/scope-and-sequence/scopeAndSequenceFocusAreas';

interface TableRow {
  prioritizedSkillId: string;
  prioritizedSkillName: string;
  resources?: ReactNode;
  wiseWordsResources?: ReactNode;
  sampleWordsAndTasks?: ReactNode;
  pathway?: ReactNode;
}

const numColumnsInFocusArea: Record<FocusAreaSlug, number> = {
  [FocusAreaSlug.preWordReading]: 3,
  [FocusAreaSlug.earlyWordReading]: 4,
  [FocusAreaSlug.complexWordReading]: 4,
  [FocusAreaSlug.textReading]: 1,
};

const getHeaders = (focusAreaSlug: FocusAreaSlug, atLeastOneSkillHasDocumentation: boolean): HeaderData<TableRow>[] => {
  let headers: HeaderData<TableRow>[] = [];

  // Ensure that each column has equal space.
  const colWidth = Math.round((1 / (numColumnsInFocusArea[focusAreaSlug] + (atLeastOneSkillHasDocumentation ? 1 : 0))) * 100);

  switch (focusAreaSlug) {
    case FocusAreaSlug.preWordReading:
      headers = [
        { name: 'Prioritized Skill ID', property: 'prioritizedSkillId', isHidden: true },
        { name: 'Prioritized Skill', property: 'prioritizedSkillName', width: `${colWidth}%` },
        { name: 'Resources', property: 'resources', width: `${colWidth}%` },
        { name: 'Sample Tasks', property: 'sampleWordsAndTasks', width: `${colWidth}%` },
      ];
      break;
    case FocusAreaSlug.earlyWordReading:
    case FocusAreaSlug.complexWordReading:
      headers = [
        { name: 'Prioritized Skill ID', property: 'prioritizedSkillId', isHidden: true },
        { name: 'Prioritized Skill', property: 'prioritizedSkillName', width: `${colWidth}%` },
        { name: 'Wise Words', property: 'wiseWordsResources', width: `${colWidth}%` },
        // Essentially just all resources that are _not_ wise words format.
        { name: 'Resources', property: 'resources', width: `${colWidth}%` },
        { name: 'Sample Words', property: 'sampleWordsAndTasks', width: `${colWidth}%` },
      ];
      break;
    case FocusAreaSlug.textReading:
      headers = [
        { name: 'Prioritized Skill ID', property: 'prioritizedSkillId', isHidden: true },
        { name: 'Prioritized Skill', property: 'prioritizedSkillName', width: `${colWidth}%` },
      ];
      break;
    default:
      console.error(`Can't map table data. Invalid focus area slug ${focusAreaSlug}`);
      return [];
  }
  if (atLeastOneSkillHasDocumentation) {
    headers.push({ name: 'Pathway', property: 'pathway', width: `${colWidth}%` });
  }
  return headers;
};

const ViewDocumentationLink = (props: {
  path: string;
  studentProfileId: string | undefined;
  color?: ButtonProps['color'];
  size?: ButtonProps['size'];
}) => (
  <ScopeAndSequenceLink to={props.path} studentProfileId={props.studentProfileId}>
    <Button variant="contained" color={props.color} size={props.size}>
      View Documentation
    </Button>
  </ScopeAndSequenceLink>
);

const mapTableData = (
  focusAreaSlug: FocusAreaSlug,
  instructionalUnitSlug: string,
  studentProfileId: string | undefined,
  results: PrioritizedSkillAndResourcesResponse[],
): TableRow[] => {
  switch (focusAreaSlug) {
    case FocusAreaSlug.preWordReading:
      return results.map((skill) => ({
        prioritizedSkillId: skill.prioritizedSkillId,
        prioritizedSkillName: skill.prioritizedSkillName,
        resources: (
          <Stack>
            {skill.books.map((book) => (
              <ScopeAndSequenceLink key={book.id} to={routesDictionary.library.book.url(book.id)} studentProfileId={studentProfileId} target="_blank">
                <HootTypography key={book.id} isPII={false} variant="tablevalue">
                  {book.title}
                </HootTypography>
              </ScopeAndSequenceLink>
            ))}
          </Stack>
        ),
        sampleWordsAndTasks: (
          <Stack>
            {skill.sampleTasksAndWords.map((x) => (
              <HootTypography key={x} isPII={false} variant="tablevalue">
                {x}
              </HootTypography>
            ))}
          </Stack>
        ),
        pathway: skill.hasDocumentation ? (
          <ViewDocumentationLink
            path={routesDictionary.scopeAndSequence.focusArea.unit.skill.documentation.url(
              focusAreaSlug,
              instructionalUnitSlug,
              skill.prioritizedSkillId,
            )}
            color={'neutral.190'}
            size="small"
            studentProfileId={studentProfileId}
          />
        ) : undefined,
      }));
    case FocusAreaSlug.earlyWordReading:
    case FocusAreaSlug.complexWordReading:
      return results.map((skill) => ({
        prioritizedSkillId: skill.prioritizedSkillId,
        prioritizedSkillName: skill.prioritizedSkillName,
        wiseWordsResources: (
          <Stack>
            {skill.books
              .filter((b) => b.decodableFormatIdEnum === DecodableFormat.WiseWords)
              .map((book) => (
                <ScopeAndSequenceLink
                  key={book.id}
                  to={routesDictionary.library.book.url(book.id)}
                  studentProfileId={studentProfileId}
                  target="_blank"
                >
                  <HootTypography key={book.id} isPII={false} variant="tablevalue">
                    {book.title}
                  </HootTypography>
                </ScopeAndSequenceLink>
              ))}
          </Stack>
        ),
        resources: (
          <Stack>
            {skill.books
              .filter((b) => b.decodableFormatIdEnum !== DecodableFormat.WiseWords)
              .map((book) => (
                <ScopeAndSequenceLink
                  key={book.id}
                  to={routesDictionary.library.book.url(book.id)}
                  studentProfileId={studentProfileId}
                  target="_blank"
                >
                  <HootTypography key={book.id} isPII={false} variant="tablevalue">
                    {book.title}
                  </HootTypography>
                </ScopeAndSequenceLink>
              ))}
          </Stack>
        ),
        sampleWordsAndTasks: (
          <HootTypography isPII={false} variant="tablevalue">
            {skill.sampleTasksAndWords.join(', ')}
          </HootTypography>
        ),
        pathway: skill.hasDocumentation ? (
          <ViewDocumentationLink
            path={routesDictionary.scopeAndSequence.focusArea.unit.skill.documentation.url(
              focusAreaSlug,
              instructionalUnitSlug,
              skill.prioritizedSkillId,
            )}
            color={'neutral.190'}
            size="small"
            studentProfileId={studentProfileId}
          />
        ) : undefined,
      }));
    case FocusAreaSlug.textReading:
      return results.map((skill) => ({
        prioritizedSkillId: skill.prioritizedSkillId,
        prioritizedSkillName: skill.prioritizedSkillName,
        pathway: skill.hasDocumentation ? (
          <ViewDocumentationLink
            path={routesDictionary.scopeAndSequence.focusArea.unit.skill.documentation.url(
              focusAreaSlug,
              instructionalUnitSlug,
              skill.prioritizedSkillId,
            )}
            color={'neutral.190'}
            size="small"
            studentProfileId={studentProfileId}
          />
        ) : undefined,
      }));
    default:
      console.error(`Can't map table data. Invalid focus area slug ${focusAreaSlug}`);
      return [];
  }
};

interface InstructionalUnitDetailsCardProps {
  focusArea: ScopeAndSequenceFocusArea;
  instructionalUnit: InstructionalUnitResponse;
  studentProfileId: string | undefined;
}

const InstructionalUnitDetailsCard = (props: InstructionalUnitDetailsCardProps) => {
  const { instructionalUnit, focusArea, studentProfileId } = props;

  const [tableHeaders, setTableHeaders] = useState<HeaderData<TableRow>[]>([]);
  const [tableRows, setTableRows] = useState<TableRow[]>([]);

  const getInstructionalUnitDetailsRequest = useGetPrioritizedSkillsAndResourcesForInstructionalUnit(
    instructionalUnit.id,
    {
      ...(focusArea.slug === FocusAreaSlug.textReading && {
        omitSampleWordsAndTasks: true,
        omitResources: true,
      }),
      excludedResourceTypes: [BookType.HootReadingAssessment, BookType.ProgressMonitoringAssessment],
      resourceType: BookType.Instruction,
    },
    {
      onSuccess: (results) => {
        const atLeastOneSkillHasDocumentation = results.some((x) => x.hasDocumentation);

        setTableHeaders(getHeaders(focusArea.slug, atLeastOneSkillHasDocumentation));
        setTableRows(mapTableData(focusArea.slug, instructionalUnit.slug, studentProfileId, results));
      },
    },
  );

  const viewState =
    getInstructionalUnitDetailsRequest.isFetching && tableRows.length === 0
      ? ViewStateEnum.Loading
      : getInstructionalUnitDetailsRequest.isError
        ? ViewStateEnum.Error
        : ViewStateEnum.Results;

  return (
    <Card isLoading={getInstructionalUnitDetailsRequest.isFetching}>
      <Stack gap={2}>
        <Stack direction="row" justifyContent="space-between" alignItems="center" gap={2}>
          <HootTypography isPII={false} variant="titlemedium">
            {instructionalUnit.name}
          </HootTypography>
          {instructionalUnit.hasDocumentation && (
            <ViewDocumentationLink
              path={routesDictionary.scopeAndSequence.focusArea.unit.documentation.url(focusArea.slug, instructionalUnit.slug)}
              studentProfileId={studentProfileId}
            />
          )}
        </Stack>
        <ViewState state={viewState} loadingContent={<SkeletonContent />}>
          <TableV2 headers={tableHeaders} data={tableRows} />
        </ViewState>
      </Stack>
    </Card>
  );
};

const SkeletonContent = () => (
  <Stack gap={2}>
    <Stack direction="row" alignItems="center" gap={2}>
      <Box flex={1}>
        <Skeleton variant="text" sx={{ maxWidth: '50px', width: '100%' }} />
      </Box>
      <Box flex={1}>
        <Skeleton variant="text" sx={{ maxWidth: '100px', width: '100%' }} />
      </Box>
      <Box flex={1}>
        <Skeleton variant="text" sx={{ maxWidth: '80px', width: '100%' }} />
      </Box>
      <Box flex={1}>
        <Skeleton variant="text" sx={{ maxWidth: '80px', width: '100%' }} />
      </Box>
    </Stack>
    {[...Array(4)].map((_, i) => (
      <Stack key={`skeleton-row-${i}`} direction="row" alignItems="flex-start" gap={2}>
        <Box flex={1}>
          <Skeleton variant="text" sx={{ maxWidth: '140px', width: '100%' }} />
        </Box>
        <Box flex={1}>
          <Skeleton variant="text" sx={{ maxWidth: '220px', width: '100%' }} />
          <Skeleton variant="text" sx={{ maxWidth: '220px', width: '100%' }} />
        </Box>
        <Box flex={1}>
          <Skeleton variant="text" sx={{ maxWidth: '220px', width: '100%' }} />
          <Skeleton variant="text" sx={{ maxWidth: '220px', width: '100%' }} />
          <Skeleton variant="text" sx={{ maxWidth: '220px', width: '100%' }} />
        </Box>
        <Stack direction="row" flex={1} gap={3} alignItems="center">
          <Skeleton variant="rectangular" sx={{ width: '160px', height: '36px' }} />
        </Stack>
      </Stack>
    ))}
  </Stack>
);

const InstructionalUnitDetailsCardWrapper = () => {
  const { instructionalUnit, focusArea } = useOutletContext<{
    instructionalUnit: InstructionalUnitResponse;
    focusArea: ScopeAndSequenceFocusArea;
  }>();

  const { studentProfileId } = useParams<{ studentProfileId?: string }>();

  return instructionalUnit ? (
    <InstructionalUnitDetailsCard instructionalUnit={instructionalUnit} focusArea={focusArea} studentProfileId={studentProfileId} />
  ) : null;
};

export default InstructionalUnitDetailsCardWrapper;
