import { LocationType } from '@hoot-reading/hoot-core/dist/enums/hfs/location-type.enum';
import axios from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '@hoot/config';
import { QueryKey } from '../queryKeys';

export interface DistrictRepresentativeLocationDetailsResponse {
  billableEntityId: string;
  billableEntityFriendlyId: string;
  isBillableEntity: boolean;
  locationType: LocationType;
  hasFullLocationAccess: boolean;
  reporting: boolean;
}

export default function useGetLocationDetails(
  districtRepId: string,
  options?: Omit<UseQueryOptions<DistrictRepresentativeLocationDetailsResponse>, 'queryKey' | 'queryFn'>,
) {
  return useQuery(
    [QueryKey.GetLocationDetails, districtRepId],
    async () => {
      return (
        await axios.get<DistrictRepresentativeLocationDetailsResponse>(
          `${config.apiUrl}/public/district-representative/${districtRepId}/location-details`,
        )
      ).data;
    },
    options,
  );
}
