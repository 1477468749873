import axios, { AxiosError } from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '../../../config';
import { QueryKey } from '../queryKeys';

export interface LessonPlanInstructionalUnit {
  id: string;
  name: string;
  primaryColor: string;
  secondaryColor: string;
}

export interface GetLessonPlanBookDetailsQuery {
  instructionalUnitIds: string[];
}
export interface GetLessonPlanBookDetailsResponse {
  instructionalUnits: LessonPlanInstructionalUnit[];
}

async function getInstructionalUnitsForLessonPlan(query: GetLessonPlanBookDetailsQuery): Promise<GetLessonPlanBookDetailsResponse> {
  const { data } = await axios.get<GetLessonPlanBookDetailsResponse>(`${config.apiUrl}/public/lesson-review/lesson-plan/instructional-units`, {
    params: { instructionalUnitIds: query.instructionalUnitIds },
  });
  return data;
}

export default function useGetInstructionalUnitsForLessonPlan(
  query: GetLessonPlanBookDetailsQuery,
  options?: Omit<UseQueryOptions<GetLessonPlanBookDetailsResponse, AxiosError>, 'queryKey' | 'queryFn'>,
) {
  return useQuery([QueryKey.GetLessonPlanBookDetails, query.instructionalUnitIds], () => getInstructionalUnitsForLessonPlan(query), options);
}
