import { LocationType } from '@hoot-reading/hoot-core/dist/enums/hfs/location-type.enum';
import { useDispatch } from 'react-redux';
import { createFlashMessage } from '@hoot/redux/reducers/flashMessageSlice';
import { formatStudentLoginUrl } from '@hoot/utils/text-display';
import useGetLocationDetails from './api/district-rep/useGetLocationDetails';
import useProfile from './useProfile';

export interface DistrictRepLocationDetails {
  billableEntityId: string;
  studentAccessLink: string;
  isBillableEntity: boolean;
  locationType: LocationType;
  hasFullLocationAccess: boolean;
  reporting: boolean;
}

/**
 * Returns the details of the location for the district rep
 * @returns the request object (`DistrictRepLocationDetails`)
 * and if available returns the billable entity id, student access link, location type, location access permission, location reporting
 */
function useDistrictRepLocationDetails() {
  const { profile, isDistrictRep } = useProfile();
  const districtRepId = profile?.id;
  const dispatch = useDispatch();

  const { data, isFetched, isFetching } = useGetLocationDetails(districtRepId ?? '', {
    enabled: isDistrictRep && !!districtRepId,
    onError: (err) => {
      console.error(err);
      dispatch(createFlashMessage({ message: 'An error occurred while fetching location details', variant: 'error' }));
    },
  });

  return {
    districtRepLocationDetails: {
      billableEntityId: data?.billableEntityId,
      studentAccessLink: data ? formatStudentLoginUrl(data.billableEntityFriendlyId) : undefined,
      isBillableEntity: data?.isBillableEntity,
      locationType: data?.locationType,
      hasFullLocationAccess: data?.hasFullLocationAccess,
      reporting: data?.reporting,
    },
    isFetched,
    isFetching,
  };
}

export default useDistrictRepLocationDetails;
