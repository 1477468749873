import { ScheduledLessonStatus } from '@hoot-reading/hoot-core/dist/enums/scheduled-lesson';
import { Box, Table, TableBody, TableCell, TableHead, TableRow, useMediaQuery, useTheme } from '@mui/material';
import { DateTime } from 'luxon';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { DAYS_OF_WEEK } from '@hoot/constants/constants';
import { LessonDetails, LessonsForDay } from '@hoot/hooks/api/lesson/useMonthlyScheduledLessonsQueryV2';
import ScheduleDrawer from '@hoot/ui/pages/v2/parent/schedule/ScheduleDrawer';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';
import { spliceChunk } from '@hoot/utils/array';
import HootTypography from './core/HootTypography';
import { Icon } from './core/Icon';
import IconButton from './core/IconButton';

export interface PopulatedDate {
  dateVal: DateTime;
  numberOfLessons: number;
  cellColourCode: string;
  textColourCode: string;
}

const Header = () => {
  return (
    <TableRow>
      {DAYS_OF_WEEK.map((weekday) => (
        <TableCell align="center" key={weekday} sx={{ borderBottom: 'none' }}>
          <HootTypography isPII={false} variant="titlesmall">
            {weekday}
          </HootTypography>
        </TableCell>
      ))}
    </TableRow>
  );
};

const DayCell = (props: {
  year: number;
  month: number;
  day: number;
  selectedDate: DateTime;
  disallowDateBefore?: number;
  populatedDates: LessonsForDay[];
  setSelectedDate: React.Dispatch<React.SetStateAction<DateTime>>;
  setSelectedDayData: React.Dispatch<React.SetStateAction<LessonDetails[] | undefined>>;
}) => {
  const { year, month, day, selectedDate, disallowDateBefore, setSelectedDate, populatedDates, setSelectedDayData } = props;
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));

  const userTimeZone = moment.tz.guess();

  const currentDate = DateTime.local(year, month, day).toFormat('yyyy-MM-dd');
  const currentDay = populatedDates.filter((d) => d.date === currentDate)[0];
  const currentDayLessons = currentDay?.lessons.sort((a, b) => a.lessonStartTime - b.lessonStartTime) ?? [];
  const cancelledLessons = currentDayLessons.filter((l) =>
    [ScheduledLessonStatus.Cancelled, ScheduledLessonStatus.Rescheduled].includes(l.lessonStatus),
  );
  const notCancelledLessons = currentDayLessons.filter(
    (l) => ![ScheduledLessonStatus.Cancelled, ScheduledLessonStatus.Rescheduled].includes(l.lessonStatus),
  );
  const sortedLessons = [...notCancelledLessons, ...cancelledLessons];

  const firstTwoLessons = sortedLessons?.slice(0, 2) ?? [];
  const remainingLessons = sortedLessons?.slice(2) ?? [];

  const handleDayClick = (day: number) => () => {
    const clickedDate = DateTime.local(year, month, day);
    const startOfClickedDate = clickedDate.startOf('day').toMillis();
    if (!disallowDateBefore || startOfClickedDate >= disallowDateBefore) {
      const dayData = populatedDates.filter((d) => d.date === clickedDate.toSQLDate());
      setSelectedDayData(dayData[0]?.lessons ?? []);
      setSelectedDate(DateTime.fromMillis(startOfClickedDate));
    }
  };

  const handleColor = (status: ScheduledLessonStatus) => {
    const cancelledStatuses = [ScheduledLessonStatus.Cancelled, ScheduledLessonStatus.Rescheduled];
    if (cancelledStatuses.includes(status)) {
      return hootTokens.palette.error[160];
    } else {
      return hootTokens.palette.success[160];
    }
  };

  const selected = selectedDate ? selectedDate.toFormat('yyyy-MM-dd') === currentDate : false;

  return (
    <Box
      component="td"
      sx={{
        border: '1px solid black',
        '&:hover': {
          cursor: 'pointer',
        },
        background: selected ? hootTokens.palette.warning[180] : undefined,
      }}
      onClick={handleDayClick(day)}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          marginTop: '16px',
          height: isDesktop ? '150px' : '125px',
          gap: '12px',
        }}
      >
        <HootTypography isPII={false} variant={isDesktop ? 'headlinelarge' : 'bodymedium'} key={day}>
          {day}
        </HootTypography>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '4px',
          }}
        >
          {!!firstTwoLessons?.length
            ? firstTwoLessons?.map((l) => (
                <Box
                  key={l.lessonNumber}
                  sx={{
                    backgroundColor: handleColor(l.lessonStatus),
                    width: '100%',
                    height: '26px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    paddingX: '8px',
                  }}
                >
                  <HootTypography isPII={true} variant="bodysmall" key={l.lessonStartTime} noWrap>
                    {l.studentName} @ {DateTime.fromMillis(l.lessonStartTime, { zone: userTimeZone }).toFormat('h:mm a')}
                  </HootTypography>
                </Box>
              ))
            : null}
          {!!remainingLessons.length ? (
            <Box
              sx={{
                backgroundColor: hootTokens.palette.success[160],
                width: '100%',
                height: '26px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                paddingX: '8px',
              }}
            >
              <HootTypography isPII={false} variant="bodysmall" noWrap>
                + {remainingLessons.length} more
              </HootTypography>
            </Box>
          ) : null}
        </Box>
      </Box>
    </Box>
  );
};

const Days = (props: {
  year: number;
  month: number;
  selectedDate: DateTime;
  disallowDateBefore: number;
  disallowDateAfter: number;
  setSelectedDate: React.Dispatch<React.SetStateAction<DateTime>>;
  populatedDates: LessonsForDay[];
  dateObject: DateTime;
  setSelectedDayData: React.Dispatch<React.SetStateAction<LessonDetails[] | undefined>>;
}) => {
  const { year, month, selectedDate, disallowDateBefore, setSelectedDate, populatedDates, dateObject, setSelectedDayData } = props;

  const firstDayOfMonth = Number.parseInt(dateObject.toFormat('c'));
  const daysInMonth = dateObject.daysInMonth;

  const padding =
    firstDayOfMonth !== 7
      ? [...Array(firstDayOfMonth)].map((_, idx) => (
          <Box
            key={idx}
            component="td"
            sx={{
              '&:hover': {
                cursor: 'pointer',
              },
              border: '1px solid black',
            }}
          >
            {''}
          </Box>
        ))
      : [];

  const dateFields = [...Array(daysInMonth)].map((_, idx) => (
    <DayCell
      year={year}
      month={month}
      day={idx + 1}
      selectedDate={selectedDate}
      setSelectedDate={setSelectedDate}
      disallowDateBefore={disallowDateBefore}
      populatedDates={populatedDates}
      key={idx + 1}
      setSelectedDayData={setSelectedDayData}
    />
  ));

  return (
    <TableBody>
      {spliceChunk([...padding, ...dateFields], 7).map((row, idx) => (
        <TableRow
          key={idx}
          sx={{
            backgroundColor: `${hootTokens.palette.white}!important`,
          }}
        >
          {row.map((d, idx) => (
            <React.Fragment key={idx}>{d}</React.Fragment>
          ))}
        </TableRow>
      ))}
    </TableBody>
  );
};

const CalendarV2 = (props: {
  disallowDateBefore: number;
  disallowDateAfter: number;
  populatedDates: LessonsForDay[];
  setSelectedCalendarMonth: React.Dispatch<React.SetStateAction<number | undefined>>;
  selectedDate: DateTime;
  setSelectedDate: React.Dispatch<React.SetStateAction<DateTime>>;
}) => {
  const { disallowDateBefore, disallowDateAfter, populatedDates, setSelectedCalendarMonth, selectedDate, setSelectedDate } = props;
  const [dateObject, setDateObject] = useState<DateTime>(DateTime.now().startOf('month'));
  const [selectedDayData, setSelectedDayData] = useState<LessonDetails[] | undefined>(undefined);

  const year = dateObject.year;
  const month = dateObject.month;

  const disableLeftArrow = dateObject.toMillis() <= disallowDateBefore;
  const disableRightArrow = dateObject.endOf('month').toMillis() >= disallowDateAfter;

  useEffect(() => {
    setSelectedCalendarMonth(dateObject.toMillis());
  }, [dateObject, setSelectedCalendarMonth]);

  useEffect(() => {
    if (selectedDate) {
      setSelectedDate(selectedDate);
    }
  }, [selectedDate, setSelectedDate]);

  const useEffectselectedDayDataDependency = JSON.stringify(selectedDayData);
  useEffect(() => {
    if (useEffectselectedDayDataDependency) {
      const dayData = populatedDates.filter((d) => d.date === selectedDate.toSQLDate());
      setSelectedDayData(dayData[0]?.lessons ?? []);
    }
  }, [selectedDate, populatedDates, useEffectselectedDayDataDependency]);

  const handleMonthClick = (direction: 'increment' | 'decrement') => {
    setDateObject((current) => {
      if (direction === 'increment') {
        return current.plus({ months: 1 });
      }
      return current.minus({ months: 1 });
    });
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'stretch',
          padding: '12px 8px 8px 8px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            textAlign: 'center',
            alignItems: 'center',
            gap: '16px',
          }}
        >
          <IconButton
            onClick={() => handleMonthClick('decrement')}
            sx={{ backgroundColor: hootTokens.palette.neutral[180] }}
            disabled={disableLeftArrow}
          >
            <Icon name="chevron" />
          </IconButton>
          <HootTypography isPII={false} width={'200px'} variant="titlelarge">
            {dateObject.toFormat('LLLL yyyy')}
          </HootTypography>
          <IconButton
            onClick={() => handleMonthClick('increment')}
            sx={{ backgroundColor: hootTokens.palette.neutral[180] }}
            disabled={disableRightArrow}
          >
            <Icon name="chevron" sx={{ rotate: '180deg' }} />
          </IconButton>
        </Box>
        <Table
          sx={{
            flex: '1 1 auto',
            borderCollapse: 'collapse',
            tableLayout: 'fixed',
          }}
        >
          <TableHead>
            <Header />
          </TableHead>
          <Days
            year={year}
            month={month}
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            disallowDateBefore={disallowDateBefore}
            populatedDates={populatedDates}
            dateObject={dateObject}
            setSelectedDayData={setSelectedDayData}
            disallowDateAfter={disallowDateAfter}
          />
        </Table>
      </Box>
      <ScheduleDrawer
        setSelectedDayData={setSelectedDayData}
        selectedDate={selectedDate}
        data={selectedDayData}
        setSelectedDate={setSelectedDate}
        setSelectedCalendarMonth={setSelectedCalendarMonth}
        handleMonthClick={handleMonthClick}
        disallowDateBefore={disallowDateBefore}
        disallowDateAfter={disallowDateAfter}
      />
    </>
  );
};

export default CalendarV2;
