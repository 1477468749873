import {
  EventVisibilityOption,
  FocusVisibilityOption,
  PeriodBasedValues,
  TimelineEvent,
  TimelineLessonReviewFocus,
  TimelineOutcome,
} from '@hoot-reading/hoot-core/dist/enums/student-timeline';
import {
  BlankIcon,
  CancellationIcon,
  CompletedLessonIcon,
  FormativeAssessmentIcon,
  HootReadingAssessmentIcon,
  InstructionRequiredIcon,
  LessonPrimaryFocusIcon,
  LessonSecondaryFocusIcon,
  MasteredIcon,
  PreviouslyMasteredOrPassedIcon,
  ProgressMonitoringAssessmentIcon,
  ReviewRequiredIcon,
  ScheduledLessonIcon,
  StudentAbsenceIcon,
} from '@hoot/ui/components/v2/icons/timeline-legend';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';

export const timelineEventIconDictionary: Record<TimelineEvent, JSX.Element> = {
  [TimelineEvent.HRA]: <HootReadingAssessmentIcon />,
  [TimelineEvent.PMA]: <ProgressMonitoringAssessmentIcon />,
  [TimelineEvent.CompletedLesson]: <CompletedLessonIcon />,
  [TimelineEvent.ScheduledLesson]: <ScheduledLessonIcon />,
  [TimelineEvent.StudentAbsence]: <StudentAbsenceIcon />,
  [TimelineEvent.Cancellation]: <CancellationIcon />,
};

export const assessmentOutcomesIconDictionary: Record<TimelineOutcome, JSX.Element> = {
  [TimelineOutcome.Mastered]: <MasteredIcon />,
  [TimelineOutcome.ReviewRequired]: <ReviewRequiredIcon />,
  [TimelineOutcome.InstructionRequired]: <InstructionRequiredIcon />,
  [TimelineOutcome.Blank]: <BlankIcon />,
};

export const lessonReviewFocusesIconDictionary: Record<TimelineLessonReviewFocus, JSX.Element> = {
  [TimelineLessonReviewFocus.AdvancedBeyond]: <PreviouslyMasteredOrPassedIcon />,
  [TimelineLessonReviewFocus.LessonPrimaryFocus]: <LessonPrimaryFocusIcon />,
  [TimelineLessonReviewFocus.LessonSecondaryFocus]: <LessonSecondaryFocusIcon />,
  [TimelineLessonReviewFocus.FormativeAssessment]: <FormativeAssessmentIcon />,
};

export const timelineIconsDictionary = { ...timelineEventIconDictionary, ...assessmentOutcomesIconDictionary, ...lessonReviewFocusesIconDictionary };

export const focusVisibilityLabels: Record<FocusVisibilityOption, string> = {
  [FocusVisibilityOption.PreWordReading]: 'Pre-Word Reading',
  [FocusVisibilityOption.EarlyWordReading]: 'Early Word Reading',
  [FocusVisibilityOption.ComplexReading]: 'Complex Word Reading',
  [FocusVisibilityOption.TextLanguage]: 'Text Language',
  [FocusVisibilityOption.TextReading]: 'Text Reading',
  [FocusVisibilityOption.OtherAreas]: 'Other Areas',
};

export const eventVisibilityLabels: Record<EventVisibilityOption, string> = {
  [EventVisibilityOption.Hra]: 'HRAs',
  [EventVisibilityOption.Pma]: 'PMAs',
  [EventVisibilityOption.Absence]: 'Absences',
  [EventVisibilityOption.Cancellation]: 'Cancellations',
  [EventVisibilityOption.Lessons]: 'Lessons',
};

export const periodBasedLabels: Record<PeriodBasedValues, string> = {
  [PeriodBasedValues.EntireEnrolment]: 'Entire Enrolment',
  [PeriodBasedValues.AllTimeLessonHistory]: 'All Time Lesson History',
  [PeriodBasedValues.MostRecentUntilNextHRA]: 'Most Recent Until Next HRA',
  [PeriodBasedValues.MostRecentUntilNextHRAOrPMA]: 'Most Recent Until Next HRA or PMA',
};

export const timelineColorDictionary: Record<TimelineEvent | TimelineOutcome | TimelineLessonReviewFocus, string> = {
  [TimelineEvent.HRA]: hootTokens.surface[2],
  [TimelineEvent.PMA]: hootTokens.surface[2],
  [TimelineEvent.CompletedLesson]: '#A3B5CB',
  [TimelineEvent.ScheduledLesson]: '#DCE5FB',
  [TimelineEvent.StudentAbsence]: hootTokens.palette.error[160],
  [TimelineEvent.Cancellation]: hootTokens.palette.error[160],
  [TimelineOutcome.Mastered]: hootTokens.palette.success[160],
  [TimelineOutcome.ReviewRequired]: hootTokens.palette.secondary[160],
  [TimelineOutcome.InstructionRequired]: hootTokens.palette.warning[160],
  [TimelineOutcome.Blank]: hootTokens.surface[2],
  [TimelineLessonReviewFocus.AdvancedBeyond]: '#C4E3B4',
  [TimelineLessonReviewFocus.LessonPrimaryFocus]: '#A3B5CB',
  [TimelineLessonReviewFocus.LessonSecondaryFocus]: '#A3B5CB',
  [TimelineLessonReviewFocus.FormativeAssessment]: '#A3B5CB',
};

export const RapportAndRelationship = 'Rapport & Relationship';
