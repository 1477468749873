import { DialogContent, DialogTitle, Skeleton, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';
import { Icon } from '@hoot/ui/components/v2/core/Icon';
import IconButton from '@hoot/ui/components/v2/core/IconButton';
import BasicAlertDialog from '@hoot/ui/components/v2/dialogs/BasicAlertDialog';
import DoublePanedDialog from '@hoot/ui/components/v2/dialogs/DoublePanedDialog';
import LessonReviewWizardContextProvider, {
  LegacyLessonReviewWizardStepEnum,
  LessonReviewWizardStepEnum,
  useLessonReviewWizardContext,
} from '@hoot/ui/pages/v2/teacher/my-students/student-details/lesson-reviews/lesson-review-wizard/LessonReviewContextProvider';
import { LessonReviewNotesTextField } from '@hoot/ui/pages/v2/teacher/my-students/student-details/lesson-reviews/lesson-review-wizard/steps/LessonReviewLessonNotesStep';
import { EventType } from '../../../../../../../../events/eventType';
import { LessonCancelledMessage } from '../../../../../../../../events/messages/lesson-cancelled.message';
import { useSocketSubscription } from '../../../../../../../../hooks/useSocketSubscription';

type LessonReviewWizardDialogProps = InternalDialogProps & InternalContextProps;

interface InternalDialogProps {
  show: boolean;
}

interface InternalContextProps {
  lessonIdToReview: string | undefined;
  onDismiss: () => void;
  onCompleted?: () => void;
}

const LessonReviewWizardDialog = (props: InternalDialogProps) => {
  const { show } = props;
  const {
    currentStep,
    lessonToReview,
    actions: { dismissLessonReviewWizard, reset },
  } = useLessonReviewWizardContext();

  const [showDirtyFormModal, setShowDirtyFormModal] = useState<boolean>(false);

  useEffect(() => {
    if (show) {
      reset();
    }
  }, [show, reset]);

  const renderRightContentPane = () => {
    const currentIntroductionStep = LessonReviewWizardStepEnum.Introduction;
    const currentLessonNotesStep = LessonReviewWizardStepEnum.LessonNotes;
    if (currentStep.id === currentIntroductionStep || currentStep.id >= currentLessonNotesStep) {
      return undefined;
    }
    return (
      <DialogContent sx={{ py: 2, mt: 2 }}>
        <Stack gap={2} height="100%">
          <HootTypography isPII={false} variant="titlelarge">
            Notes
          </HootTypography>
          <LessonReviewNotesTextField />
        </Stack>
      </DialogContent>
    );
  };

  const handleClose = () => {
    if (
      ![
        LessonReviewWizardStepEnum.Introduction,
        LessonReviewWizardStepEnum.Completed,
        LegacyLessonReviewWizardStepEnum.Introduction,
        LegacyLessonReviewWizardStepEnum.Completed,
      ].includes(currentStep.id)
    ) {
      setShowDirtyFormModal(true);
    } else {
      dismissLessonReviewWizard();
    }
  };

  const handleConfirm = () => {
    setShowDirtyFormModal(false);
    dismissLessonReviewWizard();
  };

  const calcDialogWidth = () => {
    switch (currentStep.id) {
      case LessonReviewWizardStepEnum.Introduction:
      case LessonReviewWizardStepEnum.LessonNotes:
        return '900px';
      case LessonReviewWizardStepEnum.FormativeAssessment:
      case LessonReviewWizardStepEnum.ComprehensionRubric:
        return '1400px';
      case LessonReviewWizardStepEnum.LessonStructure:
      case LessonReviewWizardStepEnum.InstructionalFocus:
      case LessonReviewWizardStepEnum.RapportBuilding:
      case LessonReviewWizardStepEnum.ResourceProgress:
      case LessonReviewWizardStepEnum.FocusPriority:
      case LessonReviewWizardStepEnum.LessonPlan:
      case LessonReviewWizardStepEnum.StudentEngagement:
        return '1200px';
      case LessonReviewWizardStepEnum.Completed:
        return '675px';
      default:
        return '900px';
    }
  };

  return (
    <>
      <DoublePanedDialog
        show={props.show}
        rightContentPane={renderRightContentPane()}
        rootDialogMaxWidth={calcDialogWidth} // adjusts the Dialogs maxWidth depending on the current Step.
      >
        <DialogTitle sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
          <span
            style={{
              display: 'flex',
              flexDirection: 'row',
              gap: '8px',
            }}
          >
            Post Lesson Review – {lessonToReview?.student.name ? lessonToReview?.student.name : <Skeleton variant="text" width={250} />}
          </span>
          <IconButton onClick={handleClose}>
            <Icon name="close" />
          </IconButton>
        </DialogTitle>
        <LessonCancelledDialog onDismiss={handleClose} lessonId={lessonToReview?.lessonId} />
        {currentStep.render()}
      </DoublePanedDialog>

      <BasicAlertDialog
        show={showDirtyFormModal}
        onDismiss={() => setShowDirtyFormModal(false)}
        title={'Are you sure you want to leave?'}
        content={
          <HootTypography isPII={false} variant="bodylarge">
            All progress will be lost.
          </HootTypography>
        }
        primaryAction={{
          label: 'Yes, I want to leave',
          onClick: handleConfirm,
          props: {
            variant: 'contained',
          },
        }}
        secondaryAction={{
          label: 'Cancel',
          onClick: () => setShowDirtyFormModal(false),
        }}
      />
    </>
  );
};

function LessonCancelledDialog(props: { lessonId?: string; onDismiss: () => void }) {
  const [lessonIsCancelled, setLessonIsCancelled] = useState<boolean>(false);

  useSocketSubscription<LessonCancelledMessage>(EventType.LessonCancelled, {
    onEventReceived(response) {
      if (response.lessonId === props.lessonId) {
        setLessonIsCancelled(true);
      }
    },
  });

  if (!lessonIsCancelled) {
    return null;
  }

  function handleDismiss() {
    props.onDismiss();
  }

  return (
    <BasicAlertDialog
      backgroundSx={{
        backdropFilter: 'blur(8px)',
      }}
      maxWidth="md"
      title="Lesson Cancellation"
      onDismiss={handleDismiss}
      show
      content={
        <Stack>
          <HootTypography isPII={false} sx={{ marginTop: '16px' }}>
            The lesson associated with this review has been cancelled and will now be closed.
          </HootTypography>
          <HootTypography isPII={false} sx={{ marginTop: '16px' }}>
            Please contact <a href="mailto:help@hootreading.com">help@hootreading.com</a> if you have any questions.
          </HootTypography>
        </Stack>
      }
      primaryAction={{
        label: 'Exit Now',
        onClick: () => {
          handleDismiss();
        },
      }}
    />
  );
}

const LessonReviewDialog = (props: LessonReviewWizardDialogProps) => {
  const { show, lessonIdToReview, onDismiss, onCompleted } = props;
  return (
    <LessonReviewWizardContextProvider lessonIdToReview={lessonIdToReview} onDismissLessonReviewWizard={onDismiss} onCompleted={onCompleted}>
      <LessonReviewWizardDialog show={show} />
    </LessonReviewWizardContextProvider>
  );
};

export default LessonReviewDialog;
