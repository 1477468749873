import {
  EventVisibilityOption,
  FocusVisibilityOption,
  TimelineFilterType,
  TimelineViewOption,
} from '@hoot-reading/hoot-core/dist/enums/student-timeline';
import { ChevronLeft } from '@mui/icons-material';
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Stack } from '@mui/material';
import { useState } from 'react';
import useGetStudentHootLevels from '@hoot/hooks/api/my-student/getStudentHootLevels';
import useGetStudentTimeline, { StudentTimelineRequest } from '@hoot/hooks/api/my-student/getStudentTimeline';
import { Button } from '@hoot/ui/components/v2/core/Button';
import Card from '@hoot/ui/components/v2/core/Card';
import { Chip } from '@hoot/ui/components/v2/core/Chip';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';
import { Icon } from '@hoot/ui/components/v2/core/Icon';
import IconButton from '@hoot/ui/components/v2/core/IconButton';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';
import FocusVisibilityMenu, { FocusOptionType } from '../../timeline/FocusVisibilityMenu';
import TimelineGrid from '../../timeline/TimelineGrid';
import TimelineLegendDialog from '../../timeline/TimelineLegendDialog';
import { focusVisibilityLabels } from '../../timeline/common';
import { SelectedUnit } from './steps/LessonReviewLessonPlanStep';

interface ILessonReviewTimelineModalProps {
  studentProfileId: string | undefined;
  studentName: string;
  onClickNext: (selectedUnit: SelectedUnit) => void;
  onDismiss: () => void;
}

const LessonReviewTimelineModal = (props: ILessonReviewTimelineModalProps) => {
  const { studentProfileId, studentName, onDismiss, onClickNext } = props;

  const [selectedUnit, setSelectedUnit] = useState<SelectedUnit>();

  // check student levels before populating filters/
  const { data: hootLevels } = useGetStudentHootLevels(studentProfileId ?? '', {
    onSuccess: (data) => {
      const focusVisibilityOptions = [
        data.moduleOneInProgress ? FocusVisibilityOption.PreWordReading : undefined,
        data.moduleTwoInProgress ? FocusVisibilityOption.EarlyWordReading : undefined,
        data.moduleThreeInProgress ? FocusVisibilityOption.ComplexReading : undefined,
        data.hasAttemptedTextReading ? FocusVisibilityOption.TextReading : undefined,
        data.hasAttemptedTextLanguage ? FocusVisibilityOption.TextLanguage : undefined,
      ].filter((option) => !!option);
      setQuery({
        filterType: TimelineFilterType.DateBased,
        viewOption: TimelineViewOption.AllTimeLessonHistory,
        eventVisibility: [EventVisibilityOption.Hra, EventVisibilityOption.Pma, EventVisibilityOption.Lessons],
        focusVisibility: focusVisibilityOptions,
      });
      setFocusOptions({
        [FocusVisibilityOption.PreWordReading]: data.moduleOneInProgress,
        [FocusVisibilityOption.EarlyWordReading]: data.moduleTwoInProgress,
        [FocusVisibilityOption.ComplexReading]: data.moduleThreeInProgress,
        [FocusVisibilityOption.TextLanguage]: data.hasAttemptedTextLanguage,
        [FocusVisibilityOption.TextReading]: data.hasAttemptedTextReading,
        [FocusVisibilityOption.OtherAreas]: false,
      });
    },
  });

  const [isFocusVisibilityOpen, setIsFocusVisibilityOpen] = useState<HTMLButtonElement | null>(null);
  const [focusOptions, setFocusOptions] = useState<FocusOptionType>({
    [FocusVisibilityOption.PreWordReading]: false,
    [FocusVisibilityOption.EarlyWordReading]: false,
    [FocusVisibilityOption.ComplexReading]: false,
    [FocusVisibilityOption.TextLanguage]: false,
    [FocusVisibilityOption.TextReading]: false,
    [FocusVisibilityOption.OtherAreas]: false,
  });

  const [query, setQuery] = useState<StudentTimelineRequest>({
    filterType: TimelineFilterType.DateBased,
    viewOption: TimelineViewOption.None,
    eventVisibility: [EventVisibilityOption.Hra, EventVisibilityOption.Pma, EventVisibilityOption.Lessons],
  });

  const { data: timelineData } = useGetStudentTimeline(studentProfileId ?? '', query, {
    enabled: !!studentProfileId && !!hootLevels,
  });

  const [isTimelineLegendOpen, setIsTimelineLegendOpen] = useState<boolean>(false);

  const onFocusVisibilityClick = (e: React.MouseEvent<HTMLButtonElement>) => setIsFocusVisibilityOpen(e.currentTarget);
  const onDismissFocusVisibility = () => setIsFocusVisibilityOpen(null);

  const toggleLegendDialog = () => setIsTimelineLegendOpen((prev) => !prev);

  const handleDeleteFocusChip = (option: FocusVisibilityOption) => {
    const currentFocusOptions = focusOptions;
    const updatedFocusOptions = { ...currentFocusOptions, [option]: !currentFocusOptions[option] };
    setFocusOptions(updatedFocusOptions);

    const focusVisibilityOptions = Object.keys(updatedFocusOptions).filter(
      (key) => updatedFocusOptions[key as FocusVisibilityOption] === true,
    ) as FocusVisibilityOption[];
    setQuery((query) => ({
      ...query,
      focusVisibility: focusVisibilityOptions,
    }));
  };

  const onSelectUnit = (selectedUnit: SelectedUnit) => {
    setSelectedUnit(selectedUnit);
  };

  return (
    <>
      <Dialog open={true} maxWidth="lg">
        <DialogTitle>
          <Stack direction="row" alignItems={'center'} justifyContent={'space-between'}>
            <HootTypography isPII={true} variant="titlelarge">
              Select Focus - {studentName}
            </HootTypography>
            <IconButton onClick={onDismiss}>
              <Icon name="close" />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent sx={{ padding: 2 }}>
          <Stack gap={2}>
            <HootTypography isPII={true} variant="bodymedium">
              To ensure an appropriate book is being added to <strong>{studentName}'s</strong> lesson plan – use the Timeline to first select what{' '}
              <strong>{studentName}'s</strong> next lesson should focus on.
            </HootTypography>
            <Card
              title={
                <HootTypography isPII={true} variant="titlemedium">
                  {studentName}'s Timeline
                </HootTypography>
              }
            >
              <Stack gap={2}>
                <Stack direction="row" gap={1}>
                  <Button size="small" onClick={onFocusVisibilityClick} startIcon={<Icon name="show" />} variant="contained" color="neutral.180">
                    Focus Visibility
                  </Button>
                  <Button size="small" onClick={toggleLegendDialog} variant="contained" color="neutral.180">
                    {isTimelineLegendOpen ? 'Hide Legend' : 'Show Legend'}
                  </Button>
                </Stack>
                <Box sx={{ border: `1px dashed ${hootTokens.palette.neutral[160]}` }} />
                <Stack gap={1}>
                  <HootTypography isPII={false} variant="tableheadingactive">
                    Visible:
                  </HootTypography>
                  <Stack direction="row" gap={1} width="100%" flexWrap="wrap">
                    {Object.entries(focusOptions).map(
                      ([key, checked]) =>
                        checked && (
                          <Chip
                            key={key}
                            label={`Focus: ${focusVisibilityLabels[key as FocusVisibilityOption]}`}
                            onDelete={() => handleDeleteFocusChip(key as FocusVisibilityOption)}
                            deleteIcon={<Icon name="close" htmlColor="#000" />}
                          />
                        ),
                    )}
                  </Stack>
                </Stack>
                <Box sx={{ border: `1px dashed ${hootTokens.palette.neutral[160]}` }} />
              </Stack>
              <TimelineGrid data={timelineData} studentProfileId={studentProfileId!} selectedUnit={selectedUnit} onSelectUnit={onSelectUnit} />
            </Card>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" startIcon={<ChevronLeft />} onClick={onDismiss}>
            Go Back
          </Button>
          <Button variant="contained" disabled={!selectedUnit} onClick={() => onClickNext(selectedUnit!)}>
            Next Step
          </Button>
        </DialogActions>
        <TimelineLegendDialog open={isTimelineLegendOpen} onDismiss={toggleLegendDialog} />
      </Dialog>
      <FocusVisibilityMenu
        anchorElement={isFocusVisibilityOpen}
        onDismiss={onDismissFocusVisibility}
        focusOptions={focusOptions}
        setFocusOptions={setFocusOptions}
        setQuery={setQuery}
      />
    </>
  );
};

export default LessonReviewTimelineModal;
