import { TeacherBlockStatus } from '@hoot-reading/hoot-core/dist/enums/teacher-block';
import axios from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '@hoot/config';
import { QueryKey } from '../queryKeys';

export interface GetTeacherBlocksRequest {
  startsAt: number;
  endsAt: number;
  includeCancelled: boolean;
}

export interface TeacherAccountBlocksResponse {
  blocks: TeacherAccountBlockResponse[];
}

export interface TeacherAccountBlockResponse {
  id: string;
  number: number;
  startsAt: number;
  endsAt: number;
  status: TeacherBlockStatus;
}

async function getTeacherBlocks(query: GetTeacherBlocksRequest): Promise<TeacherAccountBlocksResponse> {
  const { data } = await axios.post<TeacherAccountBlocksResponse>(`${config.apiUrl}/public/teacher-block`, query);
  return data;
}

export function useGetTeacherBlocks(
  query: GetTeacherBlocksRequest,
  options?: Omit<UseQueryOptions<TeacherAccountBlocksResponse>, 'mutationKey' | 'mutationFn'>,
) {
  return useQuery([QueryKey.GetTeacherBlocks, query], () => getTeacherBlocks(query), options);
}
