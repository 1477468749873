import Stack from '@mui/material/Stack';
import { useGetAvgWeeklyStudentAttendanceReportForStudent } from '@hoot/hooks/api/district-rep/reporting/useGetAvgWeeklyStudentAttendanceReport';
import useGetStudentProfileMetrics, { DateFilterQuery } from '@hoot/hooks/api/district-rep/reporting/useGetStudentProfileMetrics';
import { DistrictRepStudentProfileSnippetResponse } from '@hoot/hooks/api/district-rep/useDistrictRepresentativeGetStudentProfileSnippet';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';
import Tag from '@hoot/ui/components/v2/core/Tag';
import { formatPossessiveNoun } from '@hoot/utils/text-display';
import { studentGradeLabels, studentStatusLabels } from '../../../../../../models/api/district-rep-reporting/common';
import { AverageWeeklyStudentAttendanceBarChart } from '../../reporting/AttendanceCharts';
import StudentReportMetrics from './StudentReportMetrics';

interface StudentReportProps {
  studentProfile: DistrictRepStudentProfileSnippetResponse | undefined;
  dateFilter: DateFilterQuery | undefined;
}

const StudentReport = (props: StudentReportProps) => {
  const { studentProfile, dateFilter } = props;

  const { data, isFetching } = useGetStudentProfileMetrics(studentProfile?.id ?? '', dateFilter ?? {});

  const getAverageWeeklyStudentAttendanceRequest = useGetAvgWeeklyStudentAttendanceReportForStudent(
    studentProfile?.id ?? '',
    { date: dateFilter?.date },
    {
      enabled: !!studentProfile?.id,
      retry: false,
    },
  );

  const showSchoolTag = !!data?.schoolName && data.schoolName !== 'Unknown';

  return (
    <Stack sx={{ padding: 3, border: '1px solid #000', borderRadius: '8px' }}>
      <Stack direction="row" justifyContent="space-between" mb={3}>
        <HootTypography variant="displaysmall" isPII={true}>
          {formatPossessiveNoun(studentProfile?.name ?? '')} Report
        </HootTypography>
      </Stack>

      <Stack direction="row" gap={2}>
        {data?.grade && <Tag label={studentGradeLabels[data.grade]} />}
        {showSchoolTag && <Tag label={data.schoolName} />}
        {studentProfile?.number ? <Tag label={`Student Number: ${studentProfile?.number ?? data?.number.toString()}`} /> : null}
        {data?.status && <Tag label={`Status: ${studentStatusLabels[data.status]}`} />}
      </Stack>

      <StudentReportMetrics data={data} isLoading={isFetching} />
      <AverageWeeklyStudentAttendanceBarChart
        title="Student Weekly Attendance"
        queryResult={getAverageWeeklyStudentAttendanceRequest}
        sx={{ width: '100%' }}
      />
    </Stack>
  );
};

export default StudentReport;
