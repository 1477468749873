import { StudentType } from '@hoot-reading/hoot-core/dist/enums/user/student/student-type.enum';
import { Box, Stack } from '@mui/material';
import { useEffect, useMemo, useRef, useState } from 'react';
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom';
import { BookResponse } from '@hoot/events/interfaces/book-response';
import { BookSearch } from '@hoot/events/interfaces/book-search';
import { QueryLibraryV2 } from '@hoot/hooks/api/library/useSearchLibrary';
import useSearchStudentLibrary from '@hoot/hooks/api/student-library/useSearchStudentLibrary';
import { usePageTitle } from '@hoot/hooks/usePageTitle';
import useProfile from '@hoot/hooks/useProfile';
import { InstructionalFocus } from '@hoot/models/api/enums/instructional-focus';
import { ShelfType } from '@hoot/models/api/enums/shelf-type-enum';
import { BookType, Grade, SubLibrary } from '@hoot/models/api/library';
import { GenericPaginatedResponse } from '@hoot/models/api/pagination';
import { createFlashMessage } from '@hoot/redux/reducers/flashMessageSlice';
import { DEFAULT_LIBRARY_GRID_VIEW_PAGE_SIZE } from '@hoot/redux/reducers/librarySlice';
import { useAppDispatch } from '@hoot/redux/store';
import { routesDictionary } from '@hoot/routes/routesDictionary';
import { LottieFile } from '@hoot/ui/components/v2/lottie/Lottie';
import { useAuth } from '@hoot/ui/context/AuthContext';
import { StudentDecodableLibraryFilters } from '@hoot/ui/pages/v2/student/library/StudentDecodableAccessLibraryFilterDialog';
import StudentLibrary from '@hoot/ui/pages/v2/student/library/StudentLibrary';
import { StudentLibraryFilters } from '@hoot/ui/pages/v2/student/library/StudentLibraryFilterModal';
import StudentLibraryLeftPane, { StudentLibraryLeftPaneProps } from '@hoot/ui/pages/v2/student/library/StudentLibraryLeftPane';
import StudentLibraryRightPane from '@hoot/ui/pages/v2/student/library/StudentLibraryRightPane';
import StudentLibrarySearchModal, { StudentLibrarySearchModalProps } from '@hoot/ui/pages/v2/student/library/StudentLibrarySearchModal';
import { StudentLibraryReaderPageQueryParams } from '@hoot/ui/pages/v2/student/library/reader/StudentLibraryReaderPage';
import { removeEmptyObjectValues } from '@hoot/utils/removeEmptyObjectValues';

type StudentLibraryQueryFilters = StudentLibraryFilters & StudentDecodableLibraryFilters & { seriesId: string };

export enum StudentSubLibrarySelection {
  AllBooks = 'all-books',
  Animals = 'animals',
  FablesAndFairyTales = 'fables-and-fairy-tales',
  Favourites = 'favs',
  SportsAndGames = 'sports-and-games',
}

export enum StudentLibraryPageNavigationStateKey {
  LibraryPageUrlSearchQuery = 'libraryPageUrlSearchQuery',
}

export const sublibraryLabels: Record<StudentSubLibrarySelection, string> = {
  [StudentSubLibrarySelection.AllBooks]: 'All books',
  [StudentSubLibrarySelection.Favourites]: 'Favorite books',
  [StudentSubLibrarySelection.Animals]: 'Animals and insects',
  [StudentSubLibrarySelection.FablesAndFairyTales]: 'Fables and fairy tales',
  [StudentSubLibrarySelection.SportsAndGames]: 'Sports and activities',
};

export const sublibraryLottie: Record<StudentSubLibrarySelection, LottieFile> = {
  [StudentSubLibrarySelection.AllBooks]: LottieFile.Dictionary,
  [StudentSubLibrarySelection.Favourites]: LottieFile.Heart,
  [StudentSubLibrarySelection.Animals]: LottieFile.GuineaPig,
  [StudentSubLibrarySelection.FablesAndFairyTales]: LottieFile.Castle,
  [StudentSubLibrarySelection.SportsAndGames]: LottieFile.BasketBall,
};

enum QueryKeyEnum {
  SubLibrary = 'sub-library',
  BookTitleSearch = 'search',
  Genre = 'genre',
  Format = 'format',
  Topics = 'topics',
  Theme = 'theme',
  Grade = 'grade',
  Collection = 'collection',
  Series = 'series',
  InstructionalFocus = 'instructional-focus',
  InstructionalUnit = 'instructional-unit',
  BookLevelPrioritizedSkills = 'book-level-prioritized-skills',
}

// When the user interacts with the buttons to scroll up and down, we scroll up/down by a percentage of the view height
// rather than scrolling the entire page height.
const scrollHeightRatio = 0.9;

const emptySearchResponse = {
  data: [],
  count: 0,
  page: 1,
  pageSize: DEFAULT_LIBRARY_GRID_VIEW_PAGE_SIZE,
};

const StudentLibraryPage = () => {
  usePageTitle('Library | Hoot Reading');

  const { profile } = useProfile();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { getUser } = useAuth();
  const studentProfiles = getUser().studentProfiles;

  const studentProfile = useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
    return studentProfiles?.find((x) => x.id === profile!.id)!;
  }, [profile, studentProfiles]);

  const [searchParams, setSearchParams] = useSearchParams();

  const subLibraryQuery = (searchParams.get(QueryKeyEnum.SubLibrary) as StudentSubLibrarySelection) ?? StudentSubLibrarySelection.AllBooks;
  const bookTitleSearchQuery = searchParams.get(QueryKeyEnum.BookTitleSearch) ?? undefined;

  const subLibrarySelection = useMemo(() => {
    return Object.values(StudentSubLibrarySelection).includes(subLibraryQuery) ? subLibraryQuery : StudentSubLibrarySelection.AllBooks;
  }, [subLibraryQuery]);

  const definedCustomFilters = useMemo<Partial<StudentLibraryQueryFilters>>(() => {
    return mapUrlQueryParamsToFilters(searchParams);
  }, [searchParams]);

  const libraryContentRef = useRef<HTMLDivElement>(null);

  const subLibraryFilters: Record<StudentSubLibrarySelection, Partial<QueryLibraryV2>> = useMemo(() => {
    return {
      [StudentSubLibrarySelection.AllBooks]: {
        ...(studentProfile.studentType === StudentType.HootDecodableAccess
          ? {
              isDecodableLibrary: true,
            }
          : {
              excludedResourceTypes: [BookType.HootReadingAssessment, BookType.FormativeAssessment],
              excludedInstructionalFocusIds: [
                InstructionalFocus.PreWordResource,
                InstructionalFocus.EarlyWord,
                InstructionalFocus.ComplexWordReading,
                InstructionalFocus.FormativeAssessmentTextReading,
                InstructionalFocus.FormativeAssessmentEarlyWordReading,
                InstructionalFocus.FormativeAssessmentComplexWordReading,
                InstructionalFocus.FormativeAssessmentPreWordReading,
              ],
              hiLo: false,
            }),
      },
      [StudentSubLibrarySelection.Animals]: {
        subLibrary: SubLibrary.Animals,
      },
      [StudentSubLibrarySelection.FablesAndFairyTales]: {
        subLibrary: SubLibrary.FablesAndFairyTales,
      },
      [StudentSubLibrarySelection.Favourites]: {
        shelfType: ShelfType.Favorites,
        studentProfileId: profile?.id,
        excludedResourceTypes: [BookType.HootReadingAssessment],
        excludedInstructionalFocusIds: [
          InstructionalFocus.FormativeAssessmentTextReading,
          InstructionalFocus.FormativeAssessmentEarlyWordReading,
          InstructionalFocus.FormativeAssessmentComplexWordReading,
          InstructionalFocus.FormativeAssessmentPreWordReading,
        ],
      },
      [StudentSubLibrarySelection.SportsAndGames]: {
        subLibrary: SubLibrary.SportsAndGames,
      },
    };
  }, [profile?.id, studentProfile.studentType]);

  const [libraryQuery, setLibraryQuery] = useState<QueryLibraryV2>({
    page: 1,
    pageSize: DEFAULT_LIBRARY_GRID_VIEW_PAGE_SIZE,
    shelfType: ShelfType.AllBooks,
    ...subLibraryFilters[subLibrarySelection],
  });

  const [searchResponse, setSearchResponse] = useState<GenericPaginatedResponse<BookSearch>>({
    ...emptySearchResponse,
  });
  const [showLibrarySearchModal, setShowLibrarySearchModal] = useState(false);

  const isAtEndOfLibrary = searchResponse.data.length >= searchResponse.count;

  const { refetch, isFetching, error } = useSearchStudentLibrary(libraryQuery, {
    retry: false,
    enabled: false,
    onSuccess: (data) => {
      if (data.page === 1) {
        libraryContentRef.current!.scrollTo({ top: 0, behavior: 'instant' });
      }
      setSearchResponse((prev) => {
        // If we're fetching any page after page 1, then just append the data.
        // Side note: This isn't necessary, but we're converting the full list of books into a map and then back to a
        // list again so that we can keep the list of books unique when the UI is re-rendered while debugging locally.
        const books = libraryQuery.page === 1 ? [...data.data] : [...prev.data, ...data.data];
        const booksDictionary = new Map(books.map((x) => [x.id, x]));

        return {
          ...data,
          data: Array.from(booksDictionary.values()),
        };
      });
    },
    onError: (err) => {
      console.error(err);
      dispatch(createFlashMessage({ variant: 'error', message: 'Whoops! It looks like an error occurred.' }));
    },
  });

  // When the sub-library query param has changed, then update the query. Updating the query will resubmit the request.
  useEffect(() => {
    setLibraryQuery({
      page: 1, // Any time the URL query params change, then start over on page 1. Previous set of books was for a different set of queries.
      pageSize: DEFAULT_LIBRARY_GRID_VIEW_PAGE_SIZE,
      shelfType: ShelfType.AllBooks, // This may get overridden depending on the sub-library selection.
      ...(subLibraryFilters[subLibrarySelection] ?? subLibraryFilters[StudentSubLibrarySelection.AllBooks]),
      ...(bookTitleSearchQuery && { title: bookTitleSearchQuery }),
      genreId: definedCustomFilters.genreId ?? undefined,
      formatId: definedCustomFilters.formatId ?? undefined,
      themeIds: definedCustomFilters.themeIds ?? undefined,
      topicIds: definedCustomFilters.topicIds ?? undefined,
      grade: definedCustomFilters.grade ?? undefined,
      bookCollectionIds: definedCustomFilters.bookCollectionId ? [definedCustomFilters.bookCollectionId] : undefined,
      seriesId: definedCustomFilters.seriesId ?? undefined,
      instructionalFocusIds: definedCustomFilters.instructionalFocusId ? [definedCustomFilters.instructionalFocusId] : undefined,
      instructionalUnitIds: definedCustomFilters.instructionalUnitId ? [definedCustomFilters.instructionalUnitId] : undefined,
      filterLevelPrioritizedSkillIds: definedCustomFilters.prioritizedSkillIds ?? undefined,
    });
  }, [searchParams, definedCustomFilters, subLibraryFilters, subLibrarySelection, bookTitleSearchQuery]);

  // Fetch library books whenever the query is changed.
  useEffect(() => {
    refetch();
  }, [libraryQuery, refetch]);

  const handleSubLibraryClick = (subLibrary: StudentSubLibrarySelection) => {
    setSearchParams({ [QueryKeyEnum.SubLibrary]: subLibrary }, { replace: true });
  };

  const onPageUp = () => {
    if (libraryContentRef.current) {
      const scrollOffset = libraryContentRef.current.scrollTop - libraryContentRef.current.offsetHeight * scrollHeightRatio;
      libraryContentRef.current.scrollTo({ top: scrollOffset, behavior: 'smooth' });
    }
  };

  const onPageDown = () => {
    if (libraryContentRef.current) {
      const scrollOffset = libraryContentRef.current.scrollTop + libraryContentRef.current.offsetHeight * scrollHeightRatio;
      libraryContentRef.current.scrollTo({ top: scrollOffset, behavior: 'smooth' });
    }
  };

  const onShowLibrarySearchModal = () => {
    setShowLibrarySearchModal(true);
  };

  const onDismissLibrarySearchModal = () => {
    setShowLibrarySearchModal(false);
  };

  const onApplyFilters: StudentLibraryLeftPaneProps['onApplyFilters'] = (filters, sublibrary) => {
    const filterQueryParams = mapStudentLibraryFiltersToUrlQueryParams(filters);

    const newQueryParams = removeEmptyObjectValues({
      ...filterQueryParams,
      [QueryKeyEnum.SubLibrary]: sublibrary,
      [QueryKeyEnum.BookTitleSearch]: searchParams.get(QueryKeyEnum.BookTitleSearch),
    }) as Record<string, string | string[]>;

    setSearchParams(newQueryParams, { replace: true });
  };

  const onApplyDecodableAccessFilters: StudentLibraryLeftPaneProps['onApplyDecodableAccessFilters'] = (filters, sublibrary) => {
    const filterQueryParams = mapDecodableLibraryFiltersToUrlQueryParams(filters);

    const newQueryParams = removeEmptyObjectValues({
      ...filterQueryParams,
      [QueryKeyEnum.SubLibrary]: sublibrary,
      [QueryKeyEnum.BookTitleSearch]: searchParams.get(QueryKeyEnum.BookTitleSearch),
    }) as Record<string, string | string[]>;

    setSearchParams(newQueryParams, { replace: true });
  };

  const fetchNextPage = () => {
    if (!isFetching && !isAtEndOfLibrary) {
      setLibraryQuery((prev) => ({ ...prev, page: prev.page + 1 }));
    }
  };

  const onSearchBooks: StudentLibrarySearchModalProps['onSearchBooks'] = (searchTerm, sublibrary) => {
    setSearchParams(
      {
        // All existing URL queries.
        ...Object.fromEntries(Array.from(searchParams.entries())),
        // And the new book title + sublibrary.
        [QueryKeyEnum.BookTitleSearch]: searchTerm,
        [QueryKeyEnum.SubLibrary]: sublibrary,
      },
      { replace: true },
    );
    setShowLibrarySearchModal(false);
  };

  const onClearSearch = () => {
    const searchParamsWithoutBookTitleSearch = Object.fromEntries(
      Array.from(searchParams.entries()).filter(([key]) => key !== QueryKeyEnum.BookTitleSearch),
    );
    setSearchParams(searchParamsWithoutBookTitleSearch, {
      replace: true,
    });
  };

  const onClearFilters = () => {
    const searchParamsWithoutFilters = Object.fromEntries(
      Array.from(searchParams.entries()).filter(([key]) => key === QueryKeyEnum.SubLibrary || key === QueryKeyEnum.BookTitleSearch),
    );
    setSearchParams(searchParamsWithoutFilters, {
      replace: true,
    });
  };

  const onNavigateToSeries = (seriesId: string) => {
    setSearchParams(
      {
        [QueryKeyEnum.Series]: seriesId,
      },
      {
        replace: true,
      },
    );
  };

  // If we're viewing the favs library, and the favs were altered, then we should refresh the library.
  const onInvalidateFavs = () => {
    if (subLibrarySelection === StudentSubLibrarySelection.Favourites) {
      refetch();
    }
  };

  const openBook = (bookDetails: BookResponse) => {
    navigate(
      {
        pathname: routesDictionary.library.book.url(bookDetails.id),
        search: bookDetails.studentBookmarkPageId
          ? createSearchParams({ [StudentLibraryReaderPageQueryParams.PageId]: bookDetails.studentBookmarkPageId }).toString()
          : undefined,
      },
      {
        state: {
          [StudentLibraryPageNavigationStateKey.LibraryPageUrlSearchQuery]: searchParams?.toString(),
        },
      },
    );
  };

  return (
    <Stack direction="row" height="100%" sx={{ overflowY: 'hidden' }}>
      <StudentLibraryLeftPane
        subLibrarySelection={subLibrarySelection}
        bookTitleSearch={bookTitleSearchQuery}
        handleSubLibraryClick={handleSubLibraryClick}
        onSearchButtonClicked={onShowLibrarySearchModal}
        onApplyFilters={onApplyFilters}
        onApplyDecodableAccessFilters={onApplyDecodableAccessFilters}
        appliedCustomFilters={definedCustomFilters}
        studentType={studentProfile.studentType}
      />
      <Box
        sx={{
          flex: 1,
          overflowY: 'auto',
        }}
        ref={libraryContentRef}
      >
        <StudentLibrary
          books={searchResponse.data}
          subLibrarySelection={subLibrarySelection}
          bookTitleSearch={bookTitleSearchQuery}
          onLastBookVisible={fetchNextPage}
          numPagesLoaded={searchResponse.page}
          isLoading={isFetching}
          isError={!!error}
          isAtEndOfLibrary={isAtEndOfLibrary}
          onClearSearch={onClearSearch}
          onClearFilters={onClearFilters}
          onRefreshLastRequest={() => refetch()}
          filtersApplied={Object.keys(definedCustomFilters).length > 0}
          searchApplied={!!bookTitleSearchQuery}
          onNavigateToSeries={onNavigateToSeries}
          onInvalidateFavs={onInvalidateFavs}
          openBook={openBook}
        />
      </Box>
      <StudentLibraryRightPane onPageUp={onPageUp} onPageDown={onPageDown} />
      <StudentLibrarySearchModal
        show={showLibrarySearchModal}
        onDismiss={onDismissLibrarySearchModal}
        onSearchBooks={onSearchBooks}
        defaultSearchTerm={bookTitleSearchQuery}
        defaultSublibrarySelection={subLibrarySelection}
        studentType={studentProfile.studentType}
      />
    </Stack>
  );
};

const mapStudentLibraryFiltersToUrlQueryParams = (filters: StudentLibraryFilters): Record<string, string | string[]> => {
  return removeEmptyObjectValues({
    [QueryKeyEnum.Genre]: filters.genreId ?? undefined,
    [QueryKeyEnum.Format]: filters.formatId ?? undefined,
    [QueryKeyEnum.Topics]: filters.topicIds,
    [QueryKeyEnum.Theme]: filters.themeIds,
    [QueryKeyEnum.Grade]: filters.grade ?? undefined,
    [QueryKeyEnum.Collection]: filters.bookCollectionId ?? undefined,
  });
};

const mapDecodableLibraryFiltersToUrlQueryParams = (filters: StudentDecodableLibraryFilters): Record<string, string | string[]> => {
  return removeEmptyObjectValues({
    [QueryKeyEnum.InstructionalFocus]: filters.instructionalFocusId ?? undefined,
    [QueryKeyEnum.InstructionalUnit]: filters.instructionalUnitId ?? undefined,
    [QueryKeyEnum.BookLevelPrioritizedSkills]: filters.prioritizedSkillIds ?? undefined,
  });
};

const mapUrlQueryParamsToFilters = (searchParams: URLSearchParams): Partial<StudentLibraryQueryFilters> => {
  return removeEmptyObjectValues({
    genreId: searchParams.get(QueryKeyEnum.Genre) ?? undefined,
    formatId: searchParams.get(QueryKeyEnum.Format) ?? undefined,
    topicIds: searchParams.has(QueryKeyEnum.Topics) ? searchParams.getAll(QueryKeyEnum.Topics) : undefined,
    themeIds: searchParams.has(QueryKeyEnum.Theme) ? searchParams.getAll(QueryKeyEnum.Theme) : undefined,
    grade: (searchParams.get(QueryKeyEnum.Grade) as Grade) ?? undefined,
    bookCollectionId: searchParams.get(QueryKeyEnum.Collection) ?? undefined,
    seriesId: searchParams.get(QueryKeyEnum.Series) ?? undefined,
    // // Decodable access filters
    instructionalFocusId: (searchParams.get(QueryKeyEnum.InstructionalFocus) as InstructionalFocus) ?? undefined,
    instructionalUnitId: searchParams.get(QueryKeyEnum.InstructionalUnit) ?? undefined,
    prioritizedSkillIds: searchParams.has(QueryKeyEnum.BookLevelPrioritizedSkills)
      ? searchParams.getAll(QueryKeyEnum.BookLevelPrioritizedSkills)
      : undefined,
  });
};

export default StudentLibraryPage;
