import { SocialMedia } from '@hoot-reading/hoot-core/dist/enums/user/social-media.enum';
import { HowDidYouHearAboutHoot } from '@hoot-reading/hoot-core/dist/enums/user/student/how-did-you-hear-about-hoot.enum';
import axios, { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { config } from '@hoot/config';

export interface FlfCreateProfileRequestDto {
  country: string;
  provinceState: string;
  timeZone: string;
  howDidYouHearAboutHoot?: HowDidYouHearAboutHoot;
  howDidYouHearAboutHootOther?: string;
  socialMedia?: SocialMedia;
  referralCode?: string;
}

export default function useFlfCreateProfileRequest() {
  return useMutation<void, AxiosError, FlfCreateProfileRequestDto>(async (request) => {
    return (await axios.post(`${config.apiUrl}/free-lesson/v2/create-profile`, request)).data;
  });
}
