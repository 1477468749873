import { Box, Stack, useTheme } from '@mui/material';
import { useEffect } from 'react';
import { isIOS } from 'react-device-detect';
import { Link } from 'react-router-dom';
import { routesDictionary } from '@hoot/routes/routesDictionary';
import PlainIconButton from '@hoot/ui/components/v2/core/PlainIconButton';
import CountdownToLesson from '@hoot/ui/components/v2/library/CountdownToLesson';
import { LottieFile } from '@hoot/ui/components/v2/lottie/Lottie';
import LottieButton from '@hoot/ui/components/v2/lottie/LottieButton';
import { useFullscreen } from '@hoot/ui/context/FullscreenContext';
import { useStudentJoinLessonCtx } from '@hoot/ui/context/StudentJoinLessonContext';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';

interface StudentLibraryRightPaneProps {
  onPageUp: () => void;
  onPageDown: () => void;
}

const StudentLibraryRightPane = (props: StudentLibraryRightPaneProps) => {
  const { onPageUp, onPageDown } = props;
  const { toggleFullscreen } = useFullscreen();
  const { showCountdownButton } = useStudentJoinLessonCtx();
  const theme = useTheme();

  const handleToggleFullscreen = async () => {
    await toggleFullscreen();
  };

  useEffect(() => {
    const keyPressHandler = ({ key }: KeyboardEvent) => {
      switch (key) {
        case 'ArrowUp':
          onPageUp();
          break;
        case 'ArrowDown':
          onPageDown();
          break;
        default:
          break;
      }
    };
    window.addEventListener('keydown', keyPressHandler);

    return () => {
      window.removeEventListener('keydown', keyPressHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onPageUp, onPageUp]);

  return (
    <Stack
      sx={{
        overflowY: 'auto',
        overflowX: 'hidden',
        height: '100%',
        width: '184px',
        padding: 5,
        background: hootTokens.palette.white,
        boxShadow: '-1px 0px 3px 0px rgba(0, 0, 0, 0.30), -4px 0px 8px 3px rgba(0, 0, 0, 0.15);',
      }}
    >
      {showCountdownButton && (
        <Box
          sx={{
            animation: showCountdownButton ? '1.25s countdownAnimation' : 'unset',
            marginBottom: showCountdownButton ? '40px' : 'unset',
            '@keyframes countdownAnimation': {
              '0%': { opacity: 0, transform: 'scale(.4)', marginBottom: theme.spacing(1) },
              '50%': { opacity: 0.5, transform: 'scale(1.4)' },
              '100%': { display: 'block', opacity: 1, transform: 'scale(1)', marginBottom: theme.spacing(5) },
            },
          }}
        >
          <CountdownToLesson />
        </Box>
      )}

      <Stack direction="row" gap={2} sx={{ marginBottom: '40px' }} justifyContent="space-around">
        <Link to={routesDictionary.selectProfile.url} data-hoot-canary-id="changeProfileLink">
          <PlainIconButton icon="account_filled" tooltipLabel="Change profile" />
        </Link>
        {!isIOS && <PlainIconButton icon="enter_full_screen_filled" tooltipLabel="Toggle fullscreen" onClick={handleToggleFullscreen} />}
      </Stack>

      <Stack sx={{ height: '100%' }} justifyContent="space-between" gap={2}>
        <LottieButton
          lottieFile={LottieFile.ArrowUp}
          onClick={onPageUp}
          tooltipLabel="Page up"
          aria-label="Page up"
          sx={{ width: 104, height: 104 }}
        />
        <LottieButton
          lottieFile={LottieFile.ArrowDown}
          onClick={onPageDown}
          tooltipLabel="Page down"
          aria-label="Page down"
          sx={{ width: 104, height: 104 }}
        />
      </Stack>
    </Stack>
  );
};

export default StudentLibraryRightPane;
