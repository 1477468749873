import { Box, CircularProgress, Fade, LinearProgress, Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { BookResponse } from '@hoot/events/interfaces/book-response';
import { BookSearch } from '@hoot/events/interfaces/book-search';
import useIntersectionObserver from '@hoot/hooks/useIntersectionObserver';
import GrowList from '@hoot/ui/components/v2/GrowList';
import { Button } from '@hoot/ui/components/v2/core/Button';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';
import PageLayout from '@hoot/ui/components/v2/core/PageLayout';
import Lottie, { LottieFile } from '@hoot/ui/components/v2/lottie/Lottie';
import LottieButton from '@hoot/ui/components/v2/lottie/LottieButton';
import StudentBookPreviewModal from '@hoot/ui/pages/v2/student/library/StudentBookPreviewModal';
import StudentLibraryBook from '@hoot/ui/pages/v2/student/library/StudentLibraryBook';
import { StudentSubLibrarySelection } from '@hoot/ui/pages/v2/student/library/StudentLibraryPage';

interface StudentLibraryProps {
  books: BookSearch[];
  subLibrarySelection: StudentSubLibrarySelection | undefined;
  numPagesLoaded: number;
  isLoading: boolean;
  isError: boolean;
  isAtEndOfLibrary: boolean;
  bookTitleSearch: string | undefined;
  filtersApplied: boolean;
  searchApplied: boolean;
  onLastBookVisible: () => void;
  onClearSearch: () => void;
  onClearFilters: () => void;
  onRefreshLastRequest: () => void;
  onNavigateToSeries: (seriesId: string) => void;
  onInvalidateFavs: () => void;
  openBook: (book: BookResponse) => void;
}

const StudentLibrary = (props: StudentLibraryProps) => {
  const {
    books,
    subLibrarySelection,
    onLastBookVisible,
    isLoading,
    isError,
    numPagesLoaded,
    isAtEndOfLibrary,
    bookTitleSearch,
    filtersApplied,
    searchApplied,
    onClearSearch,
    onClearFilters,
    onRefreshLastRequest,
    onNavigateToSeries,
    onInvalidateFavs,
    openBook,
  } = props;

  const { isIntersecting: isElementVisible, observerRef } = useIntersectionObserver();

  const [showBookPreviewModal, setShowBookPreviewModal] = useState(false);
  const [bookPreview, setBookPreview] = useState<BookSearch>();

  useEffect(() => {
    if (isElementVisible) {
      onLastBookVisible();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isElementVisible]);

  const onBookClicked = (book: BookSearch) => {
    setBookPreview(book);
    setShowBookPreviewModal(true);
  };

  const onDismissBookPreviewModal = (shouldInvalidateFavs: boolean) => {
    setShowBookPreviewModal(false);
    if (shouldInvalidateFavs) {
      onInvalidateFavs();
    }
  };

  const onBookPreviewModalDismissed = () => setBookPreview(undefined);

  const NoFavourites = () => (
    <Stack
      sx={{
        position: 'absolute',
        top: '50%',
        right: 0,
        bottom: '50%',
        left: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 1,
      }}
    >
      <Lottie style={{ width: '230px', height: '230px' }} loop play lottieFile={LottieFile.HeartBalloon} />
      <HootTypography isPII={false} variant="titlelarge">
        No Favorites Yet? Let's Discover Some!
      </HootTypography>
      <HootTypography isPII={false} variant="bodysmall">
        Your favorites will appear here
      </HootTypography>
      <HootTypography isPII={false} variant="bodysmall">
        Explore and tap the ❤️ on any book you love to add it here!
      </HootTypography>
    </Stack>
  );

  const EmptyState = (props: { label: string; showRefreshButton?: boolean }) => (
    <Stack
      sx={{
        position: 'absolute',
        top: '50%',
        right: 0,
        bottom: '50%',
        left: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 2,
      }}
    >
      <Lottie style={{ width: '232px', height: '232px' }} loop={false} play lottieFile={LottieFile.ThinkingFace} />
      <HootTypography isPII={false} variant="tableheading">
        {props.label}
      </HootTypography>
      {props.showRefreshButton && (
        <Button variant="contained" onClick={onRefreshLastRequest}>
          Refresh
        </Button>
      )}
    </Stack>
  );

  const NextPageLoadingIndicator = () => (
    <Box
      sx={{
        width: '216px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        minHeight: '216px',
      }}
    >
      <CircularProgress disableShrink />
    </Box>
  );

  const FilterHeader = () => (
    <>
      {(bookTitleSearch || filtersApplied) && (
        <Stack pb={4}>
          {bookTitleSearch && (
            <Stack direction="row" justifyContent="center" alignItems="center">
              <HootTypography isPII={false} variant="titlemedium" noWrap>
                Search results for "{bookTitleSearch}"
              </HootTypography>
              <LottieButton
                variant="transparent"
                tooltipLabel="Clear search"
                aria-label="Clear search"
                lottieFile={LottieFile.ErrorCross}
                sx={{ p: 0, width: '44px', height: '44px' }}
                onClick={onClearSearch}
              />
            </Stack>
          )}
          {filtersApplied && (
            <Stack direction="row" justifyContent="center" alignItems="center">
              <HootTypography isPII={false} variant="titlemedium">
                Filtering books
              </HootTypography>
              <LottieButton
                variant="transparent"
                tooltipLabel="Clear search"
                aria-label="Clear search"
                lottieFile={LottieFile.ErrorCross}
                sx={{ p: 0, width: '44px', height: '44px' }}
                onClick={onClearFilters}
              />
            </Stack>
          )}
        </Stack>
      )}
    </>
  );

  return (
    <PageLayout RootBoxProps={{ position: 'relative', height: '100%' }} sx={{ height: '100%' }}>
      <Fade in={isLoading} style={{ position: 'absolute', top: 0, left: 0, right: 0 }}>
        <LinearProgress />
      </Fade>
      <FilterHeader />
      {isError ? (
        <EmptyState label="Oops. Sorry, an error occurred." showRefreshButton />
      ) : !isLoading && subLibrarySelection === StudentSubLibrarySelection.Favourites && !filtersApplied && !searchApplied && books.length === 0 ? (
        // If no search/filters were applied, and the student has zero favourited books, then show a different empty state.
        <NoFavourites />
      ) : !isLoading && books.length === 0 ? (
        <EmptyState label="Couldn't find any results." />
      ) : (
        <GrowList<BookSearch>
          StackProps={{
            direction: 'row',
            sx: {
              display: 'flex',
              flexDirection: 'row',
              gap: 2,
              flexWrap: 'wrap',
            },
          }}
          itemRenderTimeoutMillis={100}
          // Kinda taking the easy way out here. We're only cascading the first page of books loaded.
          // After the first page, every item just animates in at the same time.
          cascadeAnimate={numPagesLoaded <= 1}
          items={books}
          getKey={(book) => book.id}
          observerRef={observerRef}
          renderItem={(book) => <StudentLibraryBook book={book} onBookClicked={onBookClicked} />}
          // Just always render a loading indicator at the bottom of the list until we've reached the end of the library.
          // Whenever the bottom of the list is visible, we start fetching the next page of books.
          renderEnsuingItems={!isAtEndOfLibrary ? <NextPageLoadingIndicator /> : null}
        />
      )}
      <StudentBookPreviewModal
        show={showBookPreviewModal}
        book={bookPreview}
        onNavigateToSeries={onNavigateToSeries}
        onDismiss={onDismissBookPreviewModal}
        onDismissed={onBookPreviewModalDismissed}
        openBook={openBook}
      />
    </PageLayout>
  );
};

export default StudentLibrary;
