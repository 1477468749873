import { StudentStatus } from '@hoot-reading/hoot-core/dist/enums/user/student/student-status.enum';
import { Stack } from '@mui/material';
import { useState } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { LocationReportingFilters } from '@hoot/hooks/api/district-rep/reporting/useGetLocationReportingMetrics';
import useGetLocationStudentGradesAndStatuses from '@hoot/hooks/api/district-rep/reporting/useGetLocationStudentGradesAndStatuses';
import useDistrictRepGetSchoolSnippet from '@hoot/hooks/api/district-rep/useDistrictRepresentativeGetSchoolSnippet';
import useDistrictRepLocationDetails from '@hoot/hooks/useDistrictRepLocationDetails';
import { DateFilterEnum } from '@hoot/models/api/district-rep-reporting/common';
import { routesDictionary } from '@hoot/routes/routesDictionary';
import Card from '@hoot/ui/components/v2/core/Card';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';
import Page from '@hoot/ui/components/v2/core/Page';
import { AttendanceCharts } from '@hoot/ui/pages/v2/district-rep/reporting/AttendanceCharts';
import SchoolMetrics from './SchoolMetrics';
import SchoolReportingFilters from './SchoolReportingFilters';
import StudentMetricsTable from './StudentMetricsTable';

const defaultFilters: LocationReportingFilters = {
  date: [DateFilterEnum.ThisSchoolYear],
  studentStatus: [StudentStatus.Active],
  studentGrade: [],
  attendance: [],
  lateJoin: [],
};

const SchoolReportingTab = () => {
  const { schoolId } = useParams() as { schoolId: string };
  const navigate = useNavigate();

  const [filtersRequest, setFiltersRequest] = useState<LocationReportingFilters>(defaultFilters);

  const { data: schoolSnippet } = useDistrictRepGetSchoolSnippet(schoolId ?? '', {
    enabled: !!schoolId,
    retry: false,
    onError: (err) => {
      console.error(err);
      // If we don't have permission to view this school, then redirect out of this page.
      if (err.response?.status === 403) {
        navigate(routesDictionary.schools.url, { replace: true });
      }
    },
  });

  const { districtRepLocationDetails } = useDistrictRepLocationDetails();
  const { data } = useGetLocationStudentGradesAndStatuses(schoolId ?? '', { enabled: !!schoolId });

  const pageTitle = schoolSnippet?.name ? `${schoolSnippet.name} Reporting | Hoot Reading` : 'School Reporting | Hoot Reading';

  const locationHasReporting = !!districtRepLocationDetails?.reporting;
  const showReportingTab = locationHasReporting;
  const studentGrades = data?.grades ?? [];
  const studentStatuses = data?.statuses ?? [];

  if (!showReportingTab) {
    return <Navigate to="/" />;
  }

  return (
    <Page pageTitle={pageTitle}>
      <Card>
        <HootTypography variant="displaysmall" mb={2} isPII={false}>
          Reporting
        </HootTypography>

        <Stack gap={5}>
          <SchoolReportingFilters
            filters={filtersRequest}
            setFilters={setFiltersRequest}
            studentGrades={studentGrades}
            studentStatuses={studentStatuses}
          />
          <SchoolMetrics schoolId={schoolId} filters={filtersRequest} />
          <AttendanceCharts locationId={schoolId} filters={filtersRequest} />
          <StudentMetricsTable schoolId={schoolId} filters={filtersRequest} />
          <HootTypography variant="tableheadingactive" isPII={false}>
            These reports are for informational purposes only and is not to be referenced for invoicing.
          </HootTypography>
        </Stack>
      </Card>
    </Page>
  );
};

export default SchoolReportingTab;
