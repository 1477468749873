import { Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Skeleton, Stack } from '@mui/material';
import fileDownloader from 'js-file-download';
import { DateTime } from 'luxon';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { LocationReportingFilters } from '@hoot/hooks/api/district-rep/reporting/useGetLocationReportingMetrics';
import useGetStudentReportingMetrics, {
  StudentReportingMetricSortKey,
  StudentReportingRequest,
} from '@hoot/hooks/api/district-rep/reporting/useGetStudentReportingMetrics';
import useGetStudentReportingMetricsCsvReport from '@hoot/hooks/api/district-rep/reporting/useGetStudentReportingMetricsCsvReport';
import useGetDistrictRepPermissions from '@hoot/hooks/api/user/useGetDistrictRepPermissions';
import { OrderBy } from '@hoot/models/api/enums/queryEnums';
import { DEFAULT_PAGE_SIZE } from '@hoot/models/api/pagination';
import { createFlashMessage } from '@hoot/redux/reducers/flashMessageSlice';
import { RootState, useAppDispatch } from '@hoot/redux/store';
import { routesDictionary } from '@hoot/routes/routesDictionary';
import { DistrictRepAccessLevelCombinations, useHasDistrictPermission } from '@hoot/routes/secure/profile/district-rep/DistrictRepProtectedRoute';
import ProtectedLink from '@hoot/ui/components/v2/ProtectedLink';
import ViewState, { ViewStateEnum } from '@hoot/ui/components/v2/ViewState';
import { Button } from '@hoot/ui/components/v2/core/Button';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';
import { Icon } from '@hoot/ui/components/v2/core/Icon';
import IconButton from '@hoot/ui/components/v2/core/IconButton';
import { HeaderData, TableV2 } from '@hoot/ui/components/v2/core/Table';
import ArrowIcon from '@hoot/ui/components/v2/icons/ArrowIcon';
import { RotateDirection } from '@hoot/ui/components/v2/utils/icon-utils';

const defaultQuery: StudentReportingRequest = {
  page: 1,
  pageSize: DEFAULT_PAGE_SIZE,
  sortBy: StudentReportingMetricSortKey.StudentName,
  orderBy: OrderBy.Asc,
  includeSchool: true,
};

interface TableRow {
  studentName: React.ReactNode;
  studentNumber: number;
  averageAttendanceRate: string;
  lateJoinRate: string;
  minutesInLesson: string;
  schoolName: React.ReactNode;
  firstLesson: string;
  mostRecentLesson: string;
}

const headers: HeaderData<TableRow>[] = [
  { name: 'Student Name', property: 'studentName', isSortable: true },
  { name: 'Student Number', property: 'studentNumber', isSortable: true },
  { name: 'Attendance', property: 'averageAttendanceRate', isSortable: true },
  { name: 'Late Join', property: 'lateJoinRate', isSortable: true },
  { name: 'Minutes in Lesson', property: 'minutesInLesson', isSortable: true },
  { name: 'School', property: 'schoolName', isSortable: true },
  { name: 'First Lesson', property: 'firstLesson', isSortable: true },
  { name: 'Most Recent Lesson', property: 'mostRecentLesson', isSortable: true },
];

interface StudentAggregateTableProps {
  locationId: string;
  filters: LocationReportingFilters;
}

const StudentAggregateTable = (props: StudentAggregateTableProps) => {
  const { locationId, filters } = props;

  const dispatch = useAppDispatch();
  const [isDownloadDialogOpen, setIsDownloadDialogOpen] = useState<boolean>(false);
  const districtRepresentativeId = useSelector((state: RootState) => state.profile.profile?.id);
  const { data: districtRepPermissions } = useGetDistrictRepPermissions(districtRepresentativeId || '');
  const hasEnrolmentViewLevelOrHigherPermission = useHasDistrictPermission(
    DistrictRepAccessLevelCombinations.EnrolmentViewLevel,
    districtRepPermissions,
  );
  const hasSchoolOrDistrictPermission = useHasDistrictPermission(DistrictRepAccessLevelCombinations.SchoolOrDistrict, districtRepPermissions);

  const [paginationQuery, setPaginationQuery] = useState<StudentReportingRequest>(defaultQuery);

  const { data, isLoading, isFetching, isError } = useGetStudentReportingMetrics(locationId, paginationQuery, filters, {
    enabled: !!locationId,
  });

  const formattedTableData = data?.data.map(
    (x): TableRow => ({
      studentName: (
        <ProtectedLink to={routesDictionary.students.student.details.url(x.studentProfileId)} hasPermission={hasEnrolmentViewLevelOrHigherPermission}>
          {x.studentName}
        </ProtectedLink>
      ),
      studentNumber: x.studentNumber,
      averageAttendanceRate: `${x.averageAttendanceRate}%`,
      lateJoinRate: `${x.lateJoinRate}%`,
      minutesInLesson: x.minutesInLesson.toLocaleString(),
      schoolName:
        !!x.schoolId && !!x.schoolName ? (
          <ProtectedLink to={routesDictionary.schools.school.students.url(x.schoolId)} hasPermission={hasSchoolOrDistrictPermission}>
            {x.schoolName}
          </ProtectedLink>
        ) : (
          <span>-</span>
        ),
      firstLesson: x.firstLesson,
      mostRecentLesson: x.mostRecentLesson,
    }),
  );

  const viewState = useMemo<ViewStateEnum>(() => {
    if (!locationId || isFetching || isLoading) {
      return ViewStateEnum.Loading;
    } else if (isError) {
      return ViewStateEnum.Error;
    } else if (!data || data.data.length === 0) {
      return ViewStateEnum.EmptyState;
    } else {
      return ViewStateEnum.Results;
    }
  }, [locationId, isFetching, isLoading, isError, data]);

  // Side effect for resetting the Page back to 1 when filters are applied.
  useEffect(() => {
    setPaginationQuery((prev) => ({ ...prev, page: 1 }));
  }, [filters]);

  const studentMetricsCsvReport = useGetStudentReportingMetricsCsvReport(locationId, paginationQuery, filters, {
    enabled: false,
    onSuccess: (res) => {
      const fileName = `Hoot_Reading_Program_Summary_${DateTime.now().toFormat('LLL-dd-yyyy')}.csv`;
      fileDownloader(res.data, fileName);
    },
    onError: () => {
      dispatch(createFlashMessage({ variant: 'error', message: 'There was an error while downloading the CSV' }));
    },
  });

  const handleDownloadCsv = () => {
    setIsDownloadDialogOpen(false);
    studentMetricsCsvReport.refetch();
  };

  return (
    <>
      <Stack gap={2}>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <HootTypography variant="titlelarge" isPII={false}>
            Students
          </HootTypography>

          <Button
            variant="contained"
            size="medium"
            startIcon={<ArrowIcon rotate={RotateDirection.Down} htmlColor="#FFF" />}
            onClick={() => setIsDownloadDialogOpen(true)}
          >
            Download CSV
          </Button>
        </Stack>
        <ViewState
          state={viewState}
          loadingContent={<SkeletonTableItems />}
          EmptyStateIllustrationProps={{ title: 'No Results', subtitle: 'No students matching the provided filters found', showBorder: true }}
        >
          <TableV2
            data={formattedTableData ?? []}
            headers={headers}
            count={data?.count ?? 0}
            page={data?.page ? data.page - 1 : 0}
            allowRowsPerPage
            isPaginated
            isSortable
            sortOrder={paginationQuery.orderBy}
            sortBy={paginationQuery.sortBy}
            rowsPerPage={paginationQuery.pageSize}
            rowsPerPageOptions={[10, 25, 100]}
            onSortBy={(sortKey) => {
              setPaginationQuery((prev) => ({
                ...prev,
                page: 1,
                sortBy: sortKey as StudentReportingMetricSortKey,
                orderBy: prev.orderBy === OrderBy.Asc ? OrderBy.Desc : OrderBy.Asc,
              }));
            }}
            onPageChange={(_e, page) => {
              setPaginationQuery((prev) => ({ ...prev, page: page + 1 }));
            }}
            onRowsPerPageChange={(e) => {
              setPaginationQuery((prev) => ({ ...prev, page: 1, pageSize: +e.target.value }));
            }}
          />
        </ViewState>
      </Stack>
      <DownloadCsvPiiWarningDialog isOpen={isDownloadDialogOpen} onClose={() => setIsDownloadDialogOpen(false)} onDownload={handleDownloadCsv} />
    </>
  );
};

const SkeletonTableItems = () => (
  <Stack direction="column" gap={3} sx={{ width: '100%' }}>
    <Box display={'flex'} flexDirection={'column'} gap={1}>
      <Skeleton variant="rounded" sx={{ minWidth: '200px', width: '100%', minHeight: '60px' }} />
      <Skeleton variant="rounded" sx={{ minWidth: '200px', width: '100%', minHeight: '260px' }} />
      <Skeleton variant="rounded" sx={{ minWidth: '200px', width: '100%', minHeight: '75px' }} />
    </Box>
  </Stack>
);

const DownloadCsvPiiWarningDialog = (props: { isOpen: boolean; onDownload: () => void; onClose: () => void }) => {
  const { isOpen, onDownload, onClose } = props;

  return (
    <Dialog open={isOpen}>
      <DialogTitle
        id="dialog-title"
        data-hoot-canary-id="forgot-password-title"
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
      >
        <HootTypography isPII={false} variant="titlelarge">
          Confirm Download
        </HootTypography>
        <IconButton onClick={onClose}>
          <Icon name="close" />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <HootTypography isPII={false} variant="bodylarge">
            You are about to export data containing student personal information, are you sure you want to proceed?
          </HootTypography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="contained" onClick={onDownload}>
          Download
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default StudentAggregateTable;
