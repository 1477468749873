import { Box, Stack, Tooltip } from '@mui/material';
import { ReactNode } from 'react';
import { Button, ButtonProps } from '@hoot/ui/components/v2/core/Button';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';
import { maxPageWidthPx } from '@hoot/ui/components/v2/core/PageLayout';
import { ColorPaletteOption } from '@hoot/ui/theme/v2/palette';
import { getColors } from '@hoot/ui/theme/v2/util';

export interface LargeBannerProps {
  title: string;
  message: string;
  backgroundColor: ColorPaletteOption;
  primaryAction: {
    label: string;
    onClick: () => void;
    props?: ButtonProps;
    toolTip?: string | ReactNode;
  };
}

const LargeBanner = (props: LargeBannerProps) => {
  const { title, message, backgroundColor, primaryAction } = props;
  const colors = getColors(backgroundColor);

  return (
    <Box padding={3} sx={{ backgroundColor: colors.base }}>
      <Box sx={{ maxWidth: maxPageWidthPx, margin: 'auto' }}>
        <Stack direction="column">
          <Stack direction="column" alignItems="start" gap={3} sx={{ width: '100%' }}>
            <HootTypography isPII={false} variant="titlemedium" color={colors.font}>
              {title}
            </HootTypography>
            <HootTypography isPII={false} variant="bodylarge" color={colors.font}>
              {message}
            </HootTypography>
            <Stack>
              <Tooltip title={primaryAction.toolTip}>
                <div>
                  <Button
                    variant="contained"
                    size="medium"
                    onClick={primaryAction.onClick}
                    {...(primaryAction.props ?? {})}
                    color={primaryAction.props?.color}
                  >
                    {primaryAction.label}
                  </Button>
                </div>
              </Tooltip>
            </Stack>
          </Stack>
        </Stack>
      </Box>
    </Box>
  );
};

export default LargeBanner;
