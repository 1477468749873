import { Box, Stack, useMediaQuery, useTheme } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { NativeSelectInputProps } from '@mui/material/NativeSelect/NativeSelectInput';
import React, { useEffect } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { routesDictionary } from '@hoot/routes/routesDictionary';
import AsyncImage from '@hoot/ui/components/v2/AsyncImage';
import SubHeader from '@hoot/ui/components/v2/SubHeader';
import Card from '@hoot/ui/components/v2/core/Card';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';
import Page from '@hoot/ui/components/v2/core/Page';
import { Select } from '@hoot/ui/components/v2/core/Select';
import { ScopeAndSequenceLink } from '@hoot/ui/pages/v2/teacher/scope-and-sequence/focus-area/ScopeAndSequenceLink';
import { FocusAreaSlug, scopeAndSequenceFocusAreaDictionary } from '@hoot/ui/pages/v2/teacher/scope-and-sequence/scopeAndSequenceFocusAreas';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';

const ScopeAndSequencePage = () => {
  const { focusAreaId } = useParams<{ focusAreaId: FocusAreaSlug }>();

  const navigate = useNavigate();

  useEffect(() => {
    if (!Object.values(FocusAreaSlug).includes(focusAreaId!)) {
      navigate(routesDictionary.scopeAndSequence.focusArea.url(FocusAreaSlug.preWordReading), { replace: true });
    }
  }, [focusAreaId, navigate]);

  return (
    <>
      <SubHeader
        title={{
          label: (
            <Stack sx={{ flexDirection: 'row', alignItems: 'center', gap: 2 }}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '50px',
                  height: '50px',
                  borderRadius: '8px',
                  border: '1px solid',
                  borderColor: hootTokens.surface[4],
                  background: hootTokens.palette.white,
                  boxShadow: hootTokens.elevation.elevation2,
                }}
              >
                <img src="/images/hoot-shapes.svg" alt="scope-and-sequence-logo" height="33px" width="33px" />
              </Box>
              Hoot Scope & Sequence
            </Stack>
          ),
          isPII: false,
        }}
      />

      <Page pageTitle="Scope & Sequence | Hoot Reading">
        <Grid container spacing={2}>
          <Grid size={{ md: 4, xs: 12 }}>
            <FocusAreaSelectorCard selectedFocusAreaSlug={focusAreaId!} />
          </Grid>
          <Grid size={{ md: 8, xs: 12 }}>
            {/* Render selected focus area card here. */}
            <Outlet />
          </Grid>
        </Grid>
      </Page>
    </>
  );
};

interface FocusAreaSelectorCardProps {
  selectedFocusAreaSlug: FocusAreaSlug;
}

const FocusAreaSelectorCard = (props: FocusAreaSelectorCardProps) => {
  const { selectedFocusAreaSlug } = props;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const navigate = useNavigate();
  const { studentProfileId } = useParams<{ studentProfileId?: string }>();

  const onFocusAreaSelectChange: NativeSelectInputProps['onChange'] = (event) => {
    navigate(routesDictionary.scopeAndSequence.focusArea.url(event.target.value), { replace: true });
  };

  return (
    <Card title="Select a Focus Area">
      {isMobile && (
        <Select label="Drop Down Options" value={selectedFocusAreaSlug} onChange={onFocusAreaSelectChange} sx={{ width: '100%' }}>
          {Object.values(scopeAndSequenceFocusAreaDictionary).map((fa) => (
            <option key={fa.slug} value={fa.slug}>
              {fa.title}
            </option>
          ))}
        </Select>
      )}
      {!isMobile && (
        <>
          {Object.values(scopeAndSequenceFocusAreaDictionary).map((fa) => (
            <Stack
              key={fa.slug}
              direction="row"
              alignItems="center"
              gap={2}
              p={1}
              sx={{
                borderRadius: '8px',
                ...(selectedFocusAreaSlug === fa.slug && {
                  background: hootTokens.palette.success['160'],
                  boxShadow: `0 0 0 2px ${hootTokens.palette.success['100']}`,
                  transition: 'all 150ms ease',
                }),
              }}
            >
              <AsyncImage
                src={fa.tileImageUrl}
                alt={fa.tileImageAlt}
                style={{
                  width: '100px',
                  height: '100px',
                  borderRadius: '8px',
                  objectFit: 'cover',
                  ...(selectedFocusAreaSlug !== fa.slug && {
                    filter: 'grayscale(100%)',
                  }),
                }}
              />
              <ScopeAndSequenceLink to={routesDictionary.scopeAndSequence.focusArea.url(fa.slug)} studentProfileId={studentProfileId} replace>
                <HootTypography variant="labellarge" isPII={false}>
                  {fa.title}
                </HootTypography>
              </ScopeAndSequenceLink>
            </Stack>
          ))}
        </>
      )}
    </Card>
  );
};

export default ScopeAndSequencePage;
