import { Checkbox, DialogActions, DialogContent, FormControlLabel, Radio, RadioGroup, Stack } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { DateTime } from 'luxon';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import useGetFormativeAssessmentBookDetails from '@hoot/hooks/api/lesson-reviews/useGetFormativeAssessmentBookDetails';
import useGetLessonFormativeAssessmentBookIds from '@hoot/hooks/api/library/useGetLessonFormativeAssessmentBookIds';
import { FormativeAssessmentType, formativeAssessmentLetters } from '@hoot/models/api/enums/lesson-review-enums';
import CheckList, { BaseCheckListItem, CheckListItem, CheckListProps } from '@hoot/ui/components/v2/CheckList';
import { Button } from '@hoot/ui/components/v2/core/Button';
import Card, { CardProps } from '@hoot/ui/components/v2/core/Card';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';
import { Icon } from '@hoot/ui/components/v2/core/Icon';
import IconButton from '@hoot/ui/components/v2/core/IconButton';
import TextField from '@hoot/ui/components/v2/core/TextField';
import TimePicker from '@hoot/ui/components/v2/core/TimePicker';
import BasicAlertDialog from '@hoot/ui/components/v2/dialogs/BasicAlertDialog';
import {
  LessonReviewFormativeAssessmentBook,
  PerformanceMetrics,
  TextReadingAssessmentType,
  useLessonReviewWizardContext,
} from '@hoot/ui/pages/v2/teacher/my-students/student-details/lesson-reviews/lesson-review-wizard/LessonReviewContextProvider';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';
import { BookPickerModalProps } from '../book-picker/BookPickerModal';
import FormativeAssessmentBookPicker from '../book-picker/FormativeAssessmentBookPicker';

const MAX_SELECTED_FORMATIVE_ASSESSMENT_BOOKS = 3;

const LessonReviewFormativeAssessmentStep = () => {
  const {
    lessonToReview,
    formativeAssessmentState,
    actions: { goToNextStep, goToPreviousStep, attachFormativeAssessmentData, toggleWizardStep },
  } = useLessonReviewWizardContext();

  const [useFormativeAssessment, setUseFormativeAssessment] = useState(!!formativeAssessmentState?.formativeAssessmentBooks.length);
  const [enableAddBookButton, setEnableAddBookButton] = useState(false);
  const [showNoLessonHistoryBooks, setShowNoLessonHistoryBooks] = useState(false);
  const [showAddBookModal, setShowAddBookModal] = useState(false);
  const [selectedFormativeAssessmentBooks, setSelectedFormativeAssessmentBooks] = useState<LessonReviewFormativeAssessmentBook[]>(
    formativeAssessmentState?.formativeAssessmentBooks ?? [],
  );

  const [bookToRemove, setBookToRemove] = useState<LessonReviewFormativeAssessmentBook>();
  const [selectedBookId, setSelectedBookId] = useState<string>('');

  // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
  const { data: lessonHistoryBookIds } = useGetLessonFormativeAssessmentBookIds(lessonToReview?.lessonId!, {
    retry: false,
    enabled: !!lessonToReview?.lessonId,
    onError: (ex) => {
      console.error(ex);
    },
    onSuccess: (data: string[]) => {
      if (data.length === 0) {
        setShowNoLessonHistoryBooks(true);
      } else {
        setEnableAddBookButton(true);
      }
    },
  });

  const { isLoading } = useGetFormativeAssessmentBookDetails(selectedBookId, {
    retry: false,
    enabled: !!selectedBookId,
    onError: (ex) => {
      console.error(ex);
    },
    onSuccess: (data) => {
      setSelectedFormativeAssessmentBooks((selectedFormativeAssessmentBooks) => [
        ...selectedFormativeAssessmentBooks,
        {
          ...data.formativeAssessmentBook,
          formativeAssessmentType: [FormativeAssessmentType.LettersAndSounds, FormativeAssessmentType.WordReading].includes(
            data.formativeAssessmentBook.formativeAssessmentType,
          )
            ? [data.formativeAssessmentBook.formativeAssessmentType]
            : [],
          textReadingData: {
            comprehensionQuestions: undefined,
            performanceMetrics: { wordCount: data.formativeAssessmentBook.wordCount ?? undefined, errors: undefined, runningTime: undefined },
          },
        },
      ]);
      onDismissAddBookModal();
    },
  });

  // You can go to the next step if:
  // - You've selected "no" (no formative assessment books used), or
  // - You've selected "yes" (used word formative assessment books):
  //      - At least one book as been selected.
  //      - If a text reading book is selected AND a formative assessment type is selected (fluency and/or comprehension)
  //           - If fluency is selected, wordCount, errors, and runningTime have values
  const textReadingBooks = selectedFormativeAssessmentBooks.filter((book) =>
    book.formativeAssessmentType.every((type) => ![FormativeAssessmentType.WordReading, FormativeAssessmentType.LettersAndSounds].includes(type)),
  );
  const wordReadingBooks = selectedFormativeAssessmentBooks.filter((book) =>
    [book.formativeAssessmentType].includes([FormativeAssessmentType.WordReading]),
  );
  const lettersAndSoundsReadingBooks = selectedFormativeAssessmentBooks.filter((book) =>
    [book.formativeAssessmentType].includes([FormativeAssessmentType.LettersAndSounds]),
  );

  useEffect(() => {
    toggleWizardStep(
      2,
      textReadingBooks.some((book) => book.formativeAssessmentType?.includes(FormativeAssessmentType.Comprehension)),
    );
  }, [textReadingBooks, toggleWizardStep]);

  function checkGoToNextStep(useFormativeAssessment: boolean, selectedFormativeAssessmentBooks: LessonReviewFormativeAssessmentBook[]): boolean {
    if (!useFormativeAssessment) {
      return true;
    }

    if (selectedFormativeAssessmentBooks.length === 0) {
      return false;
    }

    if ((wordReadingBooks.length > 0 || lettersAndSoundsReadingBooks.length > 0) && !textReadingBooks.length) {
      return true;
    }

    const textReadingbooksHaveAllData = textReadingBooks.every((book) => {
      const requiresFluency = book.formativeAssessmentType?.includes(FormativeAssessmentType.Fluency);
      const requiresComprehension = book.formativeAssessmentType?.includes(FormativeAssessmentType.Comprehension);
      const performanceMetrics = book.textReadingData?.performanceMetrics;

      if (!requiresComprehension && !requiresFluency) {
        return false;
      }

      if (!requiresFluency) {
        return true;
      }
      const isPerformanceComplete =
        !requiresFluency || (performanceMetrics !== undefined && Object.values(performanceMetrics).every((value) => value !== undefined));
      return isPerformanceComplete;
    });
    return textReadingbooksHaveAllData;
  }

  const canGoToNextStep = checkGoToNextStep(useFormativeAssessment, selectedFormativeAssessmentBooks);

  const onUseFormativeAssessmentChanged = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.value === 'no') {
      setUseFormativeAssessment(false);
      setSelectedBookId('');
      setSelectedFormativeAssessmentBooks([]);
    } else {
      setUseFormativeAssessment(true);
    }
  };

  const onShowAddBookModal = () => {
    setShowAddBookModal(true);
  };

  const onWordsReadCorrectlyChanged = (bookId: string, wordsReadCorrectly: Set<string>) => {
    setSelectedFormativeAssessmentBooks((prevBooks) => prevBooks.map((book) => (book.bookId === bookId ? { ...book, wordsReadCorrectly } : book)));
  };

  const onPerformanceMetricsChanged = (bookId: string, performanceMetrics: PerformanceMetrics) => {
    setSelectedFormativeAssessmentBooks((prevBooks) =>
      prevBooks.map((book) =>
        book.bookId === bookId
          ? {
              ...book,
              textReadingData: {
                performanceMetrics,
                comprehensionQuestions: book.textReadingData?.comprehensionQuestions ?? undefined,
              },
            }
          : book,
      ),
    );
  };

  const onLettersReadCorrectlyChanged = (bookId: string, lettersReadCorrectly: Set<string>) => {
    setSelectedFormativeAssessmentBooks((prevBooks) => prevBooks.map((book) => (book.bookId === bookId ? { ...book, lettersReadCorrectly } : book)));
  };

  const onRemoveFormativeAssessmentBook = (removedBookId: string) => {
    setBookToRemove(undefined);
    setSelectedFormativeAssessmentBooks((selectedFormativeAssessmentBooks) => [
      ...selectedFormativeAssessmentBooks.filter((book) => book.bookId !== removedBookId),
    ]);
  };

  const onAddBook: BookPickerModalProps['onApply'] = (book) => {
    setSelectedBookId(book.id);
  };

  const onDismissAddBookModal = () => {
    setShowAddBookModal(false);
  };

  const submitAndGoToNextStep = () => {
    attachFormativeAssessmentData(selectedFormativeAssessmentBooks);
    goToNextStep();
  };

  return (
    <>
      {showAddBookModal ? (
        <FormativeAssessmentBookPicker
          show={showAddBookModal}
          onApply={onAddBook}
          onDismiss={onDismissAddBookModal}
          isLoading={isLoading}
          existingBookIds={selectedFormativeAssessmentBooks.map((book) => book.bookId)}
          lessonHistoryBookIds={lessonHistoryBookIds}
          isFormativeAssessment
        />
      ) : null}
      <BasicAlertDialog
        show={!!bookToRemove}
        onDismiss={() => setBookToRemove(undefined)}
        title="Remove Book?"
        content={
          <HootTypography isPII={false} variant="bodylarge">
            Are you sure you want to remove <strong>{bookToRemove?.title}</strong>?
          </HootTypography>
        }
        primaryAction={{
          label: 'Remove',
          onClick: () => onRemoveFormativeAssessmentBook(bookToRemove!.bookId),
          props: {
            variant: 'contained',
            color: 'error',
          },
        }}
        secondaryAction={{
          label: 'Cancel',
          onClick: () => setBookToRemove(undefined),
        }}
      />
      <DialogContent>
        <Stack gap={2} paddingBottom={1}>
          <HootTypography isPII={false} variant="titlemedium">
            Formative Assessment Resources
          </HootTypography>
          <Stack gap={1}>
            <HootTypography isPII={false} variant="bodymedium">
              Was a Formative Assessment (Quick Checks or Text Reading FA) completed in the lesson?
            </HootTypography>
            <RadioGroup value={useFormativeAssessment ? 'yes' : 'no'} onChange={onUseFormativeAssessmentChanged} sx={{ px: 1 }}>
              <Stack direction="row" gap={2}>
                <FormControlLabel value="no" control={<Radio />} label="No" />
                <FormControlLabel value="yes" control={<Radio />} label="Yes" />
              </Stack>
            </RadioGroup>
          </Stack>
          <Stack gap={2} flex={1} minHeight="327px">
            {showNoLessonHistoryBooks && (
              <HootTypography isPII={false} variant="bodymedium">
                No Formative Assessment books were used during the lesson.
              </HootTypography>
            )}
            {enableAddBookButton && useFormativeAssessment && (
              <Stack gap={2}>
                <HootTypography isPII={false} variant="titlemedium">
                  Add the Formative Assessment book(s) you used during this lesson.
                </HootTypography>
                {selectedFormativeAssessmentBooks.map((book) => (
                  <>
                    {book.formativeAssessmentType.every(
                      (type) => ![FormativeAssessmentType.WordReading, FormativeAssessmentType.LettersAndSounds].includes(type),
                    ) && (
                      <TextReadingFormativeAssessmentBookCard
                        sx={{ flex: 1, background: hootTokens.surface[1] }}
                        formativeAssessmentBook={book}
                        setBookToRemove={setBookToRemove}
                        onPerformanceMetricsChanged={onPerformanceMetricsChanged}
                        setFormativeAssessmentBooks={setSelectedFormativeAssessmentBooks}
                      />
                    )}
                    {book.formativeAssessmentType.includes(FormativeAssessmentType.WordReading) && (
                      <WordReadingFormativeAssessmentBookCard
                        sx={{ flex: 1, background: hootTokens.surface[1] }}
                        formativeAssessmentBook={book}
                        wordsReadCorrectly={book.wordsReadCorrectly ?? new Set()}
                        onWordsReadCorrectlyChanged={onWordsReadCorrectlyChanged}
                        setBookToRemove={setBookToRemove}
                      />
                    )}
                    {book.formativeAssessmentType.includes(FormativeAssessmentType.LettersAndSounds) && (
                      <LettersAndSoundsFormativeAssessmentBookCard
                        sx={{ flex: 1, background: hootTokens.surface[1] }}
                        formativeAssessmentBook={book}
                        lettersReadCorrectly={book.lettersReadCorrectly ?? new Set()}
                        onLettersReadCorrectlyChanged={onLettersReadCorrectlyChanged}
                        setBookToRemove={setBookToRemove}
                      />
                    )}
                  </>
                ))}
                <Button
                  variant="contained"
                  size="medium"
                  onClick={onShowAddBookModal}
                  disabled={selectedFormativeAssessmentBooks.length >= MAX_SELECTED_FORMATIVE_ASSESSMENT_BOOKS}
                  color="primary.0"
                  sx={{ width: '150px' }}
                >
                  + Add Book
                </Button>
              </Stack>
            )}
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={goToPreviousStep}>
          Go Back
        </Button>
        <Button variant="contained" onClick={submitAndGoToNextStep} disabled={!canGoToNextStep}>
          Next
        </Button>
      </DialogActions>
    </>
  );
};

interface WordReadingFormativeAssessmentBookCardProps {
  formativeAssessmentBook: LessonReviewFormativeAssessmentBook;
  wordsReadCorrectly: Set<string>;
  onWordsReadCorrectlyChanged: (bookId: string, wordsReadCorrectly: Set<string>) => void;
  setBookToRemove: React.Dispatch<React.SetStateAction<LessonReviewFormativeAssessmentBook | undefined>>;
  sx?: CardProps['sx'];
}

const WordReadingFormativeAssessmentBookCard = (props: WordReadingFormativeAssessmentBookCardProps) => {
  const { formativeAssessmentBook, wordsReadCorrectly, onWordsReadCorrectlyChanged, setBookToRemove, sx = {} } = props;
  const { title, coverImageUrl, formativeAssessmentWords, bookId } = formativeAssessmentBook;

  const formativeAssessmentWordCheckList = useMemo<BaseCheckListItem[]>(() => {
    return formativeAssessmentWords.map<BaseCheckListItem>((word) => {
      return {
        label: word,
        value: word,
      };
    });
  }, [formativeAssessmentWords]);

  const toggleWordReadCorrectly: CheckListProps['toggleOption'] = (checkListItem) => {
    const updatedWordsReadCorrectly = new Set(wordsReadCorrectly);
    if (updatedWordsReadCorrectly.has(checkListItem.value)) {
      updatedWordsReadCorrectly.delete(checkListItem.value);
    } else {
      updatedWordsReadCorrectly.add(checkListItem.value);
    }
    onWordsReadCorrectlyChanged(formativeAssessmentBook.bookId, updatedWordsReadCorrectly);
  };

  return (
    <Card sx={{ ...sx, overflowX: 'scroll' }}>
      <Stack direction="row" gap={3} alignItems="center">
        <Stack direction="column" gap={1}>
          <img
            alt={title}
            src={coverImageUrl}
            style={{
              width: '100%',
              maxWidth: '164px',
              objectFit: 'contain',
              border: '1px solid black',
              borderRadius: '8px',
            }}
          />
        </Stack>
        <Stack width="100%" gap={3}>
          <HootTypography isPII={false} variant="titlesmall">
            {title}
          </HootTypography>
          <CheckList
            title={'Indicate the words that were read correctly*'}
            items={[...formativeAssessmentWordCheckList]}
            selectedValues={Array.from(wordsReadCorrectly)}
            toggleOption={toggleWordReadCorrectly}
            listSx={{
              display: 'flex',
              flexDirection: 'row',
              maxWidth: '80%',
              justifyContent: 'space-evenly',
              margin: 0,
              padding: 0,
              marginTop: 2,
              gap: 0,
            }}
            titleVariant="bodymedium"
          />
        </Stack>
        <IconButton
          key={`delete-book-${bookId}`}
          sx={{ height: 'auto', alignSelf: 'stretch', width: '80px' }}
          color="error.80"
          variant="contained"
          onClick={() => setBookToRemove(formativeAssessmentBook)}
        >
          <Icon name="trash_bin" color="primary.200" />
        </IconButton>
      </Stack>
    </Card>
  );
};

interface TextReadingFormativeAssessmentBookCardProps {
  formativeAssessmentBook: LessonReviewFormativeAssessmentBook;
  setFormativeAssessmentBooks: React.Dispatch<React.SetStateAction<LessonReviewFormativeAssessmentBook[]>>;
  setBookToRemove: React.Dispatch<React.SetStateAction<LessonReviewFormativeAssessmentBook | undefined>>;
  onPerformanceMetricsChanged: (bookId: string, performanceMetrics: PerformanceMetrics) => void;
  assessmentType?: TextReadingAssessmentType[];
  setAssessmentType?: React.Dispatch<React.SetStateAction<TextReadingAssessmentType[]>>;
  sx?: CardProps['sx'];
}

const TextReadingFormativeAssessmentBookCard = (props: TextReadingFormativeAssessmentBookCardProps) => {
  const { formativeAssessmentBook, setFormativeAssessmentBooks, setBookToRemove, onPerformanceMetricsChanged, sx = {} } = props;
  const { title, coverImageUrl, bookId, wordCount, textReadingData } = formativeAssessmentBook;
  const performanceMetrics = textReadingData?.performanceMetrics;

  const [runningTimeError, setRunningTimeError] = useState<boolean>(false);

  const secondsToDate = (timeInSeconds: number): DateTime | null => {
    if (!timeInSeconds) return null;
    return DateTime.fromSeconds(timeInSeconds);
  };

  const handleChange = (newValue: DateTime | null) => {
    const minutes = newValue?.minute || 0;
    const seconds = newValue?.second || 0;
    const timeInSeconds = minutes * 60 + seconds;
    handleRunningTimeChange(timeInSeconds);
  };

  const handleWordCountChange = (wordCount: number) => {
    onPerformanceMetricsChanged(bookId, { ...performanceMetrics, errors: performanceMetrics?.errors, wordCount: wordCount });
  };

  const handleErrorChange = (newValue: string) => {
    const value = parseInt(newValue);
    onPerformanceMetricsChanged(bookId, { ...performanceMetrics, errors: isNaN(value) ? undefined : value });
  };

  const handleRunningTimeChange = (runningTime: number) => {
    setRunningTimeError(runningTime === 0);
    onPerformanceMetricsChanged(bookId, { ...performanceMetrics, runningTime: runningTime === 0 ? undefined : runningTime });
  };

  const onFormativeAssessmentTypeChanged = (type: FormativeAssessmentType) => {
    setFormativeAssessmentBooks((prevBooks) =>
      prevBooks.map((book) => {
        if (book.bookId !== formativeAssessmentBook.bookId) {
          return book;
        }

        const currentTypes = book.formativeAssessmentType ?? [];
        const isTypeCurrentlySelected = currentTypes.includes(type);

        const updatedTypes = isTypeCurrentlySelected ? currentTypes.filter((t) => t !== type) : [...currentTypes, type];

        return {
          ...book,
          formativeAssessmentType: updatedTypes.length > 0 ? updatedTypes : [],
          textReadingData: isTypeCurrentlySelected
            ? type === FormativeAssessmentType.Comprehension
              ? {
                  performanceMetrics: book.textReadingData?.performanceMetrics,
                  comprehensionQuestions: undefined,
                }
              : type === FormativeAssessmentType.Fluency
                ? {
                    performanceMetrics: book.textReadingData?.performanceMetrics
                      ? {
                          ...book.textReadingData.performanceMetrics,
                          errors: undefined,
                          runningTime: undefined,
                        }
                      : undefined,
                    comprehensionQuestions: book.textReadingData?.comprehensionQuestions,
                  }
                : undefined
            : (book.textReadingData ?? { performanceMetrics: undefined, comprehensionQuestions: undefined }),
        };
      }),
    );
  };

  const errorsMinusClick = () => {
    const newValue = (performanceMetrics?.errors ?? 1) - 1;
    if (newValue < 0) {
      return;
    }
    onPerformanceMetricsChanged(bookId, { ...performanceMetrics, errors: newValue });
  };

  const errorsPlusClick = () => {
    const newValue = (performanceMetrics?.errors ?? 0) + 1;
    if (performanceMetrics?.wordCount && newValue > performanceMetrics.wordCount) {
      return;
    }
    onPerformanceMetricsChanged(bookId, { ...performanceMetrics, errors: newValue });
  };

  return (
    <Card sx={{ ...sx, overflowX: 'scroll', background: hootTokens.surface[1] }}>
      <Stack direction="row" gap={3} width="100%" justifyContent="space-between">
        <Stack direction="column" gap={1}>
          <img
            alt={title}
            src={coverImageUrl}
            style={{
              width: '100%',
              maxWidth: '164px',
              objectFit: 'contain',
              border: '1px solid black',
              borderRadius: '8px',
            }}
          />
        </Stack>
        <Stack gap={2} justifyContent={'flex-start'}>
          <HootTypography isPII={false} variant="titlesmall">
            {title}
          </HootTypography>
          <HootTypography isPII={false} variant="bodymedium">
            What type of assessment(s) were given?
          </HootTypography>
          <Stack direction="row" gap={2}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formativeAssessmentBook.formativeAssessmentType?.includes(FormativeAssessmentType.Fluency)}
                  onChange={() => onFormativeAssessmentTypeChanged(FormativeAssessmentType.Fluency)}
                />
              }
              label={
                <HootTypography variant="labellarge" isPII={false}>
                  Fluency Assessment
                </HootTypography>
              }
              sx={{ gap: 1 }}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={formativeAssessmentBook.formativeAssessmentType?.includes(FormativeAssessmentType.Comprehension)}
                  onChange={() => onFormativeAssessmentTypeChanged(FormativeAssessmentType.Comprehension)}
                />
              }
              label={
                <HootTypography variant="labellarge" isPII={false}>
                  Comprehension Assessment
                </HootTypography>
              }
              sx={{ gap: 1 }}
            />
          </Stack>
          <Stack sx={{ width: '400px' }}>
            {formativeAssessmentBook.formativeAssessmentType?.includes(FormativeAssessmentType.Fluency) ? (
              <Stack gap={2} width="100%">
                <TextField
                  type="number"
                  label={'Word Count'}
                  required
                  value={performanceMetrics?.wordCount ?? wordCount}
                  onChange={(val) => handleWordCountChange(Number(val.target.value))}
                  slotProps={{
                    input: {
                      inputProps: { min: 0 },
                    },
                  }}
                  sx={{
                    maxWidth: '260px',
                    'input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
                      WebkitAppearance: 'none',
                      margin: 0,
                    },
                  }}
                />
                <Stack direction="row" alignItems={'center'}>
                  <TextField
                    label={'Errors'}
                    required
                    type="number"
                    value={performanceMetrics?.errors ?? ''}
                    onChange={(val) => handleErrorChange(val.target.value)}
                    slotProps={{
                      input: {
                        inputProps: { min: 0, max: performanceMetrics?.wordCount },
                      },
                    }}
                    sx={{
                      maxWidth: '260PX',
                      'input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
                        WebkitAppearance: 'none',
                        margin: 0,
                      },
                    }}
                  />
                  <IconButton>
                    <Icon name="collapse_filled" color={'primary.0'} sx={{ width: '46px', height: '46px' }} onClick={() => errorsMinusClick()} />
                  </IconButton>
                  <IconButton>
                    <Icon name="solid_add_rectangle" color="primary.0" sx={{ width: '46px', height: '46px' }} onClick={() => errorsPlusClick()} />
                  </IconButton>
                </Stack>

                <TimePicker
                  label="Running Time"
                  onChange={handleChange}
                  value={secondsToDate(performanceMetrics?.runningTime ?? 0)}
                  ampm={false}
                  views={['minutes', 'seconds']}
                  format="mm:ss"
                  helperText="Enter in format MM:SS"
                  error={runningTimeError}
                  sx={{
                    maxWidth: '260PX',
                  }}
                />
              </Stack>
            ) : null}
          </Stack>
        </Stack>
        <IconButton
          key={`delete-book-${bookId}`}
          sx={{ height: 'auto', alignSelf: 'stretch', width: '80px', marginLeft: 'auto' }}
          color="error.80"
          variant="contained"
          onClick={() => setBookToRemove(formativeAssessmentBook)}
        >
          <Icon name="trash_bin" color="primary.200" />
        </IconButton>
      </Stack>
    </Card>
  );
};

interface LettersAndSoundsFormativeAssessmentBookCardProps {
  formativeAssessmentBook: LessonReviewFormativeAssessmentBook;
  lettersReadCorrectly: Set<string>;
  onLettersReadCorrectlyChanged: (bookId: string, wordsReadCorrectly: Set<string>) => void;
  setBookToRemove: React.Dispatch<React.SetStateAction<LessonReviewFormativeAssessmentBook | undefined>>;
  sx?: CardProps['sx'];
}

const LettersAndSoundsFormativeAssessmentBookCard = (props: LettersAndSoundsFormativeAssessmentBookCardProps) => {
  const { formativeAssessmentBook, lettersReadCorrectly, onLettersReadCorrectlyChanged, setBookToRemove, sx = {} } = props;
  const { title, coverImageUrl, bookId } = formativeAssessmentBook;

  const formativeAssessmentWordCheckList = useMemo<BaseCheckListItem[]>(() => {
    return formativeAssessmentLetters.map<BaseCheckListItem>((letter) => {
      return {
        label: letter,
        value: letter,
      };
    });
  }, []);

  const toggleLetterReadCorrectly: CheckListProps['toggleOption'] = (checkListItem) => {
    const updatedWordsReadCorrectly = new Set(lettersReadCorrectly);
    if (updatedWordsReadCorrectly.has(checkListItem.value)) {
      updatedWordsReadCorrectly.delete(checkListItem.value);
    } else {
      updatedWordsReadCorrectly.add(checkListItem.value);
    }
    onLettersReadCorrectlyChanged(formativeAssessmentBook.bookId, updatedWordsReadCorrectly);
  };

  const displayItems: CheckListItem[] = formativeAssessmentWordCheckList.map((item) => ({
    label: item.label,
    value: item.value,
    isChecked: Array.from(lettersReadCorrectly).includes(item.value),
    isDisabled: false,
  }));

  return (
    <Card sx={{ ...sx }}>
      <Stack direction="row" gap={3} alignItems="center" width="100%">
        <Stack direction="column" gap={1}>
          <img
            alt={title}
            src={coverImageUrl}
            style={{
              width: '100%',
              maxWidth: '164px',
              objectFit: 'contain',
              border: '1px solid black',
              borderRadius: '8px',
            }}
          />
        </Stack>
        <Stack width="100%" gap={3}>
          <HootTypography isPII={false} variant="titlesmall">
            {title}
          </HootTypography>
          <HootTypography isPII={false} variant="bodymedium">
            Indicate the letters that were read correctly *
          </HootTypography>
          <Grid container rowSpacing={2}>
            {displayItems.map((letter, idx) => (
              <Grid key={`${letter.value}-${idx}`} size={{ xs: 4, md: 2, sm: 3 }} onClick={() => toggleLetterReadCorrectly(letter)}>
                <Stack
                  direction="row"
                  gap={1}
                  sx={{
                    '&:hover': {
                      cursor: 'pointer',
                      background: hootTokens.surface[2],
                    },
                  }}
                >
                  <Checkbox checked={letter.isChecked} />
                  <HootTypography isPII={false} variant="labellarge">
                    {letter.label}
                  </HootTypography>
                </Stack>
              </Grid>
            ))}
          </Grid>
        </Stack>
        <IconButton
          key={`delete-book-${bookId}`}
          sx={{ height: 'auto', alignSelf: 'stretch', width: '80px' }}
          color="error.80"
          variant="contained"
          onClick={() => setBookToRemove(formativeAssessmentBook)}
        >
          <Icon name="trash_bin" color="primary.200" />
        </IconButton>
      </Stack>
    </Card>
  );
};

export default LessonReviewFormativeAssessmentStep;
