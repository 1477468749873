import axios from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '@hoot/config';
import { QueryKey } from '@hoot/hooks/api/queryKeys';
import { LessonReviewFocus, LessonReviewFocusUnit } from '@hoot/models/api/enums/lesson-review-enums';
import { BookType } from '@hoot/models/api/library';

export interface InstructionalFocusUnitResponse {
  instructionalFocusId: string;
  instructionalFocusName: LessonReviewFocus;
  instructionalUnitId: string;
  instructionalUnitName: LessonReviewFocusUnit;
}

export interface PublicInstructionalFocusesWithUnitsResponse {
  instructionalFocusUnits: InstructionalFocusUnitResponse[];
}

async function getInstructionalFocusWithUnits(resourceType: BookType): Promise<PublicInstructionalFocusesWithUnitsResponse> {
  const { data } = await axios.get<PublicInstructionalFocusesWithUnitsResponse>(`${config.apiUrl}/library/instructional-focuses-with-units`, {
    params: { resourceType },
  });
  return data;
}

export default function useGetInstructionalFocusWithUnits(
  resourceType: BookType,
  options?: Omit<UseQueryOptions<PublicInstructionalFocusesWithUnitsResponse>, 'queryKey' | 'queryFn'>,
) {
  return useQuery([QueryKey.GetInstructionalFocusWithUnits, resourceType], () => getInstructionalFocusWithUnits(resourceType), options);
}
