import { Box, Dialog, IconButton, Stack } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { ChangeEvent, useState } from 'react';
import useGetLessonWhiteboardTemplates, {
  LessonWhiteboardTemplateResponse,
  LessonWhiteboardTemplateStatus,
} from '@hoot/hooks/api/whiteboard/useGetLessonWhiteboardTemplates';
import { WhiteboardTemplateTypeEnum } from '@hoot/models/api/enums/whiteboard-template-type-enum';
import { useActiveLessonState } from '@hoot/redux/reducers/activeLessonSlice';
import { Button } from '@hoot/ui/components/v2/core/Button';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';
import ReadOnlyTextField from '@hoot/ui/components/v2/core/ReadOnlyTextField';
import { Select } from '@hoot/ui/components/v2/core/Select';
import CloseIcon from '@hoot/ui/components/v2/icons/CloseIcon';
import PlayIcon from '@hoot/ui/components/v2/icons/PlayIcon';
import { wbTemplateStatusLabelDictionary, wbTemplateTypeLabelDictionary } from './utils';

interface StartWhiteboardDialogProps {
  show: boolean;
  onOpenWhiteboard: (whiteboardTemplateType: WhiteboardTemplateTypeEnum) => void;
  onDismiss: () => void;
}

const StartWhiteboardDialog = (props: StartWhiteboardDialogProps) => {
  const { show, onOpenWhiteboard, onDismiss } = props;

  const { inLesson } = useActiveLessonState();
  const lessonId = inLesson?.lessonId;

  const { data } = useGetLessonWhiteboardTemplates(lessonId ?? '', undefined, {
    enabled: !!lessonId,
  });

  const [selectValue, setSelectValue] = useState<WhiteboardTemplateTypeEnum>();
  const [currentTemplate, setCurrentTemplate] = useState<LessonWhiteboardTemplateResponse | null>(null);

  const onSelectChange = (e: ChangeEvent<HTMLSelectElement>) => {
    if (!e.target.value) return;

    setSelectValue(e.target.value as WhiteboardTemplateTypeEnum);
    findCurrentTemplate(e.target.value as WhiteboardTemplateTypeEnum);
  };

  const findCurrentTemplate = (templateType: WhiteboardTemplateTypeEnum) => {
    const findTemplate = data?.find((d) => d.whiteboardTemplate.templateType === templateType);

    if (!findTemplate) return;

    setCurrentTemplate(findTemplate);
  };

  const onStartResumeClick = () => {
    if (!selectValue) {
      console.error('Can not open whiteboard. No whiteboard template selected!');
      return;
    }
    onOpenWhiteboard(selectValue);
  };

  return (
    <Dialog
      open={show}
      onClose={() => onDismiss()}
      fullWidth
      maxWidth="sm"
      PaperProps={{
        sx: {
          p: 2,
          gap: 3,
        },
      }}
    >
      <>
        {/* Header */}
        <Stack sx={{ flexDirection: 'row', alignItems: 'center' }}>
          <div>
            <HootTypography variant="titlelarge" isPII={false}>
              Start a Whiteboard
            </HootTypography>
            <HootTypography variant="bodysmall" isPII={false}>
              Select a Whiteboard type
            </HootTypography>
          </div>
          <IconButton onClick={onDismiss} sx={{ marginLeft: 'auto' }}>
            <CloseIcon />
          </IconButton>
        </Stack>

        {/* Select */}
        <div>
          <HootTypography variant="titlesmall" mb={2} isPII={false}>
            Whiteboard Type
          </HootTypography>
          <Select label="Select Whiteboard Type" value={selectValue} defaultValue="" onChange={onSelectChange} sx={{ width: '100%' }}>
            <option value="" disabled>
              Select
            </option>
            {Object.values(WhiteboardTemplateTypeEnum).map((l) => (
              <option key={l} value={l}>
                {wbTemplateTypeLabelDictionary[l]}
              </option>
            ))}
          </Select>
        </div>

        {/* Template Details */}
        <Grid container spacing={4}>
          <Grid size={6}>
            <HootTypography variant="titlesmall" mb={2} isPII={false}>
              Word Chain Details
            </HootTypography>
            <Stack gap={2}>
              {currentTemplate && (
                <ReadOnlyTextField
                  label="Template State"
                  body={currentTemplate ? wbTemplateStatusLabelDictionary[currentTemplate.status] : ''}
                  filledColour={currentTemplate?.status === LessonWhiteboardTemplateStatus.InProgress ? 'success.190' : 'neutral.190'}
                  invertLabelStyles
                />
              )}
              {!!currentTemplate?.whiteboardTemplate.instructionalFocuses.length && (
                <ReadOnlyTextField
                  label="Instructional Focuses"
                  body={currentTemplate?.whiteboardTemplate.instructionalFocuses.map((u) => u.name).join(', ')}
                  filledColour="neutral.190"
                  invertLabelStyles
                />
              )}
              {!!currentTemplate?.whiteboardTemplate.instructionalUnits.length && (
                <ReadOnlyTextField
                  label="Instructional Units"
                  body={currentTemplate?.whiteboardTemplate.instructionalUnits.map((u) => u.name).join(', ')}
                  filledColour="neutral.190"
                  invertLabelStyles
                />
              )}
            </Stack>
          </Grid>
          <Grid size={6}>
            <HootTypography variant="titlesmall" mb={2} isPII={false}>
              Preview
            </HootTypography>
            {currentTemplate && (
              <Box
                component="img"
                src={`${currentTemplate?.whiteboardTemplate.imageUrl}`}
                sx={{ width: '100%', border: '1px solid black', borderRadius: '8px' }}
              />
            )}
          </Grid>
        </Grid>

        <Box display="flex" marginLeft="auto">
          <Button onClick={onStartResumeClick} variant="contained" disabled={!selectValue} startIcon={<PlayIcon htmlColor="#FFF" />}>
            {currentTemplate?.status === LessonWhiteboardTemplateStatus.InProgress ? 'Resume Whiteboard' : 'Start Whiteboard'}
          </Button>
        </Box>
      </>
    </Dialog>
  );
};

export default StartWhiteboardDialog;
