import {
  Box,
  TableCell as MuiTableCell,
  TableRow as MuiTableRow,
  Stack,
  Typography,
  keyframes,
  styled,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import _ from 'lodash';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { error } from '@hoot/redux/reducers/alertSlice';
import { AppDispatch, useAppDispatch } from '@hoot/redux/store';
import { TIME_FORMAT, TWENTY_FOUR_HOUR_TIME_FORMAT } from '@hoot/utils/date';
import { hootTokens } from '../../../theme/v2/tokens';
import ViewStateIllustration, { IllustrationEnum } from '../ViewStateIllustration';
import { Icon } from '../core/Icon';
import IconButton from '../core/IconButton';

/** CONSTANTS */

export const TIME_INTERVAL_IN_MINUTES = 30;
const COL_SPAN = 8;

export const calendarYAxisTimes = [
  '6:00 AM',
  '6:30 AM',
  '7:00 AM',
  '7:30 AM',
  '8:00 AM',
  '8:30 AM',
  '9:00 AM',
  '9:30 AM',
  '10:00 AM',
  '10:30 AM',
  '11:00 AM',
  '11:30 AM',
  '12:00 PM',
  '12:30 PM',
  '1:00 PM',
  '1:30 PM',
  '2:00 PM',
  '2:30 PM',
  '3:00 PM',
  '3:30 PM',
  '4:00 PM',
  '4:30 PM',
  '5:00 PM',
  '5:30 PM',
  '6:00 PM',
  '6:30 PM',
  '7:00 PM',
  '7:30 PM',
  '8:00 PM',
  '8:30 PM',
  '9:00 PM',
  '9:30 PM',
  '10:00 PM',
  '10:30 PM',
  '11:00 PM',
  '11:30 PM',
];

const HEADER_HEIGHT = 44;
const HEADER_WIDTH = 100;
export const WEEKLY_SCHEDULED_CELL_HEIGHT = 64;

export interface Events {
  startsAt: number;
  isCancelled: boolean;
}

/** COMPONENTS */

export function WeeklyScheduleTable(props: {
  startOfWeek: DateTime;
  onStartOfWeekChange: (startOfWeek: DateTime) => void;
  onTimeSlotClick?: (dayOfWeek: DateTime) => void;
  timeSlotDetails: (dayOfWeek: DateTime) => React.ReactNode;
  header?: React.ReactNode;
  isLoading?: boolean;
  footerFunction?: React.ReactNode;
  showAllTimeSlots?: boolean;
  showCancelledEvents?: boolean;
  events?: Events[];
}) {
  const daysOfWeek = [0, 1, 2, 3, 4, 5, 6].map((d) => props.startOfWeek.plus({ day: d }));
  const dispatch = useAppDispatch();
  const [noEvents, setNoEvents] = useState<boolean>(false);
  const eventsForWeek = filterEventsForWeek(props.startOfWeek, props.showCancelledEvents, props.events);
  // const noEvents = eventsForWeek.length === 0;
  const truncatedCalendarYAxisTimes = truncateCalendarYAxisTimes(eventsForWeek, dispatch, props.showAllTimeSlots);

  useEffect(() => {
    if (!props.isLoading) {
      setNoEvents(eventsForWeek.length === 0);
    }
  }, [eventsForWeek.length, props.isLoading]);

  const weeklySchedule = truncatedCalendarYAxisTimes.map((time) => {
    const { minute, hour } = DateTime.fromFormat(time, TIME_FORMAT).toObject();

    return [DateTime.now(), ...daysOfWeek].map((day) => {
      return day.set({ hour, minute });
    });
  });

  const onPrevClick = () => {
    setNoEvents(false);
    props.onStartOfWeekChange(props.startOfWeek.minus({ week: 1 }));
  };

  const onNextClick = () => {
    setNoEvents(false);
    props.onStartOfWeekChange(props.startOfWeek.plus({ week: 1 }));
  };

  const title = `${props.startOfWeek.toFormat('LLL d')} - ${props.startOfWeek.plus({ day: 6 }).toFormat('d')}`;

  return (
    <Stack position={'relative'}>
      {props.isLoading ? <LoadingAnimation /> : null}
      <WeeklySchedule sx={{ marginTop: '32px', position: 'relative' }}>
        <WeeklyScheduleHeader
          sx={{
            position: 'sticky',
            backgroundColor: hootTokens.palette.white,
            top: 0,
          }}
        >
          <WeeklyScheduleRow>
            <WeeklyScheduleHeaderCell colSpan={COL_SPAN}>
              <Header title={title} daysOfWeek={daysOfWeek} onPrevClick={onPrevClick} onNextClick={onNextClick} header={props.header} />
            </WeeklyScheduleHeaderCell>
          </WeeklyScheduleRow>
        </WeeklyScheduleHeader>
        <WeeklyScheduleBody>
          {noEvents ? (
            <MuiTableRow>
              <MuiTableCell colSpan={COL_SPAN}>
                <Stack direction="row" justifyContent="center">
                  <ViewStateIllustration illustration={IllustrationEnum.NoResults} />
                </Stack>
              </MuiTableCell>
            </MuiTableRow>
          ) : (
            <>
              {weeklySchedule.map((weekRow, idx) => (
                <WeeklyScheduleRow key={idx}>
                  {weekRow.map((timeSlot, idx) => (
                    <WeeklyScheduleCell key={`${timeSlot.toMillis()}-${idx}`}>
                      <Box sx={{ display: 'flex', justifyContent: 'center', cursor: idx !== 0 && !!props.onTimeSlotClick ? 'pointer' : 'default' }}>
                        {idx === 0 ? (
                          <Typography variant="bodysmall">{timeSlot.toFormat('h:mm a')}</Typography>
                        ) : (
                          <Stack spacing={'12px'} onClick={() => props.onTimeSlotClick?.(timeSlot)}>
                            {props.timeSlotDetails(timeSlot)}
                          </Stack>
                        )}
                      </Box>
                    </WeeklyScheduleCell>
                  ))}
                </WeeklyScheduleRow>
              ))}
            </>
          )}
        </WeeklyScheduleBody>
      </WeeklySchedule>
      {props.footerFunction}
    </Stack>
  );
}

/*
 * This function handles the logic on truncating the time slotting and trimming
 * all empty slots.
 */
function truncateCalendarYAxisTimes(eventsInWeek: Events[], dispatch: AppDispatch, showAllTimeSlots?: boolean) {
  let truncatedCalendarYAxisTimes = calendarYAxisTimes;

  if (showAllTimeSlots !== undefined && !showAllTimeSlots && eventsInWeek.length > 0) {
    const eventsToTruncate = eventsInWeek;

    const eventsTime = eventsToTruncate?.map((e) => DateTime.fromMillis(Number(e.startsAt)).toFormat(TWENTY_FOUR_HOUR_TIME_FORMAT));

    // Note: need to convert to 24-time format to use the lodash min and max functions
    const truncatedCalendarYAxisTimesIn24HourFormat = truncatedCalendarYAxisTimes.map((time) =>
      DateTime.fromFormat(time, TIME_FORMAT).toFormat(TWENTY_FOUR_HOUR_TIME_FORMAT),
    );

    const minTime = _.min(eventsTime);
    const maxTime = _.max(eventsTime);

    const minIndex = minTime ? truncatedCalendarYAxisTimesIn24HourFormat.findIndex((time) => time === minTime) : 0;
    const maxIndex = maxTime
      ? truncatedCalendarYAxisTimesIn24HourFormat.findIndex((time) => time === maxTime)
      : truncatedCalendarYAxisTimesIn24HourFormat.length - 1;
    const minIndexSterilized = minIndex === -1 ? 0 : minIndex;
    const maxIndexSterilized = maxIndex === -1 ? calendarYAxisTimes.length - 1 : maxIndex + 1;

    if (minIndexSterilized >= maxIndexSterilized) {
      dispatch(error(`There was an error with truncating the weekly view y-axis schedule!`));
      console.error('Error: minIndexSterilized >= maxIndexSterilized');
      return truncatedCalendarYAxisTimes;
    }

    return (truncatedCalendarYAxisTimes = truncatedCalendarYAxisTimes.slice(minIndexSterilized, maxIndexSterilized));
  }
  return truncatedCalendarYAxisTimes;
}

function filterEventsForWeek(startOfWeek: DateTime, showCancelledEvents?: boolean, events?: Events[]): Events[] {
  const endOfWeekInMillis = startOfWeek.endOf('week').toMillis();
  const startOfWeekInMillis = startOfWeek.toMillis();
  let eventsInWeek = events?.filter((e) => e.startsAt >= startOfWeekInMillis && e.startsAt < endOfWeekInMillis);

  if (!showCancelledEvents) {
    eventsInWeek = eventsInWeek?.filter((e) => !e.isCancelled);
  }
  return eventsInWeek ?? [];
}

function Header(props: { title: string; onPrevClick: () => void; onNextClick: () => void; daysOfWeek: DateTime[]; header?: React.ReactNode }) {
  return <DesktopHeader {...props} />;
}

function DesktopHeader(props: { title: string; onPrevClick: () => void; onNextClick: () => void; daysOfWeek: DateTime[]; header?: React.ReactNode }) {
  return (
    <Stack
      sx={{
        position: 'sticky',
        top: 0,
        backgroundColor: hootTokens.palette.white,
        boxShadow: '0 4px 8px -4px rgba(0, 0, 0, 0.15), 0 4px 3px -2px rgba(0, 0, 0, 0.30)',
      }}
    >
      <Stack justifyContent="center" direction="row">
        <Box flex={1} />
        <Stack direction="row" justifyContent="space-between" alignItems="center" flex={1}>
          <IconButton
            variant="standard"
            sx={{ backgroundColor: hootTokens.surface[2], boxShadow: hootTokens.elevation.elevation1 }}
            onClick={props.onPrevClick}
          >
            <Icon name="chevron" />
          </IconButton>
          <Typography variant="displaysmall">{props.title}</Typography>
          <IconButton
            variant="standard"
            sx={{ backgroundColor: hootTokens.surface[2], boxShadow: hootTokens.elevation.elevation1 }}
            onClick={props.onNextClick}
          >
            <Icon name="chevron" sx={{ rotate: '180deg' }} />
          </IconButton>
        </Stack>
        <Stack direction="row" justifyContent="flex-end" flex={1}>
          {props.header}
        </Stack>
      </Stack>
      <Stack marginTop="32px" flex={1} direction="row" height={`${HEADER_HEIGHT}px`} mb={2}>
        <Stack flex={1} />
        {props.daysOfWeek.map((dayOfWeek, idx) => (
          <HeaderLabel key={`${dayOfWeek.toMillis()}-${idx}`} dayOfWeek={dayOfWeek} />
        ))}
      </Stack>
    </Stack>
  );
}

function HeaderLabel(props: { dayOfWeek: DateTime }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const isToday = props.dayOfWeek.toISODate() === DateTime.now().toISODate();

  return (
    <Stack justifyContent="center" alignItems="center" flex={1}>
      {isMobile ? (
        <Stack
          sx={{
            backgroundColor: isToday ? hootTokens.palette.black : undefined,
            color: isToday ? hootTokens.palette.white : undefined,
            paddingX: isToday ? '4px' : undefined,
            borderRadius: isToday ? '4px' : undefined,
          }}
          alignItems="center"
          justifyContent="center"
          height={`${HEADER_HEIGHT}px`}
          width={`${HEADER_WIDTH}px`}
        >
          <Typography variant="bodysmall">{props.dayOfWeek.toFormat('EEE').toUpperCase()}</Typography>
          <Typography variant="bodysmall">{props.dayOfWeek.toFormat('dd').toUpperCase()}</Typography>
        </Stack>
      ) : (
        <Stack
          sx={{
            backgroundColor: isToday ? hootTokens.palette.black : undefined,
            color: isToday ? hootTokens.palette.white : undefined,
            paddingX: isToday ? '16px' : undefined,
            borderRadius: isToday ? '8px' : undefined,
          }}
          alignItems="center"
          justifyContent="center"
          height={`${HEADER_HEIGHT}px`}
          width={`${HEADER_WIDTH}px`}
        >
          <Typography variant="bodysmall">{props.dayOfWeek.toFormat('LLL dd').toUpperCase()}</Typography>
          <Typography variant="bodysmall">{props.dayOfWeek.toFormat('EEE').toUpperCase()}</Typography>
        </Stack>
      )}
    </Stack>
  );
}

/**
 * STYLES
 */

const WeeklySchedule = styled('table')({
  borderCollapse: 'collapse',
  width: '100%',
  tableLayout: 'fixed',
});
const WeeklyScheduleHeader = styled('thead')({
  height: `${WEEKLY_SCHEDULED_CELL_HEIGHT}px`,
});
const WeeklyScheduleHeaderCell = styled('th')({});
const WeeklyScheduleBody = styled('tbody')({
  borderRadius: '8px',
  borderStyle: 'hidden',
  boxShadow: `0 0 0 1px ${hootTokens.palette.neutral[80]}`,
});
const WeeklyScheduleRow = styled('tr')({});
const WeeklyScheduleCell = styled('td')({
  border: `solid 1px ${hootTokens.palette.neutral[80]}`,
  height: `${WEEKLY_SCHEDULED_CELL_HEIGHT}px`,
  padding: '16px',
});

const shimmerAnimation = keyframes`
  to {
     background-position-x: 0%
  }
`;

const LoadingAnimation = styled(Stack)({
  position: 'absolute',
  height: '100%',
  width: '100%',
  background: `linear-gradient(-45deg, ${hootTokens.palette.neutral['180']} 60%, ${hootTokens.palette.neutral['140']} 70%, ${hootTokens.palette.neutral['180']} 80%)`,
  backgroundSize: '300%',
  backgroundPositionX: '100%',
  animation: `${shimmerAnimation} 1s infinite linear`,
});
