import React from 'react';
import { useParams } from 'react-router-dom';
import useGetInstructionalUnitDocumentation from '@hoot/hooks/api/library/useGetInstructionalUnitDocumentation';
import { usePageTitle } from '@hoot/hooks/usePageTitle';
import { ViewStateEnum } from '@hoot/ui/components/v2/ViewState';
import PageLayout from '@hoot/ui/components/v2/core/PageLayout';
import { useStudentDetailsContextUnsafe } from '@hoot/ui/pages/v2/teacher/my-students/student-details/StudentDetailsContextProvider';
import InstructionalUnitResourcesTable from '@hoot/ui/pages/v2/teacher/scope-and-sequence/focus-area/instructional-unit/documentation/InstructionalUnitResourcesTable';
import ScopeAndSequenceDocumentationTemplate from '@hoot/ui/pages/v2/teacher/scope-and-sequence/focus-area/instructional-unit/documentation/ScopeAndSequenceDocumentationTemplate';
import {
  FocusAreaSlug,
  ScopeAndSequenceFocusArea,
  scopeAndSequenceFocusAreaDictionary,
} from '@hoot/ui/pages/v2/teacher/scope-and-sequence/scopeAndSequenceFocusAreas';

const InstructionalUnitDocumentation = () => {
  const { focusAreaId, unitSlug, studentProfileId } = useParams<{
    focusAreaId: FocusAreaSlug;
    unitSlug: string;
    studentProfileId?: string;
  }>();

  const focusArea: ScopeAndSequenceFocusArea | undefined = scopeAndSequenceFocusAreaDictionary[focusAreaId as FocusAreaSlug];

  const { studentProfileSnippet } = useStudentDetailsContextUnsafe() ?? {};
  const optionalStudentNamePrefix = studentProfileSnippet?.name ? `${studentProfileSnippet?.name} - ` : '';

  const getInstructionalUnitDocumentationRequest = useGetInstructionalUnitDocumentation(unitSlug!, {
    retry: false,
  });

  usePageTitle(`${getInstructionalUnitDocumentationRequest.data?.unit?.name ?? 'Unit'} Documentation | Hoot Reading`);

  return (
    <>
      <ScopeAndSequenceDocumentationTemplate
        viewState={
          getInstructionalUnitDocumentationRequest.isFetching
            ? ViewStateEnum.Loading
            : getInstructionalUnitDocumentationRequest.error
              ? ViewStateEnum.Error
              : !getInstructionalUnitDocumentationRequest.data?.documentationHtmlContent
                ? ViewStateEnum.NoResults
                : ViewStateEnum.Results
        }
        title={getInstructionalUnitDocumentationRequest.data?.unit?.name ?? null}
        navigateBackPath={'..'}
        breadcrumbs={['Hoot Scope & Sequence', focusArea.title, getInstructionalUnitDocumentationRequest.data?.unit?.name ?? '-', 'Documentation']}
        htmlContent={getInstructionalUnitDocumentationRequest.data?.documentationHtmlContent ?? null}
        NoResultsIllustrationProps={{
          title: 'No documentation found',
          subtitle: 'There might not be any documentation for this instructional unit',
        }}
      />
      {getInstructionalUnitDocumentationRequest.data?.unit?.id && (
        <PageLayout>
          <InstructionalUnitResourcesTable
            title={`${optionalStudentNamePrefix}${getInstructionalUnitDocumentationRequest.data.unit.name} - Resources`}
            focusId={focusArea.instructionalFocusId}
            unitId={getInstructionalUnitDocumentationRequest.data.unit.id}
            studentProfileId={studentProfileId}
          />
        </PageLayout>
      )}
    </>
  );
};

export default InstructionalUnitDocumentation;
