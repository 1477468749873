import { StudentType } from '@hoot-reading/hoot-core/dist/enums/user/student/student-type.enum';
import { SelectProps } from '@mui/material';
import StudentSelect, { StudentSelectMenuItem } from '@hoot/ui/components/v2/StudentSelect';
import { Icon } from '@hoot/ui/components/v2/core/Icon';
import Lottie from '@hoot/ui/components/v2/lottie/Lottie';
import { StudentSubLibrarySelection, sublibraryLabels, sublibraryLottie } from '@hoot/ui/pages/v2/student/library/StudentLibraryPage';

interface SublibrarySelectorProps extends Omit<SelectProps, 'value' | 'onChange'> {
  value: StudentSubLibrarySelection;
  onChange: (value: StudentSubLibrarySelection) => void;
  studentType?: StudentType;
}

const SublibrarySelector = (props: SublibrarySelectorProps) => {
  const { value, onChange, studentType, ...SelectProps } = props;

  const _onChange: SelectProps['onChange'] = (event) => {
    onChange(event.target.value as StudentSubLibrarySelection);
  };

  const getOptions = () => {
    if (studentType === StudentType.HootDecodableAccess) {
      // Students of type `HootDecodableAccess` don't have access to "sublibraries".
      return Object.entries(sublibraryLabels).filter(
        ([key]) => key === StudentSubLibrarySelection.AllBooks || key === StudentSubLibrarySelection.Favourites,
      );
    }
    return Object.entries(sublibraryLabels);
  };

  return (
    <StudentSelect
      variant="standard"
      IconComponent={(props) => <Icon name="menu_drop_down_icon" {...props} />}
      value={value}
      onChange={_onChange}
      {...SelectProps}
    >
      {getOptions().map(([key, label]) => (
        <StudentSelectMenuItem key={key} value={key}>
          <Lottie lottieFile={sublibraryLottie[key as StudentSubLibrarySelection]} play={false} style={{ width: '64px', height: '64px' }} />
          <span>{label}</span>
        </StudentSelectMenuItem>
      ))}
    </StudentSelect>
  );
};

export default SublibrarySelector;
