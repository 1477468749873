import { Box, Stack, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { useCallback } from 'react';
import { useQueryClient } from 'react-query';
import { DateFormats } from '../../../../../constants/constants';
import { useRevertTeacherAvailabilityExceptionDraft } from '../../../../../hooks/api/availability/useDeleteTeacherAvailabilityException';
import {
  TeacherAvailablityExceptionDraftResponse,
  useGetTeacherAvailabilityExceptionDrafts,
} from '../../../../../hooks/api/availability/useGetTeacherAvailabilityExceptionDrafts';
import { QueryKey } from '../../../../../hooks/api/queryKeys';
import { Button } from '../../../../components/v2/core/Button';
import Card from '../../../../components/v2/core/Card';
import { Icon } from '../../../../components/v2/core/Icon';
import { hootTokens } from '../../../../theme/v2/tokens';

export function TimeOffAlert() {
  const draftsRequest = useGetTeacherAvailabilityExceptionDrafts();
  // I'm filtering out anything that was created more than 3 minutes ago incase the job doesn't run, it will try triggering
  // to clear the cache every second otherwise
  const drafts = draftsRequest.data?.drafts.filter((d) => DateTime.fromMillis(d.createdAt).plus({ minute: 3 }).diffNow().toMillis() >= 0) ?? [];

  if (drafts.length < 1) {
    return;
  }

  return (
    <Stack padding="24px 24px 0px 24px" spacing="8px">
      {drafts.map((d) => (
        <Alert key={d.id} draft={d} />
      ))}
    </Stack>
  );
}

function Alert(props: { draft: TeacherAvailablityExceptionDraftResponse }) {
  const queryClient = useQueryClient();
  const revertDraftRequest = useRevertTeacherAvailabilityExceptionDraft();

  const startsAt = DateTime.fromMillis(props.draft.startsAt);
  const endsAt = DateTime.fromMillis(props.draft.endsAt);

  const timerEndsAt = DateTime.fromMillis(props.draft.createdAt).plus({ minutes: 3 });

  const ref = useCallback(
    (elem: HTMLDivElement) => {
      let intervalId = -1;

      if (intervalId >= 0) {
        window.clearInterval(intervalId);
      }

      intervalId = window.setInterval(() => {
        if (elem) {
          const countdownRemaining = timerEndsAt.diffNow();

          if (countdownRemaining.toMillis() <= 0) {
            queryClient.invalidateQueries(QueryKey.GetTeacherAvailablityExceptionDrafts);
            window.clearInterval(intervalId);
          } else {
            elem.textContent = `(${countdownRemaining.toFormat('m:ss')})`;
          }
        }
      }, 1000);
    },
    [timerEndsAt, queryClient],
  );

  const handleRevertClick = () => {
    revertDraftRequest.mutate(
      {
        teacherAvailabilityExceptionDraftId: props.draft.id,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(QueryKey.GetTeacherAvailabilityExceptions);
          queryClient.invalidateQueries(QueryKey.GetTeacherAvailablityExceptionDrafts);
        },
      },
    );
  };

  return (
    <Card>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Stack direction="row">
          <Stack paddingTop="8px" paddingRight="16px">
            <Icon name="hoot_wheel" />
          </Stack>
          <Stack>
            <Typography variant="titlemedium">Time Off Alert</Typography>
            <Typography variant="titlemedium">
              {startsAt.toFormat(DateFormats.FULL_DATE)} - {endsAt.toFormat(DateFormats.FULL_DATE)} ({startsAt.toFormat('h:mma')} -{' '}
              {endsAt.toFormat('h:mma')})
            </Typography>

            <Typography marginTop="24px" variant="bodysmall">
              You can revert the changes created by this time off and restore your lesson(s) and your availability for the next three minutes.
            </Typography>
          </Stack>
        </Stack>
        <Stack>
          <Button
            onClick={handleRevertClick}
            color="error.80"
            variant="contained"
            startIcon={<Icon htmlColor={hootTokens.palette.white} name="redo" />}
          >
            Revert <Box component="span" ref={ref} />
          </Button>
        </Stack>
      </Stack>
    </Card>
  );
}
