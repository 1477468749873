import { Stack } from '@mui/system';
import { Control, Controller } from 'react-hook-form';
import Card from '@hoot/ui/components/v2/core/Card';
import { Select } from '@hoot/ui/components/v2/core/Select';
import TextField from '@hoot/ui/components/v2/core/TextField';
import { timeZoneData } from '@hoot/utils/timeZones';
import { TeacherProfileForm } from './TeacherProfile';

interface Props {
  control: Control<TeacherProfileForm, object>;
}

const DisplayDetails = (props: Props) => {
  const { control } = props;

  const timezones = timeZoneData;

  return (
    <Card sx={{ width: '100%' }} title="Display Details">
      <Stack gap={'16px'}>
        <Controller
          name="displayName"
          control={control}
          rules={{
            required: 'Required',
          }}
          render={({ field: { onChange, value }, fieldState }) => (
            <TextField
              variant="outlined"
              fullWidth
              id="displayName"
              label="Display Name"
              onChange={onChange}
              value={value}
              error={!!fieldState.error}
              helperText={fieldState.error?.message || 'This is the name displayed to students'}
            />
          )}
        />
        <Controller
          name="timeZone"
          control={control}
          rules={{
            required: 'Required',
          }}
          render={({ field, fieldState }) => (
            <Select
              value={field.value}
              onChange={field.onChange}
              label="Timezone"
              id="timeZone"
              error={!!fieldState.error}
              helperText={fieldState.error?.message || ''}
            >
              <option disabled value="">
                Select
              </option>
              {timezones.map((d) => (
                <option key={d.value} value={d.value}>
                  {d.label}
                </option>
              ))}
            </Select>
          )}
        />
        <Controller
          name="bio"
          control={control}
          render={({ field: { onChange, value }, fieldState }) => (
            <TextField
              variant="outlined"
              fullWidth
              id="biography"
              label="Biography (Optional)"
              onChange={onChange}
              multiline
              value={value}
              rows={4}
              inputProps={{ maxLength: 500 }}
              error={!!fieldState.error}
              helperText={fieldState.error?.message || ''}
            />
          )}
        />
      </Stack>
    </Card>
  );
};

export default DisplayDetails;
