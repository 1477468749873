import axios, { AxiosError } from 'axios';
import { UseMutationOptions, useMutation } from 'react-query';
import { config } from '../../../config';

export interface UpdatedTeacherAccount {
  bio?: string | null;
  displayName?: string | null;
  branchNumber?: number | null;
  institutionNumber?: number | null;
  accountNumber?: number | null;
  identificationNumber?: number | null;
  streetAddress?: string | null;
  city?: string | null;
  provinceState?: string | null;
  country?: string | null;
  postalZipCode?: string | null;
  timeZone?: string | null;
  emergencyContactFirstName: string | null;
  emergencyContactLastName: string | null;
  emergencyContactEmail: string | null;
  emergencyContactPhoneNumber: string | null;
}

async function updateTeacherProfile(userId: string, payload: UpdatedTeacherAccount): Promise<void> {
  return axios.put(`${config.apiUrl}/public/teacher-account/${userId}`, payload);
}

export function useUpdateTeacherProfile(
  userId: string,
  options?: Omit<UseMutationOptions<void, AxiosError, UpdatedTeacherAccount>, 'mutationKey' | 'mutationFn'>,
) {
  return useMutation((request) => updateTeacherProfile(userId, request), options);
}
