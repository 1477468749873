import { Stack, useMediaQuery, useTheme } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useParams } from 'react-router-dom';
import useGetLessonReviewDetails from '@hoot/hooks/api/lesson-reviews/useGetLessonReviewDetails';
import Page from '@hoot/ui/components/v2/core/Page';
import PageLayout from '@hoot/ui/components/v2/core/PageLayout';
import LessonReviewDetailsCard from '@hoot/ui/pages/v2/teacher/my-students/student-details/lesson-reviews/lesson-review-details/LessonReviewDetailsCard';
import LessonReviewFocusCard from '@hoot/ui/pages/v2/teacher/my-students/student-details/lesson-reviews/lesson-review-details/LessonReviewFocusCard';
import LessonReviewFormativeAssessmentsCard from '@hoot/ui/pages/v2/teacher/my-students/student-details/lesson-reviews/lesson-review-details/LessonReviewFormativeAssessmentsCard';
import LessonReviewHeader from '@hoot/ui/pages/v2/teacher/my-students/student-details/lesson-reviews/lesson-review-details/LessonReviewHeader';
import LessonReviewNotesCard from '@hoot/ui/pages/v2/teacher/my-students/student-details/lesson-reviews/lesson-review-details/LessonReviewNotesCard';
import LessonWhiteboardsCard from '@hoot/ui/pages/v2/teacher/my-students/student-details/lesson-reviews/lesson-review-details/LessonWhiteboardsCard';

const LessonReviewDetailsPage = () => {
  const { lessonReviewId } = useParams();
  const theme = useTheme();
  const sub900px = useMediaQuery(theme.breakpoints.down('md'));
  const { data: lessonReview, isFetching } = useGetLessonReviewDetails(lessonReviewId || '', { enabled: !!lessonReviewId });

  return (
    <Page data-sentry-mask pageTitle={'Lesson Review | Hoot Reading'} noPadding noMaxWidth>
      <LessonReviewHeader lessonNumber={lessonReview?.number} />
      <PageLayout>
        <Grid container spacing={2} flexDirection={sub900px ? 'column' : 'row'}>
          <Grid size={{ xs: 12, md: 3 }}>
            <Stack gap={2}>
              <LessonReviewDetailsCard isLoading={isFetching} lessonReview={lessonReview} />
              {lessonReview?.notes ? <LessonReviewNotesCard isLoading={isFetching} notes={lessonReview?.notes} /> : null}
            </Stack>
          </Grid>
          <Grid size={{ xs: 12, md: 9 }}>
            <Stack gap={2}>
              <LessonReviewFocusCard isLoading={isFetching} instructionalFocus={lessonReview?.primaryFocus} isPrimary={true} />
              {lessonReview?.secondaryFocus ? (
                <LessonReviewFocusCard isLoading={isFetching} instructionalFocus={lessonReview?.secondaryFocus} />
              ) : null}
              <LessonReviewFormativeAssessmentsCard lessonReviewId={lessonReviewId} />
              <LessonWhiteboardsCard lessonId={lessonReview?.lessonId ?? null} />
            </Stack>
          </Grid>
          <Grid size={{ xs: 12, md: 4 }}></Grid>
        </Grid>
      </PageLayout>
    </Page>
  );
};

export default LessonReviewDetailsPage;
