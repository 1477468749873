import { Box, Skeleton, Stack, SxProps, Theme } from '@mui/material';
import { useMemo } from 'react';
import Chart, { ChartWrapperOptions } from 'react-google-charts';
import { UseQueryResult } from 'react-query';
import {
  RepReportingAvgWeeklyStudentAttendanceResponse,
  useGetAvgWeeklyStudentAttendanceReportForLocation,
} from '@hoot/hooks/api/district-rep/reporting/useGetAvgWeeklyStudentAttendanceReport';
import { LocationReportingFilters } from '@hoot/hooks/api/district-rep/reporting/useGetLocationReportingMetrics';
import useGetStudentAttendanceBandsReport from '@hoot/hooks/api/district-rep/reporting/useGetStudentAttendanceBandsReport';
import ViewState, { ViewStateEnum } from '@hoot/ui/components/v2/ViewState';
import Card from '@hoot/ui/components/v2/core/Card';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';

export const AverageWeeklyStudentAttendanceBarChart = (props: {
  title: string;
  queryResult: UseQueryResult<RepReportingAvgWeeklyStudentAttendanceResponse>;
  sx?: SxProps<Theme>;
}) => {
  const SkeletonChartItems = () => (
    <Stack direction="column" gap={3} sx={{ width: '100%' }}>
      <Skeleton variant="rounded" sx={{ minWidth: '100px', width: '100%', minHeight: '450px' }} />
    </Stack>
  );

  const chartData =
    !!props.queryResult.data && !props.queryResult.isFetching
      ? [
          ['Week Of', 'Average Attendance Rate', { role: 'style' }],
          ...props.queryResult.data?.weeks.map((w) => [w.weekStart, w.avgWeeklyAttendanceRate, `color: ${hootTokens.palette.success['100']}`]),
        ]
      : [['Week', 'Average Attendance Rate', { role: 'style' }]];

  const options: ChartWrapperOptions['options'] = {
    bars: 'vertical',
    colors: [`${hootTokens.palette.success['100']}`],
    axes: {
      y: {
        0: { side: 'left', label: 'Average Attendance Rate' },
      },
    },
    vAxis: {
      maxValue: 100,
      minValue: 0,
    },
    legend: { position: 'none' },
    height: 450,
  };

  const viewState = useMemo<ViewStateEnum>(() => {
    if (props.queryResult.isFetching || props.queryResult.isLoading) {
      return ViewStateEnum.Loading;
    } else if (props.queryResult.isError) {
      return ViewStateEnum.Error;
    } else if (!props.queryResult.data || props.queryResult.data.weeks.length === 0) {
      return ViewStateEnum.EmptyState;
    } else {
      return ViewStateEnum.Results;
    }
  }, [props.queryResult.isFetching, props.queryResult.isLoading, props.queryResult.isError, props.queryResult.data]);

  const resultData = props.queryResult.data;
  const hasData = !props.queryResult.isFetching && !!resultData && resultData.weeks.length > 0;

  return (
    <Card
      title={props.title}
      isLoading={props.queryResult.isFetching}
      sx={{
        width: '60%',
        height: '521px',
        ...(props.sx ?? {}),
      }}
    >
      <ViewState
        state={viewState}
        loadingContent={<SkeletonChartItems />}
        EmptyStateIllustrationProps={{ title: 'No Results', subtitle: 'No data found', showBorder: false }}
      >
        {hasData ? <Chart chartType="Bar" data={chartData} options={options} /> : null}
      </ViewState>
    </Card>
  );
};

export const StudentAttendanceBandsPieChart = (props: { title: string; locationId: string; filters: LocationReportingFilters }) => {
  const SkeletonChartItems = () => (
    <Stack direction="column" gap={3} sx={{ width: '100%' }}>
      <Box display="flex" flexDirection="row" gap={1}>
        <Skeleton variant="rounded" sx={{ minWidth: '80px', width: '70%', minHeight: '450px' }} />
        <Skeleton variant="rounded" sx={{ minWidth: '50px', width: '30%', minHeight: '220px' }} />
      </Box>
    </Stack>
  );

  const bandLegendLabelMap: Record<string, string> = {
    LTE_10: '<= 10%',
    _11_TO_20: '11 - 20%',
    _21_TO_30: '21 - 30%',
    _31_TO_40: '31 - 40%',
    _41_TO_50: '41 - 50%',
    _51_TO_60: '51 - 60%',
    _61_TO_70: '61 - 70%',
    _71_TO_80: '71 - 80%',
    _81_TO_90: '81 - 90%',
    GT_90: '91 - 100%',
  };

  const fetchStudentAttendanceBandsRequest = useGetStudentAttendanceBandsReport(props.locationId, props.filters, { enabled: !!props.locationId });

  const chartData =
    !!fetchStudentAttendanceBandsRequest.data && !fetchStudentAttendanceBandsRequest.isFetching
      ? [
          ['Attendance', 'Students', { role: 'style' }],
          ...fetchStudentAttendanceBandsRequest.data.bands.map((band) => [
            bandLegendLabelMap[band.band],
            band.studentsInBand,
            hootTokens.palette.success['100'],
          ]),
        ]
      : [['Attendance', 'Students', { role: 'style' }]];

  const viewState = useMemo<ViewStateEnum>(() => {
    if (!props.locationId || fetchStudentAttendanceBandsRequest.isFetching || fetchStudentAttendanceBandsRequest.isLoading) {
      return ViewStateEnum.Loading;
    } else if (fetchStudentAttendanceBandsRequest.isError) {
      return ViewStateEnum.Error;
    } else if (!fetchStudentAttendanceBandsRequest.data) {
      return ViewStateEnum.EmptyState;
    } else {
      const hasAnyNonZeroBands = fetchStudentAttendanceBandsRequest.data.bands.some((band) => band.studentsInBand > 0);

      return hasAnyNonZeroBands ? ViewStateEnum.Results : ViewStateEnum.EmptyState;
    }
  }, [
    props.locationId,
    fetchStudentAttendanceBandsRequest.isFetching,
    fetchStudentAttendanceBandsRequest.isLoading,
    fetchStudentAttendanceBandsRequest.isError,
    fetchStudentAttendanceBandsRequest.data,
  ]);

  return (
    <Card
      title={props.title}
      isLoading={fetchStudentAttendanceBandsRequest.isFetching}
      sx={{
        width: '40%',
        height: '521px',
      }}
    >
      <ViewState
        state={viewState}
        loadingContent={<SkeletonChartItems />}
        EmptyStateIllustrationProps={{ title: 'No Results', subtitle: 'No students matching the provided filters found', showBorder: false }}
      >
        <Chart
          chartType="PieChart"
          data={chartData}
          options={{
            chartArea: {
              width: '90%',
              height: '90%',
            },
            pieHole: 0.4,
            pieSliceText: 'percentage',
            height: 450,
            legend: {
              position: 'right',
              alignment: 'top',
            },
            tooltip: {
              text: 'both',
            },
          }}
        />
      </ViewState>
    </Card>
  );
};

export const AttendanceCharts = (props: { locationId: string; filters: LocationReportingFilters }) => {
  const getAverageWeeklyStudentAttendanceRequest = useGetAvgWeeklyStudentAttendanceReportForLocation(props.locationId, props.filters, {
    enabled: !!props.locationId,
  });

  return (
    <>
      <Stack direction="row" alignItems="center" alignSelf="center" gap={2} width="100%">
        <StudentAttendanceBandsPieChart title="Student Attendance Bands" locationId={props.locationId} filters={props.filters} />
        <AverageWeeklyStudentAttendanceBarChart title="Average Weekly Student Attendance" queryResult={getAverageWeeklyStudentAttendanceRequest} />
      </Stack>
    </>
  );
};
