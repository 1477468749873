import { StudentGrade } from '@hoot-reading/hoot-core/dist/enums/student-grade';
import { StudentStatus } from '@hoot-reading/hoot-core/dist/enums/user/student/student-status.enum';
import axios from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '@hoot/config';
import { QueryKey } from '../../queryKeys';

export interface GetLocationStudentGradesAndStatusesResponse {
  grades: StudentGrade[];
  statuses: StudentStatus[];
}

export default function useGetLocationStudentGradesAndStatuses(
  locationId: string,
  options?: Omit<UseQueryOptions<GetLocationStudentGradesAndStatusesResponse>, 'queryKey' | 'queryFn'>,
) {
  return useQuery(
    [QueryKey.DistrictRepReportingLocationStudentGradesAndStatuses, locationId],
    async () => {
      return (
        await axios.get<GetLocationStudentGradesAndStatusesResponse>(
          `${config.apiUrl}/public/district-representative/reporting/location/${locationId}/student-grades-and-statuses`,
        )
      ).data;
    },
    options,
  );
}
