import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import { InputAdornment, Stack } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { capitalCase } from 'change-case';
import { DateTime } from 'luxon';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import useSearchDistrictRepresentativeEnrolments, {
  DistrictRepresentativeEnrolmentSearchType,
  DistrictRepresentativeEnrolmentsQuery,
} from '@hoot/hooks/api/district-rep/useSearchDistrictRepresentativeEnrolments';
import useSearchDistrictRepresentativeEnrolmentsFilters from '@hoot/hooks/api/district-rep/useSearchDistrictRepresentativeEnrolmentsFilters';
import useGetDistrictRepPermissions from '@hoot/hooks/api/user/useGetDistrictRepPermissions';
import { OrderBy } from '@hoot/models/api/enums/queryEnums';
import { RootState } from '@hoot/redux/store';
import { routesDictionary } from '@hoot/routes/routesDictionary';
import { DistrictRepAccessLevelCombinations, useHasDistrictPermission } from '@hoot/routes/secure/profile/district-rep/DistrictRepProtectedRoute';
import ProtectedLink from '@hoot/ui/components/v2/ProtectedLink';
import { Button } from '@hoot/ui/components/v2/core/Button';
import Card from '@hoot/ui/components/v2/core/Card';
import { Chip } from '@hoot/ui/components/v2/core/Chip';
import Page from '@hoot/ui/components/v2/core/Page';
import SearchTextField, { SearchTextFieldProps } from '@hoot/ui/components/v2/core/SearchTextField';
import { HeaderData, TableV2 } from '@hoot/ui/components/v2/core/Table';
import EnrolmentsFiltersDialog from '@hoot/ui/pages/v2/district-rep/students/enrolments/EnrolmentsFiltersDialog';

export interface EnrolmentRow {
  id: string;
  friendlyId: React.ReactNode;
  status: string;
  startDate: string;
  endDate: string;
  numberOfStudentsRegistered: string;
  productFriendlyId: React.ReactNode;
}

export interface DistrictRepresentativeEnrolments {
  count: number;
  page: number;
  pageSize: number;
  enrolments: EnrolmentRow[];
}

const IGNORE_FILTERS = ['name', 'page', 'pageSize', 'sortBy', 'orderBy', 'studentProfileId', 'locationId', 'productId', 'searchType'];
export type DistrictRepresentativeEnrolmentsQueryKeys = keyof DistrictRepresentativeEnrolmentsQuery;

const FilterChips = (props: {
  badgesWithMultiSelectors: any[];
  query: DistrictRepresentativeEnrolmentsQuery;
  setQuery: React.Dispatch<React.SetStateAction<DistrictRepresentativeEnrolmentsQuery>>;
  districtRepresentativeId?: string;
}) => {
  const { badgesWithMultiSelectors, query, setQuery, districtRepresentativeId } = props;
  const enrolments = useSearchDistrictRepresentativeEnrolmentsFilters(districtRepresentativeId!, {
    enabled: !!districtRepresentativeId,
    keepPreviousData: true,
  });

  return (
    <Grid container gap={2}>
      {badgesWithMultiSelectors?.map((badge) => {
        const [filterKey, filterValue]: [DistrictRepresentativeEnrolmentsQueryKeys, any] = badge;
        const key = filterKey;
        let value;

        if (filterKey === 'startDate' || filterKey === 'endDate') {
          value = DateTime.fromMillis(filterValue).toFormat('LL-dd-yyyy');
        } else if (filterKey === 'enrolmentId') {
          value = enrolments.data?.find((enrolment) => enrolment.id === filterValue)?.friendlyId;
        } else {
          value = capitalCase(filterValue);
        }

        return (
          <Chip
            key={`${key}-${value}`}
            label={`${capitalCase(key)}: ${value}`}
            onDelete={() => {
              setQuery((prevState) => {
                const value = query[filterKey];
                return {
                  ...prevState,
                  [filterKey]: Array.isArray(value) ? (value as any)?.filter((val: string) => val !== filterValue) : undefined,
                };
              });
            }}
          />
        );
      })}
    </Grid>
  );
};

function HFSEnrolments(props: { searchType: DistrictRepresentativeEnrolmentSearchType }) {
  const { searchType } = props;
  const { studentProfileId, schoolId, productId } = useParams();
  const districtRepresentativeId = useSelector((state: RootState) => state.profile.profile?.id);
  const [searchText, setSearchText] = useState<string>('');
  const [query, setQuery] = useState<DistrictRepresentativeEnrolmentsQuery>({
    studentProfileId: studentProfileId,
    locationId: schoolId,
    productId: productId,
    searchType: searchType,
    name: undefined,
    page: 1,
    pageSize: 10,
    sortBy: 'name',
    orderBy: OrderBy.Asc,
  });
  const [data, setData] = useState<DistrictRepresentativeEnrolments>();
  const [showFiltersDialog, setShowFiltersDialog] = useState(false);
  const { data: districtRepPermissions } = useGetDistrictRepPermissions(districtRepresentativeId || '');
  const hasEnrolmentEditLevelOrHigherPermission = useHasDistrictPermission(
    DistrictRepAccessLevelCombinations.EnrolmentViewLevel,
    districtRepPermissions,
  );
  const isEnrolmentSearch = searchType === DistrictRepresentativeEnrolmentSearchType.Enrolment;

  const headers: HeaderData<EnrolmentRow>[] = [
    { name: 'Enrolment ID', property: 'friendlyId', isSortable: true },
    { name: 'Status', property: 'status', isSortable: true },
    { name: 'Enrolment Start', property: 'startDate', isSortable: true },
    { name: 'Enrolment End', property: 'endDate', isSortable: true },
    { name: 'Registered Students', property: 'numberOfStudentsRegistered', isHidden: !isEnrolmentSearch },
    { name: 'Product Id', property: 'productFriendlyId', isSortable: true, isHidden: !isEnrolmentSearch },
  ];

  const { isLoading } = useSearchDistrictRepresentativeEnrolments(districtRepresentativeId || '', query, {
    enabled: !!districtRepresentativeId,
    onSuccess: (data) => {
      const enrolments = data.enrolments.map(
        (enrolment): EnrolmentRow => ({
          id: enrolment.id,
          friendlyId: (
            <ProtectedLink
              to={routesDictionary.enrolments.enrolment.details.url(enrolment.id)}
              hasPermission={hasEnrolmentEditLevelOrHigherPermission}
            >
              {enrolment.friendlyId}
            </ProtectedLink>
          ),
          status: capitalCase(enrolment.status),
          startDate: DateTime.fromMillis(enrolment.startDate).toFormat('LL-dd-yyyy'),
          endDate: DateTime.fromMillis(enrolment.endDate).toFormat('LL-dd-yyyy'),
          numberOfStudentsRegistered: enrolment.numberOfStudentsRegistered?.toString() ?? '0',
          productFriendlyId: (
            <ProtectedLink
              to={routesDictionary.enrolments.product.details.url(enrolment?.productId ?? '')}
              hasPermission={hasEnrolmentEditLevelOrHigherPermission}
            >
              {enrolment.productFriendlyId}
            </ProtectedLink>
          ),
        }),
      );

      const enrolmentsResponse = {
        count: data.count,
        page: data.page,
        pageSize: data.pageSize,
        enrolments: enrolments,
      };
      setData(enrolmentsResponse);
    },
  });

  const badges = () => {
    return Object.entries(query).filter(([k, v]) => !IGNORE_FILTERS.includes(k) && v !== undefined && (Array.isArray(v) ? v.length !== 0 : true)) as [
      DistrictRepresentativeEnrolmentsQueryKeys,
      any,
    ][];
  };

  const badgesWithMultiSelectors = badges().reduce((acc: any[], badge) => {
    if (Array.isArray(badge[1])) return [...acc, ...badge[1].map((val: any) => [badge[0], val])];
    return [...acc, badge];
  }, []);

  const clearInput = () => {
    setSearchText('');
  };

  const onSearchInputChanged: SearchTextFieldProps['onSearchInputChanged'] = (text) => {
    setSearchText(text);
  };

  const onSearchInputDebounced: SearchTextFieldProps['onSearchInputDebounced'] = (text) => {
    setQuery((current) => ({ ...current, name: text }));
  };

  return (
    <Page pageTitle="Hoot for Schools Enrolments | Hoot Reading" noPadding>
      <Stack>
        <Card title="Enrolments" isLoading={isLoading} sx={{ overflowX: 'scroll' }}>
          <Stack direction="row" alignItems="center" alignSelf="center" gap={2}>
            <SearchTextField
              label="Enrolments Search"
              searchInput={searchText}
              onSearchInputChanged={onSearchInputChanged}
              onClearButtonClicked={clearInput}
              onSearchInputDebounced={onSearchInputDebounced}
              clearable
              placeholder="Search"
            />
            <Button
              color="primary"
              onFocusVisible={() => {}}
              variant="contained"
              size="medium"
              startIcon={
                <InputAdornment position="start">
                  <FilterAltOutlinedIcon sx={{ color: 'white' }} />
                </InputAdornment>
              }
              sx={{ padding: '16px' }}
              onClick={() => setShowFiltersDialog(true)}
            >
              Filter
            </Button>
          </Stack>

          <Stack sx={{ marginTop: '20px' }}>
            <FilterChips
              badgesWithMultiSelectors={badgesWithMultiSelectors}
              query={query}
              setQuery={setQuery}
              districtRepresentativeId={districtRepresentativeId}
            />
          </Stack>

          <Stack marginTop="16px">
            <TableV2
              allowRowsPerPage
              isPaginated
              isSortable
              count={data?.count ?? 0}
              data={data?.enrolments ?? []}
              headers={headers}
              onPageChange={(event, page) => {
                setQuery((prevState) => ({ ...prevState, page: page + 1 }));
              }}
              onRowsPerPageChange={(event) => {
                setQuery((prevState) => ({ ...prevState, page: 1, pageSize: +event.target.value }));
              }}
              onSortBy={(val: any) => {
                setQuery((prevState) => ({
                  ...prevState,
                  page: 1,
                  sortBy: val,
                  orderBy: prevState.orderBy === OrderBy.Asc ? OrderBy.Desc : OrderBy.Asc,
                }));
              }}
              page={data?.page ? data.page - 1 : 0}
              sortOrder={query.orderBy}
              sortBy={query.sortBy as any}
              rowsPerPage={query.pageSize}
            />
          </Stack>
        </Card>
        <Grid container size={8}></Grid>
      </Stack>
      {showFiltersDialog ? (
        <EnrolmentsFiltersDialog show={showFiltersDialog} onDismiss={() => setShowFiltersDialog(false)} query={query} onChange={setQuery} />
      ) : null}
    </Page>
  );
}

export default HFSEnrolments;
