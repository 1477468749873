import { Outlet } from 'react-router-dom';
import { TeacherStage } from '@hoot/models/api/teacher';
import PermissionDeniedDialog from '@hoot/ui/components/v2/dialogs/PermissionDeniedDialog';
import { useAuth } from '@hoot/ui/context/AuthContext';
import { routesDictionary } from '../../../routesDictionary';

const defaultPaths = [
  routesDictionary.dashboard.path,
  routesDictionary.myStudents.path,
  routesDictionary.schedule.path,
  routesDictionary.settings.audioVideo.path,
];

const limitedAccessAllowedPaths = [routesDictionary.dashboard.path, routesDictionary.invoicing.path, routesDictionary.profile.path];

const teacherStageRecord: Record<TeacherStage, string[]> = {
  [TeacherStage.Interviewing]: [...defaultPaths],
  [TeacherStage.OfferPending]: [...defaultPaths],
  [TeacherStage.NotHired]: [], // no access.
  [TeacherStage.Onboarding]: [
    ...defaultPaths,
    routesDictionary.opportunities.path,
    routesDictionary.invoicing.path,
    routesDictionary.library.path,
    routesDictionary.scopeAndSequence.path,
    routesDictionary.profile.path,
  ],
  [TeacherStage.Live]: [
    ...defaultPaths,
    routesDictionary.opportunities.path,
    routesDictionary.invoicing.path,
    routesDictionary.library.path,
    routesDictionary.scopeAndSequence.path,
    routesDictionary.profile.path,
  ],
  [TeacherStage.OnLeave]: [
    ...defaultPaths,
    routesDictionary.opportunities.path,
    routesDictionary.invoicing.path,
    routesDictionary.library.path,
    routesDictionary.scopeAndSequence.path,
    routesDictionary.profile.path,
  ],
  [TeacherStage.PreDeparture]: [
    ...defaultPaths,
    routesDictionary.opportunities.path,
    routesDictionary.invoicing.path,
    routesDictionary.library.path,
    routesDictionary.scopeAndSequence.path,
    routesDictionary.profile.path,
  ],
};

export const TeacherProtectedRoute = ({ routePath }: { routePath: string }) => {
  const { hasAccess } = useTeacherStagePermissions();

  const isAllowed = hasAccess(routePath);

  if (isAllowed) {
    return <Outlet />;
  } else {
    return <PermissionDeniedDialog isModalOpen={true} />;
  }
};

/**
 * Checks `TeacherStage` and other details for accessing Teacher routes.
 */
const useTeacherStagePermissions = () => {
  const { getUser } = useAuth();
  const user = getUser();

  const teacherDisabled = user.teacherAccount?.isDisabled;
  const teacherStage = user.teacherAccount?.teacherStage;
  const teacherStatus = user.teacherAccount?.status;

  // Block all access to the app.
  const noPageAccess = teacherDisabled || teacherStage === TeacherStage.NotHired;

  const hasAccess = (routeToCheck: string) => {
    if (noPageAccess) {
      return false;
    }

    // Allow limited access when `TeacherStage` is NULL and `TeacherStatus` is INACTIVE.
    if (!teacherStage && teacherStatus === 'INACTIVE') {
      return limitedAccessAllowedPaths.includes(routeToCheck);
    }

    // Perform the route lookup as usual.
    return teacherStage ? (teacherStageRecord[teacherStage].includes(routeToCheck) ?? false) : false;
  };

  return { hasAccess };
};

export default TeacherProtectedRoute;
