import { Box, Toolbar, Tooltip, useMediaQuery, useTheme } from '@mui/material';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import useGetDistrictRepPermissions from '@hoot/hooks/api/user/useGetDistrictRepPermissions';
import { UserProfileType, userProfileLabel } from '@hoot/models/api/user';
import { RootState } from '@hoot/redux/store';
import { routesDictionary } from '@hoot/routes/routesDictionary';
import { Button } from '@hoot/ui/components/v2/core/Button';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';
import { IconButton } from '@hoot/ui/components/v2/core/IconButton';
import { NewAppVersionAvailableBanner } from '@hoot/ui/components/v2/core/NewAppVersionAvailableBanner';
import PopoverMenu, { MenuItem } from '@hoot/ui/components/v2/core/PopoverMenu';
import ChangePasswordDialog from '@hoot/ui/components/v2/dialogs/ChangePasswordDialog';
import LogoutIcon from '@hoot/ui/components/v2/icons/LogoutIcon';
import { useAudioVideoSettingsDialog } from '@hoot/ui/context/AudioVideoSettingsDialogContext';
import { useAuth } from '@hoot/ui/context/AuthContext';
import parentNavItems from '@hoot/ui/navigation/parentNavItems';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';
import useProfile from '../../../../hooks/useProfile';
import { useCookieConsent } from '../../../context/CookieConsentContext';
import useDistrictRepNavItems from '../../../navigation/districtRepNavItems';
import useTeacherNavItems from '../../../navigation/useTeacherNavItems';
import Chevron from '../icons/Chevron';
import { Icon } from './Icon';
import { JoinLessonBanner } from './JoinLessonBanner';
import NavMenuDrawer from './NavMenuDrawer';
import { NavList } from './NavMenuList';

interface AppBarProps extends MuiAppBarProps {
  user: AppBarUserAccount;
  navList: NavList[];
}

interface AppBarUserAccount {
  fullName: string;
  accountType: UserProfileType;
}

const filteredNavList = (navList: NavList[], isDesktop: boolean): NavList[] => {
  const _navList = [...navList];
  if (isDesktop) {
    return _navList;
  }

  const reducedNavList = _navList.reduce((acc: NavList[], curr: NavList) => {
    if (!curr.hideOnMobile) {
      if (curr.subList) {
        curr.subList = curr.subList.filter((item) => !item.hideOnMobile);
      }
      acc.push(curr);
    }
    return acc;
  }, []);

  return reducedNavList;
};

const AppBar = () => {
  const profile = useSelector((state: RootState) => state.profile.profile);
  const isTeacherProfileDisabled = profile?.type === UserProfileType.Teacher && !profile?.isEnabled;
  const isDistrictRep = profile?.type === UserProfileType.DistrictRep;

  const { showModal: showCookieConsentModal } = useCookieConsent();
  const { onShow: showAudioVideoSettingsModal } = useAudioVideoSettingsDialog();
  const { data: districtRepPermissions } = useGetDistrictRepPermissions(profile?.id || '', {
    enabled: isDistrictRep,
  });

  const [showChangePasswordDialog, setShowChangePasswordDialog] = useState(false);

  const onShowChangePasswordModal = () => {
    setShowChangePasswordDialog(true);
  };

  const onDismissChangePasswordModal = () => {
    setShowChangePasswordDialog(false);
  };

  const navList = {
    [UserProfileType.DistrictRep]: useDistrictRepNavItems(districtRepPermissions),
    [UserProfileType.Teacher]: useTeacherNavItems({
      isTeacherProfileDisabled,
      showCookieConsentModal,
      showAudioVideoSettingsModal,
    }),
    [UserProfileType.Parent]: parentNavItems({
      showAudioVideoSettingsModal,
      showChangePasswordModal: onShowChangePasswordModal,
    }),
    [UserProfileType.Student]: [],
  };

  if (!profile) {
    return <Navigate to={routesDictionary.logout.url} />;
  }

  return (
    <>
      <PrimaryAppBar
        user={{
          accountType: profile.type,
          fullName: profile.name,
        }}
        navList={navList[profile.type]}
      />
      <ChangePasswordDialog show={showChangePasswordDialog} onClose={onDismissChangePasswordModal} />
    </>
  );
};

const PrimaryAppBar = (props: AppBarProps) => {
  const { user, navList, ...appBarProps } = props;
  const { fullName, accountType } = user;
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [userAccountAnchorElement, setUserAccountAnchorElement] = useState<null | HTMLElement>(null);

  const showUserAccountMenu = Boolean(userAccountAnchorElement);
  const { getUser, logout } = useAuth();
  const { isTeacher, isDistrictRep, isParent } = useProfile();
  const activeProfiles = getUser().profiles.filter((x) => x.isEnabled);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true });
  const inLesson = useSelector((state: RootState) => state.activeLesson.inLesson);

  const accountTypeLabel = useMemo(() => {
    return userProfileLabel[accountType] ?? '';
  }, [accountType]);

  /*
    If Parent, always allow change profiles
    Otherwise, if profile is teacher or district rep and they have more than 1 active profile show selector
  */
  const showProfileSelector = (activeProfiles.length > 1 && (isTeacher || isDistrictRep)) || isParent;

  const userAccountMenuItems = useMemo<MenuItem[]>(() => {
    const navigateToProfileSelect = () => {
      navigate(routesDictionary.selectProfile.url);
    };

    const list: MenuItem[] = [
      {
        id: 'sign-out',
        label: 'Sign Out',
        icon: <LogoutIcon />,
        onClick: logout,
        'data-hoot-canary-id': 'btnLogout',
      },
    ];

    if (showProfileSelector) {
      list.unshift({
        id: 'switch-profile',
        label: 'Switch Profile',
        icon: <Icon name="account_circle_filled" color="primary.200" />,
        onClick: navigateToProfileSelect,
      });
    }
    return list;
  }, [logout, navigate, showProfileSelector]);

  const onShowUserAccountMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setUserAccountAnchorElement(event.currentTarget);
  };

  const onDismissUserAccountMenu = () => {
    setUserAccountAnchorElement(null);
  };

  return (
    <>
      <MuiAppBar position="static" sx={{ borderColor: hootTokens.surface[4] }} {...appBarProps}>
        <Toolbar
          sx={{
            position: 'relative',
            display: 'flex',
            justifyContent: 'space-between',
            gap: theme.spacing(2),
            displayPrint: 'none',
            padding: '16px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              zIndex: 1,
              flex: 1,
              mr: 'auto',
            }}
          >
            <Tooltip title={!!inLesson ? 'Menu disabled while in lesson' : undefined}>
              <span style={{ cursor: !!inLesson ? 'not-allowed' : 'default' }}>
                <IconButton
                  disabled={!!inLesson}
                  variant="standard"
                  sx={{ color: 'black', marginLeft: '-10px' }}
                  onClick={() => setIsMenuOpen((prev) => !prev)}
                >
                  <Icon name="menu" />
                </IconButton>
              </span>
            </Tooltip>
            <NavMenuDrawer
              isOpen={isMenuOpen}
              setIsOpen={setIsMenuOpen}
              profileHeaderProps={{
                profileName: fullName,
                profileType: accountType,
              }}
              navMenuList={filteredNavList(navList, isDesktop)}
            />
          </Box>

          <Box sx={{ display: 'flex', flex: 1, justifyContent: 'center' }}>
            {inLesson ? (
              <img src="/images/logo-v2.svg" alt="logo" height="40px" width="120px" />
            ) : (
              <Link to={routesDictionary.home.url}>
                <img src="/images/logo-v2.svg" alt="logo" height="40px" width="120px" />
              </Link>
            )}
          </Box>

          <Box sx={{ display: 'flex', flex: 1, justifyContent: 'flex-end', ml: 'auto' }}>
            {isDesktop ? (
              <Box display={'flex'} alignItems={'center'} gap={'8px'}>
                <Tooltip title={!!inLesson ? 'Disabled while in lesson' : undefined}>
                  <span style={{ cursor: !!inLesson ? 'not-allowed' : 'default' }}>
                    <Button
                      id="user-account-button"
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        gap: 1.25,
                        padding: 0,
                        [theme.breakpoints.down('md')]: {
                          textAlign: 'left',
                        },
                      }}
                      onClick={onShowUserAccountMenu}
                      disabled={!!inLesson}
                      data-hoot-canary-id="btnOpenMenu"
                    >
                      <Icon name="account_circle_filled" color="primary" />
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'flex-start',
                          color: `${hootTokens.palette.black}`,
                        }}
                      >
                        <HootTypography isPII={true} variant="bodymedium">
                          {fullName}
                        </HootTypography>
                        <HootTypography isPII={false} variant="bodysmall">
                          {accountTypeLabel}
                        </HootTypography>
                      </Box>
                      <Chevron sx={{ rotate: '270deg' }} />
                    </Button>
                  </span>
                </Tooltip>
              </Box>
            ) : (
              <Tooltip title={!!inLesson ? 'Disabled while in lesson' : undefined}>
                <span>
                  <IconButton variant="standard" id="user-account-button" onClick={onShowUserAccountMenu} disabled={!!inLesson}>
                    <Icon name="account_circle_filled" />
                  </IconButton>
                </span>
              </Tooltip>
            )}
          </Box>
          <PopoverMenu
            id="user-account-menu"
            variant="dark"
            anchorEl={userAccountAnchorElement}
            open={showUserAccountMenu}
            onClose={onDismissUserAccountMenu}
            items={userAccountMenuItems}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
          />
        </Toolbar>
      </MuiAppBar>
      <JoinLessonBanner />
      <NewAppVersionAvailableBanner />
    </>
  );
};

export default AppBar;
