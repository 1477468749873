import { Stack, SxProps, Theme } from '@mui/material';
import React from 'react';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';
import HootTypography from './HootTypography';
import { Icon } from './Icon';
import IconButton from './IconButton';
import TextField, { TextFieldProps } from './TextField';

type NumberFieldProps = Omit<TextFieldProps, 'helperText'> & {
  label: string;
  value: number | string;
  onChange: (val: number | string) => void;
  helperText?: React.ReactNode;
  disableDecrementButton?: boolean;
  disableIncrementButton?: boolean;
  min?: number;
  max?: number;
  sxTextField?: SxProps<Theme>;
};

const NumberField = (props: NumberFieldProps) => {
  const { label, value, onChange, helperText, disableDecrementButton, disableIncrementButton, min, max, sxTextField, ...restTextField } = props;

  const handleChange = (val: string) => {
    const parsed = val ? parseInt(val, 10) : '';
    if (parsed === '' || !isNaN(parsed)) {
      onChange(parsed);
    }
  };

  const handleIncrement = () => {
    const newValue = (value === '' ? 0 : Number(value)) + 1;
    if (max === undefined || newValue <= max) {
      onChange(newValue);
    }
  };

  const handleDecrement = () => {
    const newValue = (value === '' ? 0 : Number(value)) - 1;
    if (min === undefined || newValue >= min) {
      onChange(newValue);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const numVal = Number(value);

    if (event.key === 'ArrowUp' && max !== undefined && numVal >= max) {
      event.preventDefault();
    }
    if (event.key === 'ArrowDown' && min !== undefined && numVal <= min) {
      event.preventDefault();
    }
  };

  return (
    <Stack>
      <Stack flexDirection="row" alignItems="center" gap={2}>
        <TextField
          type="number"
          label={label}
          value={value}
          onChange={(e) => handleChange(e.target.value)}
          onKeyDown={handleKeyDown}
          InputProps={{
            inputMode: 'numeric',
            readOnly: props.readOnly,
            sx: {
              '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
                display: 'none',
              },
              '& input[type=number]': {
                MozAppearance: 'textfield',
              },
            },
          }}
          {...restTextField}
          sx={{ ...sxTextField }}
        />

        {/* Increment/Decrement Buttons */}
        <Stack flexDirection="row" gap={1}>
          <IconButton
            variant="contained"
            onClick={handleDecrement}
            disabled={disableDecrementButton || (min !== undefined && Number(value) <= min)}
            sx={{ width: 42, height: 42, borderRadius: '4px' }}
          >
            <Icon name="subtract_icon" />
          </IconButton>

          <IconButton
            variant="contained"
            onClick={handleIncrement}
            disabled={disableIncrementButton || (max !== undefined && Number(value) >= max)}
            sx={{ width: 42, height: 42, borderRadius: '4px' }}
          >
            <Icon name="add" />
          </IconButton>
        </Stack>
      </Stack>

      {/* Helper/Error Messages */}
      {helperText && (
        <HootTypography
          variant="labelsmall"
          isPII={false}
          sx={{
            margin: '3px 16px 0px',
            color: `${props.error ? hootTokens.palette.error[80] : 'inherit'}`,
          }}
        >
          {helperText}
        </HootTypography>
      )}
    </Stack>
  );
};

export default NumberField;
