import { ScheduledLessonStatus } from '@hoot-reading/hoot-core/dist/enums/scheduled-lesson';
import { Stack, Tabs, Typography, useMediaQuery, useTheme } from '@mui/material';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import React from 'react';
import { createSearchParams, useNavigate } from 'react-router-dom';
import useDistrictRepScheduledLessonsQuery, {
  DistrictRepLessonsQuery,
} from '@hoot/hooks/api/district-rep/useDistrictRepMonthlyEnrolmentsLessonsQuery';
import useWeeklyDistrictRepScheduledLessonsQuery, {
  WeeklyDistrictRepScheduledLessonResults,
} from '@hoot/hooks/api/district-rep/useDistrictRepWeeklyEnrolmentsLessons';
import useProfile from '@hoot/hooks/useProfile';
import { LessonDatesStats } from '@hoot/models/api/lessons';
import { routesDictionary } from '@hoot/routes/routesDictionary';
import ViewStateIllustration, { IllustrationEnum } from '@hoot/ui/components/v2/ViewStateIllustration';
import { Checkbox } from '@hoot/ui/components/v2/core/Checkbox';
import { Tab } from '@hoot/ui/components/v2/core/Tab';
import { CalendarV3 } from '@hoot/ui/components/v2/schedule/CalendarV3';
import { Events, TIME_INTERVAL_IN_MINUTES, WeeklyScheduleTable } from '@hoot/ui/components/v2/schedule/WeeklyScheduleTable';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';
import { TIME_FORMAT } from '@hoot/utils/date';
import Card from '../../../../components/v2/core/Card';
import Page from '../../../../components/v2/core/Page';

enum ScheduleTab {
  WeeklyView = 0,
  MonthlyView = 1,
}

interface WeeklyLessonGrouping {
  count: number;
  isStatusCancelled: boolean;
}

export function DistrictRepSchedule() {
  const [tab, setTab] = useState<ScheduleTab>(ScheduleTab.WeeklyView);
  const [showCancelledEvents, setShowCancelledEvents] = useState(false);
  const [showAllTimeSlots, setShowAllTimeSlots] = useState(false);
  const { profile } = useProfile();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const sub1200px = useMediaQuery(theme.breakpoints.down('lg'));
  const [scheduledLessonsQuery, setScheduledLessonsQuery] = useState<DistrictRepLessonsQuery>();
  const navigate = useNavigate();

  const [calendarDateQuery, setCalendarDateQuery] = useState<DateTime>(
    tab === ScheduleTab.WeeklyView ? DateTime.now().startOf('week') : DateTime.now().startOf('month'),
  );
  // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
  const { data: scheduledLessonsRequest, isLoading: isLessonDataLoading } = useDistrictRepScheduledLessonsQuery(profile?.id!, scheduledLessonsQuery);
  const { data: weeklyScheduledLessonsRequest, isLoading: isWeeklyLessonDataLoading } = useWeeklyDistrictRepScheduledLessonsQuery(
    // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
    profile?.id!,
    scheduledLessonsQuery,
  );

  useEffect(() => {
    if (tab === ScheduleTab.WeeklyView) {
      setCalendarDateQuery(DateTime.now().startOf('week'));
    } else {
      setCalendarDateQuery(DateTime.now().startOf('month'));
    }
  }, [tab]);

  useEffect(() => {
    if (tab === ScheduleTab.WeeklyView) {
      setScheduledLessonsQuery({
        startDate: calendarDateQuery.startOf('week').toMillis(),
        endDate: calendarDateQuery.endOf('week').toMillis(),
      });
    } else {
      setScheduledLessonsQuery({
        startDate: calendarDateQuery.startOf('month').startOf('week').minus({ day: 1 }).toMillis(),
        endDate: calendarDateQuery.endOf('month').endOf('week').minus({ day: 1 }).toMillis(),
      });
    }
  }, [tab, calendarDateQuery]);

  const handleTabChange = (_event: React.SyntheticEvent, newTab: number) => {
    setTab(newTab);
  };

  const handleDateClick = (date: DateTime, isWeeklyTimeSlotClick: boolean) => {
    const dateString = date.toSQLDate();
    const timeString = isWeeklyTimeSlotClick ? date.toFormat(TIME_FORMAT) : null;

    navigate(
      {
        pathname: routesDictionary.schedule.details.url,
        search: createSearchParams(
          !!timeString
            ? {
                date: dateString!,
                time: timeString,
              }
            : {
                date: dateString!,
              },
        ).toString(),
      },
      { state: { fromTimeSlot: isWeeklyTimeSlotClick ? true : false } },
    );
  };

  const handleShowCancelledEventsClick = () => {
    setShowCancelledEvents((currentState) => !currentState);
  };

  const handleShowAllTimeSlotsClick = () => {
    setShowAllTimeSlots((currentState) => !currentState);
  };

  return (
    <Page data-sentry-mask pageTitle="Schedule" noPadding noMaxWidth>
      {isMobile ? (
        <Stack direction="row" justifyContent="center">
          <ViewStateIllustration illustration={IllustrationEnum.ScreenTooSmall} />
        </Stack>
      ) : (
        <>
          <Tabs value={tab} onChange={handleTabChange} variant="fullWidth" sx={{ backgroundColor: hootTokens.palette.white }}>
            <Tab label="Week view" value={ScheduleTab.WeeklyView} isSelected={tab === ScheduleTab.WeeklyView} />
            <Tab label="Month view" value={ScheduleTab.MonthlyView} isSelected={tab === ScheduleTab.MonthlyView} />
          </Tabs>

          <Stack>
            {tab === ScheduleTab.WeeklyView ? (
              <WeeklyView
                onDateClick={handleDateClick}
                showCancelledEvents={showCancelledEvents}
                setShowCancelledEvents={handleShowCancelledEventsClick}
                scheduledLessons={weeklyScheduledLessonsRequest?.lessons ?? []}
                isLessonDataLoading={isWeeklyLessonDataLoading}
                setCalendarDateQuery={setCalendarDateQuery}
                calendarDateQuery={calendarDateQuery}
                showAllTimeSlots={showAllTimeSlots}
                setShowAllTimeSlots={handleShowAllTimeSlotsClick}
                sub1200px={sub1200px}
              />
            ) : null}
            {tab === ScheduleTab.MonthlyView ? (
              <MonthlyView
                onDateClick={handleDateClick}
                scheduledLessons={scheduledLessonsRequest?.lessonDates ?? []}
                isLessonDataLoading={isLessonDataLoading}
                setCalendarDateQuery={setCalendarDateQuery}
                calendarDateQuery={calendarDateQuery}
                showCancelledEvents={showCancelledEvents}
                setShowCancelledEvents={handleShowCancelledEventsClick}
                sub1200px={sub1200px}
              />
            ) : null}
          </Stack>
        </>
      )}
    </Page>
  );
}

function WeeklyView(props: {
  onDateClick: (date: DateTime, isWeeklyTimeSlotClick: boolean) => void;
  showCancelledEvents: boolean;
  setShowCancelledEvents: () => void;
  scheduledLessons: WeeklyDistrictRepScheduledLessonResults[];
  isLessonDataLoading: boolean;
  setCalendarDateQuery: (val: DateTime) => void;
  calendarDateQuery: DateTime;
  showAllTimeSlots: boolean;
  setShowAllTimeSlots: () => void;
  sub1200px: boolean;
}) {
  const handleSelectedItem = (dayOfWeek: DateTime) => {
    props.onDateClick(dayOfWeek, true);
  };

  const handleStartOfWeekChange = (updatedVal: DateTime) => {
    props.setCalendarDateQuery(updatedVal);
  };

  const TimeSlotDetails = (dayTimeOfWeek: DateTime) => {
    return (
      <WeeklyTimeSlotDetails
        dayTimeOfWeek={dayTimeOfWeek}
        lessons={props.scheduledLessons}
        showCancelledEvents={props.showCancelledEvents}
        sub1200px={props.sub1200px}
      />
    );
  };

  const events: Events[] = props.scheduledLessons.map((l) => {
    return {
      startsAt: l.startsAt,
      isCancelled: l.lessonStatus === ScheduledLessonStatus.Cancelled || l.lessonStatus === ScheduledLessonStatus.Rescheduled,
    };
  });

  return (
    <Stack padding="16px">
      <Card isLoading={false}>
        <WeeklyScheduleTable
          isLoading={props.isLessonDataLoading}
          startOfWeek={props.calendarDateQuery}
          onStartOfWeekChange={handleStartOfWeekChange}
          timeSlotDetails={TimeSlotDetails}
          onTimeSlotClick={handleSelectedItem}
          showAllTimeSlots={props.showAllTimeSlots}
          showCancelledEvents={props.showCancelledEvents}
          events={events}
          header={
            <WeeklyViewHeader
              showCancelled={props.showCancelledEvents}
              setShowCancelled={props.setShowCancelledEvents}
              showAllTimeSlots={props.showAllTimeSlots}
              setShowAllTimeSlots={props.setShowAllTimeSlots}
            />
          }
        />
      </Card>
    </Stack>
  );
}

function WeeklyViewHeader(props: {
  showCancelled: boolean;
  setShowCancelled: () => void;
  showAllTimeSlots: boolean;
  setShowAllTimeSlots: () => void;
}) {
  return (
    <Stack sx={{ m: '16px', backgroundColor: hootTokens.surface[2], borderRadius: '4px', justifyContent: 'center' }}>
      <Checkbox label="Show All Time Slots" checked={props.showAllTimeSlots} onClick={props.setShowAllTimeSlots} />
      <Checkbox label="Show Cancelled Events" checked={props.showCancelled} onClick={props.setShowCancelled} />
    </Stack>
  );
}

function WeeklyTimeSlotDetails(props: {
  dayTimeOfWeek: DateTime;
  lessons: WeeklyDistrictRepScheduledLessonResults[];
  showCancelledEvents: boolean;
  sub1200px: boolean;
}) {
  const startOfInterval = props.dayTimeOfWeek.toMillis();
  const endOfInterval = props.dayTimeOfWeek.plus({ minute: TIME_INTERVAL_IN_MINUTES }).toMillis();
  const lessons = props.lessons?.filter((s) => s.startsAt >= startOfInterval && s.startsAt < endOfInterval) ?? [];

  const lessonsDataToDisplay: WeeklyLessonGrouping[] = organizeLessonsByStatus(lessons, props.showCancelledEvents);

  return (
    <>
      {lessonsDataToDisplay.map((l) => (
        <LessonContainer
          key={`${props.dayTimeOfWeek.toFormat(TIME_FORMAT)}-${l.isStatusCancelled ? 'cancelled-lessons' : 'lessons'}`}
          lessonCount={l.count}
          isCancelledLessonCount={l.isStatusCancelled}
          sub1200px={props.sub1200px}
        />
      ))}
    </>
  );
}

function organizeLessonsByStatus(lessons: WeeklyDistrictRepScheduledLessonResults[], showCancelledEvents: boolean): WeeklyLessonGrouping[] {
  const weeklyLessonGrouping: WeeklyLessonGrouping[] = [];

  const nonCancelledLessonCount = lessons.filter(
    (l) => ![ScheduledLessonStatus.Cancelled, ScheduledLessonStatus.Rescheduled].includes(l.lessonStatus),
  ).length;
  const cancelledLessonCount = lessons.filter((l) =>
    [ScheduledLessonStatus.Cancelled, ScheduledLessonStatus.Rescheduled].includes(l.lessonStatus),
  ).length;

  if (nonCancelledLessonCount > 0) {
    weeklyLessonGrouping.push({
      count: nonCancelledLessonCount,
      isStatusCancelled: false,
    });
  }
  if (showCancelledEvents && cancelledLessonCount > 0) {
    weeklyLessonGrouping.push({
      count: cancelledLessonCount,
      isStatusCancelled: true,
    });
  }

  return weeklyLessonGrouping;
}

// ===== Monthly Schedule functions =====
function MonthlyView(props: {
  onDateClick: (date: DateTime, isWeeklyTimeSlotClick: boolean) => void;
  scheduledLessons: LessonDatesStats[];
  isLessonDataLoading: boolean;
  setCalendarDateQuery: (val: DateTime) => void;
  calendarDateQuery: DateTime;
  showCancelledEvents: boolean;
  setShowCancelledEvents: () => void;
  sub1200px: boolean;
}) {
  const handleMonthChange = (newVal: DateTime) => {
    props.setCalendarDateQuery(newVal);
  };

  const handleOnDateClick = (date: DateTime) => () => {
    props.onDateClick(date, false);
  };

  const OnRenderCalendarDate = (date: DateTime) => {
    if (props.scheduledLessons.length === 0) {
      return null;
    }

    const todaysLessons = props.scheduledLessons.find((ld) => ld.date === date.toSQLDate());

    const cancelledLessonCount = props.showCancelledEvents
      ? (todaysLessons?.dayDetails.statuses.find(
          (s) => s.status === ScheduledLessonStatus.Cancelled || s.status === ScheduledLessonStatus.Rescheduled,
        )?.count ?? null)
      : null;

    const lessonCount =
      todaysLessons?.dayDetails.statuses
        .filter((s) => s.status !== ScheduledLessonStatus.Cancelled && s.status !== ScheduledLessonStatus.Rescheduled)
        ?.map((d) => parseInt(d.count.toString()))
        ?.reduce((a, b) => a + b, 0) ?? 0;

    return (
      <Stack spacing="16px" sx={{ width: '100%' }}>
        {lessonCount ? <LessonContainer lessonCount={lessonCount} isCancelledLessonCount={false} sub1200px={props.sub1200px} /> : null}
        {cancelledLessonCount ? (
          <LessonContainer lessonCount={cancelledLessonCount} isCancelledLessonCount={true} sub1200px={props.sub1200px} />
        ) : null}
      </Stack>
    );
  };

  return (
    <Stack padding="16px">
      <Card isLoading={false}>
        <CalendarV3
          isLoading={props.isLessonDataLoading}
          month={props.calendarDateQuery}
          onMonthChange={handleMonthChange}
          renderCalendarDate={OnRenderCalendarDate}
          onDateClick={handleOnDateClick}
          header={<MonthlyViewHeader showCancelled={props.showCancelledEvents} setShowCancelled={props.setShowCancelledEvents} />}
        />
      </Card>
    </Stack>
  );
}

function MonthlyViewHeader(props: { showCancelled: boolean; setShowCancelled: () => void }) {
  return (
    <Stack sx={{ m: '16px', backgroundColor: hootTokens.surface[2], borderRadius: '4px', justifyContent: 'center' }}>
      <Checkbox label="Show Cancelled Events" checked={props.showCancelled} onClick={props.setShowCancelled} />
    </Stack>
  );
}

function LessonContainer(props: { lessonCount: number; isCancelledLessonCount: boolean; sub1200px: boolean }) {
  return (
    <Stack
      sx={{
        backgroundColor: props.isCancelledLessonCount ? hootTokens.palette.error[160] : hootTokens.palette.secondary[160],
        boxShadow: hootTokens.elevation.elevation1,
        borderRadius: '8px',
        padding: '8px 24px',
        cursor: 'pointer',
      }}
      direction="row"
      alignItems="center"
      justifyContent="center"
      spacing="4px"
    >
      <Typography variant="tableheadingactive">{props.lessonCount}</Typography>
      {props.sub1200px ? null : <Typography variant="labelsmall">{props.isCancelledLessonCount ? 'Cancelled' : 'Lessons'}</Typography>}
    </Stack>
  );
}
