import { useMediaQuery } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { Stack } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { IMAGE_UPLOAD_URL, W9_URL } from '@hoot/constants/constants';
import { UpdatedTeacherAccount, useUpdateTeacherProfile } from '@hoot/hooks/api/profile/useTeacherProfile';
import { QueryKey } from '@hoot/hooks/api/queryKeys';
import { usePageTitle } from '@hoot/hooks/usePageTitle';
import { useBlocker } from '@hoot/hooks/usePrompt';
import { useRedirectProfile } from '@hoot/hooks/useRedirectProfile';
import { User, UserProfileType } from '@hoot/models/api/user';
import { createFlashMessage } from '@hoot/redux/reducers/flashMessageSlice';
import { useAppDispatch } from '@hoot/redux/store';
import SubHeader from '@hoot/ui/components/v2/SubHeader';
import { Button } from '@hoot/ui/components/v2/core/Button';
import Card from '@hoot/ui/components/v2/core/Card';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';
import { Icon } from '@hoot/ui/components/v2/core/Icon';
import BasicAlertDialog from '@hoot/ui/components/v2/dialogs/BasicAlertDialog';
import ChangePasswordDialog from '@hoot/ui/components/v2/dialogs/ChangePasswordDialog';
import { useAuth } from '@hoot/ui/context/AuthContext';
import { useCookieConsent } from '@hoot/ui/context/CookieConsentContext';
import theme from '@hoot/ui/theme/v2';
import AccountDetails from './AccountDetails';
import BankingDetails from './BankingDetails';
import DisplayDetails from './DisplayDetails';
import EmergencyContactDetails from './EmergencyContactDetails';

interface Props {
  user: User;
}

export interface TeacherProfileForm {
  bio: string;
  displayName: string;
  firstName: string;
  lastName: string;
  email: string;
  streetAddress: string;
  city: string;
  provinceState: string;
  country: string;
  postalZipCode: string;
  timeZone: string;
  branchNumber: string;
  institutionNumber: string;
  accountNumber: string;
  identificationNumber: string;
  emergencyContactFirstName: string;
  emergencyContactLastName: string;
  emergencyContactEmail: string;
  emergencyContactPhoneNumber: string;
}

const getFormValues: (user: User) => Partial<TeacherProfileForm> = (user: User) => {
  const formValues: Partial<TeacherProfileForm> = {
    bio: user.teacherAccount?.bio ?? '',
    displayName: user.teacherAccount?.displayName ?? '',
    firstName: user.firstName ?? '',
    lastName: user.lastName ?? '',
    email: user.email ?? '',
    streetAddress: user.streetAddress,
    city: user.city ?? '',
    provinceState: user.provinceState ?? '',
    country: user.country ?? '',
    postalZipCode: user.postalZipCode,
    timeZone: user.timeZone,
    institutionNumber: user.teacherAccount?.institutionNumber?.toString(),
    branchNumber: user.teacherAccount?.branchNumber?.toString(),
    accountNumber: user.teacherAccount?.accountNumber?.toString(),
    identificationNumber: user.teacherAccount?.identificationNumber?.toString(),
    emergencyContactFirstName: user.teacherAccount?.emergencyContactFirstName,
    emergencyContactLastName: user.teacherAccount?.emergencyContactLastName,
    emergencyContactEmail: user.teacherAccount?.emergencyContactEmail,
    emergencyContactPhoneNumber: user.teacherAccount?.emergencyContactPhoneNumber,
  };
  return formValues;
};

const TeacherProfile = (props: Props) => {
  const { user } = props;
  const { handleSubmit, control, reset, setValue, clearErrors, watch, formState } = useForm<TeacherProfileForm>({
    defaultValues: getFormValues(user),
  });

  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();

  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));

  const [closeWithoutSavingDialogOpen, setCloseWithoutSavingDialogOpen] = React.useState<boolean>(false);
  const [showChangePasswordDialog, setShowChangePasswordDialog] = React.useState<boolean>(false);

  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [blocked, proceed] = useBlocker(isDirty);
  const watchCountry = watch('country');
  const { showModal } = useCookieConsent();

  useEffect(() => {
    setCloseWithoutSavingDialogOpen(blocked);
  }, [blocked]);

  const readOnlyAccount =
    !!user.firstName &&
    !!user.lastName &&
    !!user.email &&
    !!user.country &&
    !!user.provinceState &&
    !!user.streetAddress &&
    !!user.city &&
    !!user.postalZipCode;

  const readOnlyBanking =
    (!!user.teacherAccount?.institutionNumber || watchCountry === 'US') &&
    !!user.teacherAccount?.branchNumber &&
    !!user.teacherAccount?.accountNumber &&
    !!user.teacherAccount?.identificationNumber;

  const readOnlyEmergencyContact =
    !!user.teacherAccount?.emergencyContactFirstName &&
    !!user.teacherAccount?.emergencyContactLastName &&
    !!user.teacherAccount?.emergencyContactEmail &&
    !!user.teacherAccount?.emergencyContactPhoneNumber;

  const updateTeacherProfileMutation = useUpdateTeacherProfile(user.id);

  const onSubmit = (data: TeacherProfileForm) => {
    const {
      bio,
      displayName,
      streetAddress,
      city,
      provinceState,
      country,
      postalZipCode,
      timeZone,
      branchNumber,
      institutionNumber,
      accountNumber,
      identificationNumber,
      emergencyContactFirstName,
      emergencyContactLastName,
      emergencyContactEmail,
      emergencyContactPhoneNumber,
    } = data;

    const updatedTeacherAccount: UpdatedTeacherAccount = {
      bio: bio === '' || !bio ? null : bio,
      displayName,
      branchNumber: parseInt(branchNumber),
      institutionNumber: parseInt(institutionNumber),
      accountNumber: parseInt(accountNumber),
      identificationNumber: parseInt(identificationNumber),
      streetAddress,
      city: city === '' || !city ? null : city,
      provinceState: provinceState === '' || !provinceState ? null : provinceState,
      country: country === '' || !country ? null : country,
      postalZipCode: !!postalZipCode ? postalZipCode : null,
      timeZone: !!timeZone ? timeZone : null,
      emergencyContactFirstName,
      emergencyContactLastName,
      emergencyContactEmail,
      emergencyContactPhoneNumber,
    };

    updateTeacherProfileMutation.mutate(updatedTeacherAccount, {
      onSuccess: () => {
        setCloseWithoutSavingDialogOpen(false);
        reset({
          bio,
          displayName,
          streetAddress,
          city,
          provinceState,
          country,
          postalZipCode,
          timeZone,
          branchNumber,
          institutionNumber,
          accountNumber,
          identificationNumber,
          emergencyContactFirstName,
          emergencyContactLastName,
          emergencyContactPhoneNumber,
          emergencyContactEmail,
        });
        queryClient.invalidateQueries(QueryKey.FetchUser);
        setIsDirty(false);
        dispatch(createFlashMessage({ message: 'Details Updated Successfully' }));
      },
      onError: (err) => {
        console.error(err);
        dispatch(createFlashMessage({ message: 'Could not update teacher profile information!' }));
      },
    });
  };

  const handleNavigateAway = (e: BeforeUnloadEvent) => {
    e.preventDefault();
    e.returnValue = '';
  };

  useEffect(() => {
    if (isDirty) {
      window.addEventListener('beforeunload', handleNavigateAway);
      return () => {
        window.removeEventListener('beforeunload', handleNavigateAway);
      };
    }
  }, [isDirty]);

  useEffect(() => {
    setIsDirty(formState.isDirty);
  }, [formState.isDirty, setIsDirty]);

  useEffect(() => {
    reset(getFormValues(user));
  }, [reset, user]);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <SubHeader
          title={{
            label: 'Teacher Profile Settings',
            isPII: false,
          }}
          primaryAction={
            isDesktop
              ? {
                  label: 'Save',
                  props: {
                    isLoading: updateTeacherProfileMutation.isLoading,
                    disabled: !isDirty || Object.keys(formState.errors).length > 0,
                    type: 'submit',
                  },
                }
              : undefined
          }
        />

        <Grid container padding={2}>
          <Grid size={{ xs: 12, lg: 8 }}>
            <Stack gap={'16px'}>
              <DisplayDetails control={control} />
              <AccountDetails readonly={readOnlyAccount} control={control} watch={watch} setValue={setValue} clearErrors={clearErrors} user={user} />
              <EmergencyContactDetails readonly={readOnlyEmergencyContact} control={control} watch={watch} />
              <BankingDetails readonly={readOnlyBanking} control={control} watch={watch} />
            </Stack>
          </Grid>
          <Grid size={{ xs: 12, lg: 4 }}>
            <Stack gap={'16px'} marginTop={isDesktop ? '0px' : '16px'} marginLeft={isDesktop ? '16px' : '0px'}>
              <Card sx={{ width: '100%' }} title="Password">
                <Button variant="contained" fullWidth color="error.80" onClick={() => setShowChangePasswordDialog(true)}>
                  Change Password
                </Button>
              </Card>
              <Card title="Cookies">
                <HootTypography isPII={false}>
                  We use cookies and other identifiers to ensure the functionality of our app and offer the best possible in-app experience. You can
                  choose for each category to opt in or out and can view your settings. You can learn more about how we use cookies and other
                  identifiers in the Cookies section of our Web App Privacy Policy.
                </HootTypography>
                <Button sx={{ marginTop: ' 16px', display: 'flex', gap: '8px' }} variant="outlined" fullWidth onClick={showModal}>
                  <Icon name="new_tab" /> Manage Cookies
                </Button>
              </Card>
              <Card title="External Forms">
                <Stack gap={'16px'}>
                  {watchCountry === 'US' ? (
                    <Button
                      variant="text"
                      sx={{ display: 'flex', gap: '8px', justifyContent: 'flex-start', boxShadow: 'none' }}
                      onClick={() => window.open(W9_URL.concat(`?first=${user.firstName}&last=${user.lastName}&email=${user.email}`))}
                    >
                      <Icon name="new_tab" /> Open W9 Form
                    </Button>
                  ) : null}
                  <Button
                    variant="text"
                    sx={{ display: 'flex', gap: '8px', justifyContent: 'flex-start', boxShadow: 'none' }}
                    onClick={() => window.open(IMAGE_UPLOAD_URL.concat(`?first=${user.firstName}&last=${user.lastName}&email=${user.email}`))}
                  >
                    <Icon name="new_tab" /> Display Image Upload
                  </Button>
                </Stack>
              </Card>
              {!isDesktop ? (
                <Button
                  isLoading={updateTeacherProfileMutation.isLoading}
                  type="submit"
                  variant="contained"
                  disabled={!isDirty || Object.keys(formState.errors).length > 0}
                  fullWidth
                >
                  Save
                </Button>
              ) : null}
            </Stack>
          </Grid>
        </Grid>
      </form>
      <BasicAlertDialog
        show={closeWithoutSavingDialogOpen}
        onDismiss={() => setCloseWithoutSavingDialogOpen(false)}
        title={'Discard?'}
        content={
          <HootTypography isPII={false} variant="bodylarge">
            Closing without saving will discard your changes
          </HootTypography>
        }
        primaryAction={{
          label: 'Save',
          onClick: handleSubmit(onSubmit),
          props: {
            variant: 'contained',
          },
        }}
        secondaryAction={{
          label: 'Discard',
          onClick: () => {
            setCloseWithoutSavingDialogOpen(false);
            reset();
            proceed();
          },
        }}
      />
      <ChangePasswordDialog show={showChangePasswordDialog} onClose={() => setShowChangePasswordDialog(false)} />
    </>
  );
};

const ProfileWrapper = () => {
  usePageTitle('Profile | Hoot Reading');
  useRedirectProfile(UserProfileType.Parent);
  useRedirectProfile(UserProfileType.Student);
  const { getUser } = useAuth();

  return <TeacherProfile user={getUser()} />;
};

export default ProfileWrapper;
