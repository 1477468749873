import axios from 'axios';
import { useQuery } from 'react-query';
import { config } from '@hoot/config';
import { QueryKey } from '@hoot/hooks/api/queryKeys';
import { UseGetOptions } from '@hoot/models/api/axios-options';

export interface InstructionalUnitDocumentationResponse {
  unit: {
    id: string;
    slug: string;
    name: string;
  };
  documentationHtmlContent: string | undefined;
}

export default function useGetInstructionalUnitDocumentation(unitSlug: string, options?: UseGetOptions<InstructionalUnitDocumentationResponse>) {
  return useQuery(
    [QueryKey.GetInstructionalUnitDocumentation, unitSlug],
    async () => {
      return (await axios.get<InstructionalUnitDocumentationResponse>(`${config.apiUrl}/library/instructional-unit/${unitSlug}/documentation`)).data;
    },
    options,
  );
}
