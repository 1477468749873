import axios from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '@hoot/config';
import { QueryKey } from '../queryKeys';

async function checkTeacherEmergencyContactInfoStatus(teacherId: string): Promise<boolean> {
  const { data } = await axios.get<boolean>(`${config.apiUrl}/public/teacher-account/${teacherId}/emergency-info-status`);
  return data;
}

export default function useCheckTeacherEmergencyContactInfoStatus(
  teacherId: string,
  options?: Omit<UseQueryOptions<boolean>, 'queryKey' | 'queryFn'>,
) {
  return useQuery([QueryKey.GetTeacherEmergencyContactInfoStatus, teacherId], () => checkTeacherEmergencyContactInfoStatus(teacherId), options);
}
