import { PMAUnitIdentifier } from '@hoot-reading/hoot-core/dist/enums/progress-monitoring-assessment';
import { Stack } from '@mui/system';
import _ from 'lodash';
import { useEffect } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { UnitIdentifierHRAV2 } from '@hoot/models/api/enums/hoot-reading-assessment';
import { useReaders } from '../../../../../../redux/reducers/readingSlice';
import HootTypography from '../../../core/HootTypography';
import NumberField from '../../../core/NumberField';
import ReadOnlyTextField from '../../../core/ReadOnlyTextField';
import TextField from '../../../core/TextField';
import { QuestionnaireContainer } from '../QuestionnaireContainer';
import { QuestionnaireFooter } from '../QuestionnaireFooter';
import { LeaveCurrentUnitDialog } from '../dialogs/LeaveCurrentUnitDialog';

interface Props {
  unitIdentifier: UnitIdentifierHRAV2 | PMAUnitIdentifier;
  title: string;
  instruction: string;
  unitTitle: string;
  value?: Questionnaire4Form;
  leaveDialog: boolean;
  setLeaveDialog: (val: boolean) => void;
  isNextEnabled: boolean;
  isPrevEnabled: boolean;
  isSubmitting: boolean;
  onAllQuestionsAnswered: () => void;
  onPreviousUnitClick: () => void;
  onSubmit: (data: Questionnaire4Submission) => void;
  onNext?: () => void;
  confirmPrevUnitClick: () => void;
  isUnitQuestionnaireDirty: boolean;
  isDirty: boolean;
  setIsDirty: (isDirty: boolean) => void;
  onExitAssessment: () => void;
}

export interface Questionnaire4Form {
  time: string;
  wordCount: number;
  errors: number;
}

export interface Questionnaire4Submission {
  time: string;
  timeInSeconds: number;
  wordCount: number;
  errors: number;
  wcpm: number;
  accuracyRate: number;
  bookId: string;
  bookTitle: string;
}

export function QuestionnaireLayout4(props: Props) {
  const { control, watch, formState, getValues, trigger, handleSubmit } = useForm<Questionnaire4Form>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      errors: props.value?.errors,
      wordCount: props.value?.wordCount,
      time: props.value?.time,
    },
  });

  const {
    inLessonReader: { book },
  } = useReaders();

  useEffect(() => {
    if (props.isDirty !== formState.isDirty) {
      props.setIsDirty(formState.isDirty);
    }
  }, [formState.isDirty, props]);

  const wordCount = watch('wordCount') ?? 0;
  const errors = watch('errors') ?? 0;
  const time = watch('time');

  function extractMinutes() {
    const splitTime = time?.split(':') ?? [];
    if (splitTime.length === 2) {
      return Number(splitTime[0]);
    }
    return 0;
  }

  function extractSeconds() {
    const splitTime = time?.split(':') ?? [];
    if (splitTime.length === 2) {
      return Number(splitTime[1]);
    }
    return 0;
  }

  const timeInMinutes = extractMinutes();
  const timeInSeconds = extractSeconds();

  // Calculation: (Word Count - Errors) * 100 / Word Count
  const accuracyRate = Math.round(wordCount > 0 ? ((wordCount - errors) * 100) / wordCount : 0);

  // Calculation: (Word Count - Errors) * 60 / (Time in minutes * 60 + Time in seconds)
  const wordsCorrectPerMinute = Math.round(
    timeInMinutes > 0 || timeInSeconds > 0 ? ((wordCount - errors) * 60) / (timeInMinutes * 60 + timeInSeconds) : 0,
  );

  const onSubmit: SubmitHandler<Questionnaire4Form> = (data) => {
    if (!props.onNext || formState.isDirty) {
      if (book) {
        const _timeInSeconds = timeInMinutes * 60 + timeInSeconds;
        props.onSubmit({
          accuracyRate: accuracyRate,
          bookId: book.id,
          bookTitle: book.title,
          errors: data.errors,
          time: data.time,
          timeInSeconds: _timeInSeconds,
          wordCount: data.wordCount,
          wcpm: wordsCorrectPerMinute,
        });
      }
    } else {
      if (props.onNext) {
        props.onNext();
      }
    }
  };

  const allFormFieldsFilledOut = !!time && !!wordCount && !!errors && _.isEmpty(formState.errors);
  const disableSubmissionBtn = !allFormFieldsFilledOut || props.isSubmitting;
  const primaryButtonLabel = !disableSubmissionBtn && !formState.isDirty ? 'Continue' : undefined;

  return (
    <QuestionnaireContainer
      unitIdentifier={props.unitIdentifier}
      title={props.title}
      instruction={props.instruction}
      unitTitle={props.unitTitle}
      onExitAssessment={props.onExitAssessment}
      showBook
    >
      <Stack gap={4} paddingY={3}>
        <Stack flex={1} spacing="16px">
          <Stack gap={2}>
            <Controller
              name="time"
              control={control}
              rules={{
                required: 'Time is required.',
                pattern: {
                  value: /^[0-5]?[0-9]:[0-5][0-9]$/,
                  message: 'Time needs to be in the format 59:59',
                },
              }}
              render={({ field, fieldState }) => (
                <TextField
                  placeholder="MM:SS"
                  onChange={field.onChange}
                  value={field.value}
                  label="Time"
                  required
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  sx={{ width: 250 }}
                />
              )}
            />

            <Controller
              name="wordCount"
              control={control}
              rules={{
                required: 'Word count is required',
                min: {
                  value: 0,
                  message: 'Value needs to be greater than 0',
                },
                validate: (value) => !value || value >= Number(getValues('errors')) || 'Cannot have a smaller word count than the number of errors.',
              }}
              render={({ field, fieldState }) => (
                <TextField
                  placeholder="Word Count"
                  onChange={(val) => {
                    field.onChange(val);
                    trigger('errors');
                  }}
                  type="number"
                  value={field.value}
                  label="Word Count"
                  required
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  sx={{ width: 250 }}
                />
              )}
            />

            <Controller
              name="errors"
              control={control}
              rules={{
                required: 'Errors field is required',
                min: {
                  value: 0,
                  message: 'Errors needs to be 0 or higher',
                },
                validate: (value) => !value || value <= Number(getValues('wordCount')) || 'Cannot have more errors than word count.',
              }}
              render={({ field, fieldState }) => (
                <NumberField
                  label="Errors"
                  required
                  onChange={(val) => {
                    field.onChange(val);
                    trigger('wordCount');
                  }}
                  min={0}
                  max={Number(getValues('wordCount'))}
                  value={field.value}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  sxTextField={{ width: 250 }}
                />
              )}
            />
          </Stack>

          <HootTypography variant="bodysmall" isPII={false}>
            Calculated
          </HootTypography>
          <Stack spacing="16px" direction="row">
            <ReadOnlyTextField label="WCPM" body={wordsCorrectPerMinute === 0 ? '-' : wordsCorrectPerMinute} sx={{ width: 180 }} />
            <ReadOnlyTextField label="Accuracy" body={accuracyRate === 0 ? '-' : accuracyRate} sx={{ width: 180 }} />
          </Stack>
        </Stack>
        <QuestionnaireFooter
          allFormFieldsFilledOut={allFormFieldsFilledOut}
          onPreviousUnitClick={props.onPreviousUnitClick}
          setLeaveDialog={props.setLeaveDialog}
          isUnitQuestionnaireDirty={props.isUnitQuestionnaireDirty}
          disableSubmissionBtn={disableSubmissionBtn}
          isSubmitting={props.isSubmitting}
          onNextUnitAndSubmissionClick={handleSubmit(onSubmit)}
          isNextEnabled={props.isNextEnabled}
          isPrevEnabled={props.isPrevEnabled}
          submitButtonLabel={primaryButtonLabel}
        />
        <LeaveCurrentUnitDialog open={props.leaveDialog} setOpen={props.setLeaveDialog} confirmLeave={props.confirmPrevUnitClick} />
      </Stack>
    </QuestionnaireContainer>
  );
}
