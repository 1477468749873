import { StudentGrade } from '@hoot-reading/hoot-core/dist/enums/student-grade';
import { StudentStatus } from '@hoot-reading/hoot-core/dist/enums/user/student/student-status.enum';
import axios, { AxiosError } from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '@hoot/config';
import { DateFilterEnum } from '@hoot/models/api/district-rep-reporting/common';
import { DateRangeFormValues } from '@hoot/ui/components/v2/dialogs/DateRangeDialog';
import { QueryKey } from '../../queryKeys';

export interface StudentProfileMetricsResponse {
  number: number;
  status: StudentStatus;
  grade: StudentGrade;
  schoolName: string;
  totalLessons: number;
  occurredLessons: number;
  attendanceRate: number;
  lateJoinRate: number;
  minutesInLesson: number;
  firstLessonDate: string;
  mostRecentLessonDate: string;
}

export interface DateFilterQuery {
  date?: DateFilterEnum[] | DateRangeFormValues;
}

export default function useGetStudentProfileMetrics(
  studentProfileId: string,
  query: DateFilterQuery,
  options?: Omit<UseQueryOptions<StudentProfileMetricsResponse, AxiosError>, 'queryKey' | 'queryFn'>,
) {
  return useQuery(
    [QueryKey.GetDistrictRepReportingStudentProfileMetrics, studentProfileId, query],
    async () => {
      return (
        await axios.get<StudentProfileMetricsResponse>(
          `${config.apiUrl}/public/district-representative/reporting/student/${studentProfileId}/metrics`,
          {
            params: query,
          },
        )
      ).data;
    },
    options,
  );
}
