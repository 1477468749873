import { TimePicker as MUITimePicker, TimePickerProps } from '@mui/x-date-pickers/TimePicker';
import { DateTime } from 'luxon';
import { TextFieldProps } from '@hoot/ui/components/v2/core/TextField';

type TimeFieldProps = Omit<TimePickerProps<DateTime>, 'onChange' | 'renderInput'> & {
  onChange: (val: DateTime | null) => void;
  helperText?: string;
  error?: boolean;
  textFieldProps?: Partial<TextFieldProps>;
};

function TimePicker(props: TimeFieldProps) {
  const { onChange, helperText, error, textFieldProps, ...timePickerProps } = props;

  return (
    <MUITimePicker
      {...timePickerProps}
      ampm
      orientation="landscape"
      minutesStep={5}
      onChange={onChange}
      slotProps={{
        textField: {
          ...textFieldProps,
          InputProps: {
            placeholder: textFieldProps?.placeholder,
            sx: {
              '& input': {
                padding: '24px 16px 8px',
              },
            },
          },
          helperText: helperText,
          error: error,
        },
        popper: {
          sx: {
            '.MuiDialogActions-root .MuiButtonBase-root': {
              border: 'solid 1px #333333',
            },
          },
        },
      }}
      {...timePickerProps}
    />
  );
}

export default TimePicker;
