import {
  ScheduledLessonLanguage,
  ScheduledLessonStatus,
  ScheduledLessonSubject,
  lessonLanguagesLookup,
  lessonSubjectLookup,
} from '@hoot-reading/hoot-core/dist/enums/scheduled-lesson';
import { Box, Card, Drawer, Stack, useMediaQuery, useTheme } from '@mui/material';
import { capitalCase } from 'change-case';
import { DateTime } from 'luxon';
import moment from 'moment';
import React from 'react';
import { LessonDetails } from '@hoot/hooks/api/lesson/useMonthlyScheduledLessonsQueryV2';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';
import { Icon } from '@hoot/ui/components/v2/core/Icon';
import IconButton from '@hoot/ui/components/v2/core/IconButton';
import ReadOnlyTextField from '@hoot/ui/components/v2/core/ReadOnlyTextField';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';
import { formatTimeZoneForSchedule } from '@hoot/utils/timeZones';

interface IProps {
  setSelectedDayData: React.Dispatch<React.SetStateAction<LessonDetails[] | undefined>>;
  setSelectedDate: React.Dispatch<React.SetStateAction<DateTime<boolean>>>;
  setSelectedCalendarMonth: React.Dispatch<React.SetStateAction<number | undefined>>;
  selectedDate: DateTime;
  data: LessonDetails[] | undefined;
  disallowDateBefore: number;
  disallowDateAfter: number;
  handleMonthClick: (direction: 'increment' | 'decrement') => void;
}

const ScheduleDrawer = (props: IProps) => {
  const {
    setSelectedDayData,
    data,
    disallowDateBefore,
    disallowDateAfter,
    selectedDate,
    setSelectedDate,
    setSelectedCalendarMonth,
    handleMonthClick,
  } = props;

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));

  const userTimeZone = moment.tz.guess();
  const timeZone = formatTimeZoneForSchedule(userTimeZone);

  const cancelledLessons = data?.filter((l) => [ScheduledLessonStatus.Cancelled, ScheduledLessonStatus.Rescheduled].includes(l.lessonStatus)) ?? [];
  const notCancelledLessons =
    data?.filter((l) => ![ScheduledLessonStatus.Cancelled, ScheduledLessonStatus.Rescheduled].includes(l.lessonStatus)) ?? [];
  const sortedLessons = [...notCancelledLessons, ...cancelledLessons];

  const disableLeftArrow = selectedDate.toMillis() <= disallowDateBefore;
  const disableRightArrow = selectedDate.toMillis() >= disallowDateAfter;

  const handleDayChange = (direction: 'increment' | 'decrement') => () => {
    let newDay: DateTime;
    if (direction === 'increment') {
      newDay = selectedDate.plus({ day: 1 });
    } else {
      newDay = selectedDate.minus({ day: 1 });
    }
    setSelectedCalendarMonth(newDay.startOf('month').toMillis());
    setSelectedDate(newDay);
    if (newDay.month !== selectedDate.month) {
      handleMonthClick(direction);
    }
  };

  const handleColor = (status: ScheduledLessonStatus) => {
    const cancelledStatuses = [ScheduledLessonStatus.Cancelled, ScheduledLessonStatus.Rescheduled];
    if (cancelledStatuses.includes(status)) {
      return 'error.190';
    } else {
      return 'success.190';
    }
  };

  return (
    <Drawer anchor="right" open={!!data} onClose={() => setSelectedDayData(undefined)}>
      <Stack minWidth={isDesktop ? '400px' : '100vw'} gap={'24px'} padding={'24px'}>
        <IconButton onClick={() => setSelectedDayData(undefined)} variant="contained" size="small" sx={{ width: '42px', height: '42px' }}>
          <Icon name="close" />
        </IconButton>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            textAlign: 'center',
            alignItems: 'center',
            gap: '16px',
          }}
        >
          <IconButton onClick={handleDayChange('decrement')} sx={{ backgroundColor: hootTokens.palette.neutral[180] }} disabled={disableLeftArrow}>
            <Icon name="chevron" />
          </IconButton>
          <Box>
            <HootTypography isPII={false} variant="bodylarge">
              {selectedDate.toFormat('cccc')}
            </HootTypography>
            <HootTypography isPII={false} variant="displaysmall">
              {selectedDate.toFormat('LLLL')}
            </HootTypography>
            <HootTypography isPII={false} variant="displaysmall">
              {selectedDate.toFormat('d')}
            </HootTypography>
          </Box>
          <IconButton onClick={handleDayChange('increment')} sx={{ backgroundColor: hootTokens.palette.neutral[180] }} disabled={disableRightArrow}>
            <Icon name="chevron" sx={{ rotate: '180deg' }} />
          </IconButton>
        </Box>
        {!!sortedLessons?.length ? (
          sortedLessons.map((d) => (
            <Card key={d.lessonStartTime}>
              <Stack gap={'16px'}>
                <ReadOnlyTextField label={'Student'} body={d.studentName} filledColour={handleColor(d.lessonStatus)} />
                <Box>
                  <ReadOnlyTextField
                    label={'Lesson Time'}
                    body={`${DateTime.fromMillis(d.lessonStartTime, { zone: userTimeZone }).toFormat('h:mm a')} - ${DateTime.fromMillis(
                      d.lessonEndTime,
                      { zone: userTimeZone },
                    ).toFormat('h:mm a')}`}
                    filledColour={handleColor(d.lessonStatus)}
                  />
                  <HootTypography isPII={false} sx={{ marginLeft: '16px' }} variant="labelsmall">
                    {timeZone}
                  </HootTypography>
                </Box>
                <ReadOnlyTextField label={'Teacher'} body={d.teacherName} filledColour={handleColor(d.lessonStatus)} />
                <ReadOnlyTextField label={'Lesson Number'} body={d.lessonNumber} filledColour={handleColor(d.lessonStatus)} />
                {d.lessonSubject ? (
                  <ReadOnlyTextField
                    label={'Subject'}
                    body={lessonSubjectLookup[d.lessonSubject as ScheduledLessonSubject]}
                    filledColour={handleColor(d.lessonStatus)}
                  />
                ) : null}
                {d.lessonLanguage ? (
                  <ReadOnlyTextField
                    label={'Language'}
                    body={lessonLanguagesLookup[d.lessonLanguage as ScheduledLessonLanguage]}
                    filledColour={handleColor(d.lessonStatus)}
                  />
                ) : null}
                <ReadOnlyTextField label={'Status'} body={capitalCase(d.displayedLessonStatus)} filledColour={handleColor(d.lessonStatus)} />
                {d.cancellationReason ? (
                  <ReadOnlyTextField label={'Cancellation Reason'} body={d.cancellationReason} filledColour={handleColor(d.lessonStatus)} />
                ) : null}
              </Stack>
            </Card>
          ))
        ) : (
          <HootTypography isPII={false} sx={{ textAlign: 'center' }}>
            No Scheduled Lessons
          </HootTypography>
        )}
      </Stack>
    </Drawer>
  );
};

export default ScheduleDrawer;
