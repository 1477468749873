import { DateTime } from 'luxon';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';
import { Socket, io } from 'socket.io-client';
import { DistrictRepDailyEnrolmentsLessonsMessageResponse } from '@hoot/events/messages/district-rep-daily-enrolments-lessons-message-response';
import useProfile from '@hoot/hooks/useProfile';
import {
  setRefreshDistrictRepEnrolmentsLessons,
  updateDistrictRepEnrolmentsLessons,
  updateIsDistrictRepEnrolmentsLessonsLoading,
} from '@hoot/redux/reducers/districtRepSlice';
import { createFlashMessage } from '@hoot/redux/reducers/flashMessageSlice';
import { setRefreshScheduledLessons, updateScheduledLessons } from '@hoot/redux/reducers/upcomingLessonsSlice';
import { routesDictionary } from '@hoot/routes/routesDictionary';
import { config } from '../../config';
import { LOCAL_APP_VERSION } from '../../constants/constants';
import { EventType } from '../../events/eventType';
import { AlertMessage } from '../../events/messages/alert.message';
import { LessonEndedMessage } from '../../events/messages/lesson-ended-message';
import { LessonJoinedMessage } from '../../events/messages/lesson-joined.message';
import { NotifyRemovedFromLessonMessage } from '../../events/messages/notify-removed-from-lesson-message';
import { ScheduledLessonsMessageResponse } from '../../events/messages/scheduled-lessons-message-response';
import { LessonUpdatedMessage } from '../../events/messages/updated-lesson.message';
import { handleLessonEndedMessage, joinLesson, leaveLesson, updateInLesson } from '../../redux/reducers/activeLessonSlice';
import { info } from '../../redux/reducers/alertSlice';
import { connected, disconnected, resyncSession } from '../../redux/reducers/applicationSlice';
import { LibraryContext, resetLibrary } from '../../redux/reducers/librarySlice';
import { showReconnectModal } from '../../redux/reducers/modalsSlice';
import { RootState, useAppDispatch } from '../../redux/store';
import { useAuth } from './AuthContext';

interface Props {
  children: React.ReactNode;
}

interface Values {
  socket: Socket;
  connected: boolean;
}

const SocketContext = React.createContext<Values>(undefined!);

const SocketProvider = (props: Props) => {
  const { children } = props;

  const auth = useAuth();
  const dispatch = useAppDispatch();

  const location = useLocation();
  const navigate = useNavigate();

  const { isTeacher, isStudent } = useProfile();

  const profile = useSelector((root: RootState) => root.profile.profile);

  const [socketConnected, setSocketConnected] = useState(false);

  const socket = useRef<Socket>(
    io(config.webSocket + '/v2', {
      auth: {
        token: auth.tokens?.accessToken,
        clientAppVersion: LOCAL_APP_VERSION,
      },
      autoConnect: false,
      transports: ['websocket'],
      forceNew: true,
    }),
  );

  socket.current.on(EventType.ConnectionInitialized, () => {
    setSocketConnected(true);
  });

  socket.current.on(EventType.Disconnect, () => {
    setSocketConnected(false);
  });

  useEffect(() => {
    if (socket.current) {
      socket.current.auth = {
        token: auth.tokens?.accessToken,
        clientAppVersion: LOCAL_APP_VERSION,
      };
    }
  }, [auth.tokens]);

  // ConnectionInitialized
  useEffect(() => {
    const handleConnectionInitialized = () => {
      dispatch(connected(s));
      dispatch(joinLesson({ socket: s }));
      console.log(`Socket connected. Client id: ${s.id}. Was recovered: ${s.recovered}`);
    };

    const s = socket.current;
    s.on(EventType.ConnectionInitialized, handleConnectionInitialized);

    return () => {
      s.off(EventType.ConnectionInitialized, handleConnectionInitialized);
    };
  }, [dispatch]);

  // Disconnect
  useEffect(() => {
    const handleDisconnect = () => {
      dispatch(disconnected(s));
      console.log(`Socket disconnected. Client id: ${s.id}`);
    };

    const s = socket.current;
    s.on(EventType.Disconnect, () => handleDisconnect);

    return () => {
      s.off(EventType.Disconnect, () => handleDisconnect);
    };
  }, [dispatch]);

  // ConnectError
  useEffect(() => {
    const handleConnectError = () => {
      // delay showing modal for a couple of seconds
      setTimeout(() => {
        if (s.disconnected) {
          dispatch(showReconnectModal());
        }
      }, 3000);
      console.log(`ConnectError message received. Client id: ${s.id}`);
    };

    const s = socket.current;
    s.on(EventType.ConnectError, handleConnectError);

    return () => {
      s.off(EventType.ConnectError, handleConnectError);
    };
  }, [dispatch]);

  // ResyncSession
  useEffect(() => {
    const handleResyncSession = () => {
      dispatch(resyncSession(s));
      console.log(`Session resynced. Client id: ${s.id}`);
    };

    const s = socket.current;
    s.on(EventType.ResyncSession, handleResyncSession);

    return () => {
      s.off(EventType.ResyncSession, handleResyncSession);
    };
  }, [dispatch]);

  // NotifyRemovedFromLesson
  useEffect(() => {
    const handleNotifyRemovedFromLesson = (event: NotifyRemovedFromLessonMessage) => {
      const { lesson } = event;

      // e.g. January 19, 2023, 3:18PM CST
      const lessonStartDateTime = DateTime.fromMillis(lesson.startsAt).toFormat('LLLL d, y, h:mm ZZZZ');
      const message = `You are no longer assigned to the ${lessonStartDateTime} ${lesson.subject} lesson with ${lesson.studentName}`;

      // Notify user that he/she has been removed from the lesson.
      dispatch(info(message));
      console.log(`Removed from lesson. Client id: ${s.id} | Lesson id: ${event.lesson.lessonId}`);
    };

    const s = socket.current;
    s.on(EventType.NotifyRemovedFromLesson, handleNotifyRemovedFromLesson);

    return () => {
      s.off(EventType.NotifyRemovedFromLesson, handleNotifyRemovedFromLesson);
    };
  }, [dispatch]);

  // LessonTeacherChanged
  useEffect(() => {
    const handleLessonTeacherChanged = (lessonUpdatedMessage: LessonUpdatedMessage) => {
      dispatch(updateInLesson(lessonUpdatedMessage.lesson));
    };

    const s = socket.current;
    s.on(EventType.LessonTeacherChanged, handleLessonTeacherChanged);
  }, [dispatch]);

  // LessonJoined
  useEffect(() => {
    const handleLessonJoinedMessage = (lessonJoinedMessage: LessonJoinedMessage) => {
      dispatch(updateInLesson(lessonJoinedMessage.lesson));
      console.log(`Lesson joined. Client id: ${s.id} | Lesson id: ${lessonJoinedMessage.lesson.lessonId}`);

      const baseLessonPath = routesDictionary.lesson.url(lessonJoinedMessage.lesson.lessonId);
      const currentPath = location.pathname;
      const pathMatch = matchPath({ path: baseLessonPath, end: false }, currentPath);

      // If we're already in a lesson and somewhere under the /lesson/:id path (specific lesson ID), then we don't need
      // to navigate anywhere. We should already be where we need to be.
      if (!pathMatch) {
        // With in-lesson now set, the /:lessonId path is unlocked. Go to this page. Once loaded, the page's redirect
        // logic will determine the correct URL path to view.
        navigate(routesDictionary.lesson.url(lessonJoinedMessage.lesson.lessonId));
      }
    };

    const s = socket.current;
    s.on(EventType.LessonJoined, handleLessonJoinedMessage);

    return () => {
      s.off(EventType.LessonJoined, handleLessonJoinedMessage);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isTeacher, navigate, isStudent]);

  // LessonEnded
  useEffect(() => {
    const s = socket.current;
    const _handleLessonEnded = (lessonEndedMessage: LessonEndedMessage | undefined) => {
      dispatch(handleLessonEndedMessage({ lessonEndedMessage }));
      console.log(`Lesson ended. Client id: ${s.id} | Lesson id: ${lessonEndedMessage?.lessonId}`);
    };

    s.on(EventType.LessonEnded, _handleLessonEnded);
  }, [dispatch]);

  // User Updated
  useEffect(() => {
    const s = socket.current;

    const handleUserUpdated = async () => {
      await auth.updateLocalUser();
    };

    s.on(EventType.UserUpdated, handleUserUpdated);

    return () => {
      s.off(EventType.UserUpdated, handleUserUpdated);
    };
  }, [auth]);

  // Alert
  useEffect(() => {
    const s = socket.current;

    const handleAlertMessage = (notificationMessage: AlertMessage) => {
      const { type, message } = notificationMessage;
      dispatch(createFlashMessage({ message: message, variant: type === 'error' ? 'error' : undefined }));
    };

    s.on(EventType.Alert, handleAlertMessage);

    return () => {
      s.off(EventType.Alert, handleAlertMessage);
    };
  }, [dispatch]);

  // Logout
  useEffect(() => {
    const s = socket.current;

    const handleLogout = () => {
      auth.logout();
      console.log(`Logged out. Client id: ${s.id}`);
    };

    s.on(EventType.Logout, handleLogout);

    return () => {
      s.off(EventType.Logout, handleLogout);
    };
  }, [auth]);

  // DistrictRepEnrolmentsLessons
  useEffect(() => {
    const s = socket.current;

    const handleDistrictRepEnrolmentsLessons = (districtRepEnrolmentsLessonsMessageResponse: DistrictRepDailyEnrolmentsLessonsMessageResponse) => {
      dispatch(updateDistrictRepEnrolmentsLessons(districtRepEnrolmentsLessonsMessageResponse));
      dispatch(updateIsDistrictRepEnrolmentsLessonsLoading(false));
    };

    s.on(EventType.DistrictRepEnrolmentsLessons, handleDistrictRepEnrolmentsLessons);

    return () => {
      s.off(EventType.DistrictRepEnrolmentsLessons, handleDistrictRepEnrolmentsLessons);
    };
  }, [dispatch]);

  // ScheduledLessons
  useEffect(() => {
    const s = socket.current;

    const handleScheduledLessons = (scheduledLessonsMessageResponse: ScheduledLessonsMessageResponse) => {
      dispatch(updateScheduledLessons(scheduledLessonsMessageResponse));
      console.log(`Scheduled lessons updated. Client id: ${s.id}`);
    };

    s.on(EventType.ScheduledLessons, handleScheduledLessons);

    return () => {
      s.off(EventType.ScheduledLessons, handleScheduledLessons);
    };
  }, [dispatch]);

  // RefreshScheduledLessons
  useEffect(() => {
    const s = socket.current;

    const handleRefreshScheduledLessons = () => {
      dispatch(setRefreshScheduledLessons(true));
      console.log(`RefreshScheduledLessons message received. Client id: ${s.id}`);
    };

    s.on(EventType.RefreshScheduledLessons, handleRefreshScheduledLessons);

    return () => {
      s.off(EventType.RefreshScheduledLessons, handleRefreshScheduledLessons);
    };
  }, [dispatch]);

  // RefreshDistrictRepEnrolmentsLessons
  useEffect(() => {
    const s = socket.current;

    const handleRefreshDistrictRepEnrolmentsLessons = () => {
      dispatch(setRefreshDistrictRepEnrolmentsLessons(true));
    };

    s.on(EventType.RefreshDistrictRepEnrolmentsLessons, handleRefreshDistrictRepEnrolmentsLessons);

    return () => {
      s.off(EventType.RefreshDistrictRepEnrolmentsLessons, handleRefreshDistrictRepEnrolmentsLessons);
    };
  }, [dispatch]);

  // LeaveLesson
  useEffect(() => {
    const s = socket.current;

    const handleLeaveLesson = () => {
      dispatch(leaveLesson({ socket: s }));
      dispatch(resetLibrary(LibraryContext.InLessonLibrary));
      console.log(`LeaveLesson message received. Client id: ${s.id}`);
    };
    s.on(EventType.LeaveLesson, handleLeaveLesson);

    return () => {
      s.off(EventType.LeaveLesson, handleLeaveLesson);
    };
  }, [dispatch]);

  useEffect(() => {
    if (profile) {
      const s = socket.current;
      s.connect();

      return () => {
        s.disconnect();
      };
    }
  }, [profile]);

  return (
    <SocketContext.Provider
      value={{
        socket: socket.current,
        connected: socketConnected,
      }}
    >
      {children}
    </SocketContext.Provider>
  );
};

const useSocket = () => {
  const context = React.useContext(SocketContext);

  if (context === undefined) {
    throw new Error('useSocket must be used within a SocketProvider');
  }

  return context;
};

export { SocketProvider, useSocket };
