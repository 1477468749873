import { Box, Link, useMediaQuery } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom';
import { SNACKBAR_AUTO_HIDE_DURATION, VALID_EMAIL_REGEX } from '@hoot/constants/constants';
import { CredentialType } from '@hoot/hooks/api/auth/useLogin';
import { usePageTitle } from '@hoot/hooks/usePageTitle';
import { Profile, User, UserProfileType } from '@hoot/models/api/user';
import { routesDictionary } from '@hoot/routes/routesDictionary';
import { Button } from '@hoot/ui/components/v2/core/Button';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';
import { Snackbar } from '@hoot/ui/components/v2/core/Snackbar';
import { useAuth } from '@hoot/ui/context/AuthContext';
import theme from '@hoot/ui/theme/v2';
import Card from '../../../../../components/v2/core/Card';
import TextField from '../../../../../components/v2/core/TextField';
import FreeLessonForgotPassword from './FreeLessonForgotPassword';

interface Form {
  emailAddress: string;
  password: string;
}

export function FreeLessonLogin() {
  usePageTitle('Free Lesson Login | Hoot Reading');
  const [searchParams] = useSearchParams();
  const emailFromSearchParams = searchParams.get('email');
  const referralCodeFromSearchParams = searchParams.get('referralCode') ?? '';

  const { control, handleSubmit, setError } = useForm<Form>({
    defaultValues: {
      emailAddress: emailFromSearchParams ?? '',
      password: '',
    },
  });

  const navigate = useNavigate();
  const { login, assumeProfile, getUser, jwtHasProfileId, isAuthenticated } = useAuth();
  const user = useMemo(() => {
    if (isAuthenticated) {
      return getUser();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [showForgotPassword, setShowForgotPassword] = useState<boolean>(false);
  const [showSuccess, setShowSuccess] = useState<string>('');

  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  // When authentication changes, we may do the following:
  // - If we're authenticated, and we do _not_ have a profile ID selected, and our user has a parent profile, then auto-assume the parent profile.
  // - If we're authenticated, and we _do_ have a profile ID set, then redirect out of here over to the registration wizard. There is no point showing a login page if we're already logged in.
  useEffect(() => {
    if (isAuthenticated) {
      const parentProfile = user?.profiles.find((p) => p.type === UserProfileType.Parent);

      // If we're authenticated, we do _not_ have a profile ID selected yet, and our user has a parent profile,
      // then auto-assume the parent profile.
      if (!jwtHasProfileId && parentProfile) {
        assumeParentProfile(parentProfile);
      }
      // If we're authenticated, and we have a profile ID set, then redirect out of here over to the registration wizard.
      // There is no point showing a login page if we're already logged in.
      if (jwtHasProfileId && user) {
        navigateToRegistrationStep(user);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, jwtHasProfileId, user]);

  const assumeParentProfile = (parentProfile: Profile) => {
    assumeProfile(parentProfile);
  };

  const navigateToRegistrationStep = (user: User) => {
    const countryAndProvinceExist = !!user.country && !!user.provinceState;
    if (countryAndProvinceExist) {
      navigate({
        pathname: routesDictionary.freeLesson.registration.url,
        search: createSearchParams({
          step: '4',
          referralCode: referralCodeFromSearchParams,
        }).toString(),
      });
    } else {
      navigate({
        pathname: routesDictionary.freeLesson.registration.url,
        search: createSearchParams({
          step: '3',
          referralCode: referralCodeFromSearchParams,
        }).toString(),
      });
    }
  };

  const onSubmit = async (data: Form) => {
    setIsLoggingIn(true);
    await login({
      credentials: data.emailAddress,
      password: data.password,
      credentialType: CredentialType.Email,
    }).catch(() => {
      setIsLoggingIn(false);
      setError('emailAddress', {
        type: 'manual',
      });
      setError('password', {
        type: 'manual',
        message: 'Incorrect email address or password.',
      });
    });
  };

  return showForgotPassword ? (
    <FreeLessonForgotPassword setShowForgotPassword={setShowForgotPassword} setShowSuccess={setShowSuccess} />
  ) : (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <Snackbar
        variant="light"
        message={`Instructions on how to reset your password have been sent to ${showSuccess}`}
        open={!!showSuccess}
        onClose={() => setShowSuccess('')}
        autoHideDuration={SNACKBAR_AUTO_HIDE_DURATION}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        title={'Password Reset Request'}
      />
      <Box sx={{ position: 'absolute', display: 'flex', top: 0, bottom: 0, left: 0, right: 0 }}>
        <Grid container alignItems={'center'} justifyContent={'center'}>
          <Card
            elevation={1}
            sx={{ width: isDesktop ? '348px' : '100%', borderRadius: '8px', padding: '6px', margin: '16px' }}
            title="Sign In"
            titleVariant="displaysmall"
          >
            <Grid container size={12} rowSpacing={3} display={'flex'} flexDirection={'column'}>
              <Grid size={12}>
                <HootTypography isPII={false} variant="bodymedium">
                  It looks like you already have an account associated with that email. Please sign in below.
                </HootTypography>
              </Grid>
              <Grid size={12}>
                <Controller
                  control={control}
                  name="emailAddress"
                  rules={{
                    required: 'This field is required',
                    pattern: {
                      value: VALID_EMAIL_REGEX,
                      message: 'Not a valid email address',
                    },
                  }}
                  render={({ field, fieldState }) => (
                    <TextField
                      type="email"
                      helperText={fieldState.error?.message}
                      required
                      error={!!fieldState.error}
                      value={field.value}
                      onChange={field.onChange}
                      label="Email address"
                    />
                  )}
                />
              </Grid>
              <Grid size={12}>
                <Controller
                  control={control}
                  name="password"
                  rules={{
                    required: 'This field is required',
                  }}
                  render={({ field, fieldState }) => (
                    <TextField
                      required
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                      value={field.value}
                      onChange={field.onChange}
                      label="Password"
                      type="password"
                    />
                  )}
                />
              </Grid>
              <Grid size={12}>
                <Button type="submit" fullWidth color="primary" size="medium" variant="contained" isLoading={isLoggingIn}>
                  Sign In
                </Button>
              </Grid>
              <Grid size={12}>
                <Button fullWidth color="neutral.190" size="medium" variant="contained" onClick={() => setShowForgotPassword(true)}>
                  I forgot my password
                </Button>
              </Grid>
              <Grid size={12} display={'flex'} justifyContent={'center'}>
                <Link
                  sx={{
                    textDecoration: 'none',
                    '&:hover': {
                      textDecoration: 'underline',
                    },
                  }}
                  href="https://www.hootreading.com/privacy/"
                  target="_blank"
                  rel="noreferrer"
                  variant="bodysmall"
                >
                  Privacy Policy
                </Link>
                <HootTypography isPII={false} variant="bodysmall">
                  &nbsp;&&nbsp;
                </HootTypography>
                <Link
                  variant="bodysmall"
                  sx={{
                    textDecoration: 'none',
                    '&:hover': {
                      textDecoration: 'underline',
                    },
                  }}
                  href="https://www.hootreading.com/terms/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Terms of Service
                </Link>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Box>
    </form>
  );
}
