import { Stack } from '@mui/material';
import React from 'react';
import Card, { CardProps } from '@hoot/ui/components/v2/core/Card';
import { Icon } from '@hoot/ui/components/v2/core/Icon';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';

interface Props extends CardProps {
  text: React.ReactNode;
  button?: React.ReactNode;
}

const LessonReviewWarningCard = (props: Props) => {
  const { text, button, sx, ...rest } = props;
  return (
    <Card sx={{ minHeight: '80px', backgroundColor: hootTokens.palette.error['180'], alignContent: 'center', ...sx }} {...rest}>
      <Stack direction="row" gap={2} alignItems="center" justifyContent="space-between" mt="-4px">
        <Stack direction="row" gap={2} alignItems="center">
          <Icon name="school_bell" style={{ fontSize: '40px' }} />
          {text}
        </Stack>
        {button}
      </Stack>
    </Card>
  );
};

export default LessonReviewWarningCard;
