import axios, { AxiosError, AxiosResponse } from 'axios';
import { UseMutationOptions, useMutation } from 'react-query';
import {
  LessonReviewEngagement,
  LessonReviewFocus,
  LessonReviewFocusSkill,
  LessonReviewFocusType,
  LessonReviewFocusUnit,
  ResourceReader,
} from '@hoot/models/api/enums/lesson-review-enums';
import { ErrorResponseDto } from '@hoot/models/api/error-response-dto';
import { config } from '../../../config';
import { ResourceProgress } from '../../../models/api/library';

export interface LessonStructure {
  assessment: boolean;
  instruction: boolean;
  rapportBuilding: boolean;
}

export interface WordReadingFormativeAssessment {
  bookId: string;
  word: string;
  readCorrectly: boolean;
}

export interface FluencyFormativeAssessment {
  bookId: string;
  wordCount: number;
  errors: number;
  runningTime: number;
}

export interface ComprehensionFormativeAssessment {
  bookId: string;
  textSpecificKnowledge?: number;
  textStructure: number;
  sentenceAnalysis: number;
  verbalReasoning: number;
  vocabulary: number;
}
export interface LettersAndSoundsFormativeAssessment {
  bookId: string;
  letter: string;
  readCorrectly: boolean;
}

export interface FocusBook {
  id: string;
  focusType: LessonReviewFocusType;
  reader?: ResourceReader;
  progress?: ResourceProgress;
}

export interface LessonReviewEngagementExternalFactors {
  classroomOrSurroundingsWereLoud: boolean;
  peopleInSurroundingsDistracting: boolean;
  audioOrVideoIssues: boolean;
}

export interface CreateLessonReviewRequest {
  studentProfileId: string;
  primaryFocus: LessonReviewFocus;
  primaryFocusUnit?: LessonReviewFocusUnit | undefined;
  primaryFocusSkill?: LessonReviewFocusSkill | undefined;
  secondaryFocus?: LessonReviewFocus | undefined;
  secondaryFocusUnit?: LessonReviewFocusUnit | undefined;
  secondaryFocusSkill?: LessonReviewFocusSkill | undefined;
  focusBooks: FocusBook[];
  lessonPlanBookIds: string[];
  studentEngagement: LessonReviewEngagement;
  studentEngagementExternalFactors: LessonReviewEngagementExternalFactors;
  notes: string;
  wordReadingFormativeAssessments?: WordReadingFormativeAssessment[] | undefined;
  fluencyFormativeAssessments?: FluencyFormativeAssessment[] | undefined;
  comprehensionFormativeAssessments?: ComprehensionFormativeAssessment[] | undefined;
  lettersAndSoundsFormativeAssessments?: LettersAndSoundsFormativeAssessment[] | undefined;
}

export default function useCreateLessonReview(
  lessonId: string,
  options?: Omit<UseMutationOptions<AxiosResponse<void>, AxiosError<ErrorResponseDto>, CreateLessonReviewRequest>, 'mutationKey' | 'mutationFn'>,
) {
  return useMutation(
    async (request: CreateLessonReviewRequest) => await axios.post<void>(`${config.apiUrl}/public/lesson-review/lesson/${lessonId}`, request),
    options,
  );
}
