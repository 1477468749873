import { Stack, Tabs, TabsProps } from '@mui/material';
import { Outlet, matchPath, useLocation, useNavigate, useParams } from 'react-router-dom';
import useDistrictRepGetEnrolmentSnippet from '@hoot/hooks/api/district-rep/useDistrictRepresentativeGetEnrolmentSnippet';
import { routesDictionary } from '@hoot/routes/routesDictionary';
import SubHeader from '@hoot/ui/components/v2/SubHeader';
import Page from '@hoot/ui/components/v2/core/Page';
import { Tab } from '@hoot/ui/components/v2/core/Tab';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';

export enum HFSEnrolmentsPageTabs {
  Details = 'Details',
  Students = 'Students',
  TeamMembers = 'TeamMembers',
  Schools = 'Schools',
}

const tabToRouteDictionary: Record<HFSEnrolmentsPageTabs, (enrolmentId: string) => string> = {
  [HFSEnrolmentsPageTabs.Details]: (enrolmentId: string) => routesDictionary.enrolments.enrolment.details.url(enrolmentId),
  [HFSEnrolmentsPageTabs.Students]: (enrolmentId: string) => routesDictionary.enrolments.enrolment.students.url(enrolmentId),
  [HFSEnrolmentsPageTabs.TeamMembers]: (enrolmentId: string) => routesDictionary.enrolments.enrolment.teamMembers.url(enrolmentId),
  [HFSEnrolmentsPageTabs.Schools]: (enrolmentId: string) => routesDictionary.enrolments.enrolment.schools.url(enrolmentId),
};

const HFSEnrolmentsHeader = () => {
  const { enrolmentId } = useParams();
  const { data: enrolmentSnippet, isLoading } = useDistrictRepGetEnrolmentSnippet(enrolmentId ?? '', {
    enabled: !!enrolmentId,
    retry: false,
    onError: (err) => {
      console.error(err);
      // If we don't have permission to view this enrolment, then redirect out of this page.
      if (err.response?.status === 403) {
        navigate(routesDictionary.enrolments.url, { replace: true });
      }
    },
  });
  const navigate = useNavigate();
  const { pathname, state } = useLocation();

  const pageTitle = enrolmentSnippet?.friendlyId ? `${enrolmentSnippet.friendlyId} | Hoot Reading` : 'Enrolment | Hoot Reading';

  const isActiveTabPath = (tab: HFSEnrolmentsPageTabs) => {
    return !!matchPath(tabToRouteDictionary[tab](enrolmentId!), pathname);
  };

  const getActiveTab = (): HFSEnrolmentsPageTabs => {
    if (isActiveTabPath(HFSEnrolmentsPageTabs.Details)) {
      return HFSEnrolmentsPageTabs.Details;
    } else if (isActiveTabPath(HFSEnrolmentsPageTabs.Students)) {
      return HFSEnrolmentsPageTabs.Students;
    } else {
      return HFSEnrolmentsPageTabs.Details;
    }
  };

  const handleTabChange: TabsProps['onChange'] = (_, newTab: HFSEnrolmentsPageTabs) => {
    navigate(tabToRouteDictionary[newTab]?.(enrolmentId!) ?? routesDictionary.enrolments.enrolment.details.url(enrolmentId!), {
      state: { previousPath: state?.previousPath },
    });
  };

  return (
    <>
      <SubHeader
        title={{
          label: enrolmentSnippet?.friendlyId ?? 'Enrolment',
          isPII: false,
          isLoading,
        }}
        backButton={{
          show: true,
          navigateBackPath: state?.previousPath ? state.previousPath : undefined,
        }}
        breadcrumbs={['Enrolments', 'Enrolment Details']}
      />

      <Stack>
        <Tabs value={getActiveTab()} onChange={handleTabChange} variant="fullWidth" sx={{ backgroundColor: hootTokens.palette.white, px: 2 }}>
          <Tab label={'Details'} value={HFSEnrolmentsPageTabs.Details} isSelected={getActiveTab() === HFSEnrolmentsPageTabs.Details} />
          <Tab label={'Students'} value={HFSEnrolmentsPageTabs.Students} isSelected={getActiveTab() === HFSEnrolmentsPageTabs.Students} />
        </Tabs>
      </Stack>

      <Page pageTitle={pageTitle} noPadding>
        <Outlet />
      </Page>
    </>
  );
};

export default HFSEnrolmentsHeader;
