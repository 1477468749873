import { SocialMedia } from '@hoot-reading/hoot-core/dist/enums/user/social-media.enum';
import { HowDidYouHearAboutHoot } from '@hoot-reading/hoot-core/dist/enums/user/student/how-did-you-hear-about-hoot.enum';
import axios from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '@hoot/config';
import { QueryKey } from '../queryKeys';

export interface FlfCreateProfileResponseDto {
  country: string | null;
  provinceState: string | null;
  timeZone: string;
  howDidYouHearAboutHoot: HowDidYouHearAboutHoot;
  howDidYouHearAboutHootOther?: string;
  socialMedia?: SocialMedia;
  referralCode?: string;
}

export default function useGetFlfProfileRequest(options?: Omit<UseQueryOptions<FlfCreateProfileResponseDto>, 'queryKey' | 'queryFn'>) {
  return useQuery(
    [QueryKey.GetFlfProfile],
    async () => {
      return (await axios.get(`${config.apiUrl}/free-lesson/v2/get-profile`)).data;
    },
    options,
  );
}
