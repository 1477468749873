import { Navigate } from 'react-router-dom';
import useDistrictRepLocationDetails from '@hoot/hooks/useDistrictRepLocationDetails';
import SubHeader from '@hoot/ui/components/v2/SubHeader';
import StudentAggregateReport from './student-aggregate/StudentAggregateReport';

const ReportingDashboard = () => {
  const { isFetched, districtRepLocationDetails } = useDistrictRepLocationDetails();

  const locationHasReporting = !!districtRepLocationDetails?.reporting;
  const showReportingPage = locationHasReporting;

  if (isFetched && !showReportingPage) {
    return <Navigate to="/" />;
  }

  return (
    <>
      <SubHeader title={{ label: 'Reporting Dashboard', isPII: false }} />
      <StudentAggregateReport />
    </>
  );
};

export default ReportingDashboard;
