import { StudentGrade } from '@hoot-reading/hoot-core/dist/enums/student-grade';
import axios from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '@hoot/config';
import { QueryKey } from '../queryKeys';

export interface StudentGradeResponse {
  grade: StudentGrade | null;
}

export function useGetStudentGrade(studentProfileId: string, options?: Omit<UseQueryOptions<StudentGradeResponse>, 'queryKey' | 'queryFn'>) {
  return useQuery(
    [QueryKey.GetStudentGrade, studentProfileId],
    async () => {
      return (await axios.get<StudentGradeResponse>(`${config.apiUrl}/teacher/student-profile/v2/${studentProfileId}/grade`)).data;
    },
    options,
  );
}
