import { DistrictRepPermissionLevel } from '@hoot-reading/hoot-core/dist/enums/hfs/district-rep-access-level.enum';
import { LocationType } from '@hoot-reading/hoot-core/dist/enums/hfs/location-type.enum';
import { StudentStatus } from '@hoot-reading/hoot-core/dist/enums/user/student/student-status.enum';
import { Stack } from '@mui/material';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { LocationReportingFilters } from '@hoot/hooks/api/district-rep/reporting/useGetLocationReportingMetrics';
import useGetLocationStudentGradesAndStatuses from '@hoot/hooks/api/district-rep/reporting/useGetLocationStudentGradesAndStatuses';
import useGetDistrictRepresentativeDetails from '@hoot/hooks/api/district-rep/useGetDistrictRepresentativeDetails';
import useSearchDistrictRepresentativeSchools, {
  DistrictRepresentativeSchoolsQuery,
} from '@hoot/hooks/api/district-rep/useSearchDistrictRepresentativeSchools';
import useGetDistrictRepPermissions from '@hoot/hooks/api/user/useGetDistrictRepPermissions';
import { OrderBy } from '@hoot/models/api/enums/queryEnums';
import { RootState } from '@hoot/redux/store';
import Card from '@hoot/ui/components/v2/core/Card';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';
import Page from '@hoot/ui/components/v2/core/Page';
import { AttendanceCharts } from '@hoot/ui/pages/v2/district-rep/reporting/AttendanceCharts';
import { DateFilterEnum } from '../../../../../../models/api/district-rep-reporting/common';
import StudentAggregateFilters from './StudentAggregateFilters';
import StudentAggregateMetrics from './StudentAggregateMetrics';
import StudentAggregateTable from './StudentAggregateTable';

const schoolsQuery: DistrictRepresentativeSchoolsQuery = {
  page: 1,
  pageSize: 250, // Fetching full list of Schools under a District.
  sortBy: 'name',
  orderBy: OrderBy.Asc,
};

const defaultFilters: LocationReportingFilters = {
  date: [DateFilterEnum.ThisSchoolYear],
  schoolIds: [],
  studentStatus: [StudentStatus.Active],
  studentGrade: [],
  attendance: [],
  lateJoin: [],
};

const StudentAggregateReport = () => {
  const [filtersRequest, setFiltersRequest] = useState<LocationReportingFilters>(defaultFilters);

  const districtRepId = useSelector((state: RootState) => state.profile.profile?.id);

  const { data: schoolsData } = useSearchDistrictRepresentativeSchools(districtRepId ?? '', schoolsQuery, {
    enabled: !!districtRepId,
    keepPreviousData: true,
  });

  const { data: districtRepDetails } = useGetDistrictRepresentativeDetails(districtRepId ?? '', {
    enabled: !!districtRepId,
  });

  const { data: permissions } = useGetDistrictRepPermissions(districtRepId ?? '', {
    keepPreviousData: true,
  });

  const districtLocationId =
    (districtRepDetails?.locations.find((loc) => loc.type === LocationType.District)?.id ?? districtRepDetails?.isStandaloneSchool)
      ? districtRepDetails.locations[0].id
      : '';

  const { data } = useGetLocationStudentGradesAndStatuses(districtLocationId ?? '', { enabled: !!districtLocationId });
  const statusOptions = data?.statuses ?? [];
  const gradeOptions = data?.grades ?? [];

  const hasFullAccess = permissions?.some((permission) => permission === DistrictRepPermissionLevel.DistrictFullAccess);

  return (
    <Page pageTitle={'Student Aggregate Report | Hoot Reading'}>
      <Card>
        <HootTypography variant="displaysmall" mb={2} isPII={false}>
          Student Aggregate Report
        </HootTypography>

        <Stack gap={5}>
          <StudentAggregateFilters
            filters={filtersRequest}
            setFilters={setFiltersRequest}
            schoolOptions={schoolsData?.schools ?? []}
            hasFullDistrictAccess={hasFullAccess}
            statusOptions={statusOptions}
            gradeOptions={gradeOptions}
          />
          <StudentAggregateMetrics filters={filtersRequest} locationId={districtLocationId} />
          <AttendanceCharts filters={filtersRequest} locationId={districtLocationId} />
          <StudentAggregateTable filters={filtersRequest} locationId={districtLocationId} />
          <HootTypography variant="tableheadingactive" isPII={false}>
            These reports are for informational purposes only and is not to be referenced for invoicing.
          </HootTypography>
        </Stack>
      </Card>
    </Page>
  );
};

export default StudentAggregateReport;
