import { Navigate, Route, Routes } from 'react-router-dom';
import { featureFlags } from '@hoot/constants/featureFlags';
import { routesDictionary } from '@hoot/routes/routesDictionary';
import ImpersonateLoginView from '@hoot/ui/components/v2/ImpersonateLoginView';
import Logout from '@hoot/ui/components/v2/Logout';
import AppBarLayout from '@hoot/ui/components/v2/layout/AppBarLayout';
import { ProvidersLayoutV2 } from '@hoot/ui/components/v2/layout/ProvidersLayoutV2';
import PublicApplicationLayout from '@hoot/ui/components/v2/layout/PublicApplicationLayout';
import TeacherLibraryProviderLayout from '@hoot/ui/components/v2/layout/TeacherLibraryProviderLayout';
import { useFeatureFlags } from '@hoot/ui/context/FeatureFlagContext';
import SelectProfilePage from '@hoot/ui/pages/v2/no-profile/SelectProfilePage';
import ChangePasswordV2 from '@hoot/ui/pages/v2/public/change-password/ChangePasswordV2';
import PublicStudentSchedule from '@hoot/ui/pages/v2/public/public-student-schedule/PublicStudentSchedule';
import { TeacherDashboard } from '@hoot/ui/pages/v2/teacher/dashboard/TeacherDashboard';
import { Invoice } from '@hoot/ui/pages/v2/teacher/invoicing/Invoice';
import { Invoicing } from '@hoot/ui/pages/v2/teacher/invoicing/Invoicing';
import TeacherInLessonLayout from '@hoot/ui/pages/v2/teacher/lesson/TeacherInLessonLayout';
import TeacherInLessonLibraryPage from '@hoot/ui/pages/v2/teacher/lesson/library/TeacherInLessonLibraryPage';
import TeacherInLessonReaderAppBarLayout from '@hoot/ui/pages/v2/teacher/lesson/library/reader/TeacherInLessonReaderAppBarLayout';
import TeacherInLessonReaderPage from '@hoot/ui/pages/v2/teacher/lesson/library/reader/TeacherInLessonReaderPage';
import PMAPage from '@hoot/ui/pages/v2/teacher/lesson/progress-monitoring-assessment/PMAPage';
import TeacherInLessonWhiteboardPage from '@hoot/ui/pages/v2/teacher/lesson/whiteboard/TeacherInLessonWhiteboardPage';
import TeacherLibrarySandboxPage from '@hoot/ui/pages/v2/teacher/library/TeacherLibrarySandboxPage';
import TeacherLibrarySandboxReaderPage from '@hoot/ui/pages/v2/teacher/library/reader/TeacherSandboxReaderPage';
import { MyStudents } from '@hoot/ui/pages/v2/teacher/my-students/MyStudents';
import StudentDetailsContextProvider from '@hoot/ui/pages/v2/teacher/my-students/student-details/StudentDetailsContextProvider';
import StudentDetailsHeader from '@hoot/ui/pages/v2/teacher/my-students/student-details/StudentDetailsHeader';
import StudentAssessments from '@hoot/ui/pages/v2/teacher/my-students/student-details/assessments/StudentAssessments';
import LessonReviewsPage from '@hoot/ui/pages/v2/teacher/my-students/student-details/lesson-reviews/LessonReviewsPage';
import LessonReviewDetailsPage from '@hoot/ui/pages/v2/teacher/my-students/student-details/lesson-reviews/lesson-review-details/LessonReviewDetailsPage';
import TeacherStudentSpecificLibraryPage from '@hoot/ui/pages/v2/teacher/my-students/student-details/library/TeacherStudentSpecificLibraryPage';
import TeacherStudentSpecificLibraryReaderPage from '@hoot/ui/pages/v2/teacher/my-students/student-details/library/reader/TeacherStudentSpecificLibraryReaderPage';
import TeacherStudentSpecificReaderAppBarLayout from '@hoot/ui/pages/v2/teacher/my-students/student-details/library/reader/TeacherStudentSpecificReaderAppBarLayout';
import StudentNotFoundPage from '@hoot/ui/pages/v2/teacher/my-students/student-details/profile/StudentNotFoundPage';
import StudentProfilePage from '@hoot/ui/pages/v2/teacher/my-students/student-details/profile/StudentProfilePage';
import StudentScopeAndSequenceRedirect from '@hoot/ui/pages/v2/teacher/my-students/student-details/scope-and-sequence/StudentScopeAndSequenceRedirect';
import StudentTimelinePage from '@hoot/ui/pages/v2/teacher/my-students/student-details/timeline/StudentTimelinePage';
import { LessonOpportunities } from '@hoot/ui/pages/v2/teacher/opportunities/LessonOpportunities';
import TeacherProfile from '@hoot/ui/pages/v2/teacher/profile/TeacherProfile';
import { TeacherSchedule } from '@hoot/ui/pages/v2/teacher/schedule/TeacherSchedule';
import { TeacherScheduleV2 } from '@hoot/ui/pages/v2/teacher/schedule/TeacherScheduleV2';
import ScopeAndSequencePage from '@hoot/ui/pages/v2/teacher/scope-and-sequence/ScopeAndSequencePage';
import ScopeAndSequenceFocusAreaCard from '@hoot/ui/pages/v2/teacher/scope-and-sequence/focus-area/ScopeAndSequenceFocusAreaCard';
import ScopeAndSequenceFocusAreaDetailPage from '@hoot/ui/pages/v2/teacher/scope-and-sequence/focus-area/instructional-unit/FocusAreaDetailsPage';
import InstructionalUnitDetailsCard from '@hoot/ui/pages/v2/teacher/scope-and-sequence/focus-area/instructional-unit/InstructionalUnitDetailsCard';
import InstructionalUnitDocumentation from '@hoot/ui/pages/v2/teacher/scope-and-sequence/focus-area/instructional-unit/documentation/InstructionalUnitDocumentation';
import PrioritizedSkillDocumentation from '@hoot/ui/pages/v2/teacher/scope-and-sequence/focus-area/instructional-unit/prioritized-skill/documentation/PrioritizedSkillDocumentation';
import { FocusAreaSlug } from '@hoot/ui/pages/v2/teacher/scope-and-sequence/scopeAndSequenceFocusAreas';
import HRAPage from '../../../../ui/pages/v2/teacher/lesson/hoot-reading-assessment/HRAPage';
import TeacherProtectedRoute from './TeacherProtectedRoute';

const TeacherRouter = () => {
  const { isFeatureEnabled } = useFeatureFlags();
  const isScopeAndSequenceEnabled = isFeatureEnabled(featureFlags.epic_8641_scope_and_sequence);
  const isBlocksAndOpportunitiesEnabled = isFeatureEnabled(featureFlags.ff13371_blocks_and_opportunities);

  return (
    <Routes>
      <Route element={<ProvidersLayoutV2 />}>
        {/* Main Routes */}
        <Route element={<AppBarLayout />}>
          <Route element={<TeacherProtectedRoute routePath={routesDictionary.dashboard.path} />}>
            <Route path={routesDictionary.dashboard.path} element={<TeacherDashboard />} />
          </Route>
          <Route element={<TeacherProtectedRoute routePath={routesDictionary.schedule.path} />}>
            {isBlocksAndOpportunitiesEnabled ? (
              <Route path={routesDictionary.schedule.path} element={<TeacherScheduleV2 />} />
            ) : (
              <Route path={routesDictionary.schedule.path} element={<TeacherSchedule />} />
            )}
          </Route>
          <Route element={<TeacherProtectedRoute routePath={routesDictionary.opportunities.path} />}>
            <Route path={routesDictionary.opportunities.path} element={<LessonOpportunities />} />
          </Route>
          {isScopeAndSequenceEnabled && (
            <Route element={<TeacherProtectedRoute routePath={routesDictionary.scopeAndSequence.path} />}>
              <Route path={routesDictionary.scopeAndSequence.path}>
                <Route path={routesDictionary.scopeAndSequence.focusArea.path}>
                  <Route element={<ScopeAndSequencePage />}>
                    <Route index element={<ScopeAndSequenceFocusAreaCard />} />
                  </Route>
                  <Route path={routesDictionary.scopeAndSequence.focusArea.unit.path}>
                    <Route element={<ScopeAndSequenceFocusAreaDetailPage />}>
                      <Route index element={<InstructionalUnitDetailsCard />} />
                    </Route>
                    <Route path={routesDictionary.scopeAndSequence.focusArea.unit.documentation.path} element={<InstructionalUnitDocumentation />} />
                    <Route path={routesDictionary.scopeAndSequence.focusArea.unit.skill.path}>
                      <Route
                        path={routesDictionary.scopeAndSequence.focusArea.unit.skill.documentation.path}
                        element={<PrioritizedSkillDocumentation />}
                      />
                      <Route index element={<Navigate to="../.." relative="path" />} />
                    </Route>
                  </Route>
                </Route>
                {/*/!* FYI: There is no page content for the S&S root. If we land here, then default to "pre-word reading" S&S unit. *!/*/}
                <Route index element={<Navigate to={routesDictionary.scopeAndSequence.focusArea.url(FocusAreaSlug.preWordReading)} replace />} />
              </Route>
            </Route>
          )}
          <Route element={<TeacherProtectedRoute routePath={routesDictionary.invoicing.path} />}>
            <Route path={routesDictionary.invoicing.path}>
              <Route index element={<Invoicing />} />
              <Route path="details/:invoiceId" element={<Invoice />} />
            </Route>
          </Route>
          <Route path={routesDictionary.changePassword.path} element={<ChangePasswordV2 />} />
          <Route element={<TeacherProtectedRoute routePath={routesDictionary.profile.path} />}>
            <Route path={routesDictionary.profile.path} element={<TeacherProfile />} />
          </Route>
          <Route element={<TeacherProtectedRoute routePath={routesDictionary.myStudents.path} />}>
            <Route path={routesDictionary.myStudents.path}>
              <Route index element={<MyStudents />} />
              <Route path={routesDictionary.myStudents.notFound.path} element={<StudentNotFoundPage />} />
              <Route path={routesDictionary.myStudents.details.path} element={<StudentDetailsContextProvider />}>
                {/* This may seem a lil weird, but the Scope and Sequence routes can also exist under the "student details" route. */}
                {/* Only the only real reason for this is that there are some minor UI differences under the documentation pages.  */}
                {isScopeAndSequenceEnabled && (
                  <Route element={<TeacherProtectedRoute routePath={routesDictionary.scopeAndSequence.path} />}>
                    <Route path={routesDictionary.myStudents.details.scopeAndSequence.path}>
                      <Route path={routesDictionary.myStudents.details.scopeAndSequence.focusArea.path}>
                        <Route element={<ScopeAndSequencePage />}>
                          <Route index element={<ScopeAndSequenceFocusAreaCard />} />
                        </Route>
                        <Route path={routesDictionary.myStudents.details.scopeAndSequence.focusArea.unit.path}>
                          <Route element={<ScopeAndSequenceFocusAreaDetailPage />}>
                            <Route index element={<InstructionalUnitDetailsCard />} />
                          </Route>
                          <Route
                            path={routesDictionary.myStudents.details.scopeAndSequence.focusArea.unit.documentation.path}
                            element={<InstructionalUnitDocumentation />}
                          />
                          <Route path={routesDictionary.myStudents.details.scopeAndSequence.focusArea.unit.skill.path}>
                            <Route
                              path={routesDictionary.myStudents.details.scopeAndSequence.focusArea.unit.skill.documentation.path}
                              element={<PrioritizedSkillDocumentation />}
                            />
                            <Route index element={<Navigate to="../.." relative="path" />} />
                          </Route>
                        </Route>
                      </Route>
                      {/*/!* FYI: There is no page content for the S&S root. If we land here, then default to "pre-word reading" S&S unit. *!/*/}
                      <Route index element={<StudentScopeAndSequenceRedirect />} />
                    </Route>
                  </Route>
                )}

                <Route element={<StudentDetailsHeader />}>
                  <Route path={routesDictionary.myStudents.details.profile.path} element={<StudentProfilePage />} />
                  <Route path={routesDictionary.myStudents.details.timeline.path} element={<StudentTimelinePage />} />
                  <Route path={routesDictionary.myStudents.details.reviews.path} element={<LessonReviewsPage />} />
                  <Route path={routesDictionary.myStudents.details.assessments.path} element={<StudentAssessments />} />
                </Route>
                <Route path={routesDictionary.myStudents.details.reviews.lessonReviewId.path} element={<LessonReviewDetailsPage />} />
                <Route index element={<Navigate to={routesDictionary.myStudents.details.profile.path} />} />
              </Route>
            </Route>
          </Route>
        </Route>

        {/* Library Routes */}
        <Route element={<TeacherProtectedRoute routePath={routesDictionary.library.path} />}>
          <Route element={<TeacherLibraryProviderLayout />}>
            <Route path={routesDictionary.lesson.path} element={<TeacherInLessonLayout />}>
              <Route path={routesDictionary.lesson.library.path}>
                <Route element={<TeacherInLessonReaderAppBarLayout />}>
                  <Route path={routesDictionary.library.book.path} element={<TeacherInLessonReaderPage />} />
                </Route>
                <Route index element={<TeacherInLessonLibraryPage />} />
              </Route>
              <Route path={routesDictionary.lesson.whiteboard.path} element={<TeacherInLessonWhiteboardPage />} />
              <Route path={routesDictionary.lesson.progressMonitoringAssessment.path} element={<PMAPage />} />
              <Route path={routesDictionary.lesson.hootReadingAssessment.path} element={<HRAPage />} />
            </Route>

            {/* Student-specific library. */}
            <Route path={routesDictionary.myStudents.path}>
              <Route path={routesDictionary.myStudents.details.path}>
                <Route path={routesDictionary.myStudents.details.library.path}>
                  <Route element={<TeacherStudentSpecificReaderAppBarLayout />}>
                    <Route path={routesDictionary.myStudents.details.library.book.path} element={<TeacherStudentSpecificLibraryReaderPage />} />
                  </Route>
                  <Route element={<AppBarLayout />}>
                    <Route index element={<TeacherStudentSpecificLibraryPage />} />
                  </Route>
                </Route>
              </Route>
            </Route>

            {/* Sandbox Library */}
            <Route path={routesDictionary.library.path}>
              <Route index element={<TeacherLibrarySandboxPage />} />
              <Route path={routesDictionary.library.book.path} element={<TeacherLibrarySandboxReaderPage />} />
            </Route>
          </Route>
        </Route>
      </Route>

      {/* Public Routes */}
      <Route element={<PublicApplicationLayout />}>
        <Route path={routesDictionary.selectProfile.path} element={<SelectProfilePage />} />
        <Route path={routesDictionary.impersonateLogin.path} element={<ImpersonateLoginView />} />
        <Route path={routesDictionary.publicStudentSchedule.path}>
          <Route path={routesDictionary.publicStudentSchedule.location.path} element={<PublicStudentSchedule />} />
        </Route>
      </Route>

      {/* There is no page content for the home route ('/'), so we just redirect the user to a page that makes the most sense. */}
      <Route index element={<Navigate replace to={routesDictionary.dashboard.url} />} />
      <Route path={routesDictionary.logout.path} element={<Logout />} />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};

export default TeacherRouter;
