import { DateTime } from 'luxon';
import React, { createContext, useEffect, useState } from 'react';
import { ONE_MINUTE_IN_MS } from '@hoot/constants/constants';
import { useAudioVideoSettingsDialog } from './AudioVideoSettingsDialogContext';
import { useUpcomingLessons } from './UpcomingLessonsProvider';

interface Values {
  isJoinDialogOpen: boolean;
  handleSetJoinDialog: (open: boolean) => void;
  showCountdownButton: boolean;
  handleRemindMe: () => void;
  canDialogBeMinimized: boolean;
}

interface Props {
  children: React.ReactNode;
}

const StudentJoinLessonContext = createContext<Values>(undefined!);

const StudentJoinLessonProvider = (props: Props) => {
  const { children } = props;
  const { showStudent: showingStudentGetReadyDialog } = useAudioVideoSettingsDialog();
  const { openLesson } = useUpcomingLessons();

  const [isJoinDialogOpen, setIsJoinDialogOpen] = useState<boolean>(false);
  const [showCountdownButton, setShowCountdownButton] = useState<boolean>(false);

  // It's ugly, but we initialize this state to first check if the start time has been reached yet
  const [canDialogBeMinimized, setCanDialogBeMinimized] = useState<boolean>((openLesson?.startTime ?? 0) - 1000 - DateTime.now().toMillis() > 0);

  // Interval timer that sets if the join dialog is "minimizable" or not.
  // Between 1:00 and 10:00 the Student can minimize the dialog via a `Remind Me Later` button.
  // If the timer is < 1:00, they can only Join Lesson or go to Select Profile screen.
  useEffect(() => {
    // If there is no open lesson, the bail.
    if (!openLesson) {
      // If the join lesson dialog is visible, then close it. There is no lesson to join.
      if (isJoinDialogOpen) {
        setIsJoinDialogOpen(false);
      }
      return;
    }
    const start = openLesson.startTime;

    setCanDialogBeMinimized(openLesson.startTime - 1000 - DateTime.now().toMillis() > 0);

    const interval = setInterval(() => {
      const now = DateTime.now().toMillis();
      const timeUntilLessonStart = start - now;
      const timerHasExpired = timeUntilLessonStart <= 0;
      const timerWithinOneMinute = timeUntilLessonStart <= ONE_MINUTE_IN_MS;

      if (timerHasExpired || timerWithinOneMinute) {
        clearInterval(interval);
        setCanDialogBeMinimized(false); // Stop the Student from minimizing the Dialog now.
        setIsJoinDialogOpen(true); // Show the dialog at the 1:00 mark to encourage Student to Join the Lesson.
      } else {
        setCanDialogBeMinimized(true);
      }
    }, 1000);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openLesson]);

  // Handles the conditions for rendering the join lesson dialog.
  useEffect(() => {
    if (!!openLesson && !showingStudentGetReadyDialog) {
      handleSetJoinDialog(true);
    }
  }, [openLesson, showingStudentGetReadyDialog]);

  const handleSetJoinDialog = (open: boolean) => {
    setIsJoinDialogOpen(open);
  };

  const handleRemindMe = () => {
    setIsJoinDialogOpen(false);
    setShowCountdownButton(true);
  };

  return (
    <StudentJoinLessonContext.Provider
      value={{
        isJoinDialogOpen,
        handleSetJoinDialog,
        showCountdownButton,
        handleRemindMe,
        canDialogBeMinimized,
      }}
    >
      {children}
    </StudentJoinLessonContext.Provider>
  );
};

export const useStudentJoinLessonCtx = () => {
  const context = React.useContext(StudentJoinLessonContext);

  if (context === undefined) {
    throw new Error('useStudentJoinLessonCtx must be used within the StudentJoinLessonProvider');
  }

  return context;
};

export default StudentJoinLessonProvider;
