import { Box, Stack, Typography, keyframes, styled, useMediaQuery, useTheme } from '@mui/material';
import { DateTime } from 'luxon';
import { LessonTimeSlot } from '@hoot/ui/pages/v2/teacher/schedule/TeacherSchedule';
import { isBetween } from '@hoot/utils/isBetween';
import { hootTokens } from '../../../theme/v2/tokens';
import { Button } from '../core/Button';
import { Icon } from '../core/Icon';
import IconButton from '../core/IconButton';

/** ENUMS */

export const enum ZIndexLayer {
  Bottom = 100,
  Middle = 200,
  Top = 300,
  Header = 400,
}

/** CONSTANTS */

export const calendarYAxisTimes = [
  '8:00 AM',
  '8:30 AM',
  '9:00 AM',
  '9:30 AM',
  '10:00 AM',
  '10:30 AM',
  '11:00 AM',
  '11:30 AM',
  '12:00 PM',
  '12:30 PM',
  '1:00 PM',
  '1:30 PM',
  '2:00 PM',
  '2:30 PM',
  '3:00 PM',
  '3:30 PM',
  '4:00 PM',
  '4:30 PM',
  '5:00 PM',
  '5:30 PM',
  '6:00 PM',
  '6:30 PM',
  '7:00 PM',
  '7:30 PM',
  '8:00 PM',
  '8:30 PM',
];

const HEADER_HEIGHT = 44;
const HEADER_WIDTH = 100;
export const WEEKLY_SCHEDULED_CELL_HEIGHT = 64;

const COLUMN_HEIGHT = WEEKLY_SCHEDULED_CELL_HEIGHT * calendarYAxisTimes.length;

/** COMPONENTS */

export const calendarYAxisLabels = (): string[] => {
  const centralTimeZone = 'America/Chicago';
  return calendarYAxisTimes.map((timeString) => {
    const sourceDateTime = DateTime.fromFormat(timeString, 'h:mm a', { zone: centralTimeZone });
    const localDateTime = sourceDateTime.setZone(DateTime.local().zoneName);
    return localDateTime.toFormat('h:mm a');
  });
};

export function WeeklySchedule(props: {
  startOfWeek: DateTime;
  onStartOfWeekChange: (startOfWeek: DateTime) => void;
  onSelected: (dayOfWeek: DateTime) => void;
  columnDetails: (dayOfWeek: DateTime) => React.ReactNode;
  onNewEventClick?: () => void;
  isLoading?: boolean;
  footerFunction?: React.ReactNode;
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const daysOfWeek = [0, 1, 2, 3, 4, 5, 6].map((d) => props.startOfWeek.plus({ day: d }));

  const onPrevClick = () => {
    props.onStartOfWeekChange(props.startOfWeek.minus({ week: 1 }));
  };

  const onNextClick = () => {
    props.onStartOfWeekChange(props.startOfWeek.plus({ week: 1 }));
  };

  const title = `${props.startOfWeek.toFormat('LLL d')} - ${props.startOfWeek.plus({ day: 6 }).toFormat('d')}`;

  return (
    <Stack
      sx={{
        position: 'relative',
      }}
    >
      <Header title={title} daysOfWeek={daysOfWeek} onPrevClick={onPrevClick} onNextClick={onNextClick} onNewEventClick={props.onNewEventClick} />

      <div
        style={{
          height: isMobile ? '60vh' : 'auto',
          overflowX: isMobile ? 'auto' : 'unset',
          padding: isMobile ? '0px 1px' : 'unset', // shows the edges of the table border.
          marginTop: '16px',
        }}
      >
        {isMobile && (
          <Stack flex={1} direction="row" height={`${HEADER_HEIGHT}px`}>
            <Stack flex={`1 0 120px`} />
            {daysOfWeek.map((dayOfWeek, idx) => (
              <HeaderLabel key={idx} dayOfWeek={dayOfWeek} />
            ))}
          </Stack>
        )}

        <Stack position="relative" direction="row">
          {props.isLoading ? <LoadingAnimation /> : null}
          <YAxisLabels isMobile={isMobile} />

          {daysOfWeek.map((dayOfWeek, idx) => (
            <TimeSlots key={idx} onSelected={props.onSelected} dayOfWeek={dayOfWeek} columnDetails={props.columnDetails} isMobile={isMobile} />
          ))}
        </Stack>
      </div>

      {props.footerFunction}
    </Stack>
  );
}

function Header(props: { title: string; onPrevClick: () => void; onNextClick: () => void; daysOfWeek: DateTime[]; onNewEventClick?: () => void }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  if (isMobile) {
    return <MobileHeader {...props} />;
  } else {
    return <DesktopHeader {...props} />;
  }
}

function DesktopHeader(props: {
  title: string;
  onPrevClick: () => void;
  onNextClick: () => void;
  daysOfWeek: DateTime[];
  onNewEventClick?: () => void;
}) {
  return (
    <Stack
      sx={{
        position: 'sticky',
        top: 0,
        backgroundColor: hootTokens.palette.white,
        zIndex: ZIndexLayer.Header,
        boxShadow: '0 4px 8px -4px rgba(0, 0, 0, 0.15), 0 4px 3px -2px rgba(0, 0, 0, 0.30)',
      }}
    >
      <Stack justifyContent="center" direction="row">
        <Box flex={1} />
        <Stack direction="row" justifyContent="space-between" alignItems="center" flex={'1 1 auto'} gap={1}>
          <IconButton
            variant="standard"
            sx={{ backgroundColor: hootTokens.surface[2], boxShadow: hootTokens.elevation.elevation1 }}
            onClick={props.onPrevClick}
          >
            <Icon name="chevron" />
          </IconButton>
          <Typography variant="displaysmall">{props.title}</Typography>
          <IconButton
            variant="standard"
            sx={{ backgroundColor: hootTokens.surface[2], boxShadow: hootTokens.elevation.elevation1 }}
            onClick={props.onNextClick}
          >
            <Icon name="chevron" sx={{ rotate: '180deg' }} />
          </IconButton>
        </Stack>
        <Stack direction="row" justifyContent="flex-end" flex={1}>
          {props.onNewEventClick ? (
            <Button onClick={props.onNewEventClick} variant="contained" startIcon={<Icon htmlColor={hootTokens.palette.white} name="add" />}>
              New Event
            </Button>
          ) : undefined}
        </Stack>
      </Stack>
      <Stack marginTop="32px" flex={1} direction="row" height={`${HEADER_HEIGHT}px`} mb={2}>
        <Stack flex={1} />
        {props.daysOfWeek.map((dayOfWeek, idx) => (
          <HeaderLabel key={idx} dayOfWeek={dayOfWeek} />
        ))}
      </Stack>
    </Stack>
  );
}

function MobileHeader(props: {
  title: string;
  onPrevClick: () => void;
  onNextClick: () => void;
  daysOfWeek: DateTime[];
  onNewEventClick?: () => void;
}) {
  return (
    <Stack
      sx={{
        position: 'sticky',
        top: 0,
        backgroundColor: hootTokens.palette.white,
        zIndex: ZIndexLayer.Header,
        borderBottom: '1px solid #EEE',
        paddingBottom: '16px',
        marginBottom: '8px',
      }}
    >
      <Stack justifyContent="space-between" alignItems="center" direction="row">
        <Stack direction="row" justifyContent="space-between" alignItems="center" flex={1}>
          <IconButton
            variant="standard"
            sx={{ backgroundColor: hootTokens.surface[2], boxShadow: hootTokens.elevation.elevation1 }}
            onClick={props.onPrevClick}
          >
            <Icon name="chevron" />
          </IconButton>
          <Typography variant="titlelarge">{props.title}</Typography>
          <IconButton
            variant="standard"
            sx={{ backgroundColor: hootTokens.surface[2], boxShadow: hootTokens.elevation.elevation1 }}
            onClick={props.onNextClick}
          >
            <Icon name="chevron" sx={{ rotate: '180deg' }} />
          </IconButton>
        </Stack>
      </Stack>
    </Stack>
  );
}

function HeaderLabel(props: { dayOfWeek: DateTime }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const isToday = props.dayOfWeek.toISODate() === DateTime.now().toISODate();

  return (
    <Stack justifyContent="center" alignItems="center" flex={isMobile ? '1 0 120px' : 1}>
      {isMobile ? (
        <Stack
          sx={{
            backgroundColor: isToday ? hootTokens.palette.black : undefined,
            color: isToday ? hootTokens.palette.white : undefined,
            paddingX: isToday ? '4px' : undefined,
            borderRadius: isToday ? '4px' : undefined,
          }}
          alignItems="center"
          justifyContent="center"
          height={`${HEADER_HEIGHT}px`}
          width="100%"
        >
          <Typography variant="bodysmall">{props.dayOfWeek.toFormat('EEE').toUpperCase()}</Typography>
          <Typography variant="bodysmall">{props.dayOfWeek.toFormat('dd').toUpperCase()}</Typography>
        </Stack>
      ) : (
        <Stack
          sx={{
            backgroundColor: isToday ? hootTokens.palette.black : undefined,
            color: isToday ? hootTokens.palette.white : undefined,
            paddingX: isToday ? '16px' : undefined,
            borderRadius: isToday ? '8px' : undefined,
          }}
          alignItems="center"
          justifyContent="center"
          height={`${HEADER_HEIGHT}px`}
          width={`${HEADER_WIDTH}px`}
        >
          <Typography variant="bodysmall">{props.dayOfWeek.toFormat('LLL dd').toUpperCase()}</Typography>
          <Typography variant="bodysmall">{props.dayOfWeek.toFormat('EEE').toUpperCase()}</Typography>
        </Stack>
      )}
    </Stack>
  );
}

function YAxisLabels({ isMobile }: { isMobile: boolean }) {
  return (
    <Column isMobile={isMobile}>
      {calendarYAxisLabels().map((yAxisLabel, idx) => (
        <TimeSlot key={`${yAxisLabel}-${idx}`} justifyContent="center" alignItems="center">
          <Typography variant="bodysmall" textAlign="center">
            {yAxisLabel}
          </Typography>
        </TimeSlot>
      ))}
    </Column>
  );
}

function TimeSlots(props: {
  dayOfWeek: DateTime;
  columnDetails: (dayOfWeek: DateTime) => React.ReactNode;
  onSelected: (dayOfWeek: DateTime) => void;
  isMobile: boolean;
}) {
  const handleTimeslotClick = () => {
    props.onSelected(props.dayOfWeek);
  };

  return (
    <Column isMobile={props.isMobile}>
      {props.columnDetails(props.dayOfWeek)}

      {calendarYAxisLabels().map((y, idx) => (
        <TimeSlot key={`${idx}-${y}`} onClick={handleTimeslotClick} />
      ))}
    </Column>
  );
}

// since we don't start at midnight, this will offset the time based on the first value in calendarYAxisLabels
// Note: if we don't set the zone in here, the Settings default runs after this is set, so it will be in the user's timezone instead of Winnipeg
const timeOffset = DateTime.fromFormat(`1970-01-01 ${calendarYAxisLabels()[0]}`, 'yyyy-LL-dd h:mm a').toMillis();
const topBoundary = DateTime.fromFormat(`1970-01-01 ${calendarYAxisLabels().slice(-1)[0]}`, 'yyyy-LL-dd h:mm a').plus({ minutes: 30 }); // need to go to the end of the slot, so adding extra 30 minutes

// this function calculates the offset from top of the timeslots container
// i.e: 8:00 AM ->    0
//      8:01 AM ->    1
//      8:30 AM ->   30
//      9:00 AM ->   60
//      12:00 AM -> 240
export function convertTimeToOffset(time: string): number {
  const dt = DateTime.fromFormat(`1970-01-01 ${time}`, 'yyyy-LL-dd h:mm a');
  if (dt > topBoundary) {
    return (topBoundary.toMillis() - timeOffset) / 60000;
  }
  return (dt.toMillis() - timeOffset) / 60000;
}

// This function will find all lessons that overlap with each other and group them together
// i.e: 10:00 AM -> 10:20 AM
// i.e: 10:10 AM -> 10:40 AM
// i.e: 10:30 AM -> 10:50 AM
// Would be considered 1 group since 10:10 -> 10:40 overlaps 10:00 and 10:20 AM
export function findOverlappingTimeslots(timeslots: LessonTimeSlot[]) {
  const buckets: LessonTimeSlot[][] = [];
  for (const timeslot of timeslots.sort((a, b) => (a.startAt < b.startAt ? -1 : 1))) {
    const bucket = buckets.find((bucket) =>
      bucket.some(
        (ts) =>
          isBetween(ts.startAt.toMillis(), timeslot.startAt.toMillis(), timeslot.endsAt.toMillis()) ||
          isBetween(timeslot.startAt.toMillis(), ts.startAt.toMillis(), ts.endsAt.toMillis()),
      ),
    );

    if (bucket) {
      bucket.push(timeslot);
    } else {
      buckets.push([timeslot]);
    }
  }

  return buckets;
}

const TimeSlot = styled(Stack)({
  boxSizing: 'border-box',
  height: `${WEEKLY_SCHEDULED_CELL_HEIGHT}px`,
  boxShadow: '-1px -1px #757575, inset -1px -1px 0 0 #757575',
  zIndex: ZIndexLayer.Bottom,
  backgroundColor: 'transparent',
  cursor: 'pointer',
});

const Column = styled(Stack)<{ isMobile?: boolean }>(({ isMobile }) => ({
  position: 'relative',
  height: `${COLUMN_HEIGHT}px`,
  flex: isMobile ? `1 0 120px` : 1,
  justifyContent: 'space-evenly',
}));

export const weeklySchedulePulseAnimation = keyframes`
    0% {
      opacity: 1;
      transform: scale(1);
    }
    80% {
      opacity: 0;
      transform: scale(1.5);
    }
    100% {
      opacity: 0;
      transform: scale(2);
    }
  `;

const shimmerAnimation = keyframes`
  to {
     background-position-x: 0%
  }
`;

const LoadingAnimation = styled(Stack)({
  position: 'absolute',
  height: '100%',
  width: '100%',
  background: `linear-gradient(-45deg, ${hootTokens.palette.neutral['180']} 60%, ${hootTokens.palette.neutral['140']} 70%, ${hootTokens.palette.neutral['180']} 80%)`,
  backgroundSize: '300%',
  backgroundPositionX: '100%',
  animation: `${shimmerAnimation} 1s infinite linear`,
});
