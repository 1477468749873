import { PMAUnitIdentifier } from '@hoot-reading/hoot-core/dist/enums/progress-monitoring-assessment';
import { PMAStepTestAnswer } from '@hoot-reading/hoot-core/dist/enums/progress-monitoring-assessment/pma-step-test-answer.enum';
import { UnitQuestionAnswerPair } from '@hoot-reading/hoot-core/dist/interfaces/progress-monitoring-assessment/pma-submission-data.interface';
import Grid from '@mui/material/Grid2';
import { Box, Stack } from '@mui/system';
import { Fragment, useCallback, useEffect, useState } from 'react';
import { UnitIdentifierHRAV2 } from '@hoot/models/api/enums/hoot-reading-assessment';
import { QuestionnaireContainer } from '@hoot/ui/components/v2/assessments/teacher/QuestionnaireContainer';
import { QuestionnaireFooter } from '@hoot/ui/components/v2/assessments/teacher/QuestionnaireFooter';
import UnitCompleteCard from '@hoot/ui/components/v2/assessments/teacher/UnitCompleteCard';
import { EditAnswerDialog } from '@hoot/ui/components/v2/assessments/teacher/dialogs/EditAnswerDialog';
import { LeaveCurrentUnitDialog } from '@hoot/ui/components/v2/assessments/teacher/dialogs/LeaveCurrentUnitDialog';
import { Button } from '@hoot/ui/components/v2/core/Button';
import Card from '@hoot/ui/components/v2/core/Card';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';
import { Icon } from '@hoot/ui/components/v2/core/Icon';
import CheckmarkSquare from '@hoot/ui/components/v2/icons/CheckmarkSquare';
import CloseFilledSquareIcon from '@hoot/ui/components/v2/icons/CloseFilledSquareIcon';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';

const NUM_REAL_QUESTIONS_PER_COLUMN = 10;

export interface Q3UnitQuestionAnswerPair extends UnitQuestionAnswerPair {
  // This is really only here to help us figure out which questions are _actual_ questions vs example prompts.
  // Example prompts do not display a question number beside it, nor is it included in the unfurling logic.
  displayIndex: number | null;
  isExampleQuestion?: boolean;
  helpText?: string;
}

interface AssessmentQuestionnaireLayout3Props {
  unitIdentifier: UnitIdentifierHRAV2 | PMAUnitIdentifier;
  title: string;
  instruction: string;
  instructionsExpandedDefault?: boolean;
  unitTitle: string;
  isFormIncomplete: boolean;
  currentQuestionIndex: number | null;
  onSelectQuestionAtIndex: (index: number | null) => void;
  questionAnswerPairs: Q3UnitQuestionAnswerPair[];
  onQuestionAnswered: (questionIndex: number, answer: PMAStepTestAnswer) => void;
  isFormDirty: boolean;
  isSubmitting: boolean;
  onNavToPreviousUnit: () => void;
  onSubmit: () => void;
  onNext?: () => void;
  hasUnitBeenSubmitted: boolean;
  isNextUnitEnabled: boolean;
  isPreviousUnitEnabled: boolean;
  numQuestionsToShow?: number;
  onExitAssessment: () => void;
}

const QuestionnaireLayout3 = (props: AssessmentQuestionnaireLayout3Props) => {
  const {
    unitIdentifier,
    title,
    instruction,
    instructionsExpandedDefault,
    unitTitle,
    currentQuestionIndex,
    isFormIncomplete,
    onSelectQuestionAtIndex,
    questionAnswerPairs,
    onQuestionAnswered,
    isFormDirty,
    onNavToPreviousUnit,
    onSubmit,
    onNext,
    isSubmitting,
    hasUnitBeenSubmitted,
    isNextUnitEnabled,
    isPreviousUnitEnabled,
    numQuestionsToShow = questionAnswerPairs.length,
  } = props;

  const [showConfirmLeaveDialog, setShowConfirmLeaveDialog] = useState(false);
  const [showEditAnswerDialog, setShowEditAnswerDialog] = useState(false);

  const nextUnansweredQuestionIndex = questionAnswerPairs.findIndex((x) => x.answer === PMAStepTestAnswer.NoAnswer);
  const currentQaPair = currentQuestionIndex !== null ? questionAnswerPairs[currentQuestionIndex] : undefined;

  const displayedScorecardQuestions = questionAnswerPairs.slice(0, numQuestionsToShow);
  const numDisplayedExampleScorecardQuestions = displayedScorecardQuestions.filter((x) => x.isExampleQuestion).length;
  const columnCount = Math.ceil((displayedScorecardQuestions.length - numDisplayedExampleScorecardQuestions) / NUM_REAL_QUESTIONS_PER_COLUMN);

  const selectNextUnansweredQuestionIndex = useCallback(() => {
    onSelectQuestionAtIndex(nextUnansweredQuestionIndex);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nextUnansweredQuestionIndex]);

  // EDGE CASE:
  // If the unit goes from having all answers completed, to _not_ having all answers completed, this will
  // effectively remove the "unit complete" card. In this case, we need to bring focus back to the first "focusable"
  // field. Note: this can only happen on Step-Test units.
  useEffect(() => {
    if (isFormIncomplete && currentQuestionIndex === null) {
      selectNextUnansweredQuestionIndex();
    }
  }, [isFormIncomplete, currentQuestionIndex, selectNextUnansweredQuestionIndex]);

  // If we've answered all questions, then clear out the current question selection.
  useEffect(() => {
    if (!isFormIncomplete) {
      onSelectQuestionAtIndex(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFormIncomplete]);

  const onCorrectClick = () => {
    if (currentQuestionIndex === null) return;
    onQuestionAnswered(currentQuestionIndex, PMAStepTestAnswer.Correct);
    advanceToNextQuestion();
  };

  const onIncorrectClick = () => {
    if (currentQuestionIndex === null) return;
    onQuestionAnswered(currentQuestionIndex, PMAStepTestAnswer.Wrong);
    advanceToNextQuestion();
  };

  const advanceToNextQuestion = () => {
    if (currentQuestionIndex === null) return;

    const newIndex = currentQuestionIndex + 1;
    onSelectQuestionAtIndex(newIndex < questionAnswerPairs.length ? newIndex : null);
  };

  const onPreviousUnitClick = () => {
    if (isFormDirty) {
      setShowConfirmLeaveDialog(true);
    } else {
      onNavToPreviousUnit();
    }
  };

  const handleSubmit = () => {
    if (!onNext || props.isFormDirty) {
      onSubmit();
    } else {
      if (onNext) {
        onNext();
      }
    }
  };

  const onQuestionSelected = (index: number) => {
    onSelectQuestionAtIndex(index);

    // Once the form is completed, we have to answer all Qs using the edit answer dialog.
    if (!isFormIncomplete) {
      setShowEditAnswerDialog(true);
    }
  };

  const onDismissEditAnswerDialog = () => {
    setShowEditAnswerDialog(false);
    onSelectQuestionAtIndex(null);
  };

  const onAnswerEdited = (newAnswer: PMAStepTestAnswer) => {
    if (currentQuestionIndex === null) return;
    onQuestionAnswered(currentQuestionIndex, newAnswer);
    onDismissEditAnswerDialog();

    // After we've edited an answer, remove the selected question pointer so that we can see the "correct" or "incorrect"
    // indicator in the UI for the Q that was just edited.
    onSelectQuestionAtIndex(null);
  };

  return (
    <QuestionnaireContainer
      unitIdentifier={unitIdentifier}
      title={title}
      instruction={instruction}
      unitTitle={unitTitle}
      onExitAssessment={props.onExitAssessment}
      instructionsExpandedDefault={instructionsExpandedDefault}
    >
      <Stack sx={{ width: '100%' }}>
        <Grid container spacing={8}>
          {/* Left - Student View/Full prompt */}
          <Grid size={{ md: 12, lg: 6 }}>
            <Card elevation={0} sx={{ height: 460, border: '1px solid black', borderRadius: '8px' }} contentSx={{ height: '100%', padding: 3 }}>
              <Stack justifyContent="center" alignItems="center" gap={2} height="100%">
                {!isFormIncomplete ? (
                  <UnitCompleteCard hasBeenSubmitted={hasUnitBeenSubmitted} />
                ) : currentQaPair ? (
                  <>
                    <Stack
                      sx={{
                        backgroundColor: hootTokens.surface[2],
                        borderRadius: '8px',
                        padding: '16px 32px',
                        width: '100%',
                        gap: 4,
                      }}
                    >
                      {currentQaPair.helpText && (
                        <HootTypography variant="bodysmall" isPII={false} sx={{ textAlign: 'center' }}>
                          {currentQaPair.helpText}
                        </HootTypography>
                      )}
                      <Prompt qaPair={currentQaPair} />
                    </Stack>
                    <Box display="flex" justifyContent="center" gap={2}>
                      {currentQaPair.isExampleQuestion ? (
                        <Button onClick={onCorrectClick} variant="contained" color="success.190" startIcon={<CheckmarkSquare />}>
                          OK! We are ready to continue
                        </Button>
                      ) : (
                        <>
                          <Button onClick={onIncorrectClick} variant="contained" color="error.190" startIcon={<CloseFilledSquareIcon />}>
                            Incorrect
                          </Button>
                          <Button onClick={onCorrectClick} variant="contained" color="success.190" startIcon={<CheckmarkSquare />}>
                            Correct
                          </Button>
                        </>
                      )}
                    </Box>
                  </>
                ) : null}
              </Stack>
            </Card>
          </Grid>

          {/* Right - Scorecard/Word List */}
          <Grid size={{ md: 12, lg: 6 }}>
            <HootTypography variant="tableheadingactive" isPII={false}>
              Scorecard
            </HootTypography>
            <Box sx={{ width: '100%', columnCount }}>
              {displayedScorecardQuestions.map((q, i) => (
                <Fragment key={q.wordPrompt}>
                  <ScorecardItem
                    question={q}
                    index={i}
                    currentQuestionIndex={currentQuestionIndex}
                    nextUnansweredQuestionIndex={nextUnansweredQuestionIndex}
                    onQuestionSelected={onQuestionSelected}
                    isFormComplete={!isFormIncomplete}
                  />
                </Fragment>
              ))}
            </Box>
          </Grid>
        </Grid>
        <EditAnswerDialog
          open={showEditAnswerDialog}
          questionLabel={currentQuestionIndex !== null ? (questionAnswerPairs[currentQuestionIndex]?.wordPrompt ?? '') : ''}
          defaultAnswer={
            currentQuestionIndex !== null
              ? (questionAnswerPairs[currentQuestionIndex]?.answer ?? PMAStepTestAnswer.NoAnswer)
              : PMAStepTestAnswer.NoAnswer
          }
          onSubmit={onAnswerEdited}
          onCancel={onDismissEditAnswerDialog}
          setIsUnitQuestionnaireDirty={() => {}} // Handling this differently.
        />
        <QuestionnaireFooter
          allFormFieldsFilledOut={!isFormIncomplete}
          onPreviousUnitClick={onPreviousUnitClick}
          setLeaveDialog={setShowConfirmLeaveDialog}
          isUnitQuestionnaireDirty={isFormDirty}
          disableSubmissionBtn={isFormIncomplete || isSubmitting}
          isSubmitting={isSubmitting}
          onNextUnitAndSubmissionClick={handleSubmit}
          isNextEnabled={isNextUnitEnabled}
          isPrevEnabled={isPreviousUnitEnabled}
          submitButtonLabel={!isFormIncomplete && !props.isFormDirty ? 'Continue' : undefined}
        />
        <LeaveCurrentUnitDialog open={showConfirmLeaveDialog} setOpen={setShowConfirmLeaveDialog} confirmLeave={onNavToPreviousUnit} />
      </Stack>
    </QuestionnaireContainer>
  );
};

interface ScorecardItemProps {
  question: Q3UnitQuestionAnswerPair;
  index: number;
  currentQuestionIndex: number | null;
  isFormComplete: boolean;
  nextUnansweredQuestionIndex: number;
  onQuestionSelected: (index: number) => void;
}

const ScorecardItem = (props: ScorecardItemProps) => {
  const { question, index, currentQuestionIndex, isFormComplete, nextUnansweredQuestionIndex, onQuestionSelected } = props;
  const { displayIndex } = question;
  return (
    <Stack flexDirection="row" alignItems="center" gap={1}>
      <Box sx={{ width: 24 }}>
        {currentQuestionIndex === index ? (
          <Icon name="play" />
        ) : question.answer === PMAStepTestAnswer.Correct ? (
          // Note: color={undefined} will just use the default colour which is green.
          <Icon name="checkmark_square" color={question.isExampleQuestion ? 'neutral.140' : undefined} />
        ) : question.answer === PMAStepTestAnswer.Wrong ? (
          <Icon name="close_filled_square" />
        ) : null}
      </Box>

      {/* Prompt */}
      <Button
        // Must answer questions in order. Only questions that have already been answered and the first
        // unanswered questions can be selected.
        // Also, if the form is completed, you can't "edit" any example questions (there is no point since the only
        // answer you can give them is "CORRECT").
        disabled={(nextUnansweredQuestionIndex >= 0 && index > nextUnansweredQuestionIndex) || (isFormComplete && question.isExampleQuestion)}
        onClick={() => onQuestionSelected(index)}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          minHeight: '42px',
          alignItems: 'center',
          gap: '4px',
          padding: '8px 16px',
        }}
      >
        <HootTypography variant="bodylarge" isPII={false}>
          {displayIndex ? `${displayIndex}. ` : ''}
          {question.wordPrompt}
        </HootTypography>
      </Button>
    </Stack>
  );
};

const Prompt = (props: { qaPair: Q3UnitQuestionAnswerPair }): JSX.Element | null => {
  const { qaPair } = props;

  const prompt = qaPair.fullPrompt;
  const boldedWord = qaPair.wordPrompt;
  const toArray = prompt.split(boldedWord);

  return (
    <>
      {/* Emphasizes the current word within the full prompt.*/}
      <HootTypography variant="displaysmall" textAlign="center" fontWeight={400} isPII={false}>
        {toArray.map((x, idx) => (
          <Fragment key={`${x}-${idx}`}>
            {x}
            {idx !== toArray.length - 1 && <span style={{ fontWeight: 700, textDecorationLine: 'underline' }}>{boldedWord}</span>}
          </Fragment>
        ))}
      </HootTypography>
    </>
  );
};

export default QuestionnaireLayout3;
