import { Stack } from '@mui/material';
import { capitalCase } from 'change-case';
import { DateTime } from 'luxon';
import { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import {
  FormativeAssessmentSearchResult,
  GetStudentFormativeAssessmentsQuery,
  StudentFormativeAssessmentsQuerySortKeyEnum,
  useGetFormativeAssessments,
} from '@hoot/hooks/api/assessment/useGetStudentFormativeAssessments';
import { FormativeAssessmentType } from '@hoot/models/api/enums/lesson-review-enums';
import { OrderBy } from '@hoot/models/api/enums/queryEnums';
import { DEFAULT_PAGE_SIZE, GenericPaginatedResponse } from '@hoot/models/api/pagination';
import { routesDictionary } from '@hoot/routes/routesDictionary';
import ViewStateIllustration, { IllustrationEnum } from '@hoot/ui/components/v2/ViewStateIllustration';
import { Button } from '@hoot/ui/components/v2/core/Button';
import Card from '@hoot/ui/components/v2/core/Card';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';
import { HeaderData, TableV2 } from '@hoot/ui/components/v2/core/Table';
import FluencyFormativeAssessmentDetailsModal from './FluencyFormativeAssessmentDetailsModal';

interface FormativeAssessmentTableRow {
  date: string;
  type: string;
  resourceUsed: string;
  score: React.ReactNode;
  action: React.ReactNode;
}

const headers: HeaderData<FormativeAssessmentTableRow>[] = [
  { name: 'Date', property: 'date', isSortable: true, width: '15%' },
  { name: 'Type', property: 'type', isSortable: false, width: '15%' },
  { name: 'Resource Used', property: 'resourceUsed', isSortable: true, width: '30%' },
  { name: 'Score', property: 'score', isSortable: false, width: '20%' },
  { name: 'Action', property: 'action', isSortable: false, width: '20%' },
];

const FormativeAssessmentHistoryCard = () => {
  const { studentProfileId } = useParams();

  const [query, setQuery] = useState<GetStudentFormativeAssessmentsQuery>({
    page: 1,
    pageSize: DEFAULT_PAGE_SIZE,
    orderBy: StudentFormativeAssessmentsQuerySortKeyEnum.Date,
    sortDirection: OrderBy.Desc,
  });

  const [results, setResults] = useState<GenericPaginatedResponse<FormativeAssessmentTableRow>>({
    page: 1,
    pageSize: DEFAULT_PAGE_SIZE,
    data: [],
    count: 0,
  });

  const [selectedFormativeAssessment, setSelectedFormativeAssessment] = useState<FormativeAssessmentSearchResult | undefined>();

  const getFormativeAssessmentsRequest = useGetFormativeAssessments(studentProfileId!, query, {
    enabled: !!studentProfileId,
    onSuccess: (response) => {
      setResults({
        ...response,
        data: mapAssessmentResponseToTableRows(response.data),
      });
    },
  });

  const handleScore = (formativeAssessment: FormativeAssessmentSearchResult): React.ReactNode => {
    if (formativeAssessment.score) {
      return formativeAssessment.score;
    } else {
      return (
        <Button variant="outlined" size="small" onClick={() => setSelectedFormativeAssessment(formativeAssessment)}>
          View Details
        </Button>
      );
    }
  };

  const mapAssessmentResponseToTableRows = (formativeAssessments: FormativeAssessmentSearchResult[]): FormativeAssessmentTableRow[] => {
    return formativeAssessments.map<FormativeAssessmentTableRow>((formativeAssessment) => {
      const lastUpdatedAtFormatted = DateTime.fromMillis(formativeAssessment.lastUpdatedAt).toFormat('cccc, LLLL dd, yyyy');
      return {
        date: lastUpdatedAtFormatted,
        type: [FormativeAssessmentType.LettersAndSounds, FormativeAssessmentType.WordReading].includes(formativeAssessment.formativeAssessmentType)
          ? 'Quick Checks'
          : capitalCase(formativeAssessment.formativeAssessmentType),
        resourceUsed: formativeAssessment.bookTitle,
        score: handleScore(formativeAssessment),
        action: (
          <Stack direction="row" gap={2}>
            <Link
              to={routesDictionary.myStudents.details.reviews.lessonReviewId.url(
                formativeAssessment.studentProfileId,
                formativeAssessment.lessonReviewId,
              )}
            >
              <Button variant="outlined" size="small">
                Go to Lesson Review
              </Button>
            </Link>
          </Stack>
        ),
      };
    });
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const pageSize = parseInt(event.target.value, 10);
    setQuery((currentState) => ({ ...currentState, page: 1, pageSize }));
  };

  const handleChangePage = (_event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setQuery((currentState) => ({ ...currentState, page: newPage + 1 }));
  };

  const handleSortBy = (selectedColumn: keyof FormativeAssessmentTableRow) => {
    const getQueryOrderByColumn = (): StudentFormativeAssessmentsQuerySortKeyEnum => {
      switch (selectedColumn) {
        case 'date':
          return StudentFormativeAssessmentsQuerySortKeyEnum.Date;
        case 'resourceUsed':
          return StudentFormativeAssessmentsQuerySortKeyEnum.ResourceUsed;
        case 'score':
          return StudentFormativeAssessmentsQuerySortKeyEnum.Score;
        default:
          return StudentFormativeAssessmentsQuerySortKeyEnum.Date;
      }
    };
    const queryOrderByColumn = getQueryOrderByColumn();
    setQuery((currentState) => ({
      ...currentState,
      orderBy: queryOrderByColumn,
      sortDirection:
        queryOrderByColumn !== currentState.orderBy ? OrderBy.Asc : currentState.sortDirection === OrderBy.Asc ? OrderBy.Desc : OrderBy.Asc,
    }));
  };

  function orderByColumn(): keyof FormativeAssessmentTableRow {
    switch (query.orderBy) {
      case StudentFormativeAssessmentsQuerySortKeyEnum.Date:
        return 'date';
      case StudentFormativeAssessmentsQuerySortKeyEnum.ResourceUsed:
        return 'resourceUsed';
      case StudentFormativeAssessmentsQuerySortKeyEnum.Score:
        return 'score';
      default:
        return 'date';
    }
  }

  const onDismissAssessmentDetailsModal = () => {
    setSelectedFormativeAssessment(undefined);
  };

  return (
    <>
      <Card isLoading={getFormativeAssessmentsRequest.isFetching}>
        <Stack gap={2}>
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <HootTypography isPII={false} variant="titlemedium">
              Formative Assessment History
            </HootTypography>
          </Stack>
          <TableV2
            isPaginated
            isSortable
            data={results.data}
            headers={headers}
            onRowsPerPageChange={handleChangeRowsPerPage}
            count={results.count}
            page={query.page - 1}
            onPageChange={handleChangePage}
            onSortBy={handleSortBy}
            sortOrder={query.sortDirection ?? OrderBy.Asc}
            sortBy={orderByColumn()}
            emptyViewState={<ViewStateIllustration illustration={IllustrationEnum.EmptyState} />}
          />
        </Stack>
      </Card>
      <FluencyFormativeAssessmentDetailsModal
        open={!!selectedFormativeAssessment}
        formativeAssessment={selectedFormativeAssessment}
        onClose={onDismissAssessmentDetailsModal}
      />
    </>
  );
};

export default FormativeAssessmentHistoryCard;
