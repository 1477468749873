import { useEffect } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { routesDictionary } from '@hoot/routes/routesDictionary';
import ImpersonateLoginView from '@hoot/ui/components/v2/ImpersonateLoginView';
import PublicRouterLayoutV2 from '@hoot/ui/components/v2/layout/PublicRouterLayout';
import ChangePasswordV2 from '@hoot/ui/pages/v2/public/change-password/ChangePasswordV2';
import DistrictLoginPage from '@hoot/ui/pages/v2/public/login/DistrictLoginPage';
import LoginPage from '@hoot/ui/pages/v2/public/login/LoginPage';
import PublicStudentSchedule from '@hoot/ui/pages/v2/public/public-student-schedule/PublicStudentSchedule';

const PublicRouter = () => {
  const location = useLocation();

  return (
    <>
      <Routes>
        <Route element={<PublicRouterLayoutV2 />}>
          <Route path={routesDictionary.impersonateLogin.path} element={<ImpersonateLoginView />} />
          <Route path={routesDictionary.changePassword.path} element={<ChangePasswordV2 />} />
          <Route path={routesDictionary.login.path}>
            <Route path={routesDictionary.login.district.path} element={<DistrictLoginPage />} />
            <Route index element={<LoginPage />} />
          </Route>
          <Route path={routesDictionary.publicStudentSchedule.path}>
            <Route path={routesDictionary.publicStudentSchedule.location.path} element={<PublicStudentSchedule />} />
          </Route>
          <Route path={routesDictionary.freeLesson.path} element={<ExternalRedirect to={routesDictionary.getInTouch.url} />} />
        </Route>
        <Route
          path="*"
          element={
            <Navigate
              to={routesDictionary.login.url}
              state={{
                redirectPath: location.pathname,
                redirectSearch: location.search,
              }}
              replace
            />
          }
        />
      </Routes>
    </>
  );
};

const ExternalRedirect = ({ to }: { to: string }) => {
  useEffect(() => {
    window.location.href = to;
  }, [to]);

  return null;
};

export default PublicRouter;
