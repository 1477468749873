import useProfile from '@hoot/hooks/useProfile';
import NoProfileRouter from '@hoot/routes/secure/profile/NoProfileRouter';
import ParentRouter from '@hoot/routes/secure/profile/ParentRouter';
import StudentRouter from '@hoot/routes/secure/profile/StudentRouter';
import DistrictRepsRoutes from '@hoot/routes/secure/profile/district-rep/DistrictRepRouter';
import TeacherRouter from '@hoot/routes/secure/profile/teacher/TeacherRouter';
import { DailyProviderManager } from '@hoot/ui/components/v2/layout/DailyHootLayout';
import { UserProfileType } from '../../models/api/user';
import { SocketProvider } from '../../ui/context/SocketContext';

const SecureRouter = () => {
  const { profile } = useProfile();
  const profileRouter = (): JSX.Element => {
    switch (profile?.type) {
      case UserProfileType.Teacher:
        return <TeacherRouter />;
      case UserProfileType.Student:
        return <StudentRouter />;
      case UserProfileType.DistrictRep:
        return <DistrictRepsRoutes />;
      case UserProfileType.Parent:
        return <ParentRouter />;
      default:
        // Authenticated, but no profile is selected.
        return <NoProfileRouter />;
    }
  };

  return (
    <SocketProvider>
      <DailyProviderManager>{profileRouter()}</DailyProviderManager>
    </SocketProvider>
  );
};

export default SecureRouter;
