const Google = () => {
  return (
    <>
      <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_16706_6084)">
          <path
            d="M12.5003 4.75C14.2703 4.75 15.8553 5.36 17.1053 6.55L20.5303 3.125C18.4503 1.19 15.7353 0 12.5003 0C7.81027 0 3.75527 2.69 1.78027 6.61L5.77027 9.705C6.71527 6.86 9.37027 4.75 12.5003 4.75Z"
            fill="#EA4335"
          />
          <path
            d="M23.99 12.275C23.99 11.49 23.915 10.73 23.8 10H12.5V14.51H18.97C18.68 15.99 17.84 17.25 16.58 18.1L20.445 21.1C22.7 19.01 23.99 15.92 23.99 12.275Z"
            fill="#4285F4"
          />
          <path
            d="M5.765 14.2949C5.525 13.5699 5.385 12.7999 5.385 11.9999C5.385 11.1999 5.52 10.4299 5.765 9.70486L1.775 6.60986C0.96 8.22986 0.5 10.0599 0.5 11.9999C0.5 13.9399 0.96 15.7699 1.78 17.3899L5.765 14.2949Z"
            fill="#FBBC05"
          />
          <path
            d="M12.5004 24C15.7404 24 18.4654 22.935 20.4454 21.095L16.5804 18.095C15.5054 18.82 14.1204 19.245 12.5004 19.245C9.37039 19.245 6.71539 17.135 5.76539 14.29L1.77539 17.385C3.75539 21.31 7.81039 24 12.5004 24Z"
            fill="#34A853"
          />
        </g>
        <defs>
          <clipPath id="clip0_16706_6084">
            <rect width="24" height="24" fill="white" transform="translate(0.5)" />
          </clipPath>
        </defs>
      </svg>
    </>
  );
};

export default Google;
