import { Button, Collapse, DialogActions, DialogContent, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { Stack } from '@mui/system';
import _ from 'lodash';
import { useRef, useState } from 'react';
import {
  ComprehensionAssessmentSection,
  ComprehensionFormativeAssessmentQuestionnaire,
  ComprehensionQuestions,
  FormativeAssessmentType,
  comprehensionQuestionnaire,
} from '@hoot/models/api/enums/lesson-review-enums';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';
import { Icon } from '@hoot/ui/components/v2/core/Icon';
import { LessonReviewFormativeAssessmentBook, useLessonReviewWizardContext } from '../LessonReviewContextProvider';

const LessonReviewComprehensionRubricStep = () => {
  const {
    formativeAssessmentState,
    actions: { goToNextStep, goToPreviousStep, attachComprehensionRubricData },
  } = useLessonReviewWizardContext();
  const [openInstructions, setOpenInstructions] = useState<boolean>(false);
  const [selectedFormativeAssessmentBooks, setSelectedFormativeAssessmentBooks] = useState<LessonReviewFormativeAssessmentBook[]>(
    formativeAssessmentState?.formativeAssessmentBooks.filter((book) =>
      book.formativeAssessmentType?.includes(FormativeAssessmentType.Comprehension),
    ) ?? [],
  );
  const [currentBook, setCurrentBook] = useState<LessonReviewFormativeAssessmentBook>(selectedFormativeAssessmentBooks[0]);
  const dialogContentRef = useRef<HTMLDivElement | null>(null);
  const currentIndex = selectedFormativeAssessmentBooks.findIndex((book) => book.bookId === currentBook.bookId);

  const handleClick = () => {
    setOpenInstructions((prevVal) => !prevVal);
  };

  function checkGoToNextStep(selectedFormativeAssessmentBooks: LessonReviewFormativeAssessmentBook[]): boolean {
    const currentlySelectedBook = selectedFormativeAssessmentBooks.find((b) => b.bookId === currentBook.bookId);

    if (!currentlySelectedBook) {
      return false;
    }

    const isBookComplete = (book: LessonReviewFormativeAssessmentBook) => {
      const questions = book.textReadingData?.comprehensionQuestions;
      const isComplete = !!questions && Object.values(questions).every((value) => value !== null);
      return isComplete;
    };

    const isCurrentBookComplete = isBookComplete(currentlySelectedBook);
    const incompleteBooks = selectedFormativeAssessmentBooks.filter((book) => !isBookComplete(book));
    return (isCurrentBookComplete && incompleteBooks.length > 0) || incompleteBooks.length === 0;
  }

  const canGoToNextStep = checkGoToNextStep(selectedFormativeAssessmentBooks);

  const onComprehensionQuestionsChanged = (bookId: string, comprehensionQuestions?: Partial<ComprehensionQuestions>) => {
    const updatedComprehensionQuestions: ComprehensionQuestions = {
      [ComprehensionAssessmentSection.TextSpecificKnowledge]: comprehensionQuestions?.[ComprehensionAssessmentSection.TextSpecificKnowledge] ?? null,
      [ComprehensionAssessmentSection.TextStructure]: comprehensionQuestions?.[ComprehensionAssessmentSection.TextStructure] ?? null,
      [ComprehensionAssessmentSection.SentenceAnalysis]: comprehensionQuestions?.[ComprehensionAssessmentSection.SentenceAnalysis] ?? null,
      [ComprehensionAssessmentSection.VerbalReasoning]: comprehensionQuestions?.[ComprehensionAssessmentSection.VerbalReasoning] ?? null,
      [ComprehensionAssessmentSection.Vocabulary]: comprehensionQuestions?.[ComprehensionAssessmentSection.Vocabulary] ?? null,
    };

    setSelectedFormativeAssessmentBooks((prevBooks) =>
      prevBooks.map((book) =>
        book.bookId === bookId
          ? {
              ...book,
              textReadingData: {
                comprehensionQuestions: updatedComprehensionQuestions,
                performanceMetrics: book.textReadingData?.performanceMetrics ?? undefined,
              },
            }
          : book,
      ),
    );
  };

  const onClickBack = () => {
    if (currentIndex > 0) {
      // If there is a previous book, set it as the current book
      setCurrentBook(selectedFormativeAssessmentBooks[currentIndex - 1]);
    } else {
      // If on the first book, go to the previous step
      goToPreviousStep();
    }
  };

  const onClickNext = () => {
    if (currentIndex < selectedFormativeAssessmentBooks.length - 1) {
      // Move to the next book in order
      setCurrentBook(selectedFormativeAssessmentBooks[currentIndex + 1]);
      if (dialogContentRef.current) {
        dialogContentRef.current.scrollTo({ top: 0, behavior: 'smooth' });
      }
    } else {
      // If we're at the last book, attach data and go to the next step
      attachComprehensionRubricData(selectedFormativeAssessmentBooks);
      goToNextStep();
    }
  };

  const nextButtonText = () => {
    if (currentIndex < selectedFormativeAssessmentBooks.length - 1) {
      return 'Next Book';
    } else {
      return 'Next';
    }
  };

  return (
    <>
      <DialogContent ref={dialogContentRef}>
        <Stack gap={2} padding={1}>
          <HootTypography isPII={false} variant="titlemedium">
            {`Formative Assessment - Comprehension Questions`}
          </HootTypography>
          <HootTypography isPII={false} variant="bodylarge">
            {currentBook.title}
          </HootTypography>
          <Stack gap={1}>
            <Button
              startIcon={
                openInstructions ? <Icon name="collapse_filled" sx={{ height: 20 }} /> : <Icon name="solid_add_rectangle" htmlColor="primary.0" />
              }
              onClick={handleClick}
              sx={{ width: '150px', gap: '8px' }}
            >
              <HootTypography isPII={false} variant="titlesmall">
                Instructions
              </HootTypography>
            </Button>
            <Collapse in={openInstructions} timeout="auto" unmountOnExit>
              <HootTypography isPII={false} variant="bodylarge">
                After the student has finished reading the selected text, ask every question included in the Text Reading Assessment book. Allow and
                support students to navigate back through the text to best answer these questions. Score student responses using the rubric below.
                Remember, you'll score vocabulary holistically, thinking about how the student has answered all other questions.
              </HootTypography>
            </Collapse>
          </Stack>
          <Stack gap={4}>
            <Stack key={currentBook.bookId} gap={2} padding={2} border="1px solid #ccc" borderRadius="8px">
              {comprehensionQuestionnaire.map((q) => (
                <Section
                  key={q.section}
                  onChange={(section) => (_event, value) =>
                    onComprehensionQuestionsChanged(currentBook.bookId, {
                      ...selectedFormativeAssessmentBooks.find((book) => book.bookId === currentBook.bookId)?.textReadingData?.comprehensionQuestions,
                      [section]: Number(value),
                    })
                  }
                  q={q}
                  answer={
                    selectedFormativeAssessmentBooks.find((book) => book.bookId === currentBook.bookId)?.textReadingData?.comprehensionQuestions?.[
                      q.section
                    ] ?? null
                  }
                />
              ))}
            </Stack>
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClickBack}>
          Go Back
        </Button>
        <Button variant="contained" onClick={onClickNext} disabled={!canGoToNextStep}>
          {nextButtonText()}
        </Button>
      </DialogActions>
    </>
  );
};

function Section(props: {
  q: ComprehensionFormativeAssessmentQuestionnaire;
  onChange: (section: ComprehensionAssessmentSection) => (_event: React.ChangeEvent<HTMLInputElement>, value: string) => void;
  answer: number | null;
}) {
  return (
    <Stack marginTop="24px">
      <HootTypography variant="titlesmall" isPII={false}>
        {props.q.title}
      </HootTypography>
      <HootTypography isPII={false} sx={{ fontStyle: 'italic', fontWeight: 400 }} variant="titlesmall">
        {props.q.subTitle}
      </HootTypography>
      <RadioGroup onChange={props.onChange(props.q.section)} name="test" value={props.answer}>
        {props.q.options.map((o) => (
          <FormControlLabel
            value={o.val}
            control={<Radio />}
            label={<HootTypography isPII={false}>{o.text}</HootTypography>}
            sx={{ display: 'flex', alignItems: 'center' }}
          />
        ))}
      </RadioGroup>
    </Stack>
  );
}

export default LessonReviewComprehensionRubricStep;
