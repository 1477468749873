import { StudentGrade } from '@hoot-reading/hoot-core/dist/enums/student-grade';
import { StudentStatus } from '@hoot-reading/hoot-core/dist/enums/user/student/student-status.enum';
import axios, { AxiosError } from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '@hoot/config';
import { AttendanceFilterEnum, DateFilterEnum, LateJoinFilterEnum } from '@hoot/models/api/district-rep-reporting/common';
import { DateRangeFormValues } from '@hoot/ui/components/v2/dialogs/DateRangeDialog';
import { QueryKey } from '../../queryKeys';

export interface LocationReportingFilters {
  date?: DateFilterEnum[] | DateRangeFormValues;
  schoolIds?: string[];
  studentStatus?: StudentStatus[];
  studentGrade?: StudentGrade[];
  attendance?: AttendanceFilterEnum[];
  lateJoin?: LateJoinFilterEnum[];
}

export interface LocationReportingMetricResponse {
  totalLessons: number;
  occurredLessons: number;
  totalStudents: number;
  averageAttendanceRate: number;
  lateJoinRate: number; // Used in School > Reporting tab.
  minutesInLesson: number;
  totalSchools: number; // Used in District Dashboard Page (aka Student Aggregate Report)
}

export default function useGetLocationReportingMetrics(
  locationId: string,
  filters: LocationReportingFilters,
  options?: Omit<UseQueryOptions<LocationReportingMetricResponse, AxiosError>, 'queryKey' | 'queryFn'>,
) {
  return useQuery(
    [QueryKey.GetDistrictRepReportingLocationMetrics, locationId, filters],
    async () => {
      return (
        await axios.get<LocationReportingMetricResponse>(`${config.apiUrl}/public/district-representative/reporting/location/${locationId}/metrics`, {
          params: filters,
        })
      ).data;
    },
    options,
  );
}
