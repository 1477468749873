import { ScheduledLessonStatus } from '@hoot-reading/hoot-core/dist/enums/scheduled-lesson';
import { Badge, Box } from '@mui/material';
import { DateTime } from 'luxon';
import React, { useEffect, useMemo } from 'react';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';
import { spliceChunk } from '@hoot/utils/array';
import { CalendarDay, CalendarDayValue } from './CalendarDay';
import { Icon } from './Icon';
import IconButton from './IconButton';

export interface PopulatedDate {
  dateVal: DateTime;
  numberOfLessons: number;
  statusToDisplay: ScheduledLessonStatus;
}

const WEEKDAYS = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

const Header = () => {
  return (
    <tr>
      {WEEKDAYS.map((weekday) => (
        <th key={weekday}>
          <HootTypography isPII={false} variant="titlesmall">
            {weekday}
          </HootTypography>
        </th>
      ))}
    </tr>
  );
};

const DayCell = (props: {
  year: number;
  month: number;
  day: number;
  isSelected?: boolean;
  isDisabled?: boolean;
  populatedDates?: PopulatedDate[];
  onChange?: (val: number) => void;
}) => {
  const { year, month, day, populatedDates, onChange, isSelected, isDisabled } = props;

  const currentDate = DateTime.local(year, month, day).toLocaleString();
  let numberOfLessons: number = 0;
  let cellStatus: ScheduledLessonStatus | undefined = undefined;
  const dayValue = `${day}` as CalendarDayValue;

  const handleDayClick = (day: number) => () => {
    if (!isDisabled && onChange) {
      const clickedDate = DateTime.local(year, month, day).startOf('day').toMillis();
      onChange(clickedDate);
    }
  };

  if (populatedDates) {
    for (const date of populatedDates) {
      if (date?.dateVal?.toLocaleString() === currentDate) {
        numberOfLessons = date.numberOfLessons;
        cellStatus = date.statusToDisplay;
      }
    }
  }

  return (
    <td style={{ fontWeight: 600, fontSize: '16px', lineHeight: '22px' }}>
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '46px', height: '46px' }}>
        <Badge
          badgeContent={numberOfLessons - 1 === 0 ? 0 : numberOfLessons}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          sx={{
            '& .MuiBadge-badge': {
              backgroundColor: hootTokens.palette.error[80],
              color: hootTokens.palette.white,
            },
          }}
        >
          <CalendarDay selected={isSelected} isDisabled={isDisabled} status={cellStatus} value={dayValue} onClick={handleDayClick(day)} />
        </Badge>
      </Box>
    </td>
  );
};

const Days = (props: {
  firstOfMonth: DateTime;
  minDate?: number;
  maxDate?: number;
  populatedDates?: PopulatedDate[];
  onChange?: (val: number) => void;
  value?: DateTime;
}) => {
  const { firstOfMonth, minDate = Number.MIN_SAFE_INTEGER, maxDate = Number.MAX_SAFE_INTEGER, onChange, populatedDates, value } = props;

  const firstDayOfMonth = Number.parseInt(firstOfMonth.toFormat('c'));
  const daysInMonth = firstOfMonth.daysInMonth;

  const padding = [...Array(firstDayOfMonth)].map((_, idx) => <td key={idx}>{''}</td>);

  const dateFields = [...Array(daysInMonth)].map((_, idx) => (
    <DayCell
      key={idx}
      year={firstOfMonth.year}
      month={firstOfMonth.month}
      day={idx + 1}
      isSelected={value ? firstOfMonth.set({ day: idx + 1 }).toISODate() === value.toISODate() : false}
      isDisabled={
        firstOfMonth
          .set({ day: idx + 1 })
          .startOf('day')
          .toMillis() < minDate ||
        firstOfMonth
          .set({ day: idx + 1 })
          .endOf('day')
          .toMillis() > maxDate
      }
      onChange={onChange}
      populatedDates={populatedDates}
    />
  ));

  return (
    <tbody>
      {spliceChunk([...padding, ...dateFields], 7).map((row, idx) => (
        <tr key={idx}>
          {row.map((d, idx) => (
            <React.Fragment key={idx}>{d}</React.Fragment>
          ))}
        </tr>
      ))}
    </tbody>
  );
};

const Calendar = (props: {
  minDate?: number;
  maxDate?: number;
  showMonth?: DateTime;
  allowChangeMonth?: boolean;
  populatedDates?: PopulatedDate[];
  longHeaderFormat?: boolean;
  value?: number;
  onChange?: (val: number) => void;
  setSelectedCalendarMonth?: React.Dispatch<React.SetStateAction<number>>;
  setSelectedDate?: React.Dispatch<React.SetStateAction<DateTime<boolean>>>;
}) => {
  const {
    minDate,
    maxDate,
    longHeaderFormat = true,
    onChange,
    populatedDates,
    showMonth,
    allowChangeMonth = true,
    value,
    setSelectedCalendarMonth,
    setSelectedDate,
  } = props;
  const [firstOfMonth, setFirstOfMonth] = React.useState<DateTime>(showMonth?.startOf('month') || DateTime.now().startOf('month'));

  const dtmFormat = longHeaderFormat ? 'LLLL yyyy' : 'LLL yy';

  const dateTimeValue = useMemo(() => (value ? DateTime.fromMillis(value) : undefined), [value]);

  useEffect(() => {
    const hasChangedMonth = dateTimeValue?.startOf('month').toISODate() !== firstOfMonth.toISODate();
    if (setSelectedDate && setSelectedCalendarMonth && hasChangedMonth) {
      setSelectedCalendarMonth(firstOfMonth.toMillis());
      setSelectedDate(firstOfMonth);
    }
  }, [dateTimeValue, firstOfMonth, setSelectedCalendarMonth, setSelectedDate]);

  const handleMonthClick = (direction: 'increment' | 'decrement') => () => {
    setFirstOfMonth((current) => {
      if (direction === 'increment') {
        return current.plus({ months: 1 });
      }
      return current.minus({ months: 1 });
    });
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mb: 2 }}>
        {allowChangeMonth ? (
          <IconButton onClick={handleMonthClick('decrement')}>
            <Icon name="chevron" />
          </IconButton>
        ) : null}
        <Box sx={{ textAlign: 'center' }}>
          <HootTypography isPII={false} variant="titlesmall">
            {firstOfMonth.toFormat(dtmFormat)}
          </HootTypography>
        </Box>
        {allowChangeMonth ? (
          <IconButton onClick={handleMonthClick('increment')}>
            <Icon name="chevron" sx={{ rotate: '180deg' }} />
          </IconButton>
        ) : null}
      </Box>
      <table style={{ flex: '1 1 auto', borderCollapse: 'collapse', tableLayout: 'fixed' }}>
        <thead>
          <Header />
        </thead>
        <Days
          firstOfMonth={firstOfMonth}
          onChange={onChange}
          minDate={minDate}
          maxDate={maxDate}
          populatedDates={populatedDates}
          value={dateTimeValue}
        />
      </table>
    </Box>
  );
};

export default Calendar;
