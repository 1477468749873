import { SocialMedia } from '@hoot-reading/hoot-core/dist/enums/user/social-media.enum';
import { HowDidYouHearAboutHoot } from '@hoot-reading/hoot-core/dist/enums/user/student/how-did-you-hear-about-hoot.enum';
import { Box, FormControl, useMediaQuery } from '@mui/material';
import { Stack } from '@mui/system';
import { isAxiosError } from 'axios';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { ErrorResponseDto } from '@hoot/models/api/error-response-dto';
import { googleAnalytics } from '@hoot/telemetry/google-analytics';
import { Button } from '@hoot/ui/components/v2/core/Button';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';
import { Select } from '@hoot/ui/components/v2/core/Select';
import TextField from '@hoot/ui/components/v2/core/TextField';
import { COUNTRIES } from '../../../../../../../constants/constants';
import useFlfCreateProfileRequest, { FlfCreateProfileRequestDto } from '../../../../../../../hooks/api/free-lesson-flow/useFlfCreateProfileRequest';
import useGetFlfProfileRequest from '../../../../../../../hooks/api/free-lesson-flow/useGetFlfProfileRequest';
import usePlaces from '../../../../../../../hooks/usePlaces';
import { createFlashMessage } from '../../../../../../../redux/reducers/flashMessageSlice';
import { useAppDispatch } from '../../../../../../../redux/store';
import { timeZoneData } from '../../../../../../../utils/timeZones';
import theme from '../../../../../../theme/v2';
import { FlfWizardStep } from '../FreeLessonRegistrationWizard';

interface Form {
  country: string;
  province: string;
  timezone: string;
  howDidYouHearAboutUs: HowDidYouHearAboutHoot | '';
  howDidYouHearAboutUsOther: string;
  socialMedia: SocialMedia | '';
  referralCode: string;
}

function getTimezone(): string {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

const howDidYouHearAboutUsOptions = [
  { value: HowDidYouHearAboutHoot.SocialMedia, label: 'Social Media' },
  { value: HowDidYouHearAboutHoot.OnlineAd, label: 'Online Ad' },
  { value: HowDidYouHearAboutHoot.RadioAd, label: 'Radio Ad' },
  { value: HowDidYouHearAboutHoot.FriendsFamily, label: 'Friends or Family' },
  { value: HowDidYouHearAboutHoot.WebSearch, label: 'Web Search' },
  { value: HowDidYouHearAboutHoot.ThroughMySchool, label: 'Through My School' },
  { value: HowDidYouHearAboutHoot.Email, label: 'Email' },
  { value: HowDidYouHearAboutHoot.ReferralFromAColleague, label: 'Referral From A Colleague' },
  { value: HowDidYouHearAboutHoot.ReferralFromATeacher, label: 'Referral From A Teacher' },
  { value: HowDidYouHearAboutHoot.SalesOutreach, label: 'Sales Outreach' },
  { value: HowDidYouHearAboutHoot.Other, label: 'Other' },
];

const socialMediaOptions = [
  { value: SocialMedia.Instagram, label: 'Instagram' },
  { value: SocialMedia.Facebook, label: 'Facebook' },
  { value: SocialMedia.YouTube, label: 'YouTube' },
  { value: SocialMedia.LinkedIn, label: 'LinkedIn' },
  { value: SocialMedia.MultipleDifferentPlatforms, label: 'Multiple Different Platforms' },
];

const FreeLessonCreateProfile = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const referralCodeFromSearchParams = searchParams.get('referralCode') ?? '';

  const { control, handleSubmit, watch, reset } = useForm<Form>({
    defaultValues: {
      country: 'US',
      province: '',
      timezone: getTimezone(),
      howDidYouHearAboutUs: '',
      howDidYouHearAboutUsOther: '',
      socialMedia: '',
      referralCode: referralCodeFromSearchParams,
    },
  });

  const dispatch = useAppDispatch();

  useGetFlfProfileRequest({
    onSuccess: (data) => {
      reset({
        // Country will be null initially (if the user hasn't done this step yet). If it's null, then just leave it as the default value.
        country: !!data.country ? data.country : country,
        province: data.provinceState ?? '',
        howDidYouHearAboutUs: data.howDidYouHearAboutHoot ?? '',
        howDidYouHearAboutUsOther: data.howDidYouHearAboutHootOther,
        socialMedia: data.socialMedia ?? '',
        referralCode: data.referralCode,
      });
    },
  });
  const createProfileRequest = useFlfCreateProfileRequest();

  const { country, howDidYouHearAboutUs } = watch();

  const onSubmit: SubmitHandler<Form> = (data) => {
    const request: FlfCreateProfileRequestDto = {
      country: data.country,
      provinceState: data.province,
      timeZone: data.timezone,
      howDidYouHearAboutHoot: data.howDidYouHearAboutUs.length ? (data.howDidYouHearAboutUs as HowDidYouHearAboutHoot) : undefined,
      howDidYouHearAboutHootOther: data.howDidYouHearAboutUsOther?.length ? data.howDidYouHearAboutUsOther : undefined,
      socialMedia: data.socialMedia ? data.socialMedia : undefined,
      referralCode: data.referralCode?.length ? data.referralCode : undefined,
    };
    createProfileRequest.mutate(request, {
      onSuccess: () => {
        setSearchParams({
          step: `${FlfWizardStep.CreateProfile + 1}`,
        });
        googleAnalytics.completedFLFStep3();
      },
      onError: (err) => {
        console.error(err);
        if (isAxiosError<ErrorResponseDto>(err)) {
          dispatch(createFlashMessage({ variant: 'error', message: err.response?.data.message ?? 'An error occurred while creating a profile.' }));
        } else {
          dispatch(createFlashMessage({ variant: 'error', message: 'An error occurred while creating a profile.' }));
        }
      },
    });
  };

  function provinceLabel(country: string) {
    if (country === 'US') {
      return 'State';
    } else if (country === 'CA') {
      return 'Province';
    }
  }

  const provinces = usePlaces(country);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack>
        <HootTypography isPII={false} variant="titlelarge">
          Create your Profile
        </HootTypography>
        <HootTypography isPII={false} mt={3}>
          We're thrilled to have you on board! Let’s get started with the basics.
        </HootTypography>

        <Stack sx={{ maxWidth: isDesktop ? '329px' : undefined, marginTop: '16px' }}>
          <FormControl>
            <Controller
              control={control}
              name="country"
              rules={{
                required: true,
              }}
              render={({ field, fieldState }) => (
                <Select required error={!!fieldState.error} label="Country" value={field.value} onChange={field.onChange}>
                  <option disabled value="">
                    Select
                  </option>
                  {COUNTRIES.map((c) => (
                    <option key={c.value} value={c.value}>
                      {c.label}
                    </option>
                  ))}
                </Select>
              )}
            />
          </FormControl>

          <FormControl sx={{ marginTop: '16px' }}>
            <Controller
              control={control}
              name="province"
              rules={{
                required: true,
              }}
              render={({ field, fieldState }) => (
                <Select required error={!!fieldState.error} label={provinceLabel(country)} value={field.value} onChange={field.onChange}>
                  <option disabled value="">
                    Select
                  </option>
                  {provinces.provinceStateOptions.map((p) => (
                    <option key={p.value} value={p.value}>
                      {p.label}
                    </option>
                  ))}
                </Select>
              )}
            />
          </FormControl>

          <FormControl sx={{ marginTop: '16px' }}>
            <Controller
              control={control}
              name="timezone"
              rules={{
                required: true,
              }}
              render={({ field, fieldState }) => (
                <Select required error={!!fieldState.error} label="Timezone" value={field.value} onChange={field.onChange}>
                  {timeZoneData.map((tz) => (
                    <option key={tz.value} value={tz.value}>
                      {tz.label}
                    </option>
                  ))}
                </Select>
              )}
            />
          </FormControl>

          <FormControl sx={{ marginTop: '16px' }}>
            <Controller
              control={control}
              name="howDidYouHearAboutUs"
              render={({ field, fieldState }) => (
                <Select error={!!fieldState.error} label="How did you hear about us? (Optional)" value={field.value} onChange={field.onChange}>
                  <option disabled value="">
                    Select
                  </option>
                  {howDidYouHearAboutUsOptions.map((o) => (
                    <option key={o.value} value={o.value}>
                      {o.label}
                    </option>
                  ))}
                </Select>
              )}
            />
          </FormControl>

          {howDidYouHearAboutUs === HowDidYouHearAboutHoot.Other ? (
            <FormControl sx={{ marginTop: '16px' }}>
              <Controller
                control={control}
                name="howDidYouHearAboutUsOther"
                rules={{
                  required: howDidYouHearAboutUs === HowDidYouHearAboutHoot.Other,
                }}
                render={({ field, fieldState }) => (
                  <TextField error={!!fieldState.error} label="Please indicate where" onChange={field.onChange} value={field.value} />
                )}
              />
            </FormControl>
          ) : null}

          {howDidYouHearAboutUs === HowDidYouHearAboutHoot.SocialMedia ? (
            <FormControl sx={{ marginTop: '16px' }}>
              <Controller
                control={control}
                name="socialMedia"
                rules={{
                  required: howDidYouHearAboutUs === HowDidYouHearAboutHoot.SocialMedia,
                }}
                render={({ field, fieldState }) => (
                  <Select error={!!fieldState.error} label="Which social media platform?" value={field.value} onChange={field.onChange}>
                    <option disabled value="" />
                    {socialMediaOptions.map((o) => (
                      <option key={o.value} value={o.value}>
                        {o.label}
                      </option>
                    ))}
                  </Select>
                )}
              />
            </FormControl>
          ) : null}
          <FormControl sx={{ marginTop: '16px' }}>
            <Controller
              control={control}
              name="referralCode"
              render={({ field, fieldState }) => (
                <TextField error={!!fieldState.error} label="Referral code (Optional)" value={field.value} onChange={field.onChange} />
              )}
            />
          </FormControl>
        </Stack>
        <Box>
          <Button type="submit" fullWidth={!isDesktop} sx={{ marginTop: '16px' }} variant="contained" isLoading={createProfileRequest.isLoading}>
            Next
          </Button>
        </Box>
      </Stack>
    </form>
  );
};

export default FreeLessonCreateProfile;
