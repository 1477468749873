import { Card, DialogActions, DialogContent, Stack } from '@mui/material';
import _ from 'lodash';
import React, { ReactNode, useEffect, useState } from 'react';
import { BookSearch } from '@hoot/events/interfaces/book-search';
import useSearchLibrary, { QueryLibraryV2 } from '@hoot/hooks/api/library/useSearchLibrary';
import { InstructionalFocus } from '@hoot/models/api/enums/instructional-focus';
import { LessonReviewFocus } from '@hoot/models/api/enums/lesson-review-enums';
import { ShelfType } from '@hoot/models/api/enums/shelf-type-enum';
import { resourceProgressTypes } from '@hoot/models/api/library';
import { GenericPaginatedResponse } from '@hoot/models/api/pagination';
import { error } from '@hoot/redux/reducers/alertSlice';
import { DEFAULT_PAGE } from '@hoot/redux/reducers/librarySlice';
import ViewStateIllustration, { IllustrationEnum } from '@hoot/ui/components/v2/ViewStateIllustration';
import { Button } from '@hoot/ui/components/v2/core/Button';
import { Checkbox } from '@hoot/ui/components/v2/core/Checkbox';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';
import { HeaderData, TableV2 } from '@hoot/ui/components/v2/core/Table';
import {
  LessonReviewBook,
  LessonReviewInstructionalFocusOption,
  LessonReviewWizardStepEnum,
  useLessonReviewWizardContext,
} from '@hoot/ui/pages/v2/teacher/my-students/student-details/lesson-reviews/lesson-review-wizard/LessonReviewContextProvider';

const MAX_FOCUS_BOOKS = 4;
const PAGE_SIZE = 100;
const LOCAL_PAGE_SIZE = 5;

interface RapportBuildingBookTableRow {
  id: string;
  cover: ReactNode;
  title: ReactNode;
  resourceProgress: ReactNode;
  selected: boolean;
  disabled: boolean;
}

enum RapportBuildingBookFields {
  Id = 'id',
  Title = 'title',
  Cover = 'cover',
  ResourceProgress = 'resourceProgress',
  Selected = 'selected',
  Disabled = 'disabled',
}

const headers: HeaderData<RapportBuildingBookTableRow>[] = [
  { name: '', property: RapportBuildingBookFields.Id, isSortable: false, isHidden: true },
  { name: 'Cover', property: RapportBuildingBookFields.Cover, isSortable: false },
  { name: 'Resource Title', property: RapportBuildingBookFields.Title, isSortable: false },
  { name: 'Resource State', property: RapportBuildingBookFields.ResourceProgress, isSortable: false },
  { name: '', property: RapportBuildingBookFields.Selected, isSortable: false, isHidden: true },
  { name: '', property: RapportBuildingBookFields.Disabled, isSortable: false, isHidden: true },
];

const getIsResourcesUsed = (instructionalFocusOptions: LessonReviewInstructionalFocusOption[] | undefined): boolean => {
  const rapportBuildingOption = instructionalFocusOptions?.find((option) => option.focus === LessonReviewFocus.RelationshipAndRapportBuilding);
  if (!rapportBuildingOption) {
    return false;
  }
  return rapportBuildingOption.focusBooks.length > 0;
};

const LessonReviewRapportBuildingStep = () => {
  const {
    lessonToReview,
    instructionalFocusOptions,
    actions: { goToNextStep, goToPreviousStep, attachInstructionalFocusOptions, toggleWizardStep, setShouldUpdateFocusOptions },
  } = useLessonReviewWizardContext();

  const [resourcesUsed, setResourcesUsed] = useState<boolean>(getIsResourcesUsed(instructionalFocusOptions));
  const [bookOptions, setBookOptions] = useState<LessonReviewBook[]>([]);
  const [selectedBooks, setSelectedBooks] = useState<LessonReviewBook[]>(
    instructionalFocusOptions?.find((option) => option.focus === LessonReviewFocus.RelationshipAndRapportBuilding)?.focusBooks ?? [],
  );
  const [page, setPage] = useState(0);

  const hasMaxNumberOfBooks = selectedBooks.length >= MAX_FOCUS_BOOKS;
  const isNextDisabled = resourcesUsed && selectedBooks.length === 0;

  useEffect(() => {
    if (resourcesUsed) {
      toggleWizardStep(LessonReviewWizardStepEnum.FocusPriority, resourcesUsed);
    }
  }, [resourcesUsed, toggleWizardStep]);

  const libraryQuery: QueryLibraryV2 = {
    shelfType: ShelfType.AllBooks,
    page: DEFAULT_PAGE,
    pageSize: PAGE_SIZE,
    instructionalFocusIds: undefined,
    excludedInstructionalFocusIds: [
      InstructionalFocus.FormativeAssessmentTextReading,
      InstructionalFocus.FormativeAssessmentEarlyWordReading,
      InstructionalFocus.FormativeAssessmentComplexWordReading,
      InstructionalFocus.FormativeAssessmentPreWordReading,
    ],
    scheduledLessonId: lessonToReview?.lessonId,
  };

  useSearchLibrary(libraryQuery, {
    enabled: !!lessonToReview?.lessonId,
    retry: false,
    onSuccess: (response: GenericPaginatedResponse<BookSearch>) => {
      setBookOptions(
        response.data.map((book) => ({
          bookId: book.id,
          title: book.title,
          coverImageUrl: book.coverUrl ?? undefined,
          progress: book.resourceProgress,
        })),
      );
    },
    onError: (err) => {
      console.error(err);
      error(`An error occurred while loading books.`);
    },
  });

  const sortedTableData = bookOptions.slice(page * LOCAL_PAGE_SIZE, page * LOCAL_PAGE_SIZE + LOCAL_PAGE_SIZE).map(
    (b): RapportBuildingBookTableRow => ({
      id: b.bookId,
      cover: (
        <img
          src={b.coverImageUrl}
          alt={b.title}
          style={{
            objectFit: 'contain',
            maxWidth: '50px',
            width: '100%',
            borderRadius: '8px',
          }}
        />
      ),
      title: b.title,
      resourceProgress: resourceProgressTypes(b.progress),
      selected: !!selectedBooks.find((book) => book.bookId === b.bookId),
      disabled: hasMaxNumberOfBooks && !selectedBooks.find((book) => book.bookId === b.bookId),
    }),
  );

  const onNoResourceUsedChange = () => {
    setResourcesUsed((previousValue) => {
      const newState = !previousValue;
      if (!newState) {
        setSelectedBooks([]);
      }
      return newState;
    });
  };

  const onClickNext = () => {
    const updatedRapportBuilding = {
      id: LessonReviewFocus.RelationshipAndRapportBuilding,
      focus: LessonReviewFocus.RelationshipAndRapportBuilding,
      focusUnit: undefined,
      focusSkill: undefined,
      focusBooks: selectedBooks,
    };
    const filteredInstructionalFocusOptions = instructionalFocusOptions.filter(
      (option) => option.focus !== LessonReviewFocus.RelationshipAndRapportBuilding,
    );
    const savedRapportBuilding = instructionalFocusOptions.find((option) => option.focus === LessonReviewFocus.RelationshipAndRapportBuilding);
    const hasChangedBooks = !_.isEqual(
      selectedBooks.map((book) => book.bookId),
      savedRapportBuilding?.focusBooks.map((book) => book.bookId),
    );
    if (hasChangedBooks) {
      setShouldUpdateFocusOptions(true);
    }
    attachInstructionalFocusOptions([updatedRapportBuilding, ...filteredInstructionalFocusOptions]);
    goToNextStep();
  };

  const handleToggleOption = (bookId: string) => {
    if (selectedBooks.find((book) => book.bookId === bookId)) {
      setSelectedBooks(selectedBooks.filter((book) => book.bookId !== bookId));
    } else {
      const newSelectedBook = bookOptions.find((book) => book.bookId === bookId);
      if (newSelectedBook && !hasMaxNumberOfBooks) {
        setSelectedBooks([...selectedBooks, newSelectedBook]);
      }
    }
  };

  return (
    <>
      <DialogContent>
        <Stack gap={'16px'} paddingBottom={1}>
          <HootTypography isPII={false} variant="titlemedium">
            Rapport Details
          </HootTypography>
          <Card>
            <Stack gap={'16px'}>
              <Stack alignItems={'flex-start'} gap={2}>
                <HootTypography isPII={false} variant="titlemedium">
                  Rapport Building
                </HootTypography>
                <Stack gap={'8px'} direction={'row'}>
                  <Checkbox label="Resources were used to build rapport" checked={resourcesUsed} onClick={onNoResourceUsedChange} />
                </Stack>
                {resourcesUsed ? (
                  <HootTypography isPII={true} variant="bodymedium">
                    Select resources used to build rapport that weren’t part of instruction.
                  </HootTypography>
                ) : null}
              </Stack>
              {resourcesUsed ? (
                <TableV2
                  headers={headers}
                  data={sortedTableData}
                  isSortable={false}
                  isPaginated
                  hideRowsPerPage
                  rowsPerPage={LOCAL_PAGE_SIZE}
                  count={bookOptions.length}
                  page={page}
                  onPageChange={(_event, page) => setPage(page)}
                  onRowsPerPageChange={() => ''}
                  isSelectable
                  isRowSelectable
                  onSelect={(book) => handleToggleOption(book.id)}
                  emptyViewState={<ViewStateIllustration illustration={IllustrationEnum.EmptyInbox} title={`No resources were used`} subtitle={``} />}
                />
              ) : null}
            </Stack>
          </Card>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={goToPreviousStep}>
          Go Back
        </Button>
        <Button variant="contained" onClick={onClickNext} disabled={isNextDisabled}>
          Next
        </Button>
      </DialogActions>
    </>
  );
};

export default LessonReviewRapportBuildingStep;
