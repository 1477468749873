import axios from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '@hoot/config';
import { LocationReportingFilters } from '@hoot/hooks/api/district-rep/reporting/useGetLocationReportingMetrics';
import { QueryKey } from '../../queryKeys';

export interface RepReportingStudentAttendanceBand {
  band: string;
  studentsInBand: number;
}

export interface RepReportingStudentAttendanceBandsResponse {
  bands: RepReportingStudentAttendanceBand[];
}

export default function useGetStudentAttendanceBandsReport(
  locationId: string,
  query: LocationReportingFilters,
  options?: Omit<UseQueryOptions<RepReportingStudentAttendanceBandsResponse>, 'queryKey' | 'queryFn'>,
) {
  return useQuery(
    [QueryKey.DistrictRepReportingStudentAttendanceBands, locationId, query],
    async () => {
      return (
        await axios.get<RepReportingStudentAttendanceBandsResponse>(
          `${config.apiUrl}/public/district-representative/reporting/location/${locationId}/chart/student-attendance-bands`,
          {
            params: query,
          },
        )
      ).data;
    },
    options,
  );
}
