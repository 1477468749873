import axios from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '@hoot/config';
import { QueryKey } from '@hoot/hooks/api/queryKeys';

export interface GetFluencyFormativeAssessmentDetailsResponse {
  lessonDate: number;
  wordCount: string;
  errors: string;
  runningTime: string;
  wordsCorrectPerMinute: string;
  accuracy: string;
}

export function useGetFluencyFormativeAssessmentDetails(
  lessonReviewId: string,
  bookId: string,
  options?: Omit<UseQueryOptions<GetFluencyFormativeAssessmentDetailsResponse>, 'queryKey' | 'queryFn'>,
) {
  return useQuery(
    [QueryKey.GetFluencyFormativeAssessmentDetails, lessonReviewId, bookId],
    async () => {
      return (
        await axios.get<GetFluencyFormativeAssessmentDetailsResponse>(
          `${config.apiUrl}/public/lesson-review/${lessonReviewId}/formative-assessment/fluency/${bookId}`,
        )
      ).data;
    },
    options,
  );
}
