import { Box, Stack } from '@mui/material';
import { useState } from 'react';
import { DateFormats } from '@hoot/constants/constants';
import { DateFilterQuery } from '@hoot/hooks/api/district-rep/reporting/useGetStudentProfileMetrics';
import { Button } from '@hoot/ui/components/v2/core/Button';
import { Checkbox } from '@hoot/ui/components/v2/core/Checkbox';
import { Chip } from '@hoot/ui/components/v2/core/Chip';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';
import { Icon } from '@hoot/ui/components/v2/core/Icon';
import PopoverMenu from '@hoot/ui/components/v2/core/PopoverMenu';
import CalendarIcon from '@hoot/ui/components/v2/icons/CalendarIcon';
import { DateFilterEnum, dateFilterLabels } from '../../../../../../models/api/district-rep-reporting/common';
import DateRangeDialog, { DateRangeFormValues } from '../../../../../components/v2/dialogs/DateRangeDialog';

interface ReportingFilterProps {
  dateFilter: DateFilterQuery | undefined;
  setDateFilter: React.Dispatch<React.SetStateAction<DateFilterQuery | undefined>>;
}

const StudentReportingFilters = (props: ReportingFilterProps) => {
  const { dateFilter, setDateFilter } = props;

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [isDateRangeDialogOpen, setIsDateRangeDialogOpen] = useState<boolean>(false);

  const handleMenuOpen = (target: HTMLButtonElement) => setAnchorEl(target);

  const handleMenuClose = () => setAnchorEl(null);

  const handleDateRangeDialogOpen = () => {
    handleMenuClose();
    setIsDateRangeDialogOpen(true);
  };

  const handleDateRangeDialogClose = () => setIsDateRangeDialogOpen(false);

  const handleCheckboxChange = (value: string) => {
    if (value === DateFilterEnum.SpecificDates) {
      return handleDateRangeDialogOpen();
    }

    setDateFilter({ date: [value] as DateFilterEnum[] });
  };

  const handleDateRangeFilterApply = (dateRange: DateRangeFormValues) => {
    setDateFilter({ date: dateRange });
  };

  const handleDateFilterDelete = () => {
    setDateFilter(undefined);
  };

  const renderDateFilterChip = () => {
    if (!Array.isArray(dateFilter?.date)) return;

    return (
      <Chip
        label={`Date: ${dateFilterLabels[dateFilter?.date[0]]}`}
        onDelete={() => handleDateFilterDelete()}
        deleteIcon={<Icon name="close" htmlColor="#FFF" />}
        sx={{
          background: '#000',
          color: '#FFF',
        }}
      />
    );
  };

  const renderDateRangeChip = () => {
    const { startDate, endDate } = dateFilter?.date as DateRangeFormValues;

    if (!startDate || !endDate) return;

    const formattedStart = startDate.toFormat(DateFormats.SHORT_MDY);
    const formattedEnd = endDate.toFormat(DateFormats.SHORT_MDY);

    return (
      <Chip
        label={`Date Range: ${formattedStart} - ${formattedEnd}`}
        onDelete={() => handleDateFilterDelete()}
        deleteIcon={<Icon name="close" htmlColor="#FFF" />}
        sx={{
          background: '#000',
          color: '#FFF',
        }}
      />
    );
  };

  const showDateRangeChip = !!dateFilter && !Array.isArray(dateFilter);

  return (
    <>
      <Stack gap={2}>
        <HootTypography variant="titlemedium" isPII={false}>
          Filters
        </HootTypography>
        <Stack direction="row">
          <Button
            onClick={(e) => handleMenuOpen(e.currentTarget)}
            variant="contained"
            color="neutral.180"
            startIcon={<Icon name="chevron" sx={{ rotate: '270deg' }} />}
          >
            Date
          </Button>
          <PopoverMenu
            id="date-menu"
            open={!!anchorEl}
            onClose={() => handleMenuClose()}
            anchorEl={anchorEl}
            items={Object.values(DateFilterEnum).map((df) => ({
              id: df,
              label: dateFilterLabels[df],
              onClick: () => (df === DateFilterEnum.SpecificDates ? handleDateRangeDialogOpen() : handleCheckboxChange(df)),
              icon:
                df === DateFilterEnum.SpecificDates ? (
                  <CalendarIcon />
                ) : (
                  <Checkbox checked={Array.isArray(dateFilter?.date) && dateFilter.date.includes(df)} onChange={() => handleCheckboxChange(df)} />
                ),
              menuItemProps: {
                divider: df === DateFilterEnum.LastMonth || df === DateFilterEnum.LastSchoolYear,
                sx: { borderRadius: 0 },
              },
            }))}
          />
        </Stack>

        {/* Chips */}
        <Box display="flex" flexWrap="wrap" gap={2}>
          {showDateRangeChip ? renderDateRangeChip() : null}
          {!!dateFilter ? renderDateFilterChip() : null}
        </Box>
      </Stack>

      <DateRangeDialog
        title="Specific Date Filter"
        open={isDateRangeDialogOpen}
        onDismiss={handleDateRangeDialogClose}
        onApply={handleDateRangeFilterApply}
      />
    </>
  );
};

export default StudentReportingFilters;
