import { Accordion, AccordionDetails, AccordionSummary, Backdrop, Box, DialogContent, DialogTitle, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { zIndexes } from '@hoot/constants/zIndices';
import useGetBookCollections from '@hoot/hooks/api/library/useGetBookCollections';
import useGetFormats from '@hoot/hooks/api/library/useGetFormats';
import useGetGenres from '@hoot/hooks/api/library/useGetGenres';
import useGetThemes from '@hoot/hooks/api/library/useGetThemes';
import useGetTopics from '@hoot/hooks/api/library/useGetTopics';
import { Grade, gradeOptions } from '@hoot/models/api/library';
import { Option } from '@hoot/models/formOption';
import SublibrarySelector from '@hoot/ui/components/v2/SublibrarySelector';
import BackdroplessDialog from '@hoot/ui/components/v2/core/BackdroplessDialog';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';
import { Icon } from '@hoot/ui/components/v2/core/Icon';
import OptionSelectedChip from '@hoot/ui/components/v2/core/OptionSelectedChip';
import PlainButton from '@hoot/ui/components/v2/core/PlainButton';
import { Select } from '@hoot/ui/components/v2/core/Select';
import { LottieFile } from '@hoot/ui/components/v2/lottie/Lottie';
import LottieButton from '@hoot/ui/components/v2/lottie/LottieButton';
import { StudentSubLibrarySelection } from '@hoot/ui/pages/v2/student/library/StudentLibraryPage';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';
import StudentFilterSelectionModalWrapper from './StudentFilterSelectionModal';

interface StudentLibraryFilterForm {
  genreId: string | null;
  formatId: string | null;
  topicIds: string[];
  themeIds: string[];
  grade: Grade | null;
  bookCollectionId: string | null;
}

export interface StudentLibraryFilters {
  genreId: string | undefined;
  formatId: string | undefined;
  topicIds: string[] | undefined;
  themeIds: string[] | undefined;
  grade: Grade | undefined;
  bookCollectionId: string | undefined;
}

export interface StudentLibraryFilterModalProps {
  isOpen: boolean;
  defaultSublibrarySelection: StudentSubLibrarySelection;
  onDismiss: () => void;
  defaultFilters: Partial<StudentLibraryFilters> | undefined;
  onApplyFilters: (updatedQuery: StudentLibraryFilters, sublibrary: StudentSubLibrarySelection) => void;
}

const StudentLibraryFilterModal = (props: StudentLibraryFilterModalProps) => {
  const { isOpen, defaultSublibrarySelection, onDismiss, onApplyFilters, defaultFilters } = props;

  const [isMoreOpen, setIsMoreOpen] = useState<boolean>(false);
  const [showFilterSelectionDialog, setShowFilterSelectionDialog] = useState(false);
  const [optionModalData, setOptionModalData] = useState<{ title: string; name: StudentFilterFormKeys; multiSelect: boolean } | null>(null);
  const [sublibrarySelection, setSublibrarySelection] = useState(defaultSublibrarySelection);

  type StudentFilterFormKeys = keyof StudentLibraryFilterForm;

  const bookCollections = useGetBookCollections();
  const { data: formatsData } = useGetFormats();
  const { data: genresData } = useGetGenres();
  const { data: topicsData } = useGetTopics();
  const { data: themesData } = useGetThemes();

  const bookCollectionOptions = bookCollections.data
    ? bookCollections.data.collections.map((op) => {
        return { value: op.id, label: op.name } as Option;
      })
    : [];
  const formatOptions = formatsData
    ? formatsData.formats.map((f) => {
        return { value: f.id, label: f.name } as Option;
      })
    : [];
  const genreOptions = genresData
    ? genresData.genres.map((g) => {
        return { value: g.id, label: g.name } as Option;
      })
    : [];
  const topicOptions = topicsData
    ? topicsData.categorizedTopics.flatMap((g) =>
        g.listItems.map((li) => {
          return { value: li.value, label: li.label } as Option;
        }),
      )
    : [];
  const themeOptions = themesData
    ? themesData.categorizedThemes.flatMap((g) =>
        g.listItems.map((li) => {
          return { value: li.value, label: li.label } as Option;
        }),
      )
    : [];

  const defaultFormValues = {
    genreId: defaultFilters?.genreId ?? null,
    formatId: defaultFilters?.formatId ?? null,
    topicIds: defaultFilters?.topicIds && defaultFilters.topicIds.length > 0 ? defaultFilters.topicIds : [],
    themeIds: defaultFilters?.themeIds && defaultFilters.themeIds.length > 0 ? defaultFilters.themeIds : [],
    grade: defaultFilters?.grade ?? null,
    //Note: for the student they should only ever be able to select one book collection at a time (Even though its an array type)
    bookCollectionId: defaultFilters?.bookCollectionId ?? null,
  };
  const { handleSubmit, control, reset, watch, setValue } = useForm<StudentLibraryFilterForm>({
    defaultValues: defaultFormValues,
  });

  const watchGenreId = watch('genreId');
  const watchFormatId = watch('formatId');
  const watchThemeIds = watch('themeIds');
  const watchTopicIds = watch('topicIds');

  const hideLottieAddMoreButton =
    (themeOptions.length !== 0 && watchThemeIds.length === themeOptions.length) ||
    (topicOptions.length !== 0 && watchTopicIds.length === topicOptions.length);

  // Reset form defaults when the modal is (re)opened.
  useEffect(() => {
    if (isOpen) {
      reset(defaultFormValues);
      setSublibrarySelection(defaultSublibrarySelection);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  //reset the values selected for themes and topics if the genre changes
  useEffect(() => {
    setValue('themeIds', []);
    setValue('topicIds', []);
  }, [watchGenreId, setValue]);

  const genreType = genreOptions.find((o) => o.value === watchGenreId)?.label ?? '';
  const subFilterType = !!genreType ? (genreType === 'Fiction' ? 'themeIds' : 'topicIds') : null;
  const subFilterTitle = !!genreType ? (genreType === 'Fiction' ? 'Themes' : 'Topics') : null;

  const studentFilterSelectionModalOptions = (name: StudentFilterFormKeys | null) => {
    switch (name) {
      case 'genreId':
        return genreOptions;
      case 'formatId':
        return formatOptions;
      case 'topicIds':
        return topicOptions;
      case 'themeIds':
        return themeOptions;
      default:
        return [];
    }
  };

  const optionModalTitle = (val: StudentFilterFormKeys) => {
    switch (val) {
      case 'genreId':
        return 'Genre';
      case 'formatId':
        return 'Format';
      case 'topicIds':
        return 'Topic';
      case 'themeIds':
        return 'Theme';
      default:
        return '';
    }
  };

  const onFilterOptionClick = (val: StudentFilterFormKeys | null) => {
    if (!val) {
      setOptionModalData(null);
      setShowFilterSelectionDialog(false);
    } else {
      const title = optionModalTitle(val);
      const multiSelect = title === 'Topic' || title === 'Theme';
      setOptionModalData({ title: title, name: val!, multiSelect: multiSelect });
      setShowFilterSelectionDialog(true);
    }
  };

  const onResetClick = () => {
    reset({
      genreId: null,
      formatId: null,
      topicIds: [],
      themeIds: [],
      grade: null,
      bookCollectionId: null,
    });
    setSublibrarySelection(StudentSubLibrarySelection.AllBooks);
  };

  const onSublibrarySelectionChanged = (sublibrarySelection: StudentSubLibrarySelection) => {
    setSublibrarySelection(sublibrarySelection);
  };

  const onSubmit = (data: StudentLibraryFilterForm) => {
    onApplyFilters(
      {
        bookCollectionId: data.bookCollectionId ?? undefined,
        formatId: data.formatId ?? undefined,
        genreId: data.genreId ?? undefined,
        grade: data.grade ?? undefined,
        themeIds: data.themeIds.length > 0 ? data.themeIds : undefined,
        topicIds: data.topicIds.length > 0 ? data.topicIds : undefined,
      },
      sublibrarySelection,
    );
    onDismiss();
  };

  return (
    <>
      <BackdroplessDialog
        // Hiding main modal when option modal opened
        open={isOpen && !showFilterSelectionDialog}
        onClose={onDismiss}
        maxWidth="md"
        fullWidth
        sx={{
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              px: 3,
              pb: 3,
            },
          },
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle component={'div'} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <HootTypography isPII={false} variant="displaysmall">
              Filter
            </HootTypography>

            <Stack direction={'row'}>
              <LottieButton
                tooltipLabel="Reset filters"
                lottieFile={LottieFile.RestartArrow}
                aria-label="Reset arrow"
                onClick={onResetClick}
                variant="transparent"
                sx={{ p: 1, width: '104px', height: '104px', transform: 'scale(-1, 1)' }}
              />
              <LottieButton
                tooltipLabel="Cancel changes"
                aria-label="Cancel changes"
                lottieFile={LottieFile.ErrorCross}
                onClick={onDismiss}
                variant="transparent"
                sx={{ p: 1, width: '104px', height: '104px' }}
              />
              <LottieButton
                tooltipLabel="Apply and close"
                aria-label="Apply and close"
                lottieFile={LottieFile.ApprovedCheck}
                type="submit"
                variant="transparent"
                sx={{ p: 1, width: '104px', height: '104px' }}
              />
            </Stack>
          </DialogTitle>
          <DialogContent sx={{ pb: 2 }}>
            <Stack gap={5}>
              <Stack gap={0.5}>
                <HootTypography isPII={false} variant="headlinesmall">
                  Only show results from
                </HootTypography>
                <SublibrarySelector fullWidth value={sublibrarySelection} onChange={onSublibrarySelectionChanged} />
              </Stack>
              <Stack>
                <HootTypography isPII={false} variant="headlinesmall">
                  Genre
                </HootTypography>
                {!!watchGenreId ? (
                  <Controller
                    control={control}
                    name="genreId"
                    render={({ field: { value, onChange } }) => {
                      const label = genreOptions.find((o) => o.value === value)?.label ?? '';
                      return (
                        <Box>
                          <OptionSelectedChip onDelete={() => onChange(null)} label={label} />
                        </Box>
                      );
                    }}
                  />
                ) : (
                  <PlainButton onClick={() => onFilterOptionClick('genreId')}>Select Genre</PlainButton>
                )}
              </Stack>
              <Stack>
                <HootTypography isPII={false} variant="headlinesmall">
                  Format
                </HootTypography>
                {!!watchFormatId ? (
                  <Controller
                    control={control}
                    name="formatId"
                    render={({ field: { value, onChange } }) => {
                      const label = formatOptions.find((o) => o.value === value)?.label ?? '';
                      return (
                        <Box>
                          <OptionSelectedChip onDelete={() => onChange(null)} label={label} />
                        </Box>
                      );
                    }}
                  />
                ) : (
                  <PlainButton onClick={() => onFilterOptionClick('formatId')}>Select Format</PlainButton>
                )}
              </Stack>
              {!!watchGenreId ? (
                <Stack>
                  <HootTypography isPII={false} variant="headlinesmall">
                    {subFilterTitle}
                  </HootTypography>
                  <Stack direction={'row'} gap={5}>
                    {hideLottieAddMoreButton ? null : (
                      <LottieButton
                        tooltipLabel={`Add ${optionModalTitle(subFilterType as StudentFilterFormKeys)}`}
                        aria-label="Add theme"
                        lottieFile={LottieFile.AddPlus}
                        onClick={() => onFilterOptionClick(subFilterType)}
                        sx={{ p: 1, minWidth: '104px', minHeight: '104px', maxHeight: '104px', maxWidth: '104px' }}
                      />
                    )}

                    {!!subFilterType ? (
                      <Controller
                        control={control}
                        name={subFilterType}
                        render={({ field: { value, onChange } }) => {
                          const onRemove = (idToRemove: string) => {
                            onChange([...value].filter((x) => x !== idToRemove));
                          };
                          return (
                            <Stack direction={'row'} flexWrap={'wrap'} gap={2}>
                              {value.map((val) => {
                                const label = (subFilterType === 'topicIds' ? topicOptions : themeOptions).find((o) => o.value === val)?.label ?? '';
                                return <OptionSelectedChip key={val} onDelete={() => onRemove(val)} label={label} />;
                              })}
                            </Stack>
                          );
                        }}
                      />
                    ) : null}
                  </Stack>
                </Stack>
              ) : null}
              <Stack>
                <Accordion
                  sx={{
                    padding: '0 !important',
                    margin: `0 !important`,
                    minHeight: `0 !important`,
                    boxShadow: 'unset',
                    '&::before': {
                      display: 'none',
                    },
                  }}
                  expanded={isMoreOpen}
                  onChange={() => setIsMoreOpen((curr) => !curr)}
                >
                  <AccordionSummary
                    sx={{
                      ...hootTokens.text.headlinesmall,
                      '& .MuiAccordionSummary-content': {
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        gap: 2,
                      },
                    }}
                  >
                    <Box width={44} height={44} display="flex" justifyContent="center" alignItems="center" sx={{ marginLeft: '-12px' }}>
                      {isMoreOpen ? (
                        <Icon name="collapse_filled" color={'primary.0'} sx={{ width: 20, height: 20 }} />
                      ) : (
                        <Icon name="solid_add_rectangle" color={'primary.0'} sx={{ width: 18, height: 18 }} />
                      )}
                    </Box>
                    <span>{isMoreOpen ? 'Less' : 'More'}</span>
                  </AccordionSummary>
                  <AccordionDetails sx={{ pt: 2 }}>
                    <Stack direction={'row'} gap={3}>
                      <Controller
                        control={control}
                        name="grade"
                        render={({ field: { value, onChange } }) => (
                          <Select onChange={onChange} value={value ?? ''} label="Grade">
                            <option value="">Select</option>
                            {(gradeOptions as Option[]).map((option) => (
                              <option key={`option-${option.value}`} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </Select>
                        )}
                      />
                      <Controller
                        control={control}
                        name="bookCollectionId"
                        render={({ field: { value, onChange } }) => (
                          <Select onChange={onChange} value={value ?? ''} label="Collection">
                            <option value="">Select</option>
                            {bookCollectionOptions.map((option) => (
                              <option key={`option-${option.value}`} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </Select>
                        )}
                      />
                    </Stack>
                  </AccordionDetails>
                </Accordion>
              </Stack>
            </Stack>
          </DialogContent>
        </form>
      </BackdroplessDialog>

      {/* Second-level modal for filter selection. */}
      <StudentFilterSelectionModalWrapper
        isOpen={isOpen && showFilterSelectionDialog && !!optionModalData}
        onDismiss={() => setShowFilterSelectionDialog(false)}
        filterSelectionProps={
          optionModalData
            ? {
                title: optionModalData.title,
                isMultiSelect: optionModalData.multiSelect,
                options: studentFilterSelectionModalOptions(optionModalData.name),
                controllerProps: {
                  name: optionModalData.name,
                  control: control,
                },
              }
            : undefined
        }
        onDialogDismissAnimationCompleted={() => setOptionModalData(null)}
      />
      {/* We're going to use a single backdrop for all dialogs here, so we don't see the screen flash. */}
      <Backdrop
        open={isOpen}
        sx={{
          zIndex: zIndexes.dialogBackdrop,
        }}
      />
    </>
  );
};

export default StudentLibraryFilterModal;
