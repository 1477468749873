import { ProductStatus } from '@hoot-reading/hoot-core/dist/enums/hfs/product-status.enum';
import { Fade, Paper, Skeleton, Stack, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { capitalCase } from 'change-case';
import { useSelector } from 'react-redux';
import { DistrictRepEnrolmentResponse } from '@hoot/hooks/api/district-rep/useDistrictRepresentativeGetEnrolment';
import useGetDistrictRepPermissions from '@hoot/hooks/api/user/useGetDistrictRepPermissions';
import { RootState } from '@hoot/redux/store';
import { routesDictionary } from '@hoot/routes/routesDictionary';
import { DistrictRepAccessLevelCombinations, useHasDistrictPermission } from '@hoot/routes/secure/profile/district-rep/DistrictRepProtectedRoute';
import ProtectedLink from '@hoot/ui/components/v2/ProtectedLink';
import ViewState, { ViewStateEnum } from '@hoot/ui/components/v2/ViewState';
import { Button } from '@hoot/ui/components/v2/core/Button';
import Card from '@hoot/ui/components/v2/core/Card';
import { Icon } from '@hoot/ui/components/v2/core/Icon';
import Tag, { TagColor } from '@hoot/ui/components/v2/core/Tag';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';

export function handleTagColor(color: ProductStatus | undefined) {
  switch (color) {
    case ProductStatus.Requested:
      return TagColor.Info;
    case ProductStatus.Active:
      return TagColor.Success;
    case ProductStatus.Expired:
      return TagColor.Error;
    default:
      return TagColor.Neutral;
  }
}

interface Props {
  enrolment: DistrictRepEnrolmentResponse | undefined;
  isLoading: boolean;
}

const EnrolmentProductCard = (props: Props) => {
  const { enrolment, isLoading } = props;
  const districtRepresentativeId = useSelector((state: RootState) => state.profile.profile?.id);
  const { data: districtRepPermissions } = useGetDistrictRepPermissions(districtRepresentativeId || '');
  const hasBillableEntityPermission = useHasDistrictPermission(DistrictRepAccessLevelCombinations.BillableEntity, districtRepPermissions);

  const getViewState = (): ViewStateEnum => {
    if (isLoading) {
      return ViewStateEnum.Loading;
    } else if (enrolment) {
      return ViewStateEnum.Results;
    } else {
      // If not loading, and we don't have an enrolment, then this is an invalid state.
      return ViewStateEnum.Error;
    }
  };

  return (
    <Card title="Product" isLoading={isLoading}>
      <ViewState state={getViewState()} loadingContent={<SkeletonContent />}>
        <Fade in>
          <Paper
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '16px',
              backgroundColor: hootTokens.palette.neutral['195'],
            }}
          >
            <Stack gap={2} direction="row" alignItems="center">
              <Icon name="page_white" />
              <Stack gap={1} direction="column">
                <Typography variant="bodylarge">{enrolment?.product.friendlyId}</Typography>
                <span>
                  <Tag
                    label={`Status: ${capitalCase(enrolment?.product.status ?? '')}`}
                    color={handleTagColor(enrolment?.product.status)}
                    sx={{ ...hootTokens.text.bodysmall }}
                  />
                </span>
              </Stack>
            </Stack>
            {hasBillableEntityPermission ? (
              <ProtectedLink
                to={routesDictionary.enrolments.product.details.url(enrolment?.product.id ?? '')}
                hasPermission={hasBillableEntityPermission}
              >
                <Button variant="contained" size={'small'}>
                  View
                </Button>
              </ProtectedLink>
            ) : null}
          </Paper>
        </Fade>
      </ViewState>
    </Card>
  );
};

const SkeletonContent = () => {
  return (
    <Grid container spacing={2}>
      <Grid size={12}>
        <Skeleton variant="rectangular" height="58px" />
      </Grid>
    </Grid>
  );
};

export default EnrolmentProductCard;
