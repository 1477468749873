import { StudentGrade, studentGradeLookup } from '@hoot-reading/hoot-core/dist/enums/student-grade';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { Stack } from '@mui/system';
import { useEffect } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { StudentWithGrade, useUpdateStudentGrade } from '@hoot/hooks/api/parent/useUpdateStudentGrade';
import { QueryKey } from '@hoot/hooks/api/queryKeys';
import { createFlashMessage } from '@hoot/redux/reducers/flashMessageSlice';
import { useAppDispatch } from '@hoot/redux/store';
import { Button } from '@hoot/ui/components/v2/core/Button';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';
import { Icon } from '@hoot/ui/components/v2/core/Icon';
import IconButton from '@hoot/ui/components/v2/core/IconButton';
import { Select } from '@hoot/ui/components/v2/core/Select';

interface IUpdateStudentGradeDialogProps {
  show: boolean;
  onDismiss: () => void;
  students: StudentRequiringGradeUpdate[];
  refetchStudentsRequiringGrade: () => void;
}

export interface StudentRequiringGradeUpdate {
  id: string;
  name: string;
  grade?: StudentGrade | '';
}

export interface StudentGradeForm {
  students: StudentRequiringGradeUpdate[];
}

const UpdateStudentGradeDialog = (props: IUpdateStudentGradeDialogProps) => {
  const { show, onDismiss, students, refetchStudentsRequiringGrade } = props;

  const updateStudentGrade = useUpdateStudentGrade();
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();

  const {
    handleSubmit,
    control,
    reset,
    formState: { isValid },
  } = useForm<StudentGradeForm>();

  useEffect(() => {
    reset({
      students: students.map((student) => ({
        id: student.id,
        name: student.name,
        grade: '',
      })),
    });
  }, [students, reset]);

  const { fields } = useFieldArray({
    control,
    name: 'students',
  });

  const onSubmit = async (data: StudentGradeForm) => {
    const studentsToUpdate: StudentWithGrade[] = data.students.map((student) => ({
      studentProfileId: student.id,
      grade: student.grade! as StudentGrade,
    }));
    updateStudentGrade.mutate(
      { students: studentsToUpdate },
      {
        onSuccess: () => {
          queryClient.invalidateQueries([QueryKey.GetStudentsRequiringGrade, QueryKey.GetStudentGrade]);
          refetchStudentsRequiringGrade();
          onClose();
        },
        onError: () => {
          dispatch(createFlashMessage({ variant: 'error', message: 'There was an error while updating student grade' }));
        },
      },
    );
  };

  const onClose = () => {
    reset();
    onDismiss();
  };

  return (
    <Dialog open={show} onClose={onClose} maxWidth="sm">
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>
          <Stack direction="row" justifyContent={'space-between'} alignItems={'center'} gap={3}>
            <HootTypography isPII={false} variant="titlelarge">
              Update your Student's Grade
            </HootTypography>
            <IconButton onClick={onClose}>
              <Icon name="close" />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <Stack gap={2}>
            {fields.map((student, index) => (
              <Controller
                key={student.id}
                control={control}
                name={`students.${index}.grade`}
                rules={{
                  required: {
                    value: true,
                    message: 'Student Grade is required',
                  },
                }}
                render={({ field: { value, onChange }, fieldState: { error } }) => (
                  <Select label={`${student.name}'s Grade`} fullWidth value={value} onChange={onChange} error={!!error} helperText={error?.message}>
                    <option disabled value="">
                      Select
                    </option>
                    {Object.values(StudentGrade).map((option) => (
                      <option value={option} key={`grade-opt-${option}`}>
                        {studentGradeLookup[option]}
                      </option>
                    ))}
                  </Select>
                )}
              />
            ))}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>
          <Button variant="contained" type="submit" disabled={!isValid}>
            Submit
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default UpdateStudentGradeDialog;
