import axios, { AxiosError } from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '@hoot/config';
import { LocationReportingFilters } from '@hoot/hooks/api/district-rep/reporting/useGetLocationReportingMetrics';
import { QueryKey } from '../../queryKeys';
import { DateFilterQuery } from './useGetStudentProfileMetrics';

export interface RepReportingAvgWeeklyStudentAttendance {
  weekStart: string;
  avgWeeklyAttendanceRate: number;
}

export interface RepReportingAvgWeeklyStudentAttendanceResponse {
  weeks: RepReportingAvgWeeklyStudentAttendance[];
}

export function useGetAvgWeeklyStudentAttendanceReportForLocation(
  locationId: string,
  query: LocationReportingFilters,
  options?: Omit<UseQueryOptions<RepReportingAvgWeeklyStudentAttendanceResponse>, 'queryKey' | 'queryFn'>,
) {
  return useQuery(
    [QueryKey.DistrictRepReportingAvgWeeklyAttendanceForLocation, locationId, query],
    async () => {
      return (
        await axios.get<RepReportingAvgWeeklyStudentAttendanceResponse>(
          `${config.apiUrl}/public/district-representative/reporting/location/${locationId}/chart/avg-weekly-student-attendance`,
          {
            params: query,
          },
        )
      ).data;
    },
    options,
  );
}

export function useGetAvgWeeklyStudentAttendanceReportForStudent(
  studentProfileId: string,
  query: DateFilterQuery,
  options?: Omit<UseQueryOptions<RepReportingAvgWeeklyStudentAttendanceResponse, AxiosError>, 'queryKey' | 'queryFn'>,
) {
  return useQuery(
    [QueryKey.DistrictRepReportingAvgWeeklyAttendanceForStudent, studentProfileId, query],
    async () => {
      return (
        await axios.get<RepReportingAvgWeeklyStudentAttendanceResponse>(
          `${config.apiUrl}/public/district-representative/reporting/student/${studentProfileId}/chart/avg-weekly-student-attendance`,
          {
            params: query,
          },
        )
      ).data;
    },
    options,
  );
}
