import { Box, Fade, Skeleton, Stack } from '@mui/material';
import React, { useState } from 'react';
import { BookSearch } from '@hoot/events/interfaces/book-search';
import useSearchLibrary, { QueryLibraryV2 } from '@hoot/hooks/api/library/useSearchLibrary';
import { ShelfType } from '@hoot/models/api/enums/shelf-type-enum';
import { GenericPaginatedResponse } from '@hoot/models/api/pagination';
import { error } from '@hoot/redux/reducers/alertSlice';
import { routesDictionary } from '@hoot/routes/routesDictionary';
import ViewState, { ViewStateEnum } from '@hoot/ui/components/v2/ViewState';
import ViewStateIllustration, { IllustrationEnum } from '@hoot/ui/components/v2/ViewStateIllustration';
import Card from '@hoot/ui/components/v2/core/Card';
import { HeaderData, TableV2 } from '@hoot/ui/components/v2/core/Table';
import { ScopeAndSequenceLink } from '@hoot/ui/pages/v2/teacher/scope-and-sequence/focus-area/ScopeAndSequenceLink';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';
import { toProperCase } from '@hoot/utils/text-display';

interface ResourceTableProps {
  title: string;
  unitId?: string;
  focusId: string;
  skillFilterId?: string;
  studentProfileId: string | undefined;
}

interface TableRow {
  id: string;
  title: React.ReactNode;
  resourceState: string;
  resourceProgress: string;
  instructionalFocuses: string;
  instructionalUnits: string;
  readingLevel: string;
}

const PAGE_SIZE = 5;

const InstructionalUnitResourcesTable = (props: ResourceTableProps) => {
  const { title, unitId, focusId, skillFilterId, studentProfileId } = props;

  const [viewState, setViewState] = useState<ViewStateEnum>(ViewStateEnum.Loading);
  const [searchResults, setSearchResults] = useState<GenericPaginatedResponse<BookSearch>>({
    data: [],
    count: 0,
    page: 1,
    pageSize: PAGE_SIZE,
  });

  const [libraryQuery, setLibraryQuery] = useState<QueryLibraryV2>({
    shelfType: ShelfType.AllBooks,
    instructionalFocusIds: [focusId],
    instructionalUnitIds: unitId ? [unitId] : undefined,
    filterLevelPrioritizedSkillIds: skillFilterId ? [skillFilterId] : undefined,
    studentProfileId: studentProfileId,
    page: 1,
    pageSize: PAGE_SIZE,
  });

  const searchRequest = useSearchLibrary(libraryQuery, {
    enabled: !!focusId,
    onSuccess: (data) => {
      setSearchResults(data);
      setViewState(data.count === 0 ? ViewStateEnum.Error : ViewStateEnum.Results);
    },
    onError: (err) => {
      console.error(err);
      error(`An error occurred while loading books.`);
      setViewState(ViewStateEnum.Error);
    },
  });

  const displayData = searchResults.data.map<TableRow>((b) => {
    return {
      id: b.id,
      title: (
        <Stack direction="row" alignItems="center">
          <>
            {searchRequest.isLoading ? <Skeleton variant="rectangular" width={20} height={20} /> : null}
            <Fade in={!searchRequest.isLoading}>
              <img
                draggable={false}
                style={{
                  display: `${searchRequest.isLoading ? 'none' : 'block'}`,
                  objectFit: 'cover',
                  width: '48px',
                  height: '48px',
                  borderRadius: '8px',
                  verticalAlign: 'bottom',
                  ...hootTokens.elevation.elevation1,
                }}
                src={b.coverUrl ?? undefined}
                alt={b.title}
              />
            </Fade>
          </>
          <ScopeAndSequenceLink
            style={{ paddingLeft: '24px' }}
            to={routesDictionary.library.book.url(b.id)}
            target="_blank"
            studentProfileId={studentProfileId}
          >
            {b.title}
          </ScopeAndSequenceLink>
        </Stack>
      ),
      resourceState: b.resourceState ? toProperCase(b.resourceState) : 'N/A',
      resourceProgress: b.resourceProgress ? toProperCase(b.resourceProgress) : 'N/A',
      instructionalFocuses: b.focuses ? b.focuses.map((f) => f.name).join(', ') : 'N/A',
      instructionalUnits: b.units ? b.units.map((u) => u.name).join(', ') : 'N/A',
      readingLevel: b.readingLevel,
    };
  });

  const headers: HeaderData<TableRow>[] = [
    { name: 'id', property: 'id', isHidden: true },
    { name: 'Title', property: 'title', isSortable: false },
    { name: 'State', property: 'resourceState', isSortable: false, isHidden: !studentProfileId },
    { name: 'Progress', property: 'resourceProgress', isSortable: false, isHidden: !studentProfileId },
    { name: 'Focus', property: 'instructionalFocuses', isSortable: false },
    { name: 'Unit', property: 'instructionalUnits', isSortable: false },
    { name: 'Level', property: 'readingLevel', isSortable: false },
  ];

  const handleChangePage = (_event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setLibraryQuery((prevState) => ({ ...prevState, page: newPage + 1 }));
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const pageSize = parseInt(event.target.value, 10);
    setLibraryQuery((prevState) => ({ ...prevState, pageSize: pageSize }));
  };

  return (
    <Card title={title} isLoading={viewState === ViewStateEnum.Loading}>
      <ViewState state={viewState} loadingContent={<SkeletonContent />}>
        <TableV2
          isPaginated
          allowRowsPerPage
          rowsPerPage={libraryQuery.pageSize}
          rowsPerPageOptions={[5, 10, 25]}
          data={displayData}
          headers={headers}
          onRowsPerPageChange={handleChangeRowsPerPage}
          count={searchResults.count}
          page={libraryQuery.page - 1}
          onPageChange={handleChangePage}
          emptyViewState={<ViewStateIllustration illustration={IllustrationEnum.EmptyState} />}
        />
      </ViewState>
    </Card>
  );
};

const SkeletonContent = () => (
  <Stack gap={2}>
    <Stack direction="row" alignItems="center" gap={2}>
      <Box flex={1}>
        <Skeleton variant="text" sx={{ maxWidth: '50px', width: '100%' }} />
      </Box>
      <Box flex={1}>
        <Skeleton variant="text" sx={{ maxWidth: '100px', width: '100%' }} />
      </Box>
      <Box flex={1}>
        <Skeleton variant="text" sx={{ maxWidth: '80px', width: '100%' }} />
      </Box>
      <Box flex={1}>
        <Skeleton variant="text" sx={{ maxWidth: '80px', width: '100%' }} />
      </Box>
    </Stack>
    {[...Array(5)].map((_, i) => (
      <Stack key={`skeleton-row-${i}`} direction="row" alignItems="center" gap={2}>
        <Stack direction="row" flex={1} gap={3} alignItems="center">
          <Skeleton variant="rectangular" sx={{ width: '48px', height: '48px' }} />
          <Skeleton variant="text" sx={{ maxWidth: '70px', width: '100%' }} />
        </Stack>
        <Box flex={1}>
          <Skeleton variant="text" sx={{ maxWidth: '200px', width: '100%' }} />
        </Box>
        <Box flex={1}>
          <Skeleton variant="text" sx={{ maxWidth: '220px', width: '100%' }} />
        </Box>
        <Box flex={1}>
          <Skeleton variant="text" sx={{ maxWidth: '24px', width: '100%' }} />
        </Box>
      </Stack>
    ))}
  </Stack>
);

export default InstructionalUnitResourcesTable;
