import { ProductStatus } from '@hoot-reading/hoot-core/dist/enums/hfs/product-status.enum';
import axios from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '@hoot/config';
import { QueryKey } from '@hoot/hooks/api/queryKeys';

export interface DistrictRepGetProductResponse {
  id: string;
  friendlyId: string;
  status: ProductStatus;
  startDate: number;
  endDate: number;
  numberOfStudentsAllowed: number;
  numberOfStudentsRegistered: number;
}

async function getDistrictRepProduct(productId: string): Promise<DistrictRepGetProductResponse> {
  const { data } = await axios.get<DistrictRepGetProductResponse>(`${config.apiUrl}/public/district-representative/product/${productId}`);
  return data;
}

export default function useDistrictRepresentativeGetProduct(
  productId: string,
  options?: Omit<UseQueryOptions<DistrictRepGetProductResponse>, 'queryKey' | 'queryFn'>,
) {
  return useQuery([QueryKey.GetDistrictRepProduct, productId], () => getDistrictRepProduct(productId), options);
}
