import { Box, CardProps, Skeleton, Stack } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { capitalCase } from 'change-case';
import { DateTime } from 'luxon';
import { useMemo, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import useCreateInvoiceBillableTask, { CreateInvoiceBillableTaskDto } from '@hoot/hooks/api/invoice/useCreateInvoiceBillableTask';
import useDeleteInvoiceBillableTask from '@hoot/hooks/api/invoice/useDeleteInvoiceBillableTask';
import { BillableTask, useGetBillableTasks } from '@hoot/hooks/api/invoice/useGetInvoiceBillableTasks';
import { InvoiceDetailsResponse } from '@hoot/hooks/api/invoice/useGetInvoiceDetails';
import { useGetInvoiceProjects } from '@hoot/hooks/api/invoice/useGetInvoiceProjects';
import { QueryKey } from '@hoot/hooks/api/queryKeys';
import { createFlashMessage } from '@hoot/redux/reducers/flashMessageSlice';
import { useAppDispatch } from '@hoot/redux/store';
import ViewState, { ViewStateEnum } from '@hoot/ui/components/v2/ViewState';
import { Button } from '@hoot/ui/components/v2/core/Button';
import Card from '@hoot/ui/components/v2/core/Card';
import DatePicker from '@hoot/ui/components/v2/core/DatePicker';
import EmptyCard from '@hoot/ui/components/v2/core/EmptyCard';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';
import { Select } from '@hoot/ui/components/v2/core/Select';
import Tag, { TagColor } from '@hoot/ui/components/v2/core/Tag';
import TextField from '@hoot/ui/components/v2/core/TextField';
import BasicAlertDialog from '@hoot/ui/components/v2/dialogs/BasicAlertDialog';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';
import { InvoiceLineItemStatus, InvoiceProjectId, professionalDevelopmentProjects } from './enums';
import { taskDurationsList } from './util';

interface BillableTasksProps {
  sx?: CardProps['sx'];
  invoiceDetails: InvoiceDetailsResponse | undefined;
  isSubmitted: boolean;
  isCurrentPeriod: boolean;
  isInvoicePeriodSubmitted: boolean;
}

interface Form {
  taskType: string;
  date: number;
  duration?: string;
  quantity?: number;
  comment?: string;
  lessonNumber?: string;
}

const BillableTasks = (props: BillableTasksProps) => {
  const { sx, invoiceDetails, isSubmitted, isCurrentPeriod, isInvoicePeriodSubmitted } = props;

  const [newTask, setShowNewTask] = useState<boolean>(false);

  const [showRemoveDialog, setShowRemoveDialog] = useState<string>('');

  const handleAddTask = () => {
    setShowNewTask(true);
  };

  const { data } = useGetInvoiceProjects({
    retry: false,
    onError: (ex) => {
      console.error(ex);
    },
  });

  const {
    data: billableTasksData,
    isLoading,
    isError,
  } = useGetBillableTasks(invoiceDetails?.invoiceId || '', {
    retry: false,
    enabled: !!invoiceDetails?.invoiceId,
    onError: (ex) => {
      console.error(ex);
    },
  });

  const {
    formState: { errors },
    handleSubmit,
    watch,
    control,
    reset,
  } = useForm<Form>({
    defaultValues: {
      taskType: '',
      date: Date.now(),
      duration: '',
      quantity: 1,
      comment: '',
      lessonNumber: '',
    },
  });

  const createBillableTask = useCreateInvoiceBillableTask();
  const deleteBillableTask = useDeleteInvoiceBillableTask();

  const queryClient = useQueryClient();

  const { taskType } = watch();

  const dispatch = useAppDispatch();

  const viewState = useMemo<ViewStateEnum>(() => {
    if (isLoading) {
      return ViewStateEnum.Loading;
    } else if (isError) {
      return ViewStateEnum.Error;
    } else if ((isSubmitted || !isCurrentPeriod) && !billableTasksData?.billableTasks.length) {
      return ViewStateEnum.EmptyState;
    } else {
      return ViewStateEnum.Results;
    }
  }, [isLoading, isError, isSubmitted, billableTasksData, isCurrentPeriod]);

  const onSubmit: SubmitHandler<Form> = (data: Form) => {
    const request: CreateInvoiceBillableTaskDto = {
      invoiceId: invoiceDetails?.invoiceId ?? '',
      projectId: data.taskType,
      date: data.date,
      duration: data.duration ? data.duration : null,
      quantity: data.quantity ? Number(data.quantity) : null,
      comment: data.comment ? data.comment : null,
      lessonNumber: data.lessonNumber ? Number(data.lessonNumber) : null,
    };
    createBillableTask.mutate(request, {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryKey.GetInvoiceSummary);
        queryClient.invalidateQueries(QueryKey.GetInvoiceBillableTasks);
        setShowNewTask(false);
        reset();
        dispatch(createFlashMessage({ message: 'Billable Task Successfully Added' }));
      },
      onError: () => {
        dispatch(createFlashMessage({ variant: 'error', message: 'There was an error while creating billable task' }));
      },
    });
  };

  const onRemove = (taskId: string) => {
    deleteBillableTask.mutate(taskId, {
      onSuccess: () => {
        setShowRemoveDialog('');
        queryClient.invalidateQueries(QueryKey.GetInvoiceBillableTasks);
        queryClient.invalidateQueries(QueryKey.GetInvoiceSummary);
        dispatch(createFlashMessage({ message: 'Billable Task Successfully Removed' }));
      },
      onError: (ex) => {
        console.error(ex);
        dispatch(createFlashMessage({ variant: 'error', message: 'There was an error while removing billable task' }));
      },
    });
  };

  const handleRemoveNewTask = () => {
    setShowNewTask(false);
    reset();
  };

  const AddTask = () => {
    return (
      <EmptyCard
        sx={{
          backgroundColor: hootTokens.palette.neutral[195],
        }}
        button={{
          onClick: handleAddTask,
          text: 'Add Task',
        }}
      />
    );
  };

  const SubmittedTask = (props: { task: BillableTask }) => {
    const { task } = props;
    return (
      <Card
        sx={{
          backgroundColor: hootTokens.palette.neutral[195],
          height: '100%',
          display: 'flex',
          flex: 1,
        }}
        contentSx={{
          display: 'flex',
          flex: 1,
        }}
      >
        <Box sx={{ display: 'flex', flex: 1, flexDirection: 'column', justifyContent: 'space-between' }}>
          <Stack gap={2}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  gap: 1,
                  alignItems: 'center',
                }}
              >
                <HootTypography isPII={false} variant="titlesmall">
                  {task.taskType}
                </HootTypography>
              </Box>
              {!isInvoicePeriodSubmitted || isCurrentPeriod ? (
                <Button variant="outlined" color="error" size="small" onClick={() => setShowRemoveDialog(task.id)} disabled={isSubmitted}>
                  Remove
                </Button>
              ) : (
                <Tag
                  label={capitalCase(task?.status ?? '')}
                  color={task?.status === InvoiceLineItemStatus.Approved ? TagColor.Success : TagColor.Warning}
                  sx={{ ...hootTokens.text.labellarge }}
                />
              )}
            </Box>
            <Stack>
              <HootTypography isPII={false} variant="labellarge">
                Date:
              </HootTypography>
              <HootTypography isPII={false} variant="bodysmall">
                {task.date}
              </HootTypography>
            </Stack>
            {task.teacherComment ? (
              <Stack>
                <HootTypography isPII={false} variant="labellarge">
                  Comment:
                </HootTypography>
                <HootTypography isPII={true} variant="bodysmall">
                  {task.teacherComment}
                </HootTypography>
              </Stack>
            ) : null}
            {task.lessonNumber ? (
              <Stack>
                <HootTypography isPII={false} variant="labellarge">
                  Lesson Number:
                </HootTypography>
                <HootTypography isPII={false} variant="bodysmall">
                  {task.lessonNumber}
                </HootTypography>
              </Stack>
            ) : null}
            {task.duration ? (
              <Stack>
                <HootTypography isPII={false} variant="labellarge">
                  Duration:
                </HootTypography>
                <HootTypography isPII={false} variant="bodysmall">
                  {task.duration} Minutes
                </HootTypography>
              </Stack>
            ) : null}
            {isInvoicePeriodSubmitted && task.reviewerComment && task.status === InvoiceLineItemStatus.Rejected ? (
              <Stack>
                <HootTypography isPII={false} variant="labellarge">
                  Reason for rejection:
                </HootTypography>
                <HootTypography isPII={true} variant="bodysmall">
                  {task.reviewerComment}
                </HootTypography>
              </Stack>
            ) : null}
          </Stack>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <HootTypography isPII={false} variant="titlesmall">
              Total
            </HootTypography>
            <HootTypography isPII={false} variant="titlesmall">
              {task.total !== 0 ? task.total.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) : 'TBD'}
            </HootTypography>
          </Box>
        </Box>
      </Card>
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Card
        title="Billable Tasks"
        titleVariant="titlelarge"
        sx={{ height: 'max-content', maxWidth: '100%', ...(sx ?? {}) }}
        contentSx={{ padding: '16px 0px 16px 0px' }}
      >
        <BasicAlertDialog
          show={!!showRemoveDialog}
          onDismiss={() => setShowRemoveDialog('')}
          title={'Confirm Remove'}
          content={
            <Stack spacing={2}>
              <HootTypography isPII={false} variant="bodylarge">
                You are about to remove a task.
              </HootTypography>
              <HootTypography isPII={false} variant="bodylarge">
                Are you sure you want to continue?
              </HootTypography>
            </Stack>
          }
          primaryAction={{
            onClick: () => onRemove(showRemoveDialog),
            label: 'Remove',
            props: {
              color: 'error',
              variant: 'contained',
            },
          }}
          secondaryAction={{
            onClick: () => setShowRemoveDialog(''),
            label: 'Cancel',
            props: {
              variant: 'outlined',
            },
          }}
        />
        <Stack gap={2}>
          <HootTypography isPII={false} variant="bodysmall">
            All billable tasks are subject to approval.
          </HootTypography>
          <ViewState
            state={viewState}
            loadingContent={<InvoiceBillableTasksSkeletonItems />}
            EmptyStateIllustrationProps={{ title: 'No Billable Tasks', subtitle: 'Your billable tasks will be listed here', showBorder: true }}
          >
            <Grid container spacing={2} columns={{ xs: 1, md: 12 }}>
              {billableTasksData?.billableTasks.map((task) => (
                <Grid size={{ xs: 12, md: 4 }} minHeight={'386px'} key={task.id}>
                  <SubmittedTask task={task} />
                </Grid>
              ))}
              {!newTask && !isSubmitted && !isInvoicePeriodSubmitted && isCurrentPeriod ? (
                <Grid size={{ xs: 12, md: 4 }} minHeight={'386px'}>
                  <AddTask />
                </Grid>
              ) : !isSubmitted && !isInvoicePeriodSubmitted && isCurrentPeriod ? (
                <Grid size={{ xs: 12, md: 4 }} minHeight={'386px'} maxWidth={'100%'}>
                  <Card
                    sx={{
                      backgroundColor: hootTokens.palette.neutral[195],
                    }}
                  >
                    <Stack rowGap={2} height={'100%'}>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            gap: 1,
                          }}
                        >
                          <HootTypography isPII={false} variant="titlesmall">
                            New Task
                          </HootTypography>
                        </Box>
                        <Button variant="outlined" color="error" size="small" onClick={handleRemoveNewTask}>
                          Remove
                        </Button>
                      </Box>
                      <Stack gap={3}>
                        <Controller
                          control={control}
                          name="taskType"
                          rules={{
                            required: true,
                          }}
                          render={({ field, fieldState }) => (
                            <Select
                              required
                              error={!!fieldState.error}
                              value={field.value}
                              onChange={field.onChange}
                              label="Task Type"
                              helperText={errors.taskType?.message || ''}
                            >
                              <option disabled value="">
                                Select
                              </option>
                              {data?.projects.map((d) => (
                                <option key={d.id} value={d.id}>
                                  {d.projectCode}
                                </option>
                              ))}
                            </Select>
                          )}
                        />
                        {!!taskType ? (
                          <Controller
                            control={control}
                            name="date"
                            rules={{
                              required: true,
                            }}
                            render={({ field }) => (
                              <DatePicker
                                textFieldProps={{ required: true, placeholder: 'mm/dd/yyy' }}
                                label={professionalDevelopmentProjects.includes(taskType as InvoiceProjectId) ? 'Date of Completion' : 'Date'}
                                onChange={(val) => field.onChange(val?.toMillis())}
                                value={field.value ? DateTime.fromMillis(field.value) : null}
                              />
                            )}
                          />
                        ) : null}
                        {taskType === InvoiceProjectId.OpenSeededTime ? (
                          <>
                            <Controller
                              control={control}
                              name="duration"
                              rules={{
                                required: true,
                              }}
                              render={({ field, fieldState }) => (
                                <Select
                                  required
                                  label="Duration"
                                  value={field.value}
                                  onChange={field.onChange}
                                  error={!!fieldState.error}
                                  helperText={errors.duration?.message || ''}
                                >
                                  {taskDurationsList.map((d) => (
                                    <option key={d.value} disabled={!d.value} value={d.value}>
                                      {d.label}
                                    </option>
                                  ))}
                                </Select>
                              )}
                            />
                          </>
                        ) : null}
                        {taskType === InvoiceProjectId.ApprovedAdminTime ? (
                          <>
                            <Controller
                              control={control}
                              name="comment"
                              rules={{
                                required: true,
                              }}
                              render={({ field }) => <TextField key={1} required label="Comment" value={field.value} onChange={field.onChange} />}
                            />
                            <Controller
                              control={control}
                              name="lessonNumber"
                              render={({ field }) => (
                                <TextField type="number" label="Lesson Number (Optional)" value={field.value} onChange={field.onChange} />
                              )}
                            />
                            <Controller
                              control={control}
                              name="duration"
                              render={({ field, fieldState }) => (
                                <TextField
                                  label="Duration (Optional)"
                                  value={field.value}
                                  onChange={field.onChange}
                                  type="number"
                                  inputProps={{ min: 1 }}
                                  error={!!fieldState.error}
                                  helperText={errors.duration?.message || ''}
                                />
                              )}
                            />
                          </>
                        ) : null}
                        <Button type="submit" variant="contained" fullWidth>
                          Save
                        </Button>
                      </Stack>
                    </Stack>
                  </Card>
                </Grid>
              ) : null}
            </Grid>
          </ViewState>
        </Stack>
      </Card>
    </form>
  );
};

const SkeletonCard = () => (
  <Card sx={{ minHeight: '100%', backgroundColor: hootTokens.palette.neutral[195] }}>
    <Stack gap={4}>
      <Stack gap={3} sx={{ height: '100%' }}>
        <Skeleton variant="rounded" sx={{ maxWidth: '100%', ...hootTokens.text.titlelarge }} />
        <Stack gap={1}>
          <Skeleton variant="rounded" sx={{ maxWidth: '150px', ...hootTokens.text.labellarge }} />
          <Skeleton variant="rounded" sx={{ maxWidth: '150px', ...hootTokens.text.bodysmall }} />
        </Stack>
        <Stack gap={1}>
          <Skeleton variant="rounded" sx={{ maxWidth: '150px', ...hootTokens.text.labellarge }} />
          <Skeleton variant="rounded" sx={{ maxWidth: '150px', ...hootTokens.text.bodysmall }} />
        </Stack>
        <Stack gap={1}>
          <Skeleton variant="rounded" sx={{ maxWidth: '150px', ...hootTokens.text.labellarge }} />
          <Skeleton variant="rounded" sx={{ maxWidth: '150px', ...hootTokens.text.bodysmall }} />
        </Stack>
        <Stack gap={1}>
          <Skeleton variant="rounded" sx={{ maxWidth: '150px', ...hootTokens.text.labellarge }} />
          <Skeleton variant="rounded" sx={{ maxWidth: '150px', ...hootTokens.text.bodysmall }} />
        </Stack>
        <Skeleton variant="rounded" sx={{ maxWidth: '100%', ...hootTokens.text.titlesmall }} />
      </Stack>
    </Stack>
  </Card>
);

const InvoiceBillableTasksSkeletonItems = () => (
  <Grid container spacing={2} columns={{ xs: 1, sm: 12, md: 12 }}>
    <Grid size={{ xs: 12, sm: 6, md: 4 }} minHeight={'386px'}>
      <SkeletonCard />
    </Grid>
    <Grid size={{ xs: 12, sm: 6, md: 4 }} minHeight={'386px'}>
      <SkeletonCard />
    </Grid>
    <Grid size={{ xs: 12, sm: 6, md: 4 }} minHeight={'386px'}>
      <SkeletonCard />
    </Grid>
  </Grid>
);

export default BillableTasks;
