import { Navigate, useParams } from 'react-router-dom';
import { routesDictionary } from '@hoot/routes/routesDictionary';
import { FocusAreaSlug } from '@hoot/ui/pages/v2/teacher/scope-and-sequence/scopeAndSequenceFocusAreas';

const StudentScopeAndSequenceRedirect = () => {
  const { studentProfileId } = useParams<{ studentProfileId: string }>();

  return (
    <Navigate to={routesDictionary.myStudents.details.scopeAndSequence.focusArea.url(studentProfileId!, FocusAreaSlug.preWordReading)} replace />
  );
};

export default StudentScopeAndSequenceRedirect;
