import { scheduledLessonTypeLookup } from '@hoot-reading/hoot-core/dist/enums/scheduled-lesson';
import { Stack, useMediaQuery, useTheme } from '@mui/material';
import { DateTime } from 'luxon';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { URGENT_LESSON_RANGE_IN_HOURS } from '@hoot/constants/constants';
import useGetLessonOpportunities, { LessonOpportunitiesQuery, LessonOpportunityResponse } from '@hoot/hooks/api/schedule/useGetLessonOpportunities';
import usePickUpLessonOpportunity from '@hoot/hooks/api/schedule/usePickLessonOpportunity';
import useProfile from '@hoot/hooks/useProfile';
import { useRedirectProfile } from '@hoot/hooks/useRedirectProfile';
import { OrderBy } from '@hoot/models/api/enums/queryEnums';
import { TeacherStage } from '@hoot/models/api/teacher';
import { UserProfileType } from '@hoot/models/api/user';
import { createFlashMessage } from '@hoot/redux/reducers/flashMessageSlice';
import { useAppDispatch } from '@hoot/redux/store';
import { routesDictionary } from '@hoot/routes/routesDictionary';
import SubHeader from '@hoot/ui/components/v2/SubHeader';
import ViewStateIllustration, { IllustrationEnum } from '@hoot/ui/components/v2/ViewStateIllustration';
import { Button } from '@hoot/ui/components/v2/core/Button';
import Card from '@hoot/ui/components/v2/core/Card';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';
import Page from '@hoot/ui/components/v2/core/Page';
import { HeaderData, TableV2 } from '@hoot/ui/components/v2/core/Table';
import Tag, { TagColor } from '@hoot/ui/components/v2/core/Tag';
import TextField from '@hoot/ui/components/v2/core/TextField';
import BasicAlertDialog from '@hoot/ui/components/v2/dialogs/BasicAlertDialog';
import PermissionDeniedDialog from '@hoot/ui/components/v2/dialogs/PermissionDeniedDialog';
import { useAuth } from '@hoot/ui/context/AuthContext';
import { hoursFromUnixMillis } from '@hoot/utils/date';

interface LessonOpportunitiesTableRow {
  id: string;
  date: string;
  time: React.ReactNode;
  duration: string;
  lessonDetails: string;
  action: React.ReactNode;
}

const isLessonWithinSixHours = (lesson: LessonOpportunityResponse) => {
  const currentTimeMillis = DateTime.local().toMillis();
  return hoursFromUnixMillis(currentTimeMillis, lesson.startsAt) <= URGENT_LESSON_RANGE_IN_HOURS;
};

const AddToMyScheduleItem = (props: { label: string; value: string }) => {
  return <TextField label={props.label} value={props.value} variant="filled" filledColour="warning.190" readOnly />;
};

const AddToMyScheduleDialogContent = (props: { lesson: LessonOpportunityResponse; isMobile: boolean }) => {
  const { lesson, isMobile } = props;
  return (
    <Stack spacing={2}>
      <HootTypography isPII={false}>Do you want to add this Lesson to your schedule?</HootTypography>
      <Stack spacing={2}>
        <Stack direction={isMobile ? 'column' : 'row'} spacing={2}>
          <AddToMyScheduleItem label="Date" value={DateTime.fromMillis(lesson.startsAt).toLocaleString(DateTime.DATE_FULL)} />
          <AddToMyScheduleItem label="Time" value={DateTime.fromMillis(lesson.startsAt).toLocaleString(DateTime.TIME_SIMPLE)} />
        </Stack>
        <Stack direction={isMobile ? 'column' : 'row'} spacing={2}>
          <AddToMyScheduleItem label="Duration" value={`${lesson.duration} Minutes`} />
          <AddToMyScheduleItem label="Lesson Details" value={scheduledLessonTypeLookup[lesson.lessonType]} />
        </Stack>
      </Stack>
    </Stack>
  );
};

const AddToMyScheduleSuccessDialogContent = () => {
  return <ViewStateIllustration illustration={IllustrationEnum.SetupComplete} title="Success!" subtitle="Lesson added to My Schedule" />;
};

const LessonTime = (props: { lesson: LessonOpportunityResponse; isMobile: boolean }) => {
  const { lesson, isMobile } = props;

  return (
    <Stack direction="row" gap={2} alignItems="center">
      {DateTime.fromMillis(lesson.startsAt).toLocaleString(DateTime.TIME_SIMPLE)}
      {isLessonWithinSixHours(lesson) && !isMobile ? <Tag label="Starts Soon" color={TagColor.Error} /> : null}
    </Stack>
  );
};

export function LessonOpportunities() {
  useRedirectProfile(UserProfileType.Student);
  useRedirectProfile(UserProfileType.Parent);
  useRedirectProfile(UserProfileType.DistrictRep);

  const { isTeacher, isProfileEnabled } = useProfile();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { getUser } = useAuth();
  const user = getUser();
  const isTeacherOnboarding = user.teacherAccount?.teacherStage === TeacherStage.Onboarding;

  const [query, setQuery] = useState<LessonOpportunitiesQuery>({
    page: 1,
    pageSize: 10,
    orderBy: OrderBy.Asc,
  });
  const [, setPermissionDeniedModal] = useState<boolean>(false);
  const [pickUpDialogOpen, setPickUpDialogOpen] = useState(false);
  const [pickUpSuccessOpen, setPickUpSuccessOpen] = useState(false);
  const [selectedLesson, setSelectedLesson] = useState<LessonOpportunityResponse>();

  const { data: oppsData, refetch: refetchOpportunities, isFetching } = useGetLessonOpportunities(query);
  const pickUpLessonMutation = usePickUpLessonOpportunity();

  const handlePermissionModalClose = () => setPermissionDeniedModal(false);
  const isTeacherInactive = isTeacher && !isProfileEnabled;
  if (isTeacherInactive || isTeacherOnboarding) {
    return <PermissionDeniedDialog isModalOpen={true} closeModal={handlePermissionModalClose} />;
  }

  const onTableActionClick = (lesson: LessonOpportunityResponse) => {
    setSelectedLesson(lesson);
    setPickUpDialogOpen(true);
  };

  const handleDismiss = () => {
    setPickUpDialogOpen(false);
    setPickUpSuccessOpen(false);
  };

  let data: LessonOpportunitiesTableRow[] = [];
  if (!isFetching && oppsData?.lessons) {
    data = oppsData.lessons.map((opp) => ({
      id: opp.lessonId,
      date: DateTime.fromMillis(opp.startsAt).toLocaleString(DateTime.DATE_FULL),
      time: <LessonTime lesson={opp} isMobile={isMobile} />,
      duration: `${opp.duration} Minutes`,
      lessonDetails: scheduledLessonTypeLookup[opp.lessonType],
      action: (
        <Button variant="contained" size="small" color={isLessonWithinSixHours(opp) ? 'error.80' : undefined} onClick={() => onTableActionClick(opp)}>
          {isMobile ? 'Add' : 'Add To My Schedule'}
        </Button>
      ),
    }));
  }

  const headers: HeaderData<LessonOpportunitiesTableRow>[] = [
    { name: 'ID', property: 'id', isHidden: true },
    { name: 'Date', property: 'date' },
    { name: 'Time', property: 'time' },
    { name: 'Duration', property: 'duration', isHidden: isMobile },
    { name: 'Lesson Details', property: 'lessonDetails', isHidden: isMobile },
    { name: 'Action', property: 'action' },
  ];

  const handleChangePage = (_event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setQuery((currentState) => ({
      ...currentState,
      page: newPage + 1, // underlying MUITable uses page numbers starting from 0, but we use page numbers starting from one
    }));
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const pageSize = parseInt(event.target.value, 10);
    setQuery((currentState) => ({
      ...currentState,
      pageSize,
    }));
  };

  const handleSortBy = () => {
    setQuery((currentState) => ({
      ...currentState,
      orderBy: OrderBy.Asc ? OrderBy.Desc : OrderBy.Asc,
    }));
  };

  const onConfirmAddLesson = async () => {
    if (!selectedLesson) {
      return;
    }

    await pickUpLessonMutation.mutateAsync(selectedLesson.lessonId, {
      onError: (err) => {
        console.error(err);
        setPickUpDialogOpen(false);
        dispatch(createFlashMessage({ variant: 'error', message: `Uh oh! This lesson is no longer available.` }));
      },
      onSuccess: () => {
        dispatch(createFlashMessage({ variant: 'light', message: 'Success! This lesson has been added to your schedule.' }));
        setPickUpSuccessOpen(true);
        setPickUpDialogOpen(false);
      },
      onSettled: async () => {
        await refetchOpportunities();
      },
    });
  };

  const handleViewSchedule = () => {
    navigate(routesDictionary.schedule.url);
  };

  return (
    <>
      <SubHeader
        title={{
          label: 'Opportunities',
          isPII: false,
        }}
      />

      <Page pageTitle="Opportunities | Hoot Reading">
        <Card isLoading={isFetching}>
          <TableV2
            isPaginated
            isSortable
            data={data}
            headers={headers}
            hideRowsPerPage={isMobile}
            rowsPerPage={query.pageSize}
            onRowsPerPageChange={handleChangeRowsPerPage}
            count={oppsData?.count || 0}
            page={query.page - 1}
            onPageChange={handleChangePage}
            onSortBy={handleSortBy}
            sortOrder={query.orderBy}
            sortBy={'date'}
          />
        </Card>
      </Page>

      <BasicAlertDialog
        show={pickUpDialogOpen}
        onDismiss={handleDismiss}
        maxWidth={'sm'}
        title="Add to My Schedule"
        content={<AddToMyScheduleDialogContent lesson={selectedLesson!} isMobile={isMobile} />}
        stackDialogActions={isMobile}
        primaryAction={{
          label: 'Confirm',
          onClick: onConfirmAddLesson,
        }}
        secondaryAction={{
          label: 'Cancel',
          onClick: handleDismiss,
        }}
      />
      <BasicAlertDialog
        show={pickUpSuccessOpen}
        onDismiss={handleDismiss}
        maxWidth={'sm'}
        title="Add to My Schedule"
        content={<AddToMyScheduleSuccessDialogContent />}
        dialogActionsSx={{ justifyContent: 'space-between' }}
        stackDialogActions={isMobile}
        primaryAction={{
          label: 'Close',
          onClick: handleDismiss,
        }}
        secondaryAction={{
          label: 'View My Schedule',
          onClick: handleViewSchedule,
        }}
      />
    </>
  );
}
