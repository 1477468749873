import * as React from 'react';
import { useSelector } from 'react-redux';
import useSearchDistrictRepresentativeSchools, {
  DistrictRepresentativeSchoolsQuery,
} from '@hoot/hooks/api/district-rep/useSearchDistrictRepresentativeSchools';
import { OrderBy } from '@hoot/models/api/enums/queryEnums';
import { RootState } from '@hoot/redux/store';
import { Select } from '@hoot/ui/components/v2/core/Select';

function DistrictRepScheduleSchoolFilter(props: {
  label: string;
  onChange: (val: string | undefined) => void;
  value?: string;
  error?: boolean;
  helperText?: string;
}) {
  const { label, onChange, value, error, helperText } = props;

  const districtRepresentativeId = useSelector((state: RootState) => state.profile.profile?.id);

  const schoolsQuery: DistrictRepresentativeSchoolsQuery = {
    page: 1,
    pageSize: 100,
    sortBy: 'name',
    orderBy: OrderBy.Asc,
  };

  const { data: schoolsData } = useSearchDistrictRepresentativeSchools(districtRepresentativeId!, schoolsQuery, {
    enabled: !!districtRepresentativeId,
    keepPreviousData: true,
  });

  return (
    <Select
      label={label}
      value={value}
      onChange={(event) => {
        if (onChange) {
          onChange(event.target.value);
        }
      }}
      error={error}
      helperText={helperText}
    >
      <option value={undefined} disabled selected={!value}>
        Select
      </option>
      {schoolsData?.schools.map((school) => {
        return (
          <option key={school.id} value={school.id}>
            {school.name}
          </option>
        );
      })}
    </Select>
  );
}

export default DistrictRepScheduleSchoolFilter;
