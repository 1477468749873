import Stack from '@mui/material/Stack';
import { useState } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { DateFilterQuery } from '@hoot/hooks/api/district-rep/reporting/useGetStudentProfileMetrics';
import useDistrictRepGetStudentProfileSnippet from '@hoot/hooks/api/district-rep/useDistrictRepresentativeGetStudentProfileSnippet';
import useDistrictRepLocationDetails from '@hoot/hooks/useDistrictRepLocationDetails';
import { DateFilterEnum } from '@hoot/models/api/district-rep-reporting/common';
import { routesDictionary } from '@hoot/routes/routesDictionary';
import Card from '@hoot/ui/components/v2/core/Card';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';
import Page from '@hoot/ui/components/v2/core/Page';
import StudentReport from './StudentReport';
import StudentReportingFilters from './StudentReportingFilters';

const StudentReportingPage = () => {
  const { studentProfileId } = useParams();
  const navigate = useNavigate();

  const [dateFilter, setDateFilter] = useState<DateFilterQuery | undefined>({ date: [DateFilterEnum.ThisSchoolYear] });

  const { data: studentProfileSnippet } = useDistrictRepGetStudentProfileSnippet(studentProfileId ?? '', {
    enabled: !!studentProfileId,
    retry: false,
    onError: (err) => {
      console.error(err);
      // If we don't have permission to view this student, then redirect out of this page.
      if (err.response?.status === 403) {
        navigate(routesDictionary.myStudents.url, { replace: true });
      }
    },
  });

  const pageTitle = studentProfileSnippet?.name ? `${studentProfileSnippet.name}'s Reporting | Hoot Reading` : 'Student Reporting | Hoot Reading';

  const { districtRepLocationDetails } = useDistrictRepLocationDetails();

  const locationHasReporting = !!districtRepLocationDetails?.reporting;
  const showReportingPage = locationHasReporting;

  if (!showReportingPage) {
    return <Navigate to="/" />;
  }

  return (
    <Page pageTitle={pageTitle}>
      <Card>
        <HootTypography variant="displaysmall" mb={2} isPII={false}>
          Reporting
        </HootTypography>

        <Stack gap={5} mb={2}>
          <StudentReportingFilters dateFilter={dateFilter} setDateFilter={setDateFilter} />
          <StudentReport studentProfile={studentProfileSnippet} dateFilter={dateFilter} />
        </Stack>

        <HootTypography variant="tableheadingactive" isPII={false}>
          These reports are for informational purposes only and is not to be referenced for invoicing.
        </HootTypography>
      </Card>
    </Page>
  );
};

export default StudentReportingPage;
