import { AssessmentReportStatus } from '@hoot-reading/hoot-core/dist/enums/hoot-assessment/assessment-reporting';
import { Accordion, AccordionDetails, AccordionSummary, Box, Stack, Tooltip, useTheme } from '@mui/material';
import Grid from '@mui/material/Grid2';
import * as CSS from 'csstype';
import React from 'react';
import { Chart } from 'react-google-charts';
import { ChartWrapperOptions } from 'react-google-charts/dist/types';
import { Link } from 'react-router-dom';
import { featureFlags } from '@hoot/constants/featureFlags';
import { InstructionalFocusModule, InstructionalFocusUnit } from '@hoot/hooks/api/assessment/useGetStudentInstructionalFocusAreasReport';
import { routesDictionary } from '@hoot/routes/routesDictionary';
import ViewStateIllustration, { IllustrationEnum } from '@hoot/ui/components/v2/ViewStateIllustration';
import Card from '@hoot/ui/components/v2/core/Card';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';
import Tag, { TagColor } from '@hoot/ui/components/v2/core/Tag';
import Chevron from '@hoot/ui/components/v2/icons/Chevron';
import ScopeAndSequenceIcon from '@hoot/ui/components/v2/icons/ScopeAndSequenceIcon';
import { useFeatureFlags } from '@hoot/ui/context/FeatureFlagContext';
import { useStudentDetailsContext } from '@hoot/ui/pages/v2/teacher/my-students/student-details/StudentDetailsContextProvider';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';

export const statusColorMap: Record<AssessmentReportStatus, CSS.Property.Color> = {
  [AssessmentReportStatus.Bypassed]: hootTokens.palette.success['180'],
  [AssessmentReportStatus.Mastered]: hootTokens.palette.success['100'],
  [AssessmentReportStatus.NeedsInstruction]: hootTokens.palette.warning['100'],
  [AssessmentReportStatus.NeedsReview]: hootTokens.palette.secondary['100'],
  [AssessmentReportStatus.NotStarted]: hootTokens.palette.primary['180'],
};

const SubmissionChart = (props: { instructionalFocusUnit: InstructionalFocusUnit }) => {
  const { instructionalFocusUnit } = props;

  const submissions = instructionalFocusUnit.submissions?.length
    ? instructionalFocusUnit.submissions.map((submission) => {
        return [submission.date, submission.score, statusColorMap[submission.status]];
      })
    : [];

  const data = [['Date', 'Score', { role: 'style' }], ...submissions];

  const options: ChartWrapperOptions['options'] = {
    legend: 'none',
    bar: { groupWidth: 40 },
    chartArea: {
      height: '80%',
      width: '80%',
    },
    vAxis: {
      viewWindow: {
        min: 0,
        max: instructionalFocusUnit.maxScore ?? 5,
      },
    },
  };

  return <Chart chartType="ColumnChart" width="100%" height="218px" data={data} options={options} />;
};

const scopeAndSequenceIconColours = [
  { primary: '#B80201', secondary: '#C05B5B' },
  { primary: '#A7006A', secondary: '#A55387' },
  { primary: '#111C54', secondary: '#576193' },
  { primary: '#01591C', secondary: '#387A4C' },
  { primary: '#180D87', secondary: '#5C55A9' },
  { primary: '#880394', secondary: '#AA51B2' },
];

const UnitCard = (props: { focusAreaSlug: string | undefined; unit: InstructionalFocusUnit; index: number }) => {
  const { focusAreaSlug, unit, index } = props;
  const theme = useTheme();

  const isMastered = unit && unit.status === AssessmentReportStatus.Mastered;
  const renderChart = ![AssessmentReportStatus.NotStarted, AssessmentReportStatus.Mastered, AssessmentReportStatus.Bypassed].includes(unit.status);

  const { isFeatureEnabled } = useFeatureFlags();
  const isScopeAndSequenceEnabled = isFeatureEnabled(featureFlags.epic_8641_scope_and_sequence);

  const { studentProfileSnippet } = useStudentDetailsContext();

  const scopeAndSequenceLink =
    focusAreaSlug && unit.instructionalUnitSlug && unit.prioritizedSkillId
      ? routesDictionary.myStudents.details.scopeAndSequence.focusArea.unit.skill.documentation.url(
          studentProfileSnippet.id,
          focusAreaSlug,
          unit.instructionalUnitSlug,
          unit.prioritizedSkillId,
        )
      : focusAreaSlug && unit.instructionalUnitSlug
        ? routesDictionary.myStudents.details.scopeAndSequence.focusArea.unit.documentation.url(
            studentProfileSnippet.id,
            focusAreaSlug,
            unit.instructionalUnitSlug,
          )
        : undefined;

  return (
    <Grid size={{ xs: 12, sm: 6, md: 4, xl: 3 }} key={unit.order}>
      <Card contentSx={{ pb: theme.spacing(3) }}>
        <Stack direction="row" justifyContent="center" alignItems="center" sx={{ height: 44 }}>
          {isScopeAndSequenceEnabled && scopeAndSequenceLink && (
            <Tooltip title={`View documentation on "${unit.label}"`}>
              <Link to={scopeAndSequenceLink} target="_blank" style={{ padding: '8px', display: 'flex', alignItems: 'center' }}>
                <ScopeAndSequenceIcon
                  fill={scopeAndSequenceIconColours[index % scopeAndSequenceIconColours.length].secondary}
                  stroke={scopeAndSequenceIconColours[index % scopeAndSequenceIconColours.length].primary}
                />
              </Link>
            </Tooltip>
          )}
          <HootTypography isPII={false} variant="titlesmall">
            {unit.label}
          </HootTypography>
        </Stack>
        {renderChart && <SubmissionChart instructionalFocusUnit={unit} />}
        {!renderChart && (
          <ViewStateIllustration
            illustration={isMastered ? IllustrationEnum.SetupComplete : IllustrationEnum.EmptyState}
            title={''}
            subtitle={isMastered ? 'This unit has been mastered' : 'This unit has not been assessed'}
            padding={0}
            grayScale={isMastered}
          />
        )}
      </Card>
    </Grid>
  );
};

interface LegendStatus {
  status: AssessmentReportStatus;
  label: string;
}

const legendStatuses: LegendStatus[] = [
  { status: AssessmentReportStatus.NeedsInstruction, label: 'Instruction Required' },
  { status: AssessmentReportStatus.NeedsReview, label: 'Review Required' },
  { status: AssessmentReportStatus.Mastered, label: 'Mastered' },
];

const StudentInProgressModules = (props: { instructionalFocusModule?: InstructionalFocusModule[] }) => {
  const { instructionalFocusModule } = props;

  const { isFeatureEnabled } = useFeatureFlags();
  const isScopeAndSequenceEnabled = isFeatureEnabled(featureFlags.epic_8641_scope_and_sequence);

  const { studentProfileSnippet } = useStudentDetailsContext();

  if (!instructionalFocusModule || instructionalFocusModule.length === 0) return null;

  return (
    <Stack gap={2}>
      {instructionalFocusModule.map((module) => (
        <Card key={module.order}>
          <Accordion elevation={0} defaultExpanded sx={{ p: 0 }}>
            <AccordionSummary expandIcon={<Chevron sx={{ rotate: '90deg' }} />}>
              <Stack gap={2} direction="row" alignItems="center">
                {isScopeAndSequenceEnabled && module.instructionalFocusSlug && (
                  <Tooltip title={`View documentation on "${module.label}"`}>
                    <Link
                      to={routesDictionary.myStudents.details.scopeAndSequence.focusArea.url(studentProfileSnippet.id, module.instructionalFocusSlug)}
                      target="_blank"
                      // So that clicking this link doesn't collapse the Accordion.
                      onClick={(e) => e.stopPropagation()}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          width: '49px',
                          height: '49px',
                          borderRadius: '8px',
                          border: '1px solid',
                          borderColor: hootTokens.surface[4],
                          background: hootTokens.palette.white,
                          boxShadow: hootTokens.elevation.elevation2,
                        }}
                      >
                        <img src="/images/hoot-shapes.svg" alt="scope-and-sequence-logo" height="33px" width="33px" />
                      </Box>
                    </Link>
                  </Tooltip>
                )}
                <Stack>
                  <HootTypography isPII={false} variant="titlemedium">
                    {module.label}
                  </HootTypography>
                  <Tag label="In Progress" color={TagColor.Neutral} sx={{ width: 'max-content' }} />
                </Stack>
              </Stack>
            </AccordionSummary>
            <AccordionDetails sx={{ mt: 2 }}>
              <Stack gap={2}>
                <Grid container direction="row" columnGap={2}>
                  {legendStatuses.map((status) => (
                    <Stack key={status.label} columnGap={2} direction="row" alignItems="center">
                      <div
                        style={{
                          width: 15,
                          height: 15,
                          borderRadius: 2,
                          backgroundColor: statusColorMap[status.status],
                        }}
                      />
                      <HootTypography isPII={false} variant="bodysmall">
                        {status.label}
                      </HootTypography>
                    </Stack>
                  ))}
                </Grid>
                <Grid container spacing={2}>
                  {module.units.map((unit, index) => (
                    <UnitCard key={unit.order} focusAreaSlug={module.instructionalFocusSlug} unit={unit} index={index} />
                  ))}
                </Grid>
              </Stack>
            </AccordionDetails>
          </Accordion>
        </Card>
      ))}
    </Stack>
  );
};

export default StudentInProgressModules;
