import { StudentProfileMetricsResponse } from '@hoot/hooks/api/district-rep/reporting/useGetStudentProfileMetrics';

export const studentProfileMetricLabels: Record<keyof StudentProfileMetricsResponse, string> = {
  number: 'Student Number',
  status: 'Status',
  grade: 'Grade',
  schoolName: 'School Name',
  totalLessons: 'Total Lessons',
  occurredLessons: 'Occurred Lessons',
  attendanceRate: 'Attendance Rate',
  lateJoinRate: 'Late Join Rate',
  minutesInLesson: 'Minutes in Lesson',
  firstLessonDate: 'First Lesson',
  mostRecentLessonDate: 'Most Recent Lesson',
};

export const getFormattedMetricValue = (metricValue: number | string, metricKey: keyof StudentProfileMetricsResponse) => {
  switch (metricKey) {
    case 'totalLessons':
    case 'occurredLessons':
    case 'minutesInLesson':
      return Number(metricValue).toLocaleString();
    case 'attendanceRate':
    case 'lateJoinRate':
      return `${Number(metricValue.toLocaleString())}%`;
    case 'firstLessonDate':
    case 'mostRecentLessonDate':
    default:
      return metricValue;
  }
};
