import { useMediaQuery, useTheme } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useNavigate } from 'react-router-dom';
import useCheckTeacherEmergencyContactInfoStatus from '@hoot/hooks/api/profile/useGetTeacherEmergencyContactInfoStatus';
import { routesDictionary } from '@hoot/routes/routesDictionary';
import LargeBanner from '@hoot/ui/components/LargeBanner';
import SubHeader from '@hoot/ui/components/v2/SubHeader';
import Page from '@hoot/ui/components/v2/core/Page';
import { useAuth } from '@hoot/ui/context/AuthContext';
import TeacherAnnouncements from '@hoot/ui/pages/v2/teacher/dashboard/announcements/TeacherAnnouncements';
import TeacherUpcomingLessons from '@hoot/ui/pages/v2/teacher/dashboard/upcoming-lessons/TeacherUpcomingLessons';
import ActionRequired from './alerts/ActionRequired';

export function TeacherDashboard() {
  const theme = useTheme();
  const { getUser } = useAuth();
  const { teacherAccount } = getUser();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();

  const { data: shouldPromptTeacherToUpdateProfile } = useCheckTeacherEmergencyContactInfoStatus(teacherAccount?.id ?? '');

  return (
    <>
      <SubHeader
        title={{
          label: 'Dashboard',
          isPII: false,
        }}
      />

      {shouldPromptTeacherToUpdateProfile ? (
        <LargeBanner
          backgroundColor="warning.180"
          title="Action Required: Complete Your Profile"
          message="Some important details are missing from your profile. Take a moment to update your information."
          primaryAction={{
            label: 'Complete Profile Now',
            onClick: () => navigate(routesDictionary.profile.url),
          }}
        />
      ) : null}

      <Page pageTitle="Dashboard | Hoot Reading">
        <Grid container spacing={2}>
          <Grid size={{ md: 4, xs: 12 }}>
            <TeacherUpcomingLessons />
          </Grid>
          <Grid size={{ md: 8, xs: 12 }} sx={{ display: 'flex', flexDirection: 'column' }} gap={2}>
            {isMobile ? (
              <>
                <ActionRequired />
                <TeacherAnnouncements />
              </>
            ) : (
              <>
                <TeacherAnnouncements />
                <ActionRequired />
              </>
            )}
          </Grid>
        </Grid>
      </Page>
    </>
  );
}
