import { ENV_NAME, IS_LOCAL, IS_PROD } from '@hoot/constants/constants';

/*
 * This function will split an enum string into words that each have a capitalized first letter in them
 */
export const toProperCase = (text: string) => {
  if (!text) {
    return '';
  }

  const words = text.split(/_/g);
  let properCase = '';

  words.forEach((word) => {
    properCase += word.charAt(0).toUpperCase() + word.substring(1).toLowerCase() + ' ';
  });

  return properCase.trim();
};

export function formatPhoneNumber(phoneNumberString: string) {
  const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    const intlCode = match[1] ? '1 ' : '';
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
  }
  return null;
}

export function formatStudentLoginUrl(friendlyId: string) {
  return IS_LOCAL
    ? `http://localhost:3000/login/${friendlyId}`
    : IS_PROD
      ? `https://app.hootreading.com/login/${friendlyId}`
      : `https://app.${ENV_NAME}.hootreading.com/login/${friendlyId}`;
}

/**
 * Converts a string (usually a name or other Noun) into a properly formatted Possessive Noun.
 * Examples:
 * a) Input: James -> Output: James'
 * b) Input: Sara -> Output: Sara's
 */
export function formatPossessiveNoun(text: string) {
  if (!text) return '';

  const endsWithS = text.slice(-1) === 's';

  if (endsWithS) {
    return text + "'";
  } else {
    return text + "'s";
  }
}
