import { DistrictRepPermissionLevel } from '@hoot-reading/hoot-core/dist/enums/hfs/district-rep-access-level.enum';
import useDistrictRepLocationDetails from '@hoot/hooks/useDistrictRepLocationDetails';
import { routesDictionary } from '@hoot/routes/routesDictionary';
import { DistrictRepAccessLevelCombinations, useHasDistrictPermission } from '@hoot/routes/secure/profile/district-rep/DistrictRepProtectedRoute';
import { NavList } from '@hoot/ui/components/v2/core/NavMenuList';
import { LIVE_CHAT_TITLE, zendeskOpenLiveChat } from '@hoot/utils/zendesk-methods';

const useDistrictRepNavItems = (districtRepPermissions?: DistrictRepPermissionLevel[]): NavList[] => {
  const hasEnrolmentEditOrHigherPermission = useHasDistrictPermission(DistrictRepAccessLevelCombinations.EnrolmentEditLevel, districtRepPermissions);
  const hasSchoolOrDistrictOrHigherPermission = useHasDistrictPermission(DistrictRepAccessLevelCombinations.SchoolOrDistrict, districtRepPermissions);
  const { districtRepLocationDetails } = useDistrictRepLocationDetails();

  if (hasSchoolOrDistrictOrHigherPermission) {
    const navArray = [
      { label: routesDictionary.dashboard.label, url: routesDictionary.dashboard.url },
      { label: routesDictionary.schools.label, url: routesDictionary.schools.url },
      { label: routesDictionary.students.label, url: routesDictionary.students.url },
      { label: routesDictionary.schedule.label, url: routesDictionary.schedule.url },
      { label: routesDictionary.enrolments.label, url: routesDictionary.enrolments.url },
      { label: routesDictionary.teamMembers.label, url: routesDictionary.teamMembers.url },
      { label: LIVE_CHAT_TITLE, url: '', onClick: () => zendeskOpenLiveChat() },
      { label: routesDictionary.settings.label, url: routesDictionary.settings.url },
    ];

    if (districtRepLocationDetails?.reporting) {
      navArray.splice(6, 0, { label: routesDictionary.reporting.label, url: routesDictionary.reporting.url });
    }
    return navArray;
  } else if (hasEnrolmentEditOrHigherPermission) {
    const navArray = [
      { label: routesDictionary.dashboard.label, url: routesDictionary.dashboard.url },
      { label: routesDictionary.students.label, url: routesDictionary.students.url },
      { label: routesDictionary.schedule.label, url: routesDictionary.schedule.url },
      { label: routesDictionary.enrolments.label, url: routesDictionary.enrolments.url },
      { label: LIVE_CHAT_TITLE, url: '', onClick: () => zendeskOpenLiveChat() },
      { label: routesDictionary.settings.label, url: routesDictionary.settings.url },
    ];

    if (districtRepLocationDetails?.reporting) {
      navArray.splice(4, 0, { label: routesDictionary.reporting.label, url: routesDictionary.reporting.url });
    }
    return navArray;
  }
  return [
    { label: routesDictionary.dashboard.label, url: routesDictionary.dashboard.url },
    { label: LIVE_CHAT_TITLE, url: '', onClick: () => zendeskOpenLiveChat() },
    { label: routesDictionary.settings.label, url: routesDictionary.settings.url },
  ];
};

export default useDistrictRepNavItems;
