import { ScheduledLessonLanguage, ScheduledLessonSubject } from '@hoot-reading/hoot-core/dist/enums/scheduled-lesson';
import { Box, Card, DialogActions, DialogContent, Skeleton, Stack, useMediaQuery, useTheme } from '@mui/material';
import { capitalCase } from 'change-case';
import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { SNACKBAR_AUTO_HIDE_DURATION } from '@hoot/constants/constants';
import useGetLessonPlan from '@hoot/hooks/api/lesson-reviews/useGetLessonPlan';
import useGetLessonPlanBookDetails, { LessonPlanBook } from '@hoot/hooks/api/lesson-reviews/useGetLessonPlanBookDetails';
import { OrderBy } from '@hoot/models/api/enums/queryEnums';
import ViewState, { ViewStateEnum } from '@hoot/ui/components/v2/ViewState';
import ViewStateIllustration, { IllustrationEnum } from '@hoot/ui/components/v2/ViewStateIllustration';
import { Button } from '@hoot/ui/components/v2/core/Button';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';
import { Snackbar } from '@hoot/ui/components/v2/core/Snackbar';
import { HeaderData, TableV2 } from '@hoot/ui/components/v2/core/Table';
import BasicAlertDialog from '@hoot/ui/components/v2/dialogs/BasicAlertDialog';
import { useLessonReviewWizardContext } from '@hoot/ui/pages/v2/teacher/my-students/student-details/lesson-reviews/lesson-review-wizard/LessonReviewContextProvider';
import LessonReviewWarningCard from '@hoot/ui/pages/v2/teacher/my-students/student-details/lesson-reviews/lesson-review-wizard/LessonReviewWarningCard';
import LessonReviewTimelineModal from '../LessonReviewTimelineModal';
import LessonPlanBookPicker, { LessonPlanBookPickerProps } from '../book-picker/LessonPlanBookPicker';

interface LessonPlanTableRow {
  title: string;
  resourceProgress: string;
  resourceType: string;
  instructionalFocus: string;
  instructionalUnit: string;
  action: JSX.Element;
}

export interface SelectedUnit {
  unitId: string;
  unitName: string;
  moduleName: string;
  focusId: string | undefined;
}

enum LessonPlanBookFields {
  Title = 'title',
  ResourceProgress = 'resourceProgress',
  ResourceType = 'resourceType',
  InstructionalFocus = 'instructionalFocus',
  InstructionalUnit = 'instructionalUnit',
}

const LessonReviewLessonPlanStep = () => {
  const { studentProfileId } = useParams();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const {
    lessonToReview,
    lessonPlanState,
    instructionalFocusState,
    actions: { goToNextStep, goToPreviousStep, attachLessonPlanData },
  } = useLessonReviewWizardContext();

  const lessonReviewBooks = [...(instructionalFocusState?.primaryFocusBooks ?? []), ...(instructionalFocusState?.secondaryFocusBooks ?? [])];

  const [, setInitialLessonPlanBooks] = useState<LessonPlanBook[]>(lessonPlanState?.lessonPlanBooks ?? []);
  const [editedLessonPlanBooksMap, setEditedLessonPlanBooksMap] = useState<Map<string, LessonPlanBook>>(
    new Map(
      lessonPlanState?.lessonPlanBooks.map((b) => [
        b.id,
        {
          ...b,
          resourceProgress: lessonReviewBooks.find((lrBook) => lrBook.bookId === b.id && lrBook.progress)?.progress ?? b.resourceProgress,
        },
      ]),
    ),
  );

  const editedLessonPlanBooks = Array.from(editedLessonPlanBooksMap.values());

  const [bookIdsToAdd, setBookIdsToAdd] = useState<string[]>([]);
  const [bookToRemove, setBookToRemove] = useState<LessonPlanBook | undefined>(undefined);

  const [showSelectUnitModal, setShowSelectUnitModal] = useState<boolean>(false);
  const [selectedUnit, setSelectedUnit] = useState<SelectedUnit | undefined>();
  const [showUndoDialog, setShowUndoDialog] = useState<boolean>(false);

  const [showError, setShowError] = useState<string>();

  const [sortBy, setSortBy] = useState<string>('title');
  const [orderBy, setOrderBy] = useState<OrderBy>(OrderBy.Asc);

  const studentName = lessonToReview?.student.name;
  const isLessonPlanFull = editedLessonPlanBooksMap.size > 5;

  const {
    isError: lessonPlanError,
    isFetching: lessonPlanFetching,
    data: latestLessonPlanFromApi,
  } = useGetLessonPlan(
    studentProfileId || '',
    {
      lessonSubject: lessonToReview?.subject ?? ScheduledLessonSubject.Reading,
      lessonLanguage: lessonToReview?.language ?? ScheduledLessonLanguage.English,
    },
    {
      retry: false,
      onError: (ex) => {
        console.error(ex);
        setShowError('There was an error while loading the lesson plan.');
      },
      onSuccess: (data) => {
        // If we haven't submitted this step yet, then default this lesson review's lesson plan to be the most
        // up-to-date lesson plan from the API.
        if (!lessonPlanState) {
          const lessonPlanData: LessonPlanBook[] = data.lessonPlanBookDetails.map((book) => ({
            id: book.id,
            title: book.title,
            resourceProgress: lessonReviewBooks.find((lrBook) => lrBook.bookId === book.id && lrBook.progress)?.progress ?? book.resourceProgress,
            resourceType: book.resourceType,
            instructionalFocus: book.instructionalFocus,
            instructionalUnit: book.instructionalUnit,
          }));
          setInitialLessonPlanBooks(lessonPlanData);
          setEditedLessonPlanBooksMap(new Map(lessonPlanData.map((b) => [b.id, b])));
        }
      },
    },
  );

  // When we've selected a book from the modal, we have _some_ info, but not everything that we need. We have to do an
  // additional query for that.
  const { isLoading: bookDetailsLoading, isError: bookDetailsError } = useGetLessonPlanBookDetails(
    lessonToReview?.student.id ?? '',
    { bookIds: bookIdsToAdd },
    {
      retry: false,
      enabled: !!bookIdsToAdd.length && !!lessonToReview && !!lessonToReview.student,
      onError: (ex) => {
        console.error(ex);
      },
      onSuccess: (data) => {
        // We have all the data that we need about the book now. Add the book to the lesson plan, and dismiss the add book modal.
        const updatedLessonPlanBooksMap = new Map(editedLessonPlanBooksMap);
        data.bookDetails.forEach((book) => {
          updatedLessonPlanBooksMap.set(book.id, {
            ...book,
            resourceProgress: lessonReviewBooks.find((lrBook) => lrBook.bookId === book.id && lrBook.progress)?.progress ?? book.resourceProgress,
          });
        });
        setEditedLessonPlanBooksMap(updatedLessonPlanBooksMap);

        setBookIdsToAdd([]);
        setSelectedUnit(undefined);
      },
    },
  );

  const hasLessonPlanBeenEdited = () => {
    const latestLessonPlanBookIds = (latestLessonPlanFromApi?.lessonPlanBookDetails ?? []).map((b) => b.id);
    const editedLessonPlanBookIds = new Set(editedLessonPlanBooks.map((b) => b.id));

    const areSetsEqual =
      latestLessonPlanBookIds.length === editedLessonPlanBookIds.size && [...latestLessonPlanBookIds].every((x) => editedLessonPlanBookIds.has(x));

    return !areSetsEqual;
  };

  const headers: HeaderData<LessonPlanTableRow>[] = [
    { name: 'Title', property: LessonPlanBookFields.Title, isSortable: true },
    { name: 'Resource Progress', property: LessonPlanBookFields.ResourceProgress, isSortable: true },
    { name: 'Resource Type', property: LessonPlanBookFields.ResourceType, isSortable: true },
    {
      name: 'Instructional Focus',
      property: LessonPlanBookFields.InstructionalFocus,
      isSortable: false,
      isHidden: !isDesktop,
    },
    {
      name: 'Instructional Unit',
      property: LessonPlanBookFields.InstructionalUnit,
      isSortable: false,
      isHidden: !isDesktop,
    },
    { name: 'Action', property: 'action' },
  ];

  const getViewState = () => {
    if (lessonPlanFetching) {
      return ViewStateEnum.Loading;
    } else if (lessonPlanError || bookDetailsError) {
      return ViewStateEnum.Error;
    } else {
      return ViewStateEnum.Results;
    }
  };

  const removeButton = (book: LessonPlanBook) => {
    const showRemoveBookDialog = (book: LessonPlanBook) => () => {
      setBookToRemove(book);
    };
    return (
      <Button onClick={showRemoveBookDialog(book)} size="small" variant="outlined" color="error.80">
        Remove
      </Button>
    );
  };

  const sortedTableData = useMemo(() => {
    return editedLessonPlanBooks
      .map((book) => ({
        id: book.id,
        title: book.title,
        resourceProgress: capitalCase(book.resourceProgress),
        resourceType: book.resourceType,
        instructionalFocus: book.instructionalFocus,
        instructionalUnit: book.instructionalUnit,
        action: removeButton(book),
      }))
      .sort((a, b) => {
        switch (sortBy) {
          case LessonPlanBookFields.Title:
            return orderBy === OrderBy.Asc ? a.title.localeCompare(b.title) : b.title.localeCompare(a.title);
          case LessonPlanBookFields.ResourceProgress:
            return orderBy === OrderBy.Asc
              ? a.resourceProgress.localeCompare(b.resourceProgress)
              : b.resourceProgress.localeCompare(a.resourceProgress);
          case LessonPlanBookFields.ResourceType:
            return orderBy === OrderBy.Asc ? a.resourceType.localeCompare(b.resourceType) : b.resourceType.localeCompare(a.resourceType);
          case LessonPlanBookFields.InstructionalFocus:
            return orderBy === OrderBy.Asc
              ? a.instructionalFocus.localeCompare(b.instructionalFocus)
              : b.instructionalFocus.localeCompare(a.instructionalFocus);
          case LessonPlanBookFields.InstructionalUnit:
            return orderBy === OrderBy.Asc
              ? a.instructionalUnit.localeCompare(b.instructionalUnit)
              : b.instructionalUnit.localeCompare(a.instructionalUnit);
          default:
            return orderBy === OrderBy.Asc ? a.title.localeCompare(b.title) : b.title.localeCompare(a.title);
        }
      });
  }, [editedLessonPlanBooks, orderBy, sortBy]);

  const handleRemoveBook = () => {
    if (bookToRemove) {
      const updatedLessonPlanBooksMap = new Map(editedLessonPlanBooksMap);
      updatedLessonPlanBooksMap.delete(bookToRemove.id);
      setEditedLessonPlanBooksMap(updatedLessonPlanBooksMap);
    }
    setBookToRemove(undefined);
  };

  const handleUndoChanges = () => {
    setEditedLessonPlanBooksMap(new Map((latestLessonPlanFromApi?.lessonPlanBookDetails ?? []).map((b) => [b.id, b])));
    setShowUndoDialog(false);
  };

  const loadBookDetailsAndAddToLessonPlan: LessonPlanBookPickerProps['onApply'] = (books) => {
    // If we haven't already added these books, then fetch the book details and add them to the lesson plan.
    const booksToAdd = books.filter((book) => !editedLessonPlanBooksMap.has(book.id));
    if (booksToAdd.length > 0) {
      setBookIdsToAdd(booksToAdd.map((book) => book.id));
    }
  };

  const onClickNext = () => {
    attachLessonPlanData(Array.from(editedLessonPlanBooks.values()));
    goToNextStep();
  };

  const onSelectUnit = (selectedUnit: SelectedUnit) => {
    setSelectedUnit(selectedUnit);
    setShowSelectUnitModal(false);
  };

  const onCancelBookSelection = () => {
    setSelectedUnit(undefined);
    setShowSelectUnitModal(true);
  };

  return (
    <>
      <Snackbar
        variant="error"
        message={`There was an error while loading the lesson plan.`}
        open={!!showError}
        onClose={() => setShowError(undefined)}
        autoHideDuration={SNACKBAR_AUTO_HIDE_DURATION}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      />
      {showSelectUnitModal ? (
        <LessonReviewTimelineModal
          onClickNext={onSelectUnit}
          onDismiss={() => setShowSelectUnitModal(false)}
          studentProfileId={lessonToReview?.student.id}
          studentName={studentName ?? ''}
        />
      ) : null}
      {selectedUnit ? (
        <LessonPlanBookPicker
          show={!!selectedUnit}
          onApply={loadBookDetailsAndAddToLessonPlan}
          onDismiss={onCancelBookSelection}
          existingBookIds={editedLessonPlanBooks.map((book) => book.id)}
          isLoading={bookDetailsLoading}
          studentProfileId={lessonToReview?.student.id}
          selectedUnit={selectedUnit}
          studentName={studentName ?? ''}
        />
      ) : null}
      <BasicAlertDialog
        show={!!bookToRemove}
        onDismiss={() => setBookToRemove(undefined)}
        title={'Remove Resource?'}
        content={
          <HootTypography isPII={false} variant="bodylarge">
            Are you sure you want to remove <strong>{bookToRemove?.title}</strong>?
          </HootTypography>
        }
        primaryAction={{
          label: 'Remove',
          onClick: handleRemoveBook,
          props: {
            variant: 'contained',
            color: 'error',
          },
        }}
        secondaryAction={{
          label: 'Cancel',
          onClick: () => setBookToRemove(undefined),
        }}
      />
      <BasicAlertDialog
        show={showUndoDialog}
        onDismiss={() => setShowUndoDialog(false)}
        title={'Undo All Changes?'}
        content={
          <HootTypography isPII={false} variant="bodylarge">
            All progress will be lost
          </HootTypography>
        }
        primaryAction={{
          label: 'Undo',
          onClick: handleUndoChanges,
        }}
        secondaryAction={{
          label: 'Cancel',
          onClick: () => setShowUndoDialog(false),
        }}
      />
      <DialogContent>
        <Stack gap={'16px'} paddingBottom={1}>
          <HootTypography isPII={false} variant="titlemedium">
            Lesson Plan
          </HootTypography>
          <HootTypography isPII={true} variant="bodymedium">
            Please ensure <strong>{studentName}</strong>'s lesson plan is up to date by adding or removing resources.
          </HootTypography>
          <ViewState state={getViewState()} loadingContent={<SkeletonTableItems />}>
            <Card>
              <Stack gap={'16px'}>
                <Stack alignItems={'flex-start'} gap={2}>
                  <HootTypography isPII={true} variant="titlemedium">
                    {studentName}'s Lesson Plan
                  </HootTypography>
                  <Stack gap={'8px'} direction={'row'}>
                    <Button size="small" variant="contained" onClick={() => setShowSelectUnitModal(true)}>
                      Add Resource
                    </Button>
                    <Button size="small" variant="outlined" onClick={() => setShowUndoDialog(true)} disabled={!hasLessonPlanBeenEdited()}>
                      Undo Changes
                    </Button>
                  </Stack>
                </Stack>
                <TableV2
                  isPaginated={false}
                  data={sortedTableData}
                  headers={headers}
                  isSortable
                  onSortBy={(val: any) => {
                    setSortBy(val);
                    setOrderBy((orderBy) => (val === sortBy ? (orderBy === OrderBy.Asc ? OrderBy.Desc : OrderBy.Asc) : orderBy));
                  }}
                  sortOrder={orderBy}
                  sortBy={sortBy as any}
                  emptyViewState={
                    <ViewStateIllustration
                      illustration={IllustrationEnum.EmptyInbox}
                      title={`${studentName}'s Lesson Plan is empty`}
                      subtitle="Add resources to continue"
                    />
                  }
                />
                <HootTypography isPII={false} variant="bodysmall" sx={{ textAlign: 'right' }} color={isLessonPlanFull ? 'error' : 'textPrimary'}>
                  {editedLessonPlanBooks.length} Resource{editedLessonPlanBooks.length > 1 ? 's' : ''} in Plan
                </HootTypography>
              </Stack>
              {isLessonPlanFull ? (
                <LessonReviewWarningCard
                  sx={{ mt: 2 }}
                  text={
                    <Stack>
                      <HootTypography isPII={false} variant="bodylarge">
                        Too many Resources
                      </HootTypography>
                      <HootTypography isPII={false} variant="bodylarge">
                        Lesson Plans may contain a <strong>maximum of 5 resources.</strong>
                      </HootTypography>
                    </Stack>
                  }
                />
              ) : null}
            </Card>
          </ViewState>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={goToPreviousStep}>
          Go Back
        </Button>
        <Button variant="contained" onClick={onClickNext} disabled={editedLessonPlanBooks.length < 1 || isLessonPlanFull}>
          Next
        </Button>
      </DialogActions>
    </>
  );
};

const SkeletonTableItems = () => (
  <Stack direction="column" gap={3} sx={{ width: '100%' }}>
    <Box display={'flex'} flexDirection={'column'} gap={1}>
      <Skeleton variant="rounded" sx={{ minWidth: '200px', width: '100%', minHeight: '60px' }} />
      <Skeleton variant="rounded" sx={{ minWidth: '200px', width: '100%', minHeight: '260px' }} />
      <Skeleton variant="rounded" sx={{ minWidth: '200px', width: '100%', minHeight: '75px' }} />
    </Box>
  </Stack>
);

export default LessonReviewLessonPlanStep;
