import { DistrictRepresentativeStatus } from '@hoot-reading/hoot-core/dist/enums/user/district-representative/district-representative-status.enum';
import CloseIcon from '@mui/icons-material/Close';
import { Dialog, DialogActions, DialogContent, DialogTitle, Stack } from '@mui/material';
import { capitalCase } from 'change-case';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { DistrictRepresentativeTeamMembersQuery } from '@hoot/hooks/api/district-rep/useSearchDistrictRepTeamMembers';
import CheckList, { CheckListItem } from '@hoot/ui/components/v2/CheckList';
import { Button } from '@hoot/ui/components/v2/core/Button';
import ChipGroup from '@hoot/ui/components/v2/core/ChipGroup';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';
import { IconButton } from '@hoot/ui/components/v2/core/IconButton';
import EnrolmentFilter from '@hoot/ui/pages/v2/district-rep/students/enrolments/EnrolmentFilter';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';

interface FilterDialogProps {
  show: boolean;
  query: DistrictRepresentativeTeamMembersQuery;
  onDismiss: () => void;
  onChange: React.Dispatch<React.SetStateAction<DistrictRepresentativeTeamMembersQuery>>;
}

export interface DistrictRepresentativeTeamMembersFiltersForm {
  status?: DistrictRepresentativeStatus[];
  enrolmentId?: string;
}

const filterOptions = ['status', 'enrolmentId'] as const;
type FilterOptionsType = (typeof filterOptions)[number];

const TeamMembersFiltersDialog = (props: FilterDialogProps) => {
  const { show, onDismiss, query, onChange } = props;
  const [queryOptions, setQueryOptions] = useState<FilterOptionsType[]>(
    filterOptions.filter((option) => {
      const value = query[option];
      return Object.keys(query).includes(option) && ((Array.isArray(value) && value.length > 0) || (!Array.isArray(value) && value));
    }),
  );
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm<DistrictRepresentativeTeamMembersFiltersForm>({
    defaultValues: {
      status: query.status,
      enrolmentId: query.enrolmentId,
    },
  });

  const toggleOption = (option: CheckListItem) => {
    const optionSelected = queryOptions.includes(option.value as FilterOptionsType);
    if (optionSelected) {
      const filteredOptions = queryOptions.filter((qo) => qo !== option.value);
      setQueryOptions(filteredOptions);
      setValue(`${option.value as FilterOptionsType}`, undefined);
    } else {
      setQueryOptions([...queryOptions, option.value as FilterOptionsType]);
      setValue(`${option.value as FilterOptionsType}`, query[option.value as FilterOptionsType]);
    }
  };

  const onSubmit = async (data: DistrictRepresentativeTeamMembersFiltersForm) => {
    onChange((prevState) => ({ ...prevState, ...data }));
    onDismiss();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} id="district-rep-students-filter-form">
      <Dialog open={show} onClose={onDismiss} fullWidth maxWidth="md">
        <DialogTitle sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          Filter
          <IconButton onClick={onDismiss} sx={{ color: hootTokens.palette.black }}>
            <CloseIcon color="inherit" />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Stack direction="row" gap={3} justifyContent="space-between">
            <Stack gap={1} width="40%">
              <CheckList
                title="Select Filters"
                divider
                items={filterOptions.map((option) => ({
                  label: capitalCase(option),
                  value: option,
                }))}
                selectedValues={queryOptions}
                toggleOption={toggleOption}
              />
            </Stack>
            <Stack gap={1} width="60%">
              <HootTypography variant="titlesmall" isPII={false}>
                Filters that will be applied
              </HootTypography>
              {queryOptions.length ? (
                queryOptions.map((option) => {
                  switch (option) {
                    case 'status':
                      return (
                        <Controller
                          name={option}
                          key={option}
                          control={control}
                          rules={{
                            required: 'A selection is required',
                          }}
                          render={({ field: { onChange, value } }) => (
                            <ChipGroup
                              items={Object.entries(DistrictRepresentativeStatus).map((option) => {
                                const [key, value] = option;
                                return {
                                  value: value,
                                  label: capitalCase(key),
                                };
                              })}
                              onChange={onChange}
                              value={value ?? []}
                              isMultiSelect
                              label="Enrolment Status"
                              error={!!errors.status}
                              helperText={errors.status?.message ?? ''}
                              cannotRemoveSelection
                            />
                          )}
                        />
                      );
                    case 'enrolmentId':
                      return (
                        <Controller
                          name={option}
                          key={option}
                          control={control}
                          rules={{
                            required: 'Enrolment is required',
                          }}
                          render={({ field: { value, onChange } }) => (
                            <EnrolmentFilter
                              label="Enrolment Id"
                              value={value}
                              onChange={onChange}
                              error={!!errors.enrolmentId}
                              helperText={errors.enrolmentId?.message ?? ''}
                            />
                          )}
                        />
                      );
                    default:
                      return null;
                  }
                })
              ) : (
                <HootTypography variant="bodysmall" isPII={false}>
                  No filters selected
                </HootTypography>
              )}
            </Stack>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button type="button" onClick={onDismiss} variant="outlined" size="medium">
            Cancel
          </Button>
          <Button type="submit" variant="contained" size="medium" form="district-rep-students-filter-form">
            Apply ({queryOptions && queryOptions.length})
          </Button>
        </DialogActions>
      </Dialog>
    </form>
  );
};
export default TeamMembersFiltersDialog;
