import axios, { AxiosError } from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '@hoot/config';
import { OrderBy } from '@hoot/models/api/enums/queryEnums';
import { QueryKey } from '../../queryKeys';
import { LocationReportingFilters } from './useGetLocationReportingMetrics';

export enum StudentReportingMetricSortKey {
  StudentName = 'studentName',
  StudentNumber = 'studentNumber',
  AttendanceRate = 'averageAttendanceRate',
  LateJoinRate = 'lateJoinRate',
  MinutesInLesson = 'minutesInLesson',
  FirstLesson = 'firstLesson',
  MostRecentLesson = 'mostRecentLesson',
  SchoolName = 'schoolName',
}

interface StudentReportingMetricData {
  studentProfileId: string;
  studentName: string;
  studentNumber: number;
  averageAttendanceRate: number;
  lateJoinRate: number;
  minutesInLesson: number;
  firstLesson: string;
  mostRecentLesson: string;
  schoolId: string;
  schoolName: string;
}

export interface StudentReportingRequest {
  page: number;
  pageSize: number;
  sortBy: StudentReportingMetricSortKey;
  orderBy: OrderBy;
  filters?: LocationReportingFilters;
  includeSchool?: boolean;
}

interface StudentReportingMetricResponse {
  data: StudentReportingMetricData[];
  count: number;
  page: number;
  pageSize: number;
}

export default function useGetStudentReportingMetrics(
  locationId: string,
  paginationQuery: StudentReportingRequest,
  filtersQuery: LocationReportingFilters,
  options?: Omit<UseQueryOptions<StudentReportingMetricResponse, AxiosError>, 'queryKey' | 'queryFn'>,
) {
  const reportingRequestParams: StudentReportingRequest = { ...paginationQuery, filters: filtersQuery };

  return useQuery(
    [QueryKey.GetDistrictRepReportingLocationStudentMetrics, locationId, paginationQuery, filtersQuery],
    async () => {
      return (
        await axios.get<StudentReportingMetricResponse>(
          `${config.apiUrl}/public/district-representative/reporting/location/${locationId}/student-metrics`,
          { params: reportingRequestParams },
        )
      ).data;
    },
    options,
  );
}
