import { Box, Fade, Skeleton, Stack } from '@mui/material';
import React from 'react';
import { useParams } from 'react-router-dom';
import useGetInstructionalUnitsForInstructionalFocus from '@hoot/hooks/api/library/useGetInstructionalUnitsForInstructionalFocus';
import { routesDictionary } from '@hoot/routes/routesDictionary';
import GrowList from '@hoot/ui/components/v2/GrowList';
import Card from '@hoot/ui/components/v2/core/Card';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';
import ScopeAndSequenceIcon from '@hoot/ui/components/v2/icons/ScopeAndSequenceIcon';
import ScopeAndSequenceFocusAreaBanner from '@hoot/ui/pages/v2/teacher/scope-and-sequence/focus-area/ScopeAndSequenceFocusAreaBanner';
import { ScopeAndSequenceLink } from '@hoot/ui/pages/v2/teacher/scope-and-sequence/focus-area/ScopeAndSequenceLink';
import {
  FocusAreaSlug,
  ScopeAndSequenceFocusArea,
  scopeAndSequenceFocusAreaDictionary,
  scopeAndSequenceTileColourDictionary,
} from '@hoot/ui/pages/v2/teacher/scope-and-sequence/scopeAndSequenceFocusAreas';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';

interface ScopeAndSequenceFocusAreaCardProps {
  focusArea: ScopeAndSequenceFocusArea;
}

const ScopeAndSequenceFocusAreaCard = (props: ScopeAndSequenceFocusAreaCardProps) => {
  const { focusArea } = props;

  const { studentProfileId } = useParams<{ studentProfileId?: string }>();

  const tileColours = scopeAndSequenceTileColourDictionary[focusArea.slug as FocusAreaSlug].tileColours;

  const getInstructionalUnitsRequest = useGetInstructionalUnitsForInstructionalFocus(focusArea.instructionalFocusId);

  return (
    <Card isLoading={getInstructionalUnitsRequest.isFetching}>
      <Stack gap={2}>
        <ScopeAndSequenceFocusAreaBanner focusArea={focusArea} />
        {getInstructionalUnitsRequest.isFetching ? (
          <Fade in key={`skeleton-${focusArea.slug}`}>
            <Stack direction="row" justifyContent="center" gap={2} sx={{ opacity: 0 }}>
              {[...Array(2)].map((_, i) => (
                <InstructionalUnitTileSkeletonTile key={`skeleton-${focusArea.slug}-${i}`} />
              ))}
            </Stack>
          </Fade>
        ) : (
          <GrowList
            StackProps={{ direction: 'row', justifyContent: 'center', gap: 2 }}
            items={getInstructionalUnitsRequest.data ?? []}
            ItemWrapperProps={{ width: '132px' }}
            getKey={(x) => x.id}
            renderItem={(x, i) => (
              <InstructionalUnitTile
                unitSlug={x.slug}
                label={x.name}
                focusAreaId={focusArea.slug}
                primaryColour={tileColours[i % tileColours.length].primary}
                secondaryColour={tileColours[i % tileColours.length].secondary}
                studentProfileId={studentProfileId}
              />
            )}
          />
        )}
      </Stack>
    </Card>
  );
};

interface InstructionalUnitTileProps {
  label: string;
  unitSlug: string;
  focusAreaId: string;
  primaryColour: string;
  secondaryColour: string;
  studentProfileId: string | undefined;
}

const InstructionalUnitTile = (props: InstructionalUnitTileProps) => {
  const { unitSlug, label, focusAreaId, primaryColour, secondaryColour, studentProfileId } = props;

  return (
    <Stack
      sx={{
        width: '100%', // Each GrowList item is wrapped in a Box (div). This is where the actual width is defined for this component.
        height: '132px',
        background: hootTokens.palette.neutral['190'],
        boxShadow: hootTokens.elevation['elevation1'],
        borderRadius: '8px',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 1,
        gap: 1,
      }}
    >
      <Box
        sx={{
          borderRadius: '8px',
          width: '100%',
          height: '16px',
          backgroundColor: primaryColour,
        }}
      />
      <HootTypography isPII={false} variant="labelsmall" sx={{ textAlign: 'center', lineHeight: '1.5em', minHeight: '3em' }}>
        <ScopeAndSequenceLink to={routesDictionary.scopeAndSequence.focusArea.unit.url(focusAreaId, unitSlug)} studentProfileId={studentProfileId}>
          {label}
        </ScopeAndSequenceLink>
      </HootTypography>
      <ScopeAndSequenceIcon fill={secondaryColour} stroke={primaryColour} />
    </Stack>
  );
};

const InstructionalUnitTileSkeletonTile = () => (
  <Skeleton
    variant="rounded"
    sx={{
      width: '132px',
      height: '132px',
      borderRadius: '8px',
    }}
  />
);

const ScopeAndSequenceFocusAreaCardWrapper = () => {
  const { focusAreaId } = useParams<{ focusAreaId: FocusAreaSlug }>();

  const focusArea = scopeAndSequenceFocusAreaDictionary[focusAreaId!];

  return focusArea ? <ScopeAndSequenceFocusAreaCard focusArea={focusArea} /> : null;
};

export default ScopeAndSequenceFocusAreaCardWrapper;
