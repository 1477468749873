import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Skeleton, Stack } from '@mui/material';
import { useGetPMASubmissionDetails } from '@hoot/hooks/api/assessment/useGetPMASubmissionDetails';
import { Button } from '@hoot/ui/components/v2/core/Button';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';
import { Icon } from '@hoot/ui/components/v2/core/Icon';
import ReadOnlyTextField from '@hoot/ui/components/v2/core/ReadOnlyTextField';

interface IPMADetailsModalProps {
  assessmentId: string | undefined;
  onClose: () => void;
}

const PMADetailsModal = (props: IPMADetailsModalProps) => {
  const { assessmentId, onClose } = props;
  const { data, isLoading } = useGetPMASubmissionDetails(assessmentId!, { enabled: !!assessmentId });

  return (
    <Dialog open={!!assessmentId} onClose={onClose}>
      <DialogTitle>
        <Stack direction="row" justifyContent="space-between" gap={4}>
          <HootTypography isPII={false} variant="titlelarge">
            Progress Monitoring Assessment Details
          </HootTypography>
          <IconButton onClick={onClose}>
            <Icon name="close" />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent>
        {isLoading ? (
          <SkeletonItems />
        ) : (
          <Stack gap={2}>
            {data?.map((submission) => (
              <Stack gap={2} key={submission.unit.unitId}>
                <HootTypography variant="titlesmall" isPII={false}>
                  {submission.unit.unitName}
                </HootTypography>
                <Stack direction="row" flexWrap="wrap" justifyContent={'space-between'} gap={2}>
                  {submission.fields.map((f) => (
                    <ReadOnlyTextField key={f.value} sx={{ flex: '1 1 48%' }} label={f.name} body={f.value} />
                  ))}
                </Stack>
              </Stack>
            ))}
          </Stack>
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const SkeletonItems = () => {
  return (
    <Stack gap={1}>
      <Stack key={'skeleton-1'}>
        <Skeleton sx={{ height: '30px', width: '200px' }} />
        <Stack direction="row" justifyContent={'space-between'} gap={2}>
          <Skeleton sx={{ height: '90px', width: '100%' }} />
          <Skeleton sx={{ height: '90px', width: '100%' }} />
        </Stack>
      </Stack>
      <Stack key={'skeleton-2'}>
        <Skeleton sx={{ height: '30px', width: '200px' }} />
        <Stack direction="row" justifyContent={'space-between'} gap={2}>
          <Skeleton sx={{ height: '90px', width: '100%' }} />
          <Skeleton sx={{ height: '90px', width: '100%' }} />
        </Stack>
      </Stack>
      <Stack key={'skeleton-3'}>
        <Skeleton sx={{ height: '30px', width: '200px' }} />
        <Stack direction="row" justifyContent={'space-between'} gap={2}>
          <Skeleton sx={{ height: '90px', width: '100%' }} />
          <Skeleton sx={{ height: '90px', width: '100%' }} />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default PMADetailsModal;
