import { ScheduledLessonType } from '@hoot-reading/hoot-core/dist/enums/scheduled-lesson';
import axios, { AxiosError } from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '@hoot/config';
import { QueryKey } from '@hoot/hooks/api/queryKeys';
import { ResourceProgress, ResourceState } from '@hoot/models/api/library';

interface InstructionalFocusBook {
  bookId: string;
  title: string;
  state: ResourceState | undefined;
  progress: ResourceProgress | undefined;
  focus: string | undefined;
  unit: string | undefined;
  level: string;
}

export interface InstructionalFocus {
  focus: string;
  unit: string;
  books: InstructionalFocusBook[];
}

export interface LessonReviewDetailsResponse {
  lessonId: string;
  number: number;
  startsAt: number;
  lessonType: ScheduledLessonType;
  notes: string | null;
  primaryFocus: InstructionalFocus;
  secondaryFocus: InstructionalFocus | null;
}

async function getLessonReviewDetails(lessonReviewId: string): Promise<LessonReviewDetailsResponse> {
  const { data } = await axios.get<LessonReviewDetailsResponse>(`${config.apiUrl}/public/lesson-review/${lessonReviewId}`);
  return data;
}

export default function useGetLessonReviewDetails(
  lessonReviewId: string,
  options?: Omit<UseQueryOptions<LessonReviewDetailsResponse, AxiosError>, 'queryKey' | 'queryFn'>,
) {
  return useQuery([QueryKey.GetLessonReviewDetails], () => getLessonReviewDetails(lessonReviewId), options);
}
