import { DialogContent, DialogTitle, Stack } from '@mui/material';
import { Fragment, useState } from 'react';
import { FieldValues, Path, UseControllerProps, useController } from 'react-hook-form';
import { Option } from '@hoot/models/formOption';
import BackdroplessDialog from '@hoot/ui/components/v2/core/BackdroplessDialog';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';
import PlainButton from '@hoot/ui/components/v2/core/PlainButton';
import { LottieFile } from '@hoot/ui/components/v2/lottie/Lottie';
import LottieButton from '@hoot/ui/components/v2/lottie/LottieButton';
import { LottieMultiSelectButton } from '@hoot/ui/components/v2/lottie/LottieMultiSelectButton';

interface Props<TFieldValues extends FieldValues = FieldValues, TName extends Path<TFieldValues> = Path<TFieldValues>> {
  isOpen: boolean;
  onDismiss: () => void;
  filterSelectionProps:
    | {
        title: string;
        options: Option[];
        isMultiSelect: boolean;
        controllerProps: UseControllerProps<TFieldValues, TName>;
      }
    | undefined;
  onDialogDismissAnimationCompleted: () => void;
}

const StudentFilterSelectionModalWrapper = <TFieldValues extends FieldValues = FieldValues, TName extends Path<TFieldValues> = Path<TFieldValues>>(
  props: Props<TFieldValues, TName>,
) => {
  if (!props.filterSelectionProps) {
    return null;
  }
  return <StudentFilterSelectionModal {...props} />;
};

const StudentFilterSelectionModal = <TFieldValues extends FieldValues = FieldValues, TName extends Path<TFieldValues> = Path<TFieldValues>>(
  props: Props<TFieldValues, TName>,
) => {
  const { isOpen, onDismiss, filterSelectionProps, onDialogDismissAnimationCompleted } = props;
  const { title, isMultiSelect, options, controllerProps } = filterSelectionProps ?? {};
  const { field } = useController(controllerProps!);

  const [selectedValues, setSelectedValues] = useState<string[] | null>(isMultiSelect ? field.value : null);

  const onClick = (val: string) => {
    if (!isMultiSelect) {
      field.onChange(val);
      onDismiss();
    } else {
      if (!!selectedValues && selectedValues.includes(val)) {
        setSelectedValues((prevValues) => [...prevValues!.filter((pv) => pv !== val)]);
      } else {
        setSelectedValues((prevValues) => [...prevValues!, val]);
      }
    }
  };

  const onClose = () => {
    setSelectedValues(field.value);
    onDismiss();
  };

  const onApplyClick = () => {
    field.onChange(selectedValues);
    onDismiss();
  };

  return (
    <BackdroplessDialog
      open={isOpen}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            px: 3,
            pb: 3,
          },
        },
      }}
      TransitionProps={{
        onExited: () => onDialogDismissAnimationCompleted(),
      }}
    >
      <DialogTitle component={'div'} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <HootTypography isPII={false} variant="displaysmall">
          Select {isMultiSelect ? `${title}(s)` : `${title}`}
        </HootTypography>
        <Stack direction={'row'}>
          <LottieButton
            tooltipLabel="Cancel"
            lottieFile={LottieFile.ErrorCross}
            aria-label="Close cross"
            onClick={onClose}
            variant="transparent"
            sx={{ p: 1, width: '104px', height: '104px' }}
          />
          {isMultiSelect ? (
            <LottieButton
              lottieFile={LottieFile.ApprovedCheck}
              tooltipLabel={`Apply ${isMultiSelect ? `${title}(s)` : `${title}`}`}
              aria-label={`Apply ${isMultiSelect ? `${title}(s)` : `${title}`}`}
              onClick={onApplyClick}
              variant="transparent"
              sx={{ p: 1, width: '104px', height: '104px' }}
            />
          ) : null}
        </Stack>
      </DialogTitle>
      <DialogContent sx={{ pb: 2 }}>
        <Stack gap={5}>
          {options?.map((option) => {
            const isIconFilled = !!selectedValues ? selectedValues.includes(option.value) : false;
            return (
              <Fragment key={option.value}>
                {isMultiSelect ? (
                  <LottieMultiSelectButton onClick={() => onClick(option.value)} isSelected={isIconFilled}>
                    {option.label}
                  </LottieMultiSelectButton>
                ) : (
                  <PlainButton onClick={() => onClick(option.value)}>{option.label}</PlainButton>
                )}
              </Fragment>
            );
          })}
        </Stack>
      </DialogContent>
    </BackdroplessDialog>
  );
};

export default StudentFilterSelectionModalWrapper;
