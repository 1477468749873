import { Stack } from '@mui/material';
import { capitalCase } from 'change-case';
import { DateTime } from 'luxon';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  FormativeAssessmentSearchResult,
  GetStudentFormativeAssessmentsQuery,
  StudentFormativeAssessmentsQuerySortKeyEnum,
  useGetFormativeAssessments,
} from '@hoot/hooks/api/assessment/useGetStudentFormativeAssessments';
import { FormativeAssessmentType } from '@hoot/models/api/enums/lesson-review-enums';
import { OrderBy } from '@hoot/models/api/enums/queryEnums';
import { DEFAULT_PAGE_SIZE, GenericPaginatedResponse } from '@hoot/models/api/pagination';
import Card from '@hoot/ui/components/v2/core/Card';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';
import { HeaderData, TableV2 } from '@hoot/ui/components/v2/core/Table';

interface FormativeAssessmentTableRow {
  date: string;
  type: string;
  resourceUsed: string;
  score: string;
}

const headers: HeaderData<FormativeAssessmentTableRow>[] = [
  { name: 'Date', property: 'date', isSortable: false, width: '25%' },
  { name: 'Resource Used', property: 'resourceUsed', isSortable: false, width: '25%' },
  { name: 'Score', property: 'score', isSortable: false, width: '25%' },
  { name: 'Type', property: 'type', isSortable: false, width: '25%' },
];

const LessonReviewFormativeAssessmentsCard = (props: { lessonReviewId?: string }) => {
  const { studentProfileId } = useParams();

  const query: GetStudentFormativeAssessmentsQuery = {
    page: 1,
    pageSize: DEFAULT_PAGE_SIZE,
    orderBy: StudentFormativeAssessmentsQuerySortKeyEnum.Date,
    sortDirection: OrderBy.Desc,
    lessonReviewId: props.lessonReviewId,
  };

  const [results, setResults] = useState<GenericPaginatedResponse<FormativeAssessmentTableRow>>({
    page: 1,
    pageSize: DEFAULT_PAGE_SIZE,
    data: [],
    count: 0,
  });

  useGetFormativeAssessments(studentProfileId!, query, {
    enabled: !!studentProfileId,
    onSuccess: (response) => {
      setResults({
        ...response,
        data: mapAssessmentResponseToTableRows(response.data),
      });
    },
  });

  const mapAssessmentResponseToTableRows = (formativeAssessments: FormativeAssessmentSearchResult[]): FormativeAssessmentTableRow[] => {
    return formativeAssessments.map<FormativeAssessmentTableRow>((formativeAssessment) => {
      const lastUpdatedAtFormatted = DateTime.fromMillis(formativeAssessment.lastUpdatedAt).toFormat('cccc, LLLL dd, yyyy');
      return {
        date: lastUpdatedAtFormatted,
        type: [FormativeAssessmentType.LettersAndSounds, FormativeAssessmentType.WordReading].includes(formativeAssessment.formativeAssessmentType)
          ? 'Quick Checks'
          : capitalCase(formativeAssessment.formativeAssessmentType),
        resourceUsed: formativeAssessment.bookTitle,
        score: formativeAssessment.score ?? '-',
      };
    });
  };

  return (
    <>
      {results.count > 0 ? (
        <Card>
          <Stack gap={2}>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <HootTypography isPII={false} variant="titlemedium">
                Formative Assessments
              </HootTypography>
            </Stack>
            <TableV2 data={results.data} headers={headers} count={results.count} />
          </Stack>
        </Card>
      ) : null}
    </>
  );
};

export default LessonReviewFormativeAssessmentsCard;
