import { Subject } from '@hoot/models/api/library';
import { RadioOption } from '@hoot/ui/pages/v2/teacher/lesson/progress-monitoring-assessment/interface';

export enum LessonReviewFocus {
  // Reading
  PhonologicalAwareness = 'PHONOLOGICAL_AWARENESS',
  PhonemicAwareness = 'PHONEMIC_AWARENESS',
  LettersAndSounds = 'LETTERS_AND_SOUNDS',
  EarlyWordReading = 'EARLY_WORD_READING',
  ComplexWordReading = 'COMPLEX_WORD_READING',
  TextReading = 'TEXT_READING',
  LanguageStructures = 'LANGUAGE_STRUCTURES',
  HootReadingAssessment = 'HOOT_READING_ASSESSMENT',
  PreWordReading = 'PRE_WORD_READING',
  // Math
  Subitization = 'SUBITIZATION',
  Counting = 'COUNTING',
  NumberRecognition = 'NUMBER_RECOGNITION',
  Sequencing = 'SEQUENCING',
  Money = 'MONEY',
  Addition = 'ADDITION',
  Subtraction = 'SUBTRACTION',
  Multiplication = 'MULTIPLICATION',
  Division = 'DIVISION',
  Fractions = 'FRACTIONS',
  Time = 'TIME',
  Geometry = 'GEOMETRY',
  RegardlessOfSubject = 'REGARDLESS_OF_SUBJECT',
  // Both
  ProgressMonitoringAssessment = 'PROGRESS_MONITORING_ASSESSMENT',
  RelationshipAndRapportBuilding = 'RELATIONSHIP_RAPPORT_BUILDING',
}

export const lessonReviewFocusesBySubject: Record<Subject, LessonReviewFocus[]> = {
  [Subject.Reading]: [
    LessonReviewFocus.ComplexWordReading,
    LessonReviewFocus.EarlyWordReading,
    LessonReviewFocus.HootReadingAssessment,
    LessonReviewFocus.LettersAndSounds,
    LessonReviewFocus.PhonologicalAwareness,
    LessonReviewFocus.PhonemicAwareness,
    LessonReviewFocus.RelationshipAndRapportBuilding,
    LessonReviewFocus.TextReading,
    // "Temporarily" hide Language Structures as a focus area. Request from L&L.
    // LessonReviewFocus.LanguageStructures,
  ],
  [Subject.Math]: [
    LessonReviewFocus.Subitization,
    LessonReviewFocus.Counting,
    LessonReviewFocus.NumberRecognition,
    LessonReviewFocus.Sequencing,
    LessonReviewFocus.Money,
    LessonReviewFocus.Addition,
    LessonReviewFocus.Subtraction,
    LessonReviewFocus.Multiplication,
    LessonReviewFocus.Division,
    LessonReviewFocus.Fractions,
    LessonReviewFocus.Time,
    LessonReviewFocus.Geometry,
    LessonReviewFocus.RelationshipAndRapportBuilding,
  ],
};

export const lessonReviewFocusesBySubjectV2: Record<Subject, LessonReviewFocus[]> = {
  [Subject.Reading]: [
    LessonReviewFocus.PreWordReading,
    LessonReviewFocus.EarlyWordReading,
    LessonReviewFocus.ComplexWordReading,
    LessonReviewFocus.TextReading,
    LessonReviewFocus.HootReadingAssessment,
    LessonReviewFocus.ProgressMonitoringAssessment,
    LessonReviewFocus.RelationshipAndRapportBuilding,
    // "Temporarily" hide Language Structures as a focus area. Request from L&L.
    // LessonReviewFocus.LanguageStructures,
  ],
  [Subject.Math]: [
    LessonReviewFocus.Subitization,
    LessonReviewFocus.Counting,
    LessonReviewFocus.NumberRecognition,
    LessonReviewFocus.Sequencing,
    LessonReviewFocus.Money,
    LessonReviewFocus.Addition,
    LessonReviewFocus.Subtraction,
    LessonReviewFocus.Multiplication,
    LessonReviewFocus.Division,
    LessonReviewFocus.Fractions,
    LessonReviewFocus.Time,
    LessonReviewFocus.Geometry,
    LessonReviewFocus.RelationshipAndRapportBuilding,
  ],
};

export const lessonReviewFocusLabel: Record<LessonReviewFocus, string> = {
  [LessonReviewFocus.PhonologicalAwareness]: 'Phonological Awareness',
  [LessonReviewFocus.PhonemicAwareness]: 'Phonemic Awareness',
  [LessonReviewFocus.LettersAndSounds]: 'Letters and Sounds',
  [LessonReviewFocus.EarlyWordReading]: 'Early Word Reading',
  [LessonReviewFocus.ComplexWordReading]: 'Complex Word Reading',
  [LessonReviewFocus.TextReading]: 'Text Reading',
  [LessonReviewFocus.LanguageStructures]: 'Language Structures',
  [LessonReviewFocus.HootReadingAssessment]: 'Hoot Reading Assessment',
  [LessonReviewFocus.PreWordReading]: 'Pre-Word Reading',
  [LessonReviewFocus.Subitization]: 'Subitization',
  [LessonReviewFocus.Counting]: 'Counting',
  [LessonReviewFocus.NumberRecognition]: 'Number Recognition',
  [LessonReviewFocus.Sequencing]: 'Sequencing',
  [LessonReviewFocus.Money]: 'Money',
  [LessonReviewFocus.Addition]: 'Addition',
  [LessonReviewFocus.Subtraction]: 'Subtraction',
  [LessonReviewFocus.Multiplication]: 'Multiplication',
  [LessonReviewFocus.Division]: 'Division',
  [LessonReviewFocus.Fractions]: 'Fractions',
  [LessonReviewFocus.Time]: 'Time',
  [LessonReviewFocus.Geometry]: 'Geometry',
  [LessonReviewFocus.RegardlessOfSubject]: 'Regardless of Subject',
  [LessonReviewFocus.ProgressMonitoringAssessment]: 'Progress Monitoring Assessment',
  [LessonReviewFocus.RelationshipAndRapportBuilding]: 'Relationship and Rapport Building',
};

export const lessonReviewLabelToFocus: Record<string, LessonReviewFocus> = Object.entries(lessonReviewFocusLabel).reduce(
  (acc, [key, value]) => {
    acc[value as string] = key as LessonReviewFocus;
    return acc;
  },
  {} as Record<string, LessonReviewFocus>,
);

export enum LessonReviewFocusType {
  Primary = 'PRIMARY',
  Secondary = 'SECONDARY',
}

export enum LessonReviewEngagement {
  NotAtAllEngaged = 'NOT_AT_ALL_ENGAGED',
  SomewhatEngaged = 'SOMEWHAT_ENGAGED',
  FairlyEngaged = 'FAIRLY_ENGAGED',
  Engaged = 'ENGAGED',
  HighlyEngaged = 'HIGHLY_ENGAGED',
}

export enum LessonReviewFocusUnit {
  // Phonological Awareness
  InitialSounds = 'INITIAL_SOUNDS',
  WordAwareness = 'WORD_AWARENESS',
  SyllableAwareness = 'SYLLABLE_AWARENESS',
  RhymeAwareness = 'RHYME_AWARENESS',
  OnsetRimeAwareness = 'ONSET_RIME_AWARENESS',
  // Phoneme Awareness
  PhonemeSegmentation = 'PHONEME_SEGMENTATION',
  PhonemeBlending = 'PHONEME_BLENDING',
  PhonemeDeletion = 'PHONEME_DELETION',
  PhonemeSubstitution = 'PHONEME_SUBSTITUTION',
  // Early Word Reading
  ShortVowelsCVC = 'SHORT_VOWELS_CVC',
  Digraphs = 'DIGRAPHS',
  Blends = 'BLENDS',
  LongVowels = 'LONG_VOWELS',
  BasicRControlledVowels = 'BASIC_R_CONTROLLED_VOWELS',
  BasicVowelTeams = 'BASIC_VOWEL_TEAMS',
  // Complex Word Reading
  AdvancedVowelTeams = 'ADVANCED_VOWEL_TEAMS',
  Diphthongs = 'DIPHTHONGS',
  AdvancedRControlledVowels = 'ADVANCED_R_CONTROLLED_VOWELS',
  SilentLettersAndSoftConsonants = 'SILENT_LETTERS_AND_SOFT_CONSONANTS',
  WordEndings = 'WORD_ENDINGS',
  SyllableTypes = 'SYLLABLE_TYPES',
  AdvancedVowels = 'ADVANCED_VOWELS',
  // Text Reading
  Fluency = 'FLUENCY',
  Vocabulary = 'VOCABULARY',
  VerbalReasoning = 'VERBAL_REASONING',
  KnowledgeBuilding = 'KNOWLEDGE_BUILDING',
  TextStructure = 'TEXT_STRUCTURE',
  SentenceAnalysis = 'SENTENCE_ANALYSIS',
  LettersAndSounds = 'LETTERS_AND_SOUNDS',
  PhonomemicAwareness = 'PHONEMIC_AWARENESS',
  Affixes = 'AFFIXES',
  MultisyllabicWords = 'MULTISYLLABIC_WORDS',
  LanguageComprehension = 'LANGUAGE_COMPREHENSION',
  ReadingComprehension = 'READING_COMPREHENSION',
  AdvancedConsonants = 'ADVANCED_CONSONANTS',
}

// TODO SC-11212: Remove this record
export const lessonReviewUnitByFocus: Record<LessonReviewFocus, LessonReviewFocusUnit[]> = {
  [LessonReviewFocus.PhonologicalAwareness]: [
    LessonReviewFocusUnit.InitialSounds,
    LessonReviewFocusUnit.WordAwareness,
    LessonReviewFocusUnit.SyllableAwareness,
    LessonReviewFocusUnit.RhymeAwareness,
    LessonReviewFocusUnit.OnsetRimeAwareness,
  ],
  [LessonReviewFocus.PhonemicAwareness]: [
    LessonReviewFocusUnit.PhonemeSegmentation,
    LessonReviewFocusUnit.PhonemeBlending,
    LessonReviewFocusUnit.PhonemeDeletion,
    LessonReviewFocusUnit.PhonemeSubstitution,
  ],
  [LessonReviewFocus.EarlyWordReading]: [
    LessonReviewFocusUnit.ShortVowelsCVC,
    LessonReviewFocusUnit.Digraphs,
    LessonReviewFocusUnit.Blends,
    LessonReviewFocusUnit.LongVowels,
    LessonReviewFocusUnit.BasicRControlledVowels,
    LessonReviewFocusUnit.BasicVowelTeams,
  ],
  [LessonReviewFocus.ComplexWordReading]: [
    LessonReviewFocusUnit.AdvancedVowelTeams,
    LessonReviewFocusUnit.Diphthongs,
    LessonReviewFocusUnit.AdvancedRControlledVowels,
    LessonReviewFocusUnit.SilentLettersAndSoftConsonants,
    LessonReviewFocusUnit.WordEndings,
    LessonReviewFocusUnit.SyllableTypes,
  ],
  [LessonReviewFocus.TextReading]: [
    LessonReviewFocusUnit.Fluency,
    LessonReviewFocusUnit.Vocabulary,
    LessonReviewFocusUnit.VerbalReasoning,
    LessonReviewFocusUnit.KnowledgeBuilding,
    LessonReviewFocusUnit.TextStructure,
    LessonReviewFocusUnit.SentenceAnalysis,
  ],
  [LessonReviewFocus.LettersAndSounds]: [],
  [LessonReviewFocus.LanguageStructures]: [],
  [LessonReviewFocus.HootReadingAssessment]: [],
  [LessonReviewFocus.Subitization]: [],
  [LessonReviewFocus.Counting]: [],
  [LessonReviewFocus.NumberRecognition]: [],
  [LessonReviewFocus.Sequencing]: [],
  [LessonReviewFocus.Money]: [],
  [LessonReviewFocus.Addition]: [],
  [LessonReviewFocus.Subtraction]: [],
  [LessonReviewFocus.Multiplication]: [],
  [LessonReviewFocus.Division]: [],
  [LessonReviewFocus.Fractions]: [],
  [LessonReviewFocus.Time]: [],
  [LessonReviewFocus.Geometry]: [],
  [LessonReviewFocus.RegardlessOfSubject]: [],
  [LessonReviewFocus.ProgressMonitoringAssessment]: [],
  [LessonReviewFocus.RelationshipAndRapportBuilding]: [],
  [LessonReviewFocus.PreWordReading]: [],
};

// TODO SC-11212: Remove V2 from name
export const lessonReviewUnitByFocusV2: Record<LessonReviewFocus, LessonReviewFocusUnit[]> = {
  [LessonReviewFocus.PhonologicalAwareness]: [
    LessonReviewFocusUnit.InitialSounds,
    LessonReviewFocusUnit.WordAwareness,
    LessonReviewFocusUnit.SyllableAwareness,
    LessonReviewFocusUnit.RhymeAwareness,
    LessonReviewFocusUnit.OnsetRimeAwareness,
  ],
  [LessonReviewFocus.PhonemicAwareness]: [
    LessonReviewFocusUnit.PhonemeSegmentation,
    LessonReviewFocusUnit.PhonemeBlending,
    LessonReviewFocusUnit.PhonemeDeletion,
    LessonReviewFocusUnit.PhonemeSubstitution,
  ],
  [LessonReviewFocus.EarlyWordReading]: [
    LessonReviewFocusUnit.ShortVowelsCVC,
    LessonReviewFocusUnit.Digraphs,
    LessonReviewFocusUnit.Blends,
    LessonReviewFocusUnit.LongVowels,
    LessonReviewFocusUnit.BasicRControlledVowels,
    LessonReviewFocusUnit.BasicVowelTeams,
  ],
  [LessonReviewFocus.ComplexWordReading]: [
    LessonReviewFocusUnit.AdvancedVowels,
    LessonReviewFocusUnit.AdvancedRControlledVowels,
    LessonReviewFocusUnit.AdvancedConsonants,
    LessonReviewFocusUnit.Affixes,
    LessonReviewFocusUnit.SyllableTypes,
    LessonReviewFocusUnit.MultisyllabicWords,
  ],
  [LessonReviewFocus.TextReading]: [
    LessonReviewFocusUnit.Fluency,
    LessonReviewFocusUnit.LanguageComprehension,
    LessonReviewFocusUnit.ReadingComprehension,
  ],
  [LessonReviewFocus.LettersAndSounds]: [],
  [LessonReviewFocus.LanguageStructures]: [],
  [LessonReviewFocus.HootReadingAssessment]: [],
  [LessonReviewFocus.Subitization]: [],
  [LessonReviewFocus.Counting]: [],
  [LessonReviewFocus.NumberRecognition]: [],
  [LessonReviewFocus.Sequencing]: [],
  [LessonReviewFocus.Money]: [],
  [LessonReviewFocus.Addition]: [],
  [LessonReviewFocus.Subtraction]: [],
  [LessonReviewFocus.Multiplication]: [],
  [LessonReviewFocus.Division]: [],
  [LessonReviewFocus.Fractions]: [],
  [LessonReviewFocus.Time]: [],
  [LessonReviewFocus.Geometry]: [],
  [LessonReviewFocus.RegardlessOfSubject]: [],
  [LessonReviewFocus.ProgressMonitoringAssessment]: [],
  [LessonReviewFocus.RelationshipAndRapportBuilding]: [],
  [LessonReviewFocus.PreWordReading]: [LessonReviewFocusUnit.PhonomemicAwareness, LessonReviewFocusUnit.LettersAndSounds],
};

export const lessonReviewFocusUnitLabel: Record<LessonReviewFocusUnit, string> = {
  [LessonReviewFocusUnit.InitialSounds]: 'Initial Sounds',
  [LessonReviewFocusUnit.WordAwareness]: 'Word Awareness',
  [LessonReviewFocusUnit.SyllableAwareness]: 'Syllable Awareness',
  [LessonReviewFocusUnit.RhymeAwareness]: 'Rhyme Awareness',
  [LessonReviewFocusUnit.OnsetRimeAwareness]: 'Onset-Rime Awareness',
  [LessonReviewFocusUnit.PhonemeSegmentation]: 'Phoneme Segmentation',
  [LessonReviewFocusUnit.PhonemeBlending]: 'Phoneme Blending',
  [LessonReviewFocusUnit.PhonemeDeletion]: 'Phoneme Deletion',
  [LessonReviewFocusUnit.PhonemeSubstitution]: 'Phoneme Substitution',
  [LessonReviewFocusUnit.ShortVowelsCVC]: 'Short Vowels CVC',
  [LessonReviewFocusUnit.Digraphs]: 'Digraphs',
  [LessonReviewFocusUnit.Blends]: 'Blends',
  [LessonReviewFocusUnit.LongVowels]: 'Long Vowels',
  [LessonReviewFocusUnit.BasicRControlledVowels]: 'Basic R-Controlled Vowels',
  [LessonReviewFocusUnit.BasicVowelTeams]: 'Basic Vowel Teams',
  [LessonReviewFocusUnit.AdvancedVowelTeams]: 'Advanced Vowel Teams',
  [LessonReviewFocusUnit.Diphthongs]: 'Diphthongs',
  [LessonReviewFocusUnit.AdvancedRControlledVowels]: 'Advanced R-Controlled Vowels',
  [LessonReviewFocusUnit.SilentLettersAndSoftConsonants]: 'Silent Letters & Soft Consonants',
  [LessonReviewFocusUnit.WordEndings]: 'Word Endings',
  [LessonReviewFocusUnit.SyllableTypes]: 'Syllable Types',
  [LessonReviewFocusUnit.Fluency]: 'Fluency',
  [LessonReviewFocusUnit.Vocabulary]: 'Vocabulary',
  [LessonReviewFocusUnit.VerbalReasoning]: 'Verbal Reasoning',
  [LessonReviewFocusUnit.KnowledgeBuilding]: 'Knowledge-Building',
  [LessonReviewFocusUnit.TextStructure]: 'Text Structure',
  [LessonReviewFocusUnit.SentenceAnalysis]: 'Sentence Analysis',
  [LessonReviewFocusUnit.LettersAndSounds]: 'Letters and Sounds',
  [LessonReviewFocusUnit.PhonomemicAwareness]: 'Phonemic Awareness',
  [LessonReviewFocusUnit.AdvancedVowels]: 'Advanced Vowels',
  [LessonReviewFocusUnit.Affixes]: 'Affixes',
  [LessonReviewFocusUnit.MultisyllabicWords]: 'Multisyllabic Words',
  [LessonReviewFocusUnit.LanguageComprehension]: 'Language Comprehension',
  [LessonReviewFocusUnit.ReadingComprehension]: 'Reading Comprehension',
  [LessonReviewFocusUnit.AdvancedConsonants]: 'Advanced Consonants',
};

export const lessonReviewLabelToUnit: Record<string, LessonReviewFocusUnit> = Object.entries(lessonReviewFocusUnitLabel).reduce(
  (acc, [key, value]) => {
    acc[value as string] = key as LessonReviewFocusUnit;
    return acc;
  },
  {} as Record<string, LessonReviewFocusUnit>,
);

export enum LessonReviewFocusSkill {
  SentenceAnalysis = 'SENTENCE_ANALYSIS',
  TextSpecificKnowledge = 'TEXT_SPECIFIC_KNOWLEDGE',
  TextStructure = 'TEXT_STRUCTURE',
  VerbalReasoning = 'VERBAL_REASONING',
  Vocabulary = 'VOCABULARY',
}

export const lessonReviewLabelToSkill: Record<string, LessonReviewFocusSkill> = {
  SENTENCE_ANALYSIS: LessonReviewFocusSkill.SentenceAnalysis,
  TEXT_SPECIFIC_KNOWLEDGE: LessonReviewFocusSkill.TextSpecificKnowledge,
  TEXT_STRUCTURE: LessonReviewFocusSkill.TextStructure,
  VERBAL_REASONING: LessonReviewFocusSkill.VerbalReasoning,
  VOCABULARY: LessonReviewFocusSkill.Vocabulary,
};

export const lessonReviewSkillByUnit: Record<LessonReviewFocusUnit, LessonReviewFocusSkill[]> = {
  [LessonReviewFocusUnit.InitialSounds]: [],
  [LessonReviewFocusUnit.WordAwareness]: [],
  [LessonReviewFocusUnit.SyllableAwareness]: [],
  [LessonReviewFocusUnit.RhymeAwareness]: [],
  [LessonReviewFocusUnit.OnsetRimeAwareness]: [],
  [LessonReviewFocusUnit.PhonemeSegmentation]: [],
  [LessonReviewFocusUnit.PhonemeBlending]: [],
  [LessonReviewFocusUnit.PhonemeDeletion]: [],
  [LessonReviewFocusUnit.PhonemeSubstitution]: [],
  [LessonReviewFocusUnit.ShortVowelsCVC]: [],
  [LessonReviewFocusUnit.Digraphs]: [],
  [LessonReviewFocusUnit.Blends]: [],
  [LessonReviewFocusUnit.LongVowels]: [],
  [LessonReviewFocusUnit.BasicRControlledVowels]: [],
  [LessonReviewFocusUnit.BasicVowelTeams]: [],
  [LessonReviewFocusUnit.AdvancedVowelTeams]: [],
  [LessonReviewFocusUnit.Diphthongs]: [],
  [LessonReviewFocusUnit.AdvancedRControlledVowels]: [],
  [LessonReviewFocusUnit.SilentLettersAndSoftConsonants]: [],
  [LessonReviewFocusUnit.WordEndings]: [],
  [LessonReviewFocusUnit.SyllableTypes]: [],
  [LessonReviewFocusUnit.AdvancedVowels]: [],
  [LessonReviewFocusUnit.Fluency]: [],
  [LessonReviewFocusUnit.Vocabulary]: [],
  [LessonReviewFocusUnit.VerbalReasoning]: [],
  [LessonReviewFocusUnit.KnowledgeBuilding]: [],
  [LessonReviewFocusUnit.TextStructure]: [],
  [LessonReviewFocusUnit.SentenceAnalysis]: [],
  [LessonReviewFocusUnit.LettersAndSounds]: [],
  [LessonReviewFocusUnit.PhonomemicAwareness]: [],
  [LessonReviewFocusUnit.Affixes]: [],
  [LessonReviewFocusUnit.MultisyllabicWords]: [],
  [LessonReviewFocusUnit.AdvancedConsonants]: [],
  [LessonReviewFocusUnit.LanguageComprehension]: [
    LessonReviewFocusSkill.Vocabulary,
    LessonReviewFocusSkill.VerbalReasoning,
    LessonReviewFocusSkill.TextSpecificKnowledge,
    LessonReviewFocusSkill.TextStructure,
    LessonReviewFocusSkill.SentenceAnalysis,
  ],
  [LessonReviewFocusUnit.ReadingComprehension]: [
    LessonReviewFocusSkill.Vocabulary,
    LessonReviewFocusSkill.VerbalReasoning,
    LessonReviewFocusSkill.TextSpecificKnowledge,
    LessonReviewFocusSkill.TextStructure,
    LessonReviewFocusSkill.SentenceAnalysis,
  ],
};

export const lessonReviewFocusSkillLabel: Record<LessonReviewFocusSkill, string> = {
  [LessonReviewFocusSkill.SentenceAnalysis]: 'Sentence Analysis',
  [LessonReviewFocusSkill.TextSpecificKnowledge]: 'Text-Specific Knowledge',
  [LessonReviewFocusSkill.TextStructure]: 'Text Structure',
  [LessonReviewFocusSkill.VerbalReasoning]: 'Verbal Reasoning',
  [LessonReviewFocusSkill.Vocabulary]: 'Vocabulary',
};

/** Resource Reader */

export enum ResourceReader {
  Student = 'STUDENT',
  Teacher = 'TEACHER',
  MostlyStudent = 'MOSTLY_STUDENT',
  MostlyTeacher = 'MOSTLY_TEACHER',
}

export const resourceReaderLabel: Record<ResourceReader, string> = {
  STUDENT: 'Student',
  TEACHER: 'Teacher',
  MOSTLY_STUDENT: 'Mostly Student',
  MOSTLY_TEACHER: 'Mostly Teacher',
};

export enum FormativeAssessmentType {
  WordReading = 'WORD_READING',
  Fluency = 'FLUENCY',
  Comprehension = 'COMPREHENSION',
  LettersAndSounds = 'LETTERS_AND_SOUNDS',
}

export const formativeAssessmentLetters = [
  'm',
  't',
  'a',
  's',
  'i',
  'p',
  'e',
  'r',
  'd',
  'f',
  'o',
  'l',
  'g',
  'h',
  'u',
  'c',
  'n',
  'b',
  'j',
  'k',
  'y',
  'w',
  'v',
  'qu',
  'x',
  'z',
];

export enum ComprehensionAssessmentSection {
  TextSpecificKnowledge = 'TEXT_SPECIFIC_KNOWLEDGE',
  TextStructure = 'TEXT_STRUCTURE',
  SentenceAnalysis = 'SENTENCE_ANALYSIS',
  VerbalReasoning = 'VERBAL_REASONING',
  Vocabulary = 'VOCABULARY',
}

export type ComprehensionQuestions = Record<ComprehensionAssessmentSection, number | null>;

export interface ComprehensionFormativeAssessmentQuestionnaire {
  title: string;
  section: ComprehensionAssessmentSection;
  subTitle?: string;
  options: RadioOption[];
}

export const comprehensionQuestionnaire: ComprehensionFormativeAssessmentQuestionnaire[] = [
  {
    title: 'Text-Specific Knowledge',
    section: ComprehensionAssessmentSection.TextSpecificKnowledge,
    options: [
      {
        text: 'Provides incorrect or extremely basic information, doesn’t return to the text to get better information when prompted, or identifies irrelevant information.',
        val: '1',
      },
      {
        text: 'Partial information provided, may return to text for more info when prompted or rely on memory. More precise information was available to report, but the student did not do so',
        val: '2',
      },
      {
        text: 'Specific information provided, including names and other relevant details, returns to text as required to gather or confirm precise information.',
        val: '3',
      },
    ],
  },
  {
    title: 'Text Structure',
    section: ComprehensionAssessmentSection.TextStructure,
    options: [
      {
        text: 'Is unable to discuss genre and text-specific features, elements and overall structure. Is unclear on the likely purpose for reading this text or how the text’s organization supports that purpose.',
        val: '1',
      },
      {
        text: 'Demonstrates partial understanding of genre and text-specific features, elements and overall structure. Can provide a basic purpose for reading, but may struggle to connect how the organization of the text supports their understanding and reading experience.',
        val: '2',
      },
      {
        text: 'Demonstrates understanding of the way the text is organized and their purpose for reading. Can correctly identify and utilize relevant text features or genre-based elements in the text to build comprehension.',
        val: '3',
      },
    ],
  },
  {
    title: 'Sentence Analysis',
    section: ComprehensionAssessmentSection.SentenceAnalysis,
    options: [
      {
        text: 'Could not answer sentence-level question or displays confusion over pronouns, direction words or other sentence features.',
        val: '1',
      },
      {
        text: 'Has difficulty in answering the sentence-level question, or demonstrates partial understanding of the focus sentence.',
        val: '2',
      },
      {
        text: 'Answered question correctly, showing command of syntax and sentence comprehension.',
        val: '3',
      },
    ],
  },
  {
    title: 'Verbal Reasoning',
    section: ComprehensionAssessmentSection.VerbalReasoning,
    options: [
      {
        text: 'Little to no evidence of verbal reasoning, unable to connect text information and events to logical predictions, inferences, or implications that are not stated in the text explicitly, even with prompting.',
        val: '1',
      },
      {
        text: 'Basic verbal reasoning skills demonstrated through connecting text evidence to reasonable or logical inferences, but may be unable to explain their thinking or require a good amount of scaffolding to answer this question.',
        val: '2',
      },
      {
        text: 'Makes reasonable predictions or inferences and is able to explain what in the text informed their thinking. Demonstrates metacognition, or monitoring their own thinking.',
        val: '3',
      },
    ],
  },
  {
    title: 'Vocabulary (Overall)',
    section: ComprehensionAssessmentSection.Vocabulary,
    subTitle: 'Consider the student’s use of vocabulary holistically.',
    options: [
      {
        text: 'Uses general words, does not provide specific vocabulary provided in the text. Words used may reflect some misunderstanding of meaning of words in the text. Example from a book about kangaroos: The mom kangaroo carries the baby in her stomach.',
        val: '1',
      },
      {
        text: 'Uses some specific vocabulary words from the text correctly and/or uses synonyms or other words that show they have grasped the meaning of text-specific vocabulary words. Example from a book about kangaroos: The mom kangaroo carries the baby kangaroo (what was it called?) in her sack.',
        val: '2',
      },
      {
        text: 'Uses names and/or other specific vocabulary words as used in the text and uses them correctly to show meaning has been grasped. Example from a book about kangaroos: The mother kangaroo carries the joey in her pouch.',
        val: '3',
      },
    ],
  },
];
