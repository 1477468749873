import { Stack, useMediaQuery, useTheme } from '@mui/material';
import { DateTime } from 'luxon';
import { useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { DateFormats } from '@hoot/constants/constants';
import {
  GetStudentProgressMonitoringAssessmentsQuery,
  ProgressMonitoringAssessmentResponse,
  StudentProgressMonitoringAssessmentsQuerySortKeyEnum,
  useGetProgressMonitoringAssessments,
} from '@hoot/hooks/api/assessment/useGetStudentProgressMonitoringAssessments';
import { OrderBy } from '@hoot/models/api/enums/queryEnums';
import { DEFAULT_PAGE_SIZE, GenericPaginatedResponse } from '@hoot/models/api/pagination';
import ViewStateIllustration, { IllustrationEnum } from '@hoot/ui/components/v2/ViewStateIllustration';
import { Button } from '@hoot/ui/components/v2/core/Button';
import Card from '@hoot/ui/components/v2/core/Card';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';
import { HeaderData, TableV2 } from '@hoot/ui/components/v2/core/Table';
import { hootAssessmentStatusLabel } from '../../../hoot-reading-assessment/utils/assessment-utils';
import PMADetailsModal from './PMADetailsModal';

interface PMATableRow {
  id: string;
  startDate: string;
  status: string;
  completionDate: string;
  units: React.ReactNode;
  action: React.ReactNode;
}

const headers: HeaderData<PMATableRow>[] = [
  { name: 'Id', property: 'id', isHidden: true },
  { name: 'Start Date', property: 'startDate', isSortable: true },
  { name: 'Status', property: 'status', isSortable: true },
  { name: 'Completion Date', property: 'completionDate', isSortable: true },
  { name: 'Units', property: 'units', isSortable: false },
  { name: 'Action', property: 'action', isSortable: false },
];

const mobileHeaders: HeaderData<PMATableRow>[] = [
  { name: 'Id', property: 'id', isHidden: true },
  { name: 'Start Date', property: 'startDate', isSortable: true },
  { name: 'Status', property: 'status', isSortable: true },
  { name: 'Completion Date', property: 'completionDate', isSortable: true },
  { name: 'Units', property: 'units', isSortable: true, isHidden: false },
  { name: 'Action', property: 'action', isSortable: false, isHidden: true },
];

const ProgressMonitoringAssessmentHistoryCard = () => {
  const { studentProfileId } = useParams();
  const location = useLocation();
  const pmaId: string | undefined = location.state?.pmaId;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [selectedAssessment, setSelectedAssessment] = useState<string | undefined>(pmaId);

  const [query, setQuery] = useState<GetStudentProgressMonitoringAssessmentsQuery>({
    page: 1,
    pageSize: DEFAULT_PAGE_SIZE,
    orderBy: StudentProgressMonitoringAssessmentsQuerySortKeyEnum.StartDate,
    sortDirection: OrderBy.Desc,
  });

  const [results, setResults] = useState<GenericPaginatedResponse<PMATableRow>>({
    page: 1,
    pageSize: DEFAULT_PAGE_SIZE,
    data: [],
    count: 0,
  });

  const getAssessmentsRequest = useGetProgressMonitoringAssessments(studentProfileId!, query, {
    enabled: !!studentProfileId,
    onSuccess: (response) => {
      setResults({
        ...response,
        data: response.data.map((x) => mapAssessmentResponseToTableRow(x)),
      });
    },
  });

  const onShowAssessmentDetailsModal = (assessmentId: string) => {
    setSelectedAssessment(assessmentId);
  };

  const onCloseAssessmentDetailsModal = () => {
    setSelectedAssessment(undefined);
  };

  const mapAssessmentResponseToTableRow = (assessment: ProgressMonitoringAssessmentResponse): PMATableRow => {
    return {
      id: assessment.id,
      startDate: DateTime.fromMillis(assessment.createdAt).toFormat(DateFormats.FULL_DATE),
      status: hootAssessmentStatusLabel[assessment.status],
      completionDate: assessment.completedAt ? DateTime.fromMillis(assessment.completedAt).toFormat(DateFormats.FULL_DATE) : '-',
      units: (
        <Stack>
          {assessment.units ? (
            assessment.units.map((unit) => (
              <HootTypography key={unit} variant="bodysmall" isPII={false}>
                {unit}
              </HootTypography>
            ))
          ) : (
            <HootTypography variant="bodymedium" isPII={false}>
              -
            </HootTypography>
          )}
        </Stack>
      ),
      action: (
        <Button variant="outlined" size="small" onClick={() => onShowAssessmentDetailsModal(assessment.id)}>
          Open
        </Button>
      ),
    };
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const pageSize = parseInt(event.target.value, 10);
    setQuery((currentState) => ({ ...currentState, page: 1, pageSize }));
  };

  const handleChangePage = (_event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setQuery((currentState) => ({ ...currentState, page: newPage + 1 }));
  };

  const handleSortBy = (selectedColumn: keyof PMATableRow) => {
    const getQueryOrderByColumn = (): StudentProgressMonitoringAssessmentsQuerySortKeyEnum => {
      switch (selectedColumn) {
        case 'completionDate':
          return StudentProgressMonitoringAssessmentsQuerySortKeyEnum.CompletionDate;
        case 'status':
          return StudentProgressMonitoringAssessmentsQuerySortKeyEnum.Status;
        case 'startDate':
        default:
          return StudentProgressMonitoringAssessmentsQuerySortKeyEnum.StartDate;
      }
    };
    const queryOrderByColumn = getQueryOrderByColumn();
    setQuery((currentState) => ({
      ...currentState,
      orderBy: queryOrderByColumn,
      // If the user clicked on a new column, then sort in ascending order.
      sortDirection:
        queryOrderByColumn !== currentState.orderBy
          ? OrderBy.Asc
          : // Else, flip the sort order.
            currentState.sortDirection === OrderBy.Asc
            ? OrderBy.Desc
            : OrderBy.Asc,
    }));
  };

  function orderByColumn(): keyof PMATableRow {
    switch (query.orderBy) {
      case StudentProgressMonitoringAssessmentsQuerySortKeyEnum.Status:
        return 'status';
      case StudentProgressMonitoringAssessmentsQuerySortKeyEnum.CompletionDate:
        return 'completionDate';
      case StudentProgressMonitoringAssessmentsQuerySortKeyEnum.StartDate:
      default:
        return 'startDate';
    }
  }

  return (
    <>
      <Card isLoading={getAssessmentsRequest.isFetching}>
        <Stack gap={2}>
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <HootTypography isPII={false} variant="titlemedium">
              Progress Monitoring Assessment History
            </HootTypography>
          </Stack>
          <TableV2
            isPaginated
            isSortable
            data={results.data}
            headers={isMobile ? mobileHeaders : headers}
            onRowsPerPageChange={handleChangeRowsPerPage}
            count={results.count}
            page={query.page - 1}
            onPageChange={handleChangePage}
            onSortBy={handleSortBy}
            sortOrder={query.sortDirection ?? OrderBy.Asc}
            sortBy={orderByColumn()}
            emptyViewState={<ViewStateIllustration illustration={IllustrationEnum.EmptyState} />}
          />
        </Stack>
      </Card>
      <PMADetailsModal assessmentId={selectedAssessment} onClose={onCloseAssessmentDetailsModal} />
    </>
  );
};

export default ProgressMonitoringAssessmentHistoryCard;
