import qs from 'qs';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { GetSsoLaunchInfoResponse } from '@hoot/hooks/api/auth/useGetEdlinkSsoLaunchInfo';
import { useSessionStorage } from '@hoot/hooks/useSessionStorage';
import { SessionStorageKey } from '@hoot/models/sessionStorageKey';
import { routesDictionary } from '@hoot/routes/routesDictionary';
import { useAuth } from '@hoot/ui/context/AuthContext';
import HootAlert from './core/HootAlert';

export interface EdlinkSessionState {
  state: string;
  friendlyId: string | undefined;
}

export const handleSsoLaunchClick = (ssoRedirectInfo: GetSsoLaunchInfoResponse | undefined, loginLocationFid: string | undefined) => {
  if (!ssoRedirectInfo) {
    return;
  }

  window.sessionStorage.setItem(
    SessionStorageKey.EDLINK_SSO_STATE,
    JSON.stringify({
      state: ssoRedirectInfo.state,
      friendlyId: loginLocationFid || null,
    }),
  );

  if (loginLocationFid) {
    window.sessionStorage.setItem(SessionStorageKey.DISTRICT_LOGIN_FRIENDLY_ID, loginLocationFid);
  }

  window.location.href = ssoRedirectInfo.redirectUrl;
};

const EdlinkSsoRedirectHandler = () => {
  const { handleSsoAuthRequest } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const [exchangeError, setExchangeError] = useState<string | undefined>(undefined);
  const [storedSsoStateSessionData] = useSessionStorage<EdlinkSessionState>(SessionStorageKey.EDLINK_SSO_STATE);

  const query = qs.parse(location.search, { ignoreQueryPrefix: true });
  const state = (query.state as string) || null;
  const code = (query.code as string) || null;
  const error = (query.error as string) || null;

  function handleError(): void {
    // TODO: Should we be more specific about what went wrong, would need to wire up to both the authcontext and the tokenexchange method
    //       to expose additional details.
    //       Or should we avoid exposing this sort of info on failed login?
    setExchangeError(`Could not log you in`);
    window.sessionStorage.removeItem(SessionStorageKey.EDLINK_SSO_STATE);
  }

  useEffect(() => {
    if (!!code && !error) {
      console.log(`SSO: Received state: ${state}, stored prior to launch was: ${storedSsoStateSessionData?.state || ''}`);

      // Some sso launches will not be initiated from our app so we won't have generated a state param to store
      // Examples: Clever Instant Login, Clever Portal Login, LMS LTI launches, Edlink Impersonate logins
      if (!!storedSsoStateSessionData?.state) {
        if (storedSsoStateSessionData.state !== state) {
          console.error(
            `SSO: State check ❌ :: Received state '${state || ''}', does not match the one generated prior to redirect '${
              storedSsoStateSessionData.state
            }'`,
          );
          handleError();
        } else {
          console.log(`SSO: State check ✅ :: Received state matches previously stored value`);
        }
        console.log(`SSO: State check ✅ :: Did not receive a state search param`);
      }

      // The redirect url is always /login as Clever recommends that all users are able to login at our primary redirect url
      // If the user started the SSO process at our district login route /login/000-00, we store the FID and link it to the
      // 'state' variable we pass to Edlink. If the state.friendlyId exists, we can then redirect them from /login to /login/fid
      console.log(`SSO: current location ${location.pathname}${location.search}`);
      if (!!storedSsoStateSessionData?.friendlyId && location.pathname.endsWith(routesDictionary.login.url)) {
        const navTo = routesDictionary.login.district.url(storedSsoStateSessionData.friendlyId) + location.search;
        console.log(`SSO: Found FID ${storedSsoStateSessionData.friendlyId} in state, navigating from login/ -> ${navTo}`);
        navigate(navTo);
      } else {
        console.log(`SSO: Received code: ${code}, performing server side validation & exchange....`);
        // remove the search params to avoid
        //  - triggering AlreadyAuthenticatedSsoRequestHandler one the following auth request completes
        //  - keeping code/state in the users browser location
        navigate(location.pathname);

        handleSsoAuthRequest(code, state)
          .then(() => {
            console.log(`SSO: Edlink token exchange success, redirecting to ${routesDictionary.home.url}`);
            navigate(routesDictionary.home.url);
          })
          .catch(() => {
            console.log(`SSO: Edlink token exchange error....`);
            handleError();
          });
      }
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (!!error || !!exchangeError) {
    return <HootAlert severity="error">{error || exchangeError}</HootAlert>;
  }

  return null;
};

export const AlreadyAuthenticatedSsoRequestHandler = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const query = qs.parse(location.search, { ignoreQueryPrefix: true });
  const code = (query.code as string) || null;

  useEffect(() => {
    if (!!code) {
      console.log(
        `SSO [AASRH]: Received SSO redirect with code but there is a session currently logged in [User ID: ${auth.getUser().id}, IsAuthenticated: ${
          auth.isAuthenticated
        }]. Logging out`,
      );
      auth.logout(false, false);
    } else {
      console.log(
        `SSO [AASRH]: Received request for '${location.pathname}${location.search}' with no 'code' parameter. Navigating to ${routesDictionary.home.url}`,
      );
      navigate(routesDictionary.home.url);
    }
  }, [location.pathname, location.search, auth, code, navigate]);

  return null;
};

export default EdlinkSsoRedirectHandler;
