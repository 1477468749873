import { Box, Skeleton, Stack } from '@mui/material';
import Grid from '@mui/material/Grid2';
import React from 'react';
import SubHeader from '@hoot/ui/components/v2/SubHeader';
import ViewState, { ViewStateEnum, ViewStateProps } from '@hoot/ui/components/v2/ViewState';
import Card from '@hoot/ui/components/v2/core/Card';
import PageLayout from '@hoot/ui/components/v2/core/PageLayout';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';

interface ScopeAndSequenceDocumentationTemplateProps {
  viewState: ViewStateEnum;
  title: string | null; // May be null while request is fetching.
  navigateBackPath: string;
  breadcrumbs: string[];
  htmlContent: string | null;
  NoResultsIllustrationProps: ViewStateProps['NoResultsIllustrationProps'];
}

const ScopeAndSequenceDocumentationTemplate = (props: ScopeAndSequenceDocumentationTemplateProps) => {
  const { viewState, title, navigateBackPath, breadcrumbs, htmlContent, NoResultsIllustrationProps } = props;

  return (
    <>
      <SubHeader
        title={{
          label: title ?? <Skeleton variant="text" sx={{ width: '100px%' }} />,
          isPII: false,
        }}
        backButton={{
          show: true,
          navigateBackPath: navigateBackPath,
        }}
        breadcrumbs={breadcrumbs}
      />
      <PageLayout>
        <Grid size={{ md: 4, xs: 12 }}>
          <Card isLoading={viewState === ViewStateEnum.Loading}>
            <ViewState state={viewState} loadingContent={<SkeletonContent />} NoResultsIllustrationProps={NoResultsIllustrationProps}>
              <Box
                // Set up the localized styling for the documentation content.
                sx={{
                  h1: {
                    ...hootTokens.text.headlinelarge,
                  },
                  h2: {
                    ...hootTokens.text.titlelarge,
                  },
                  table: {
                    width: '100%',
                    borderCollapse: 'collapse',
                    borderSpacing: 0,
                    boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)',
                    outline: 'solid 1px #808080',
                    borderRadius: '8px',
                  },
                  thead: {
                    borderStyle: 'solid',
                    borderWidth: '0px 0px 1px',
                    borderColor: 'rgb(128, 128, 128)',
                  },
                  th: {
                    ...hootTokens.text.tableheading,
                    textAlign: 'left',
                    margin: '0px',
                    padding: '16px 12px',
                  },
                  tbody: {
                    '& > :nth-child(2n+1)': {
                      backgroundColor: 'rgb(250, 250, 250)',
                    },
                  },
                  td: {
                    ...hootTokens.text.tablevalue,
                    verticalAlign: 'baseline',
                    textAlign: 'left',
                    color: 'rgb(0, 0, 0)',
                    borderStyle: 'solid',
                    borderWidth: '0px 0px 1px',
                    borderColor: 'rgb(242, 242, 242)',
                    margin: '0px',
                    padding: '16px 12px',
                  },
                }}
              >
                {htmlContent && <div dangerouslySetInnerHTML={{ __html: htmlContent }} />}
              </Box>
            </ViewState>
          </Card>
        </Grid>
      </PageLayout>
    </>
  );
};

const SkeletonContent = () => (
  <Stack gap={2}>
    {[...Array(2)].map((_, i) => (
      <Stack key={`skeleton-${i}`} gap={2} sx={{ padding: 1 }}>
        <Skeleton variant="text" sx={{ fontSize: '2rem', width: '50%' }} />
        <Skeleton variant="text" sx={{ fontSize: '1rem', width: '100%' }} />
        <Stack direction="row" alignItems="center" gap={2} sx={{ padding: 1 }}>
          <Skeleton variant="rectangular" width="25px" height="25px" />
          <Skeleton variant="text" sx={{ fontSize: '1rem', width: '100%' }} />
        </Stack>
        <Stack direction="row" alignItems="center" gap={2} sx={{ padding: 1 }}>
          <Skeleton variant="rectangular" width="25px" height="25px" />
          <Skeleton variant="text" sx={{ fontSize: '1rem', width: '100%' }} />
        </Stack>
      </Stack>
    ))}
  </Stack>
);

export default ScopeAndSequenceDocumentationTemplate;
