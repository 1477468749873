import { EnrolmentStatus } from '@hoot-reading/hoot-core/dist/enums/hfs/enrolment-status.enum';
import { LocationType } from '@hoot-reading/hoot-core/dist/enums/hfs/location-type.enum';
import { DistrictRepresentativeStatus } from '@hoot-reading/hoot-core/dist/enums/user/district-representative/district-representative-status.enum';
import axios from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '@hoot/config';
import { QueryKey } from '../queryKeys';

export interface Location {
  id: string;
  name: string;
  type: LocationType;
  shortId: string;
  districtName?: string;
}

export interface Enrolment {
  id: string;
  name: string;
  locationName: string;
  locationId: string;
  locationType: LocationType;
  shortId: string;
  status: EnrolmentStatus;
  friendlyId: string;
}

export interface DistrictRepresentativeDetailsResponse {
  districtName: string;
  status: DistrictRepresentativeStatus;
  firstName: string;
  lastName: string;
  email: string | null;
  phoneNumber: string | null;
  locations: Location[];
  enrolments: Enrolment[];
  isStandaloneSchool: boolean;
}

export default function useGetDistrictRepresentativeDetails(
  districtRepId: string,
  options?: Omit<UseQueryOptions<DistrictRepresentativeDetailsResponse>, 'queryKey' | 'queryFn'>,
) {
  return useQuery(
    [QueryKey.GetDistrictRepresentativeDetails, districtRepId],
    async () => {
      return (await axios.get<DistrictRepresentativeDetailsResponse>(`${config.apiUrl}/public/district-representative/${districtRepId}/details`))
        .data;
    },
    options,
  );
}
