import { FrontEndFacingScheduledLessonStatus } from '@hoot-reading/hoot-core/dist/enums/scheduled-lesson';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import SearchIcon from '@mui/icons-material/Search';
import { InputAdornment, Stack } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { capitalCase } from 'change-case';
import { DateTime } from 'luxon';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import useDistrictRepGetEnrolmentSnippet from '@hoot/hooks/api/district-rep/useDistrictRepresentativeGetEnrolmentSnippet';
import useSearchDistrictRepresentativeScheduleResponse, {
  DistrictRepresentativeScheduleQuery,
} from '@hoot/hooks/api/district-rep/useSearchDistrictRepresentativeSchedule';
import { useDebounce } from '@hoot/hooks/useDebounce';
import { OrderBy } from '@hoot/models/api/enums/queryEnums';
import { createFlashMessage } from '@hoot/redux/reducers/flashMessageSlice';
import { RootState, useAppDispatch } from '@hoot/redux/store';
import { routesDictionary } from '@hoot/routes/routesDictionary';
import SubHeader from '@hoot/ui/components/v2/SubHeader';
import { Button } from '@hoot/ui/components/v2/core/Button';
import Card from '@hoot/ui/components/v2/core/Card';
import { Chip } from '@hoot/ui/components/v2/core/Chip';
import { Icon } from '@hoot/ui/components/v2/core/Icon';
import Page from '@hoot/ui/components/v2/core/Page';
import { HeaderData, TableV2 } from '@hoot/ui/components/v2/core/Table';
import TextField from '@hoot/ui/components/v2/core/TextField';
import { useScrollToTop } from '@hoot/ui/context/ScrollToTopContext';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';
import useSearchDistrictRepresentativeSchoolsResponse, {
  DistrictRepresentativeSchoolsQuery,
} from '../../../../../hooks/api/district-rep/useSearchDistrictRepresentativeSchools';
import DistrictRepScheduleFiltersDialog from './DistrictRepScheduleFiltersDialog';

export interface ScheduleRow {
  time: string;
  name: React.ReactNode;
  duration: string;
  status: string;
  school: string;
  enrolmentId: string;
}

export interface DistrictRepresentativeSchedule {
  count: number;
  page: number;
  pageSize: number;
  schedule: ScheduleRow[];
}

const headers: HeaderData<ScheduleRow>[] = [
  { name: 'Time', property: 'time', isSortable: true },
  { name: 'Student Name', property: 'name', isSortable: true },
  { name: 'Duration', property: 'duration', isSortable: true },
  { name: 'Status', property: 'status', isSortable: true },
  { name: 'School', property: 'school', isSortable: true },
  { name: 'Enrolment ID', property: 'enrolmentId', isSortable: true },
];

const IGNORE_FILTERS = ['name', 'timeZone', 'page', 'pageSize', 'sortBy', 'orderBy'];
export type DistrictRepresentativeScheduleQueryKeys = keyof DistrictRepresentativeScheduleQuery;

function EnrolmentChipDisplay(props: {
  filterKey: keyof DistrictRepresentativeScheduleQuery;
  enrolmentId: string;
  query: DistrictRepresentativeScheduleQuery;
  setQuery: React.Dispatch<React.SetStateAction<DistrictRepresentativeScheduleQuery>>;
}) {
  const { enrolmentId, query, setQuery, filterKey } = props;

  const { data: enrolemnt } = useDistrictRepGetEnrolmentSnippet(enrolmentId, {
    enabled: !!enrolmentId,
  });

  const value = enrolemnt?.friendlyId ?? '';

  return (
    <Chip
      key={`${filterKey}-${value}`}
      label={`${capitalCase(filterKey)}: ${value}`}
      onDelete={() => {
        setQuery((prevState) => {
          const value = query[filterKey];
          return {
            ...prevState,
            page: 1,
            [filterKey]: Array.isArray(value) ? (value as any)?.filter((val: string) => val !== value) : undefined,
          };
        });
      }}
    />
  );
}

const FilterChips = (props: {
  badgesWithMultiSelectors: any[];
  query: DistrictRepresentativeScheduleQuery;
  setQuery: React.Dispatch<React.SetStateAction<DistrictRepresentativeScheduleQuery>>;
  districtRepresentativeId?: string;
}) => {
  const { badgesWithMultiSelectors, query, setQuery, districtRepresentativeId } = props;

  const schoolsQuery: DistrictRepresentativeSchoolsQuery = {
    page: 1,
    pageSize: 100,
    sortBy: 'name',
    orderBy: OrderBy.Asc,
  };

  const schools = useSearchDistrictRepresentativeSchoolsResponse(districtRepresentativeId!, schoolsQuery, {
    enabled: !!districtRepresentativeId,
    keepPreviousData: true,
  });

  return (
    <Grid container gap={2}>
      {badgesWithMultiSelectors?.map((badge, idx) => {
        const [filterKey, filterValue]: [DistrictRepresentativeScheduleQueryKeys, any] = badge;
        const key = filterKey;
        let value;

        if (filterKey === 'date') {
          const filterValueDay = DateTime.fromSQL(filterValue).toFormat('LL-dd-yyyy');
          const today = DateTime.now().toFormat('LL-dd-yyyy');
          if (filterValueDay === today) {
            value = 'Today';
          } else {
            value = DateTime.fromSQL(filterValue).toFormat('LL-dd-yyyy');
          }
        } else if (filterKey === 'time') {
          value = filterValue;
        } else if (filterKey === 'enrolmentId') {
          //Note: enrolment Id is saved in the database as the friendly id
          return <EnrolmentChipDisplay key={idx} filterKey={filterKey} enrolmentId={filterValue} query={query} setQuery={setQuery} />;
        } else if (filterKey === 'school') {
          value = schools.data?.schools?.find((school) => school.id === filterValue)?.name;
        } else {
          value = capitalCase(filterValue);
        }

        return (
          <Chip
            key={`${key}-${value}`}
            label={`${capitalCase(key)}: ${value}`}
            onDelete={() => {
              setQuery((prevState) => {
                const value = query[filterKey];
                return {
                  ...prevState,
                  page: 1,
                  [filterKey]: Array.isArray(value) ? (value as any)?.filter((val: string) => val !== filterValue) : undefined,
                };
              });
            }}
          />
        );
      })}
    </Grid>
  );
};

export function DistrictRepScheduleDayDetails() {
  const districtRepresentativeId = useSelector((state: RootState) => state.profile.profile?.id);
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const { scrollToTop } = useScrollToTop();

  const [searchText, setSearchText] = useState<string>(searchParams.get('name') ?? '');
  const debouncedSearchText = useDebounce(searchText, 1000);

  const enrolmentFilterParam = searchParams.get('enrolmentId');
  const dateFilterParam = searchParams.get('date');
  const timeFilterParam = searchParams.get('time');

  useEffect(() => {
    scrollToTop();
  }, [scrollToTop]);

  const subHeaderTitle = `${dateFilterParam ? DateTime.fromISO(dateFilterParam).toFormat('LLLL d, yyyy') : ''} ${location.state?.fromTimeSlot || timeFilterParam ? `${timeFilterParam ? `@ ${timeFilterParam}` : ''}` : ''}`;

  // if no date filter was provided but an enrolment was, don't apply a single date filter. Otherwise, default to today
  let queryDate = dateFilterParam;
  if (!queryDate && !enrolmentFilterParam) {
    queryDate = DateTime.now().toSQLDate();
  }

  const [query, setQuery] = useState<DistrictRepresentativeScheduleQuery>({
    name: searchParams.get('name') ?? '',
    page: parseInt(searchParams.get('page') ?? '1'),
    pageSize: parseInt(searchParams.get('pageSize') ?? '10'),
    sortBy: searchParams.get('sortBy') ?? 'time',
    orderBy: OrderBy.Asc,
    date: queryDate ?? undefined,
    timeZone: moment.tz.guess(),
    status: (searchParams.get('status') as FrontEndFacingScheduledLessonStatus) ?? undefined,
    time: timeFilterParam ?? undefined,
    school: searchParams.get('school') ?? undefined,
    enrolmentId: enrolmentFilterParam ?? undefined,
  });
  const [data, setData] = useState<DistrictRepresentativeSchedule>();
  const [showDialog, setShowDialog] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const params = Object.entries(query)
      .filter(([a, b]) => !!b && a !== 'timeZone')
      .reduce((a, [key, val]) => ({ ...a, [key]: val }), {}) as Record<string, string>;
    setSearchParams(params, { replace: true, state: { fromTimeSlot: location.state.fromTimeSlot } });
  }, [query, setSearchParams, location.state.fromTimeSlot]);

  const { isLoading, refetch } = useSearchDistrictRepresentativeScheduleResponse(districtRepresentativeId!, query, {
    onSuccess: (data) => {
      const schedule = data.schedule.map(
        (schedule): ScheduleRow => ({
          time: schedule.time,
          name: (
            <p
              onClick={() => navigate(routesDictionary.students.student.schedule.url(schedule.id))}
              style={{ cursor: 'pointer', textDecoration: 'underline' }}
            >
              {schedule.name}
            </p>
          ),
          duration: schedule.duration,
          status: schedule.status,
          school: schedule.school,
          enrolmentId: schedule.enrolmentId,
        }),
      );

      const scheduleResponse = {
        count: data.count,
        page: data.page,
        pageSize: data.pageSize,
        schedule: schedule,
      };
      setData(scheduleResponse);
    },
  });

  const badges = () => {
    return Object.entries(query).filter(([k, v]) => !IGNORE_FILTERS.includes(k) && v !== undefined && (Array.isArray(v) ? v.length !== 0 : true)) as [
      DistrictRepresentativeScheduleQueryKeys,
      any,
    ][];
  };

  const badgesWithMultiSelectors = badges().reduce((acc: any[], badge) => {
    if (Array.isArray(badge[1])) return [...acc, ...badge[1].map((val: any) => [badge[0], val])];
    return [...acc, badge];
  }, []);

  useEffect(() => {
    setQuery((current) => ({ ...current, page: 1, name: debouncedSearchText }));
  }, [debouncedSearchText]);

  const clearInput = () => {
    setSearchText('');
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  const handleRefreshBtnClick = () => {
    refetch().then(() => {
      dispatch(createFlashMessage({ message: 'Successfully Refreshed', variant: 'dark' }));
    });
  };

  return (
    <>
      <SubHeader
        title={{
          label: subHeaderTitle,
          isPII: false,
          isLoading,
        }}
        backButton={{
          show: true,
          navigateBackPath: location.state?.previousPath ? location.state?.previousPath : undefined,
        }}
        breadcrumbs={['Schedule', `${location.state?.fromTimeSlot ? 'Time slot' : 'Day'}`]}
      />

      <Page pageTitle="District Representative Schedule | Hoot Reading">
        <Stack>
          <Stack>
            <Card title="" isLoading={isLoading} sx={{ overflowX: 'scroll' }}>
              <Stack direction="row" alignItems="center" alignSelf="center" gap={2}>
                <TextField
                  clearable
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon sx={{ color: hootTokens.palette.black }} />
                      </InputAdornment>
                    ),
                  }}
                  label="Student Search"
                  onClearButtonClicked={clearInput}
                  variant="outlined"
                  value={searchText}
                  onChange={handleInputChange}
                />

                <Button
                  color="primary"
                  onFocusVisible={() => {}}
                  variant="contained"
                  size="medium"
                  startIcon={
                    <InputAdornment position="start">
                      <FilterAltOutlinedIcon sx={{ color: hootTokens.palette.white }} />
                    </InputAdornment>
                  }
                  sx={{ py: '16px' }}
                  onClick={() => setShowDialog(true)}
                >
                  Filter
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  size="medium"
                  startIcon={
                    <InputAdornment position="start">
                      <Icon name="redo" htmlColor="white" sx={{ ml: '8px' }} />
                    </InputAdornment>
                  }
                  sx={{ py: '16px' }}
                  onClick={handleRefreshBtnClick}
                  disabled={isLoading}
                >
                  Refresh
                </Button>
              </Stack>

              <Stack sx={{ marginTop: '20px' }}>
                <FilterChips
                  badgesWithMultiSelectors={badgesWithMultiSelectors}
                  query={query}
                  setQuery={setQuery}
                  districtRepresentativeId={districtRepresentativeId}
                />
              </Stack>

              <Stack marginTop="16px">
                <TableV2
                  allowRowsPerPage
                  isPaginated
                  isSortable
                  count={data?.count ?? 0}
                  data={data?.schedule ?? []}
                  headers={headers}
                  onPageChange={(event, page) => {
                    setQuery((prevState) => ({ ...prevState, page: page + 1 }));
                  }}
                  onRowsPerPageChange={(event) => {
                    setQuery((prevState) => ({ ...prevState, page: 1, pageSize: +event.target.value }));
                  }}
                  onSortBy={(val: any) => {
                    setQuery((prevState) => ({
                      ...prevState,
                      page: 1,
                      sortBy: val,
                      orderBy: prevState.orderBy === OrderBy.Asc ? OrderBy.Desc : OrderBy.Asc,
                    }));
                  }}
                  page={data?.page ? data.page - 1 : 0}
                  sortOrder={query.orderBy}
                  sortBy={query.sortBy as any}
                  rowsPerPage={query.pageSize}
                />
              </Stack>
            </Card>
            <Grid container size={8}></Grid>
          </Stack>
          {showDialog ? (
            <DistrictRepScheduleFiltersDialog show={showDialog} onDismiss={() => setShowDialog(false)} query={query} onChange={setQuery} />
          ) : null}
        </Stack>
      </Page>
    </>
  );
}
