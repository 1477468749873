import { ScheduledLessonType } from '@hoot-reading/hoot-core/dist/enums/scheduled-lesson';
import { DialogActions, DialogContent, Stack } from '@mui/material';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { BookSearch } from '@hoot/events/interfaces/book-search';
import useSearchLibrary, { QueryLibraryV2 } from '@hoot/hooks/api/library/useSearchLibrary';
import { InstructionalFocus } from '@hoot/models/api/enums/instructional-focus';
import { LessonReviewFocus } from '@hoot/models/api/enums/lesson-review-enums';
import { ShelfType } from '@hoot/models/api/enums/shelf-type-enum';
import { GenericPaginatedResponse } from '@hoot/models/api/pagination';
import { DEFAULT_PAGE } from '@hoot/redux/reducers/librarySlice';
import { Button } from '@hoot/ui/components/v2/core/Button';
import { Checkbox } from '@hoot/ui/components/v2/core/Checkbox';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';
import { useLessonReviewWizardContext } from '@hoot/ui/pages/v2/teacher/my-students/student-details/lesson-reviews/lesson-review-wizard/LessonReviewContextProvider';
import LessonReviewWarningCard from '@hoot/ui/pages/v2/teacher/my-students/student-details/lesson-reviews/lesson-review-wizard/LessonReviewWarningCard';

const PAGE_SIZE = 100;

interface LessonReviewStructureForm {
  assessment: boolean;
  instruction: boolean;
  rapportBuilding: boolean;
}

const assessmentFocusType: Record<string, LessonReviewFocus> = {
  [ScheduledLessonType.Assessment]: LessonReviewFocus.HootReadingAssessment,
  [ScheduledLessonType.ProgressMonitoring]: LessonReviewFocus.ProgressMonitoringAssessment,
};

const assessmentTile: Record<string, string> = {
  [ScheduledLessonType.Assessment]: 'Hoot Reading Assessment',
  [ScheduledLessonType.ProgressMonitoring]: 'Progress Monitoring Assessment',
};

const CheckBoxLabelNode = (props: { title: string; text: string }) => {
  const { title, text } = props;
  return (
    <Stack sx={{ pl: 1 }} alignContent="center">
      <HootTypography isPII={false} variant="titlemedium">
        {title}
      </HootTypography>
      <HootTypography isPII={false} variant="bodymedium">
        {text}
      </HootTypography>
    </Stack>
  );
};

const LessonReviewLessonStructureStep = () => {
  const {
    lessonToReview,
    lessonStructureState,
    instructionalFocusOptions,
    actions: { goToNextStep, goToPreviousStep, setLessonStructure, attachInstructionalFocusData, attachInstructionalFocusOptions },
  } = useLessonReviewWizardContext();

  const [isInstructionDisabled, setIsInstructionDisabled] = React.useState(!lessonStructureState?.instruction);
  const booksUsedInRapportBuilding = instructionalFocusOptions
    .find((focusOption) => focusOption.focus === LessonReviewFocus.RelationshipAndRapportBuilding)
    ?.focusBooks.map((books) => books.bookId);

  const { control, watch } = useForm<LessonReviewStructureForm>({
    defaultValues: {
      assessment: lessonStructureState?.assessment ?? false,
      instruction: lessonStructureState?.instruction ?? false,
      rapportBuilding: lessonStructureState?.rapportBuilding ?? false,
    },
  });

  const { assessment, instruction, rapportBuilding } = watch();
  const allSelected = assessment && instruction && rapportBuilding;
  const canGoToNextStep = (assessment || instruction || rapportBuilding) && !allSelected;
  const isAssessmentLesson =
    lessonToReview?.lessonType && [ScheduledLessonType.Assessment, ScheduledLessonType.ProgressMonitoring].includes(lessonToReview.lessonType);
  const assessmentTitle =
    lessonToReview?.lessonType && [ScheduledLessonType.Assessment, ScheduledLessonType.ProgressMonitoring].includes(lessonToReview.lessonType)
      ? assessmentTile[lessonToReview?.lessonType]
      : '';
  const isAssessmentReviewOnly = assessment && !instruction && !rapportBuilding;

  const libraryQuery: QueryLibraryV2 = {
    shelfType: ShelfType.AllBooks,
    page: DEFAULT_PAGE,
    pageSize: PAGE_SIZE,
    instructionalFocusIds: undefined,
    excludedInstructionalFocusIds: [
      InstructionalFocus.FormativeAssessmentTextReading,
      InstructionalFocus.FormativeAssessmentEarlyWordReading,
      InstructionalFocus.FormativeAssessmentComplexWordReading,
      InstructionalFocus.FormativeAssessmentPreWordReading,
    ],
    scheduledLessonId: lessonToReview?.lessonId,
    excludeBookIds: booksUsedInRapportBuilding,
  };

  useSearchLibrary(libraryQuery, {
    enabled: !!lessonToReview?.lessonId,
    retry: false,
    onSuccess: (response: GenericPaginatedResponse<BookSearch>) => {
      if (response.data.length > 0) {
        setIsInstructionDisabled(false);
      }
    },
  });

  useEffect(() => {
    setLessonStructure({ assessment, instruction, rapportBuilding });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assessment, instruction, rapportBuilding]);

  const submitAndGoToNextStep = () => {
    let localFocusOptions = _.clone(instructionalFocusOptions);
    const updatedAssessmentFocusOption = {
      id: assessmentFocusType[lessonToReview?.lessonType!],
      focus: assessmentFocusType[lessonToReview?.lessonType!],
      focusUnit: undefined,
      focusSkill: undefined,
      focusBooks: [],
    };
    if (assessment && isAssessmentLesson && isAssessmentReviewOnly) {
      attachInstructionalFocusData({
        // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
        primaryFocus: assessmentFocusType[lessonToReview?.lessonType!],
        secondaryFocus: undefined,
        primaryFocusBooks: [],
        secondaryFocusBooks: [],
        primaryFocusUnit: undefined,
        secondaryFocusUnit: undefined,
        primaryFocusSkill: undefined,
        secondaryFocusSkill: undefined,
        noResourcesUsed: true,
        lessonReviewVersion: 2,
      });
      goToNextStep();
      return;
    }

    if (assessment && isAssessmentLesson && !isAssessmentReviewOnly) {
      const nonAssessmentFocuses = instructionalFocusOptions.filter(
        (option) => option.focus && ![LessonReviewFocus.HootReadingAssessment, LessonReviewFocus.ProgressMonitoringAssessment].includes(option.focus),
      );
      localFocusOptions = [...nonAssessmentFocuses, updatedAssessmentFocusOption];
    }

    if (!assessment) {
      localFocusOptions = localFocusOptions.filter(
        (option) => option.focus && ![LessonReviewFocus.HootReadingAssessment, LessonReviewFocus.ProgressMonitoringAssessment].includes(option.focus),
      );
    }

    if (!instruction) {
      localFocusOptions = localFocusOptions.filter(
        (option) =>
          option.focus &&
          ![
            LessonReviewFocus.EarlyWordReading,
            LessonReviewFocus.PreWordReading,
            LessonReviewFocus.ComplexWordReading,
            LessonReviewFocus.TextReading,
          ].includes(option.focus),
      );
    }

    if (!rapportBuilding) {
      localFocusOptions = localFocusOptions.filter(
        (option) => option.focus && ![LessonReviewFocus.RelationshipAndRapportBuilding].includes(option.focus),
      );
    }

    attachInstructionalFocusOptions(localFocusOptions);
    goToNextStep();
  };

  return (
    <>
      <DialogContent>
        <Stack gap={2} paddingBottom={1}>
          <HootTypography isPII={false} variant="titlemedium">
            Lesson Structure
          </HootTypography>
          <Stack gap={1}>
            <HootTypography isPII={false} variant="bodymedium">
              What was done in the lesson time with the student? (Select up to 2 options)
            </HootTypography>
            <Stack direction={'column'} gap={'16px'}>
              {allSelected ? (
                <HootTypography isPII={false} variant="bodymedium" color="error">
                  This should not exceed 2 selections
                </HootTypography>
              ) : null}
              {isAssessmentLesson ? (
                <Controller
                  name="assessment"
                  control={control}
                  render={({ field }) => (
                    <Checkbox
                      {...field}
                      checked={field.value || false}
                      onChange={(e) => field.onChange(e.target.checked)}
                      label={<CheckBoxLabelNode title={assessmentTitle} text={`Conducted part or all of a ${assessmentTitle} with the student.`} />}
                      error={allSelected}
                    />
                  )}
                />
              ) : null}

              <Controller
                name="instruction"
                control={control}
                render={({ field }) => (
                  <Checkbox
                    {...field}
                    checked={field.value || false}
                    disabled={isInstructionDisabled}
                    onChange={(e) => field.onChange(e.target.checked)}
                    label={<CheckBoxLabelNode title="Instruction" text="Focused time on reading instruction with the student." />}
                    error={allSelected}
                  />
                )}
              />
              <Controller
                name="rapportBuilding"
                control={control}
                render={({ field }) => (
                  <Checkbox
                    {...field}
                    checked={field.value || false}
                    onChange={(e) => field.onChange(e.target.checked)}
                    label={
                      <CheckBoxLabelNode
                        title="Rapport Building"
                        text="Spent more than 5 minutes focused on building a positive connection with the student."
                      />
                    }
                    error={allSelected}
                  />
                )}
              />
            </Stack>
          </Stack>
          <Stack gap={2} flex={1} minHeight="327px">
            {allSelected ? (
              <LessonReviewWarningCard
                text={
                  <HootTypography isPII={false} variant="bodylarge">
                    Too many focuses
                  </HootTypography>
                }
              />
            ) : null}
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={goToPreviousStep}>
          Go Back
        </Button>
        <Button variant="contained" onClick={submitAndGoToNextStep} disabled={!canGoToNextStep}>
          Next
        </Button>
      </DialogActions>
    </>
  );
};

export default LessonReviewLessonStructureStep;
